import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import IconsPagination from '../icons-pagination';
import SearchBar from '../search-bar';
import IconsList from '../../icons-list';

const SelectIconsModal = ({
    storeID,
    storeIcons = [],
    closeModal = () => { },
    setCurrentStoreIcons = () => { },
}) => {
    const pageSize = 20;
    const [icons, setIcons] = useState([]);
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [nbPages, setNbPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalResults, setTotalResults] = useState(0);

    const [selectedIcons, setSelectedIcons] = useState([]);

    useEffect(() => {
        fetchIcons();
    }, [currentPage]);

    const fetchIcons = async (pageIndex = currentPage) => {
        setLoading(true);
        let url;
        url = 'resources/icons/?query=' + query + '&page=' + pageIndex + '&page_size=' + pageSize;
        const res = await getDataFromAPI(url);
        const nbResults = res.data['count'];
        const pageResults = res.data['results'];
        const filteredPageResults = pageResults.filter((icon) => !storeIcons.some((i) => i.id === icon.id))
        setIcons(filteredPageResults);
        setTotalResults(nbResults);
        setNbPages(Math.ceil(nbResults / pageSize));
        setLoading(false);
    }

    const handleClickIcon = (targetIcon) => {
        if (selectedIcons.some((icon) => icon.id === targetIcon.id)) {
            setSelectedIcons(selectedIcons.filter((icon) => icon.id !== targetIcon.id));
        } else {
            setSelectedIcons([...selectedIcons, targetIcon]);
        }
    };

    const handleAddIconsToStore = async () => {
        if (selectedIcons.length > 0) {
            try {
                const url = 'resources/add-icons-to-store/';

                const res = await updateDataAPI('POST', url, {
                    iconIDs: selectedIcons.map((icon) => icon.id),
                    storeID,
                });

                setCurrentStoreIcons((prev) => ([
                    ...prev,
                    ...selectedIcons,
                ]));
                setSelectedIcons([]);
                closeModal();
            } catch(err) {
                console.error(`An error occured when adding icons to store: ${err.message}`);
            };
        };
    }

    return (
        <Modal show={true} size="lg" onHide={closeModal} className="select-icons-modal">
            <Modal.Header>Icons list</Modal.Header>
            <Modal.Body>
                <Container>
                    <SearchBar
                        query={query}
                        handleChangeQuery={(e) => setQuery(e.target.value.toLowerCase())}
                        handleSearchByQuery={(e) => {
                            e.preventDefault();
                            setCurrentPage(1);
                            fetchIcons(1);
                        }}
                    />
                    <span className="mb-2">{totalResults} results</span>
                    <Row className="mb-2">
                        <IconsList
                            icons={icons}
                            selectedIcons={selectedIcons}
                            showDeleteBtn={false}
                            handleClickIcon={handleClickIcon}
                        />
                    </Row>
                    {nbPages > 0 && (
                        <IconsPagination currentPage={currentPage} setCurrentPage={setCurrentPage} nbPages={nbPages} />
                    )}
                    {selectedIcons.length > 0 && (
                        <div className="d-flex flex-column gap-2">
                            <Button
                                variant="info"
                                onClick={handleAddIconsToStore}
                            >
                                Add selected icons to current store
                            </Button>
                        </div>
                    )}

                    {loading && (
                        <div className="loading-container">
                            <Spinner animation="border" variant="dark" />
                        </div>
                    )}
                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default SelectIconsModal;