import { useEffect, useRef, useState } from 'react';
import Constant from '../../../Constant';

const DEFAULT_TEXT_LINKED_ZONE = {
    id: null,
    idType: '',
    activityId: '',
    pageIndex: 0,
    x: null,
    y: null,
    type: 4,
    worksheet: '',
    width: null,
    height: null,
    rotate: 0,
    textAttribute: null,
    wordList: [],
    isShow: true,
    opacity: 1,
    moduleId: '',
    isGroup: false,
    resourceIds: [],
    backgroundColor: '#FFFFFF',
    customZoneID: null,
}

const TextZone = ({ zone, isText, isThumbnail, size, text }) => {
    const textAttribute = zone?.textAttribute ?? DEFAULT_TEXT_LINKED_ZONE;

    const [fontSize, setFontSize] = useState(textAttribute?.fontSize);
    const contentRef = useRef(null);
    const scaleRatio = isThumbnail ? Constant.LITE_CANVAS_THUMBNAIL_SCALE : ((size === 'medium' ? 1 : 1.5) * Constant.LITE_CANVAS_SCALE);

    useEffect(() => {
        const contentElement = contentRef.current;
        const containerWidth = contentElement.offsetWidth;
        const containerHeight = contentElement.offsetHeight;
        const contentWidth = contentElement.scrollWidth;
        const contentHeight = contentElement.scrollHeight;

        if (contentWidth > containerWidth || contentHeight > containerHeight) {
            const newFontSize = Math.min(
                containerWidth / contentWidth,
                containerHeight / contentHeight
            ) * fontSize;
            setFontSize(newFontSize);
        }
    }, [fontSize]);

    return (
        <div className="text-zone"
            ref={contentRef}
            style={{
                width: `${zone.width * scaleRatio}px`,
                height: `${zone.height * scaleRatio}px`,
                transform: `translate(${zone.x * scaleRatio}px, ${zone.y * scaleRatio}px) rotate(${zone.rotate}deg)`,
                fontSize: `${isText ? (zone.textAttribute.fontSize * scaleRatio) : fontSize}px`,
                touchAction: 'pan-x pan-y pinch-zoom',
                textAlign: textAttribute.align,
                color: '#212121',
                fontFamily: textAttribute.fontFamily,
                fontStyle: textAttribute.isItalic ? 'italic' : 'normal',
                fontWeight: textAttribute.isBold ? 'bold' : null,
            }}
        >
            {text}
        </div>
    )
}

export default TextZone;