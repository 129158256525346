import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import Constant from '../../../Constant';
import { updateDataAPI } from '../../../utils/query';
import { Col, InputGroup, Row } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import MultiValues from '../../../forms/multi-values';

import './styles.scss';
import { hasPermission } from '../../../utils/auth';
import { useSelector } from 'react-redux';

const ManualTaskModal = ({ task = null, closeModal, taskFlowSteps }) => {
    const { activityPermissions } = useSelector((state) => state.permissions);

    const [title, setTitle] = useState(task?.title);
    const [description, setDescription] = useState(task?.description);
    const [assignees, setAssignees] = useState(task?.assignees.join(','));
    const [advanced, setAdvanced] = useState(task ? task.advanced : false);
    const [priority, setPriority] = useState(task ? task.priority : 'normal');
    const [taskDifficulty, setTaskDifficulty] = useState(task ? task.taskDifficulty : null);
    const [usedHours, setUsedHours] = useState(task?.usedHours);

    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const update = async () => {
        const data = {
            title,
            description: description !== undefined ? description : null,
            advanced,
            priority: priority !== '' ? priority : null,
            taskDifficulty: taskDifficulty ? taskDifficulty : null,
            usedHours: usedHours !== undefined ? usedHours : null,
        };
        if (assignees !== undefined && assignees.length > 0) {
            data['assignees'] = assignees.split(',');
        }
        const res = await updateDataAPI('PATCH', 'product-dev/tasks/' + task.id + '/', data);
        if (res.status === 200) {
            closeModal('update');
        }
    }

    const create = async () => {
        if (title) {
            const data = {
                title,
                description: description !== undefined ? description : null,
                step: taskFlowSteps[0].id,
                advanced,
                priority: priority !== undefined ? priority : null,
                taskDifficulty: taskDifficulty ? taskDifficulty : null,
                usedHours: usedHours !== undefined ? usedHours : null,
            };
            if (assignees !== undefined && assignees.length > 0) {
                data['assignees'] = assignees.split(',');
            }
            const res = await updateDataAPI('POST', 'product-dev/tasks/', data);
            if (res.status === 201) {
                closeModal('create');
            }
        } else {
            window.alert('Điền các trường bắt buộc');
        }
    }

    const deleteTask = async () => {
        if (window.confirm('Bạn chắc chắn muốn xóa task này chứ?')) {
            await updateDataAPI('DELETE', 'product-dev/tasks/' + task.id + '/');
            closeModal('delete');
        }
    }

    return (
        <Modal show={true} backdrop="static" keyboard={false} onHide={() => closeModal('hide')} size="xl"
            className="manual-task-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    {task ? 'Edit' : 'New'} Task
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label><strong>Title*</strong></Form.Label>
                                <Form.Control as="textarea" rows={4} value={title} onChange={e => setTitle(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="taskAssignees">
                                <Form.Label>Assignees</Form.Label>
                                <MultiValues value={assignees} setValue={val => setAssignees(val)} choicesDataAPI="product-dev/staffs/?roles=product-collector,idea-manager,designer,product-informator"
                                    labelField="name" valueField="name" />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <ReactQuill theme="snow" value={description} onChange={setDescription} />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="taskUsedHours">
                                <Form.Label>How long does it take for this task?</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control value={usedHours} onChange={e => setUsedHours(e.target.value)} type="number" />
                                    <InputGroup.Text>hours</InputGroup.Text>
                                </InputGroup>
                                <Form.Text>Hours</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="taskAdvanced">
                                <Form.Check type="checkbox" label="Advanced" checked={advanced} onChange={e => setAdvanced(e.target.checked)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Task Priority</Form.Label>
                                <MultiValues value={priority} setValue={val => setPriority(val)} isMulti={false} defaultChoices={Constant.TASK_PRIORITIES} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Task Difficulty</Form.Label>
                                <Form.Select
                                    onChange={(e) => setTaskDifficulty(e.target.value)}
                                    value={taskDifficulty}
                                >
                                    <option value={null}></option>
                                    <option value="easy">easy</option>
                                    <option value="medium">medium</option>
                                    <option value="hard">hard</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {hasPermission(userRoles, activityPermissions['DELETE_PRODUCT_IDEA']) && task && (
                    <Button variant="danger" onClick={deleteTask}>Delete</Button>
                )}
                {hasPermission(userRoles, activityPermissions['UPDATE_PRODUCT_IDEA']) && task && (
                    <Button variant="primary" onClick={update}>Update</Button>
                )}
                {hasPermission(userRoles, activityPermissions['CREATE_PRODUCT_IDEA']) && task === null && (
                    <Button variant="primary" onClick={create} disabled={!title}>Create</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default ManualTaskModal;