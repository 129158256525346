import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import UploadFile from '../../../content-studio/upload-file';

import './styles.scss';

const DataSetIconsManagementModal = ({dataSet, updateIcons, hideModal}) => {
    const [icons, setIcons] = useState({});
    const [uploadingImage, setUploadingImage] = useState(false);

    useEffect(() => {
        const icons = {};

        for (let i = 0; i < dataSet.topics.length; i++) {
            if ('icon' in dataSet.topics[i]) {
                icons[(i + 1).toString()] = dataSet.topics[i]['icon'];
            }
        }

        setIcons(icons);
    }, []);

    return (
        <Modal show={true} onHide={hideModal} size="xl" className="data-set-icons-management-modal">
            <Modal.Header closeButton>
                <Modal.Title>DataSet Icons Management</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={8}>
                        <div className="icons-management">
                            {Object.keys(icons).map((iconIndex) => (
                                <div key={iconIndex}>
                                    <img src={icons[iconIndex]} />
                                    <div className="icon-name">{iconIndex}</div>
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col md={4}>
                        <div>
                            <UploadFile
                                id={dataSet.id}
                                setLoading={setUploadingImage}
                                uploadUrl={'resources/upload-data-set-icons/'}
                                allowedFileExtentions={['png', 'jpg', 'jpeg']}
                                fieldName={'temp'}
                                uploadedFileName={null}
                                setUploadedFileName={data => {
                                    const clonedIcons = {...icons};
                                    Object.keys(data).forEach(index => {
                                        clonedIcons[index] = data[index];
                                    });
                                    setIcons(clonedIcons);
                                }}
                                multiple={true}
                            />
                            {uploadingImage && (
                                <div>Uploading...</div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => updateIcons(icons)}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DataSetIconsManagementModal;