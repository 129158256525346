import { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { getDataFromAPI } from '../../../../utils/query';

import './styles.scss';
import TemplateList from '../template-list';
import AddTemplateAsVersion from './add-template-as-version';
import VersionInfoModal from './version-info-modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { updateDataAPI } from '../../../../utils/query';

const TemplateVersionsModal = ({ originalTemplate, ideaTemplateIDs = [], customID, hideModal, appliedVersionToAllPages = true }) => {
    const [globalVersions, setGlobalVersions] = useState([]);
    const [storeVersions, setStoreVersions] = useState([]);
    const [ideaVersions, setIdeaVersions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showVersionInfoModal, setShowVersionInfoModal] = useState(false);
    const [versionInInfoModal, setVersionInInfoModal] = useState(null);
    const [selectedVersions, setSelectedVersions] = useState([]);

    useEffect(() => {
        fetchVersions();
    }, []);

    const fetchVersions = async () => {
        setLoading(true);
        let originalVersionID;
        if (originalTemplate.originalVersionTemplate) {
            // if this template is not original, get the original version id
            originalVersionID = originalTemplate.originalVersionTemplate;
        } else {
            // otherwise, the template is an original template
            originalVersionID = parseInt(originalTemplate.id);
        }

        const resVersions = await getDataFromAPI('auto-content/templates/?page_size=1000&originalVersionID=' + originalVersionID);
        let versions = resVersions.data['results'];
        versions.sort((a, b) => {
            if (a.originalVersionTemplate === null) {
                return -1;
            } else if (b.originalVersionTemplate === null) {
                return 1;
            }
            return 0;
        });

        setGlobalVersions(versions.filter(version => (version.ideaVersion === null || version.ideaVersion === '') && version.store === null));
        setStoreVersions(versions.filter(version => (version.ideaVersion === null || version.ideaVersion === '') && version.store !== null));
        setIdeaVersions(versions.filter(version => version.ideaVersion !== null && version.ideaVersion !== ''));
        setLoading(false);
    }

    const updateVersion = async () => {
        if (appliedVersionToAllPages && !window.confirm('This operation will update all pages using this template. Are you sure?')) return;

        setLoading(true);
        await updateDataAPI('POST', 'auto-content/update-multi-idea-templates/', {
            ideaTemplateIDs,
            changes: [{
                field: 'template',
                value: selectedVersions[0].id,
            }]
        });
        setLoading(false);
        hideModal('refresh', selectedVersions[0]);
    }

    return (
        <>
            <Modal show={true} onHide={() => hideModal('hide')} size="xl" className="template-versions-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Template {originalTemplate.id}. {originalTemplate.name}'s versions
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="globalVersions" id="tabs-template-versions">
                        <Tab eventKey="globalVersions" title="Global versions">
                            {globalVersions.length > 0 && (
                                <TemplateList
                                    templates={globalVersions}
                                    setTemplates={setGlobalVersions}
                                    showTemplateActions={true}
                                    mode="listVersions"
                                    selectedTemplates={selectedVersions}
                                    setSelectedTemplates={setSelectedVersions}
                                    editVersionInfo={version => {
                                        setShowVersionInfoModal(true);
                                        setVersionInInfoModal(version);
                                    }}
                                />
                            )}
                        </Tab>
                        <Tab eventKey="storeVersions" title="Store versions">
                            {storeVersions.length > 0 && (
                                <TemplateList
                                    templates={storeVersions}
                                    setTemplates={setStoreVersions}
                                    showTemplateActions={true}
                                    mode="listVersions"
                                    selectedTemplates={selectedVersions}
                                    setSelectedTemplates={setSelectedVersions}
                                    editVersionInfo={version => {
                                        setShowVersionInfoModal(true);
                                        setVersionInInfoModal(version);
                                    }}
                                />
                            )}
                        </Tab>
                        <Tab eventKey="ideaVersions" title="Idea versions">
                            {ideaVersions.length > 0 && (
                                <TemplateList
                                    templates={ideaVersions}
                                    setTemplates={setIdeaVersions}
                                    showTemplateActions={true}
                                    mode="listVersions"
                                    selectedTemplates={selectedVersions}
                                    setSelectedTemplates={setSelectedVersions}
                                    editVersionInfo={version => {
                                        setShowVersionInfoModal(true);
                                        setVersionInInfoModal(version);
                                    }}
                                />
                            )}
                        </Tab>
                    </Tabs>
                    <AddTemplateAsVersion
                        customID={customID}
                        originalTemplate={originalTemplate}
                        setLoadingModal={setLoading}
                        refreshVersionList={async () => {
                            await fetchVersions();
                        }} />
                </Modal.Body>
                <Modal.Footer>
                    {selectedVersions.length === 1 && ideaTemplateIDs.length > 0 && (
                        <Button onClick={updateVersion}>Update</Button>
                    )}
                </Modal.Footer>

                {loading && (
                    <div className="loading-container">
                        <Spinner animation="border" variant="light" />
                    </div>
                )}
            </Modal>

            {showVersionInfoModal && (
                <VersionInfoModal
                    originalTemplate={originalTemplate}
                    version={versionInInfoModal}
                    hideModal={async (action, data = null) => {
                        if (action === 'open_template_iframe') {
                            hideModal(action, data);
                        }
                        if (action === 'update') {
                            await fetchVersions();
                        }
                        setShowVersionInfoModal(false);
                        setVersionInInfoModal(null);
                    }} />
            )}
        </>
    );
}

export default TemplateVersionsModal;