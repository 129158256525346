import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import Constant from '../../../../../Constant';
import { getDataFromAPI, updateDataAPI } from '../../../../../utils/query';
import { useEffect, useState } from 'react';

const VersionInfoModal = ({originalTemplate, version, hideModal}) => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(version?.versionName);
    const [store, setStore] = useState(version?.store);
    const [ideaVersion, setIdeaVersion] = useState(version?.ideaVersion);
    const [allStores, setAllStores] = useState([]);

    useEffect(() => {
        fetchStores();
    }, []);

    const save = async() => {
        if (version === null) {
            setLoading(true);
            try {
                const staffID = localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID);
                const newTemplateRes = await updateDataAPI('POST', 'auto-content/duplicate-template/?templateID=' + originalTemplate.id + "&staffID=" + staffID);
                const res = await updateDataAPI('PATCH', 'auto-content/templates/' + newTemplateRes.data['id'] + '/', {
                    originalVersionTemplate: originalTemplate.id,
                    versionName: name,
                    ideaVersion: ideaVersion,
                    answerSheetForm: originalTemplate.answerSheetForm,
                    store,
                });
                hideModal('open_template_iframe', res.data);
            } catch (e) {
                window.alert(e);
            }
            setLoading(false);
        } else {
            setLoading(true);
            await updateDataAPI('PATCH', 'auto-content/templates/' + version.id + '/', {
                originalVersionTemplate: originalTemplate.id,
                answerSheetForm: originalTemplate.answerSheetForm,
                versionName: name,
                ideaVersion: ideaVersion,
                store,
            });
            hideModal('update', {id: version.id, name});
            setLoading(false);
        }
    }

    const fetchStores = async () => {
        const res = await getDataFromAPI('stores/?active=true');
        setAllStores(res.data);
    }

    return (
        <Modal show={true} onHide={() => hideModal('hide')} className="version-info-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    {version === null ? 'New Version' : 'Edit Version'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="form-group mb-3">
                        <Form.Label><strong>Name*</strong></Form.Label>
                        <Form.Control
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="form-group mb-3">
                        <Form.Label>Store</Form.Label>
                        <Form.Select
                            onChange={e => setStore(e.target.value)}
                            value={store}
                        >
                            <option></option>
                            {allStores.map(store => (
                                <option key={store.id} value={store.id}>Store {store.id}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Idea</Form.Label>
                        <Form.Control
                            value={ideaVersion}
                            onChange={e => setIdeaVersion(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={save}>Save</Button>
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    )
}

export default VersionInfoModal;