import { useEffect, useState } from 'react';
import './styles.scss';
import { Badge } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

import Moment from 'react-moment';
import { useSelector } from 'react-redux';

const TaskStatisticHistory = ({ durationStartDate, durationEndDate, allActivities }) => {
    const [taskActivities, setTaskActivities] = useState({});
    const [worksheetStepTaskCount, setWorksheetStepTaskCount] = useState({});
    const [taskcardStepTaskCount, setTaskcardStepTaskCount] = useState({});

    const selectedStaff = useSelector((state) => state.filters.selectedStaff);

    useEffect(() => {
        const taskActivities = {};
        let worksheetStepTaskCount = {};
        let taskcardStepTaskCount = {};
        let activities;
        if (selectedStaff !== null) {
            activities = allActivities.filter(act => act['taskData']['staffs'].find(staff => staff.id === selectedStaff));
        } else {
            activities = allActivities;
        }
        for (let i = 0; i < activities.length; i++) {
            const activity = activities[i];
            if (taskActivities[activity['task']]) {
                taskActivities[activity['task']]['activities'].push(activity);
            } else {
                taskActivities[activity['task']] = {
                    customID: activity['taskData']['customID'],
                    title: activity['taskData']['title'],
                    staffs: activity['taskData']['staffs'],
                    category: activity['taskData']['category'],
                    activities: [activity],
                };
            }
            if (activity['taskData']['category'] === 'Worksheet') {
                if (worksheetStepTaskCount[activity['step']]) {
                    worksheetStepTaskCount[activity['step']]['count']++;
                } else {
                    worksheetStepTaskCount[activity['step']] = {
                        label: activity['stepLabel'],
                        category: activity['taskData']['category'],
                        count: 1
                    }
                }
            } else if (activity['taskData']['category'] === 'Taskcard') {
                if (taskcardStepTaskCount[activity['step']]) {
                    taskcardStepTaskCount[activity['step']]['count']++;
                } else {
                    taskcardStepTaskCount[activity['step']] = {
                        label: activity['stepLabel'],
                        category: activity['taskData']['category'],
                        count: 1
                    }
                }
            }
        }

        setTaskActivities(taskActivities);
        setWorksheetStepTaskCount(worksheetStepTaskCount);
        setTaskcardStepTaskCount(taskcardStepTaskCount);
    }, [allActivities, selectedStaff]);

    return (
        <div className="task-statistic-history">
            <div className="header">
                <div>Total: {Object.values(taskActivities).length} tasks</div>
                <div>
                    <Moment format="DD/MM/YYYY">{durationStartDate}</Moment> - <Moment format="DD/MM/YYYY">{durationEndDate}</Moment>
                </div>
            </div>
            <div className="summary">
                <h2>Summary</h2>
                <h3>{'>'} Worksheet</h3>
                {Object.values(worksheetStepTaskCount).map((step, index) => (
                    <div key={'worksheet-' + index}>
                        {step.label}: {step.count} tasks moved to this column
                    </div>
                ))}
                <h3>{'>'} Taskcard</h3>
                {Object.values(taskcardStepTaskCount).map((step, index) => (
                    <div key={'taskcard-' + index}>
                        {step.label}: {step.count} tasks moved to this column
                    </div>
                ))}
            </div>
            <div className="details">
                <h2>Worksheet</h2>
                {Object.values(taskActivities).filter(t => t.category === 'Worksheet').map(task => (
                    <div key={task.customID} className="task-activities">
                        <h3>{task.title}</h3>
                        <div className="staffs">
                            {task.staffs.map(staff => staff.name).join(', ')}
                        </div>
                        <div>
                            {task.activities.length === 1 && (
                                <FontAwesomeIcon icon={faArrowCircleRight} />
                            )}
                            <Badge bg="secondary">{task.activities[0].stepLabel}</Badge>
                            {task.activities.length > 1 && (
                                <>
                                    <FontAwesomeIcon icon={faArrowCircleRight} />
                                    <Badge bg="secondary">{task.activities[task.activities.length - 1].stepLabel}</Badge>
                                </>
                            )}

                        </div>
                    </div>
                ))}
                <h2>Taskcard</h2>
                {Object.values(taskActivities).filter(t => t.category === 'Taskcard').map(task => (
                    <div key={task.customID} className="task-activities">
                        <h3>{task.title}</h3>
                        <div className="staffs">
                            {task.staffs.map(staff => staff.name).join(', ')}
                        </div>
                        <div>
                            {task.activities.length === 1 && (
                                <FontAwesomeIcon icon={faArrowCircleRight} />
                            )}
                            <Badge bg="secondary">{task.activities[0].stepLabel}</Badge>
                            {task.activities.length > 1 && (
                                <>
                                    <FontAwesomeIcon icon={faArrowCircleRight} />
                                    <Badge bg="secondary">{task.activities[task.activities.length - 1].stepLabel}</Badge>
                                </>
                            )}

                        </div>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default TaskStatisticHistory;