import { Button } from 'react-bootstrap';
import './styles.scss';
import { updateDataAPI } from '../../../utils/query';
import { warningUserWhenPageIsValidate } from '../../../utils/autoContentUtils';

const PageButtons = ({page, pageIndex, pages, refresh, setLoading}) => {
    const duplicatePage = async() => {
        const nbPages = window.prompt('Number of pages:', '1');
        if (nbPages !== null && !isNaN(nbPages) && !isNaN(parseInt(nbPages))) {
            setLoading(true);
            for (let i = 0; i < parseInt(nbPages); i++) {
                await updateDataAPI('POST', `resources/duplicate-idea-page/?pageID=${page.id}`);
            }
            refresh(['pages']);
        }
        setLoading(false);
    }

    const deletePage = async() => {
        if (warningUserWhenPageIsValidate([page])) return;

        if (window.confirm('Are you sure?')) {
            setLoading(true);
            await updateDataAPI('POST', 'resources/delete-idea-pages/', {
                pageIDs: [page.id]
            });
            await refresh(['pages']);
            setLoading(false);
        }
    }

    const movePage = async (direction) => {
        setLoading(true);
        const currentPageIndex = page.pageIndex;
        const targetPage = pages[direction === 'up' ? (pageIndex - 1) : (pageIndex + 1)];
        await updateDataAPI('PATCH', 'resources/idea-pages/' + page.id + '/', {
            pageIndex: targetPage.pageIndex
        });
        await updateDataAPI('PATCH', 'resources/idea-pages/' + targetPage.id + '/', {
            pageIndex: currentPageIndex
        });
        refresh(['pages']);
    }

    return (
        <div className="page-buttons">
            <div className="btns">
                <Button size="sm" variant="info" onClick={duplicatePage}>Duplicate</Button>
                <Button size="sm" variant="danger" onClick={deletePage}>Delete</Button>
            </div>
            <div className="btns">
                {pageIndex > 0 && (
                    <Button size="sm" variant="secondary" onClick={() => movePage('up')}>Up</Button>
                )}
                {pageIndex < pages.length - 1 && (
                    <Button size="sm" variant="secondary" onClick={() => movePage('down')}>Down</Button>
                )}
            </div>
        </div>
    )
}

export default PageButtons;