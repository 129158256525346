import { useState, useEffect } from 'react';
import './styles.scss';
import Moment from 'react-moment';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import { updateDataAPI } from '../../../utils/query';
import RefreshTaskProgressButton from './refresh-task-progress-button';
import TaskCheckListModal from '../../task-management/task-checklist-modal';
import Constant from '../../../Constant';

const FlowProgress = ({
    task = {},
    flowSteps = [],
    taskHistories,
    showRefreshButton = true,
    isStepActive = () => false,
    hanldeClickStep = () => { },
    refreshTaskProgress = () => { },
    setLoading,
    tags,
}) => {
    const [stepActiveIndex, setStepActiveIndex] = useState(null);
    const [taskInCheckListModal, setTaskInCheckListModal] = useState(null);
    const [showDesignDoneModal, setShowDesignDoneModal] = useState(false);
    const [isDesignDone, setIsDesignDone] = useState(false);

    useEffect(() => {
        const getStepActiveIndex = () => {
            for (let i = 0; i < flowSteps.length; i++) {
                if (isStepActive(flowSteps[i])) {
                    setStepActiveIndex(i);
                }
            }
        };

        getStepActiveIndex();
    }, [flowSteps]);

    const updateTaskField = async (field, val) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'product-dev/tasks/' + task.id + '/', {
            [field]: val
        });
        setLoading(false);
    }

    const confirmDesignDone = async () => {
        await updateTaskField('designDone', true);
        refreshTaskProgress();
        setIsDesignDone(true);
        setShowDesignDoneModal(false);
    }

    return (
        <div className="d-flex flex-column align-items-end">
            {showRefreshButton && (
                <RefreshTaskProgressButton task={task} handleRefreshSteps={refreshTaskProgress} />
            )}
            <div className="flow-progress">
                {flowSteps.map((step, index) => (
                    <div
                        className={'step ' + ((stepActiveIndex === index) && 'active')}
                        key={step.id}
                        onClick={() => {
                            if (index == stepActiveIndex + 1) {
                                setTaskInCheckListModal(task);
                            }
                            hanldeClickStep(index + 1);
                        }}
                    >
                        <span className="step-dot"></span>
                        <span className="step-label">{step.label}</span>
                        {taskHistories && taskHistories.find(h => h.step === step.id) && (
                            <div className="step-date"><Moment format="DD/MM/YYYY">{taskHistories.find(h => h.step === step.id).startDate}</Moment></div>
                        )}
                        {index === 4 && !isDesignDone && (
                            <Button variant="success" size="sm" onClick={() => setShowDesignDoneModal(true)}>Design Done</Button>
                        )}
                    </div>
                ))}
            </div>
            {taskInCheckListModal !== null && (
                <TaskCheckListModal
                    task={taskInCheckListModal}
                    taskFlowSteps={flowSteps}
                    closeModal={() => setTaskInCheckListModal(null)} />
            )}

            {showDesignDoneModal && (
                <Modal show={true} onHide={() => setShowDesignDoneModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Design Completion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {tags && (tags.indexOf(Constant.TASKCARD_TAG) > -1) && (
                            <ListGroup as="ol" numbered>
                                {Constant.TASKCARD_DESIGN_VALIDATION_CRITERIAS.map((criteria, index) => (
                                    <ListGroup.Item as="li" key={index}>
                                        {criteria}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                        {tags && (tags.indexOf(Constant.WORKSHEET_TAG) > -1) && (
                            <ListGroup as="ol" numbered>
                                {Constant.WORKSHEET_DESIGN_VALIDATION_CRITERIAS.map((criteria, index) => (
                                    <ListGroup.Item as="li" key={index}>
                                        {criteria}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                        {tags && (tags.indexOf(Constant.COLOR_BY_CODE_TAG) > -1) && (
                            <ListGroup as="ol" numbered>
                                {Constant.CBC_DESIGN_VALIDATION_CRITERIAS.map((criteria, index) => (
                                    <ListGroup.Item as="li" key={index}>
                                        {criteria}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDesignDoneModal(false)}>Cancel</Button>
                        <Button variant="primary" onClick={confirmDesignDone}>Confirm</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    )
};

export default FlowProgress;