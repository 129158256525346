import React from "react";
import { useDispatch } from "react-redux";
import { setCurrentStep } from "../colorbycodeMakerSlice";

const defaultStep = {
    id: 1,
    step: 'Choose templates'
}

const Step = ({ step = defaultStep, isActive = false }) => {
    const dispatch = useDispatch();

    return (
        <div
            className={`step ${isActive && 'completed'}`}
            onClick={() => dispatch(setCurrentStep(step))}
        >
            <div className="step__id">
                <span>
                    {step.id}
                </span>
            </div>
            <span className="step__value">{step.step}</span>
        </div>
    )
};

export default Step;