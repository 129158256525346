import { Form } from 'react-bootstrap';
import Constant from '../../Constant';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCategory } from '../filtersSlice';
import './styles.scss';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../utils/query';

const FilterByCategory = ({ filtering = true, value, setValue, hasAllOptions = true }) => {
    const dispatch = useDispatch();
    const selectedCategory = useSelector((state) => state.filters.selectedCategory);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        const res = await getDataFromAPI('resources/categories/');
        setCategories(res.data);
        if (!hasAllOptions && value === undefined) {
            if (filtering) {
                dispatch(setSelectedCategory(parseInt(res.data[0].id)));
            } else {
                setValue(res.data[0].id);
            }
        }
    }

    return (
        <div className="filter-by-category">
            <Form.Select onChange={e => {
                if (e.target.value === 'all') {
                    if (filtering) {
                        dispatch(setSelectedCategory(undefined));
                    } else {
                        setValue(undefined);
                    }
                } else {
                    if (filtering) {
                        dispatch(setSelectedCategory(parseInt(e.target.value)));
                    } else {
                        setValue(parseInt(e.target.value));
                    }
                }
            }} value={filtering ? selectedCategory : value}>
                {hasAllOptions && (
                    <option value="all">All</option>
                )}
                {categories.map(cat => (
                    <option value={cat.id} key={cat.id}>{cat.name}</option>
                ))}
            </Form.Select>
        </div>
    );
}

export default FilterByCategory;