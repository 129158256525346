import { useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';

const DATA_SET_MAPPING_DEFAULT = {
    'MCQ': ['question', 'choices', 'correct_choice'],
    'BLANK': ['sentence', 'correct_answer'],
}

const DataSetMappingTable = ({mapping, setMapping, data, activity}) => {
    const [fromValue, setFromValue] = useState(null);
    const [toValue, setToValue] = useState(null);

    const addNewRow = () => {
        if (fromValue === null || toValue === null) {
            return;
        }

        if (mapping) {
            setMapping({...mapping, [fromValue]: toValue});
        } else {
            setMapping({[fromValue]: toValue});
        }
        setFromValue(null);
        setToValue(null);
    }

    const deleteRow = (from) => {
        if (mapping && mapping[from]) {
            const mappingClone = {...mapping};
            delete mappingClone[from];
            setMapping(mappingClone);
        }
    }

    return (
        <div>
            {mapping && (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>From</th>
                            <th>To</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(mapping).map((key) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{mapping[key]}</td>
                                <td>
                                    <Button size="sm" variant="danger" onClick={() => deleteRow(key)}>Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>From</Form.Label>
                        {data && data.length > 0 && (
                            <Form.Select value={fromValue} onChange={e => setFromValue(e.target.value)}>
                                <option></option>
                                {Object.keys(data[0]).map(key => (
                                    <option key={key}>{key}</option>
                                ))}
                            </Form.Select>
                        )}
                        <Form.Control value={fromValue} onChange={e => setFromValue(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>To</Form.Label>
                        {DATA_SET_MAPPING_DEFAULT[activity] ? (
                            <Form.Select value={toValue} onChange={e => setToValue(e.target.value)}>
                                <option></option>
                                {DATA_SET_MAPPING_DEFAULT[activity].map(key => (
                                    <option key={key}>{key}</option>
                                ))}
                            </Form.Select>
                        ) : (
                            <Form.Control value={toValue} onChange={e => setToValue(e.target.value)} />
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Button className="mt-3" onClick={addNewRow} size="sm">Add</Button>
        </div>
    )
}

export default DataSetMappingTable;