import { Button } from 'react-bootstrap';
import TaskCardTable from '../taskcard-table';
import { useEffect, useState } from 'react';

import './styles.scss';
import Constant from '../../../Constant';
import { hasPermission } from '../../../utils/auth';
import { useSelector } from 'react-redux';

const TaskCardResult = ({ customID, selectedPartID, result, setResult, clearDataPart }) => {
    const { activityPermissions } = useSelector((state) => state.permissions);

    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const downloadCSV = async () => {
        let url = Constant.API_URL + 'resources/download-task-card-csv/?tarkCardPart=' + selectedPartID + '&field=taskCardCSV';
        fetch(url, {
            method: 'POST',
            headers: {
                Authorization: 'Token ' + localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN),
                'Content-Type': 'multipart/form-data',
            }
        })
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', customID + '.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                return Promise.reject({ Error: 'Something Went Wrong', err });
            })
    }

    return (
        <div className="taskcard-result">
            <div className="btns">
                {hasPermission(userRoles, activityPermissions['DELETE_TASK_CARD_PART']) && (
                    <Button size="sm" onClick={clearDataPart} variant="danger">Clear Data</Button>
                )}
                <Button size="sm" onClick={downloadCSV} variant="success">Download CSV</Button>
            </div>

            <TaskCardTable selectedPartID={selectedPartID} data={result} setData={setResult} canDeleteRow={true} />
        </div>
    )
}

export default TaskCardResult;