import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { toast } from 'react-toastify';

const FetchExamplesProducts = () => {
    const [customID, setCustomID] = useState(null);
    const [inputURLs, setInputURLs] = useState(['']);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const checkCustomIDExistInURL = () => {
            const urlSearchParams = (new URLSearchParams(window.location.search));
            if (urlSearchParams.has('customID')) {
                const customID = urlSearchParams.get('customID');
                setCustomID(customID);
            } else {
                window.location.href = '/';
            }
        };

        checkCustomIDExistInURL();
    }, []);

    const handleInputChange = (index, value) => {
        const updatedInputURLs = [...inputURLs];
        updatedInputURLs[index] = value;
        setInputURLs(updatedInputURLs);
    };

    const handleAddInput = () => {
        setInputURLs([...inputURLs, '']);
    };

    const handleSaveInputURLs = async () => {
        if (inputURLs.length > 0) {
            setLoading(true);
            const uniqueURLs= Array.from(new Set(inputURLs));
            for (const inputURL of uniqueURLs) {
                if (inputURL === '') continue;

                const cleanURL = inputURL.split('?')[0];
                const tptID = parseInt(cleanURL.split('-').pop());

                const data = {
                    customID,
                    tptID,
                    url: cleanURL,
                };

                const url = `${process.env.REACT_APP_API_TPTSELLER}external-products/tpt-products/`;

                const options = {
                    headers: {
                        Authorization: 'Token ' + process.env.REACT_APP_API_TPTSELLER_TOKEN
                    }
                };

                try {
                    const res = await axios.post(url, data, options);
                } catch (err) {
                    console.error(`An error occured when pushing url: ${err.message}`);
                    toast.error(`An error occured when pushing url: ${err.message}`);
                    continue;
                };
            };

            setLoading(false);
        }
    };

    return (
        <Container className="fetch-examples-products" fluid>
            <Form>
                {inputURLs.map((inputURL, index) => (
                    <Form.Group key={index} className="mb-2">
                        <Form.Control
                            type="text"
                            placeholder="Enter an url"
                            value={inputURL}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                        />
                    </Form.Group>
                ))}
                <div className="d-flex mt-3">
                    <Button variant="primary" onClick={handleAddInput} className="me-2">
                        Add more input
                    </Button>
                    {!loading ? (
                        <Button variant="success" onClick={handleSaveInputURLs}>
                            Save input urls
                        </Button>
                    ) : (
                        <span>Loading...</span>
                    )}
                </div>
            </Form>
        </Container>
    );
};

export default FetchExamplesProducts;