import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import UploadFile from '../../../content-studio/upload-file';

import './styles.scss';

const StoreTemplateFilesTab = ({storeID}) => {
    const [loading, setLoading] = useState(false);
    const [templatePDFUrl, setTemplatePDFUrl] = useState(null);
    const [ctaBackgroundImageUrl, setCTABackgroundImageUrl] = useState(null);
    const [relatedProductsBackgroundImageUrl, setRelatedProductsBackgroundImageUrl] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [canvaTemplateUrl, setCanvaTemplateUrl] = useState(null);

    useEffect(() => {
        const fetchTemplateUrls = async () => {
            setLoading(true);
            const res = await getDataFromAPI('stores/' + storeID + '/');
            setTemplatePDFUrl(res.data['templatePDFUrl']);
            setCTABackgroundImageUrl(res.data['ctaBackgroundImageUrl']);
            setRelatedProductsBackgroundImageUrl(res.data['relatedProductsBackgroundImageUrl']);
            setThumbnailUrl(res.data['thumbnailUrl']);
            setCanvaTemplateUrl(res.data['canvaTemplateUrl']);
            setLoading(false);
        }

        fetchTemplateUrls();
    }, []);

    const save = async() => {
        setLoading(true);
        await updateDataAPI('PATCH', 'stores/' + storeID + '/', {canvaTemplateUrl});
        window.alert('saved');
        setLoading(false);
    }

    return (
        <Container className="store-template-files-tab" fluid>
            <Row>
                <Col>
                    <div className="upload-template-file">
                        <h3>Template PDF File</h3>
                        {templatePDFUrl && (
                            <a href={templatePDFUrl}>Download</a>
                        )}
                        <UploadFile
                            label="Upload"
                            id={storeID}
                            setLoading={setLoading}
                            uploadUrl={'resources/upload-store-template-files/'}
                            allowedFileExtentions={['pdf']}
                            fieldName={'templatePDFUrl'}
                            uploadedFileName={null}
                            setUploadedFileName={url => setTemplatePDFUrl(url)}
                        />
                    </div>
                </Col>
                <Col>
                    <div className="upload-template-file">
                        <h3>Related Products Image File</h3>
                        {relatedProductsBackgroundImageUrl && (
                            <a href={relatedProductsBackgroundImageUrl}>
                                <img src={relatedProductsBackgroundImageUrl} />
                            </a>
                        )}
                        <UploadFile
                            label="Upload"
                            id={storeID}
                            setLoading={setLoading}
                            uploadUrl={'resources/upload-store-template-files/'}
                            allowedFileExtentions={['png', 'jpg', 'jpeg']}
                            fieldName={'relatedProductsBackgroundImageUrl'}
                            uploadedFileName={null}
                            setUploadedFileName={url => setRelatedProductsBackgroundImageUrl(url)}
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className="upload-template-file">
                        <h3>CTA Image File (REMEMBER to use TRANSPARENT BACKGROUND image)</h3>
                        {ctaBackgroundImageUrl && (
                            <a href={ctaBackgroundImageUrl}>
                                <img src={ctaBackgroundImageUrl} />
                            </a>
                        )}
                        <UploadFile
                            label="Upload"
                            id={storeID}
                            setLoading={setLoading}
                            uploadUrl={'resources/upload-store-template-files/'}
                            allowedFileExtentions={['png', 'jpg', 'jpeg']}
                            fieldName={'ctaBackgroundImageUrl'}
                            uploadedFileName={null}
                            setUploadedFileName={url => setCTABackgroundImageUrl(url)}
                        />
                    </div>
                </Col>
                <Col>
                    <div className="upload-template-file">
                        <h3>Thumbnail</h3>
                        {thumbnailUrl && (
                            <a href={thumbnailUrl}>
                                <img src={thumbnailUrl} />
                            </a>
                        )}
                        <UploadFile
                            label="Upload"
                            id={storeID}
                            setLoading={setLoading}
                            uploadUrl={'resources/upload-store-template-files/'}
                            allowedFileExtentions={['png', 'jpg', 'jpeg']}
                            fieldName={'thumbnailUrl'}
                            uploadedFileName={null}
                            setUploadedFileName={url => setThumbnailUrl(url)}
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Canva Template Url</Form.Label>
                        <Form.Control value={canvaTemplateUrl} onChange={e => setCanvaTemplateUrl(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col>
                    <Button onClick={save}>Save</Button>
                </Col>
            </Row>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    );
}

export default StoreTemplateFilesTab;