import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { updateDataAPI } from '../../../../utils/query';
import { toast } from 'react-toastify';

const TagSettingModal = ({
    tags = [],
    categories = [],
    fetchAllCategories,
    selectedTag = {},
    hideModal = () => { }
}) => {
    const [editedTag, setEditedTag] = useState(selectedTag || {
        name: '',
        category: '',
        parent: ''
    });
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [parentTags, setParentTags] = useState([]);

    useEffect(() => {
        const generateCategoryOptions = () => {
            const options = categories.map((category, index) => ({
                id: index,
                value: category['id'],
                label: category['name']
            }));

            setCategoryOptions(options);
        };

        generateCategoryOptions();
    }, [categories]);

    useEffect(() => {
        const generateParentTags = () => {
            const options = tags.filter((tag) => tag.id !== selectedTag.id).map((tag, index) => ({
                id: index,
                value: tag['id'],
                label: tag['name']
            }));
            setParentTags(options);
        }

        generateParentTags();
    }, [tags, selectedTag]);

    const handleTagFieldChange = (field, value) => {
        setEditedTag((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSaveTagChange = async (e) => {
        e.preventDefault();
        const url = `auto-content/tags/${selectedTag['id']}/`;

        try {
            await updateDataAPI('PATCH', url, editedTag);
            fetchAllCategories();
            hideModal();
        } catch (error) {
            toast.error('Error, check the tag\'s parent');
        };
    }

    return (
        <Modal show={true} onHide={hideModal} contentLabel="Tag-setting-modal">
            <Modal.Header>
                <Modal.Title>
                    Tag {editedTag?.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Name: </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Edit name's tag"
                            value={editedTag?.name || ''}
                            onChange={(e) => handleTagFieldChange('name', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Category: </Form.Label>
                        <Select
                            classNamePrefix="select-category"
                            name="category"
                            isClearable
                            options={categoryOptions}
                            value={categoryOptions.find((category) => category.value === editedTag.category)}
                            onChange={(selectedOption) => handleTagFieldChange('category', selectedOption?.value || '')}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Parent: </Form.Label>
                        <Select
                            classNamePrefix="select-parent"
                            name="parent"
                            isClearable
                            options={parentTags}
                            value={parentTags.find((tag) => tag.value === editedTag.parent)}
                            onChange={(selectedOption) => handleTagFieldChange('parent', selectedOption?.value || '')}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" type='submit' onClick={handleSaveTagChange}>Save</Button>
                <Button onClick={hideModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default TagSettingModal;