import { Fragment, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import { toast } from 'react-toastify';
import { getZoneLabel, getZonesOfResource } from '../../../../utils/autoContentUtils';
import { Col, Row, Spinner, Table } from 'react-bootstrap';
import { checkObjectHasNullebaleValue } from '../../../../utils/commonUtil';
import './styles.scss';
import Constant from '../../../../Constant';

export const canEditTemplateRequirementFields = [
    {
        id: 1,
        field: 'targetTemplate',
        type: 'select',
    },
    {
        id: 2,
        field: 'accessPath',
        type: 'text',
    },
]

export const getTargetOptionsForCurrentTemplateRequirement = (options = [], currentTargetTemplateID, requirements = []) => {
    const templateRequirementsSet = new Set(requirements.map(templateRequirement => templateRequirement.targetTemplate));

    return options.filter(option => {
        if (option.value === currentTargetTemplateID) {
            return true;
        }
        return !templateRequirementsSet.has(option.id);
    });
}

const TemplateRequirementsSettingModal = ({
    currentTemplate = {},
    templates,
    setTemplates,
    collection,
    hideModal = () => { }
}) => {
    const initialNewTemplateRequirementValue = {
        accessPath: null,
        template: currentTemplate['id'],
        targetTemplate: null,
        collection: collection['id']
    };

    const [templateRequirements, setTemplateRequirements] = useState([]);
    const [editedCell, setEditedCell] = useState(null);
    const [targetTemplateAccessPaths, setTargetTemplateAccessPaths] = useState([]);
    const [copyAccessPaths, setCopyAccessPaths] = useState([]);
    const [loading, setLoading] = useState(false);

    const [newTemplateRequirement, setNewTemplateRequirement] = useState(initialNewTemplateRequirementValue);

    useEffect(() => {
        const fetchTemplateRequirements = async () => {
            const url = `auto-content/template-requirements/?templateID=${currentTemplate['id']}&collectionID=${collection?.id}`;

            try {
                const res = await getDataFromAPI(url);
                const data = res.data;
                setTemplateRequirements(data);
            } catch (e) {
                console.log(`An error occured when check exist template requirements with the current template: ${e.message}`);
            };
        };

        fetchTemplateRequirements();
    }, [currentTemplate]);

    useEffect(() => {
        const fetchTargetTemplateAccessPaths = async () => {
            if (newTemplateRequirement?.targetTemplate) {
                let zones = await getZonesOfResource(newTemplateRequirement.targetTemplate);
                let accessPathOptions = zones.map((zone) => ({
                    ...zone,
                    value: zone.accessPath,
                    label: getZoneLabel(zone),
                }));
                if (!newTemplateRequirement.accessPath) {
                    setNewTemplateRequirement((prev) => ({
                        ...prev,
                        accessPath: accessPathOptions.find((option) => option.value === newTemplateRequirement.accessPath)
                    }));
                };
                setTargetTemplateAccessPaths(accessPathOptions);
            };
        };

        fetchTargetTemplateAccessPaths();
    }, [newTemplateRequirement.targetTemplate, newTemplateRequirement.accessPath]);

    useEffect(() => {
        const fetchCopyAccessPaths = async () => {
            const res = await getDataFromAPI('auto-content/design-items/?templateID=' + currentTemplate.id);
            setCopyAccessPaths(res.data.filter((item) => item.type === Constant.TYPE_RESOURCE_ZONE || item.type === Constant.TYPE_RESOURCE_ZONE_STATIC));
        }

        if (newTemplateRequirement.copyValue) {
            fetchCopyAccessPaths();
        }
    }, [newTemplateRequirement.copyValue]);

    const handleClickCell = (field, item) => {
        setEditedCell({
            ...item,
            field: field,
            value: item[field],
        });
    };

    const handleCellChange = (value) => {
        setEditedCell((prevCell) => ({
            ...prevCell,
            value,
        }));
    }

    const handleCreateTemplateRequirement = async () => {
        if (!checkObjectHasNullebaleValue(newTemplateRequirement)) return;

        try {
            const res = await updateDataAPI('POST', 'auto-content/template-requirements/', newTemplateRequirement);
            setTemplates(templates.map(t => {
                if (t.id === currentTemplate.id) {
                    return { ...t, requirements: t.requirements.concat([res.data]) };
                }
                return t;
            }))
            setTemplateRequirements((prev) => ([...prev, res.data]));
            setNewTemplateRequirement(initialNewTemplateRequirementValue);
            hideModal();
            toast.success('Successfully created.')
        } catch (e) {
            console.log(`An error occured when creating template requirement: ${e.message}`);
            toast.error(`An error occured when creating template requirement: ${e.message}`)
        }
    }

    const copyAllValues = async () => {
        if (window.confirm('Are you sure want to copy all values?')) {
            setLoading(true);
            for (let i = 0; i < targetTemplateAccessPaths.length; i++) {
                const newRequirement = await updateDataAPI('POST', 'auto-content/template-requirements/', {
                    targetTemplate: newTemplateRequirement['targetTemplate'],
                    template: currentTemplate.id,
                    accessPath: targetTemplateAccessPaths[i].value,
                    copyValue: true,
                    collection: collection['id'],
                });
                setTemplateRequirements((prev) => ([...prev, newRequirement.data]));
            }
            setLoading(false);
        }
    }

    const hanldeUpdateTemplateRequirement = async () => {
        if (editedCell.accessPath === '' | editedCell.targetTemplate === null) return;

        try {
            const url = `auto-content/template-requirements/${editedCell['id']}/`;

            const res = await updateDataAPI('PATCH', url, {
                [editedCell['field']]: editedCell['value'],
                collection: collection['id']
            });
            setTemplateRequirements((prev) => prev.map((item) => {
                if (item.id === editedCell['id']) {
                    return {
                        ...item,
                        [editedCell['field']]: editedCell['value']
                    }
                };

                return item;
            }));
            toast.success('Successfully updated.');
            setEditedCell(null);
        } catch (e) {
            console.log(`An error occured when updating template requirement: ${e.message}`);
            toast.error(`An error occured when updating template requirement: ${e.message}`)
        };
    };

    const handleDeleteTemplateRequirement = async (templateRequirement) => {
        const confirmed = window.confirm('Are you sure want to delete this requirement?');

        if (confirmed) {
            try {
                await updateDataAPI('DELETE', `auto-content/template-requirements/${templateRequirement['id']}/`);
                setTemplateRequirements((prev) => prev.filter((item) => item.id !== templateRequirement['id']));
                toast.success('Successfully deleted.');
            } catch (e) {
                console.log(`An error occured when deleting template requirement: ${e.message}`);
                toast.error(`An error occured when deleting template requirement: ${e.message}`)
            };
        };
    }

    return (
        <Modal show={true} onHide={hideModal} size="lg"
            className="template-requirements-setting-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Template Requirements
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            <th>Target Template</th>
                            <th>Access path</th>
                            <th>Copy Value</th>
                            <th>Copy Destination</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {templateRequirements.map((templateRequirement) => (
                            <tr key={templateRequirement.id}>
                                {canEditTemplateRequirementFields.map(({ id, field, type }) => (
                                    <Fragment key={id}>
                                        {editedCell && editedCell.id === templateRequirement.id && editedCell.field === field ? (
                                            <td>
                                                {/* {editedCell.field === 'targetTemplate' && (
                                                    <Select
                                                        className="target-templates"
                                                        classNamePrefix="select"
                                                        defaultValue={templates}
                                                        isSearchable={true}
                                                        name="target-templates"
                                                        options={templates}
                                                        value={targetOptions.find(option => option.value === editedCell.value)}
                                                        onChange={(selectedOption) => handleCellChange(selectedOption['value'])}
                                                    />
                                                )} */}
                                                {editedCell.field === 'accessPath' && (
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter access path"
                                                        value={editedCell.value}
                                                        onChange={(e) => handleCellChange(e.target.value)}
                                                    />
                                                )}
                                            </td>
                                        ) : (
                                            <td onClick={() => handleClickCell(field, templateRequirement)}>{templateRequirement[field]}</td>
                                        )}
                                    </Fragment>
                                ))}
                                <td>
                                    {templateRequirement.copyValue ? 'Yes' : 'No'}
                                </td>
                                <td>
                                    {templateRequirement.copyDestinationAccessPath}
                                </td>
                                <td>
                                    {(editedCell && editedCell.id === templateRequirement.id) && (
                                        <>
                                            <Button
                                                variant="success"
                                                size="sm"
                                                onClick={() => hanldeUpdateTemplateRequirement()}
                                                className="me-2"
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => setEditedCell(null)}
                                                className="me-2"
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                    )}
                                    <Button variant="danger" size="sm" onClick={() => handleDeleteTemplateRequirement(templateRequirement)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <h2>New Requirement</h2>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Template</Form.Label>
                                <Form.Select value={newTemplateRequirement['targetTemplate']} onChange={e => setNewTemplateRequirement({...newTemplateRequirement, targetTemplate: e.target.value})}>
                                    <option></option>
                                    {templates.map((template) => (
                                        <option key={template.id} value={template.id}>{template.id}. {template.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Field</Form.Label>
                                {targetTemplateAccessPaths.length > 0 ? (
                                    <Select
                                        className="target-template-access-paths"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        name="target-template-access-paths"
                                        options={targetTemplateAccessPaths}
                                        value={targetTemplateAccessPaths.find(option => option.accessPath === newTemplateRequirement['accessPath'])}
                                        onChange={(selectedOption) => setNewTemplateRequirement((prev) => ({
                                            ...prev,
                                            accessPath: selectedOption['value']
                                        }))}
                                    />
                                ) : (
                                    <div>Loading...</div>
                                )}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Copy Value</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    checked={newTemplateRequirement['copyValue']}
                                    onChange={(e) => setNewTemplateRequirement((prev) => ({
                                        ...prev,
                                        copyValue: e.target.checked
                                    }))}
                                />
                            </Form.Group>
                            {newTemplateRequirement['copyValue'] && copyAccessPaths.length > 0 && (
                                <Form.Group className="mb-3">
                                    <Form.Label>Destination Field</Form.Label>
                                    <Form.Select
                                        value={newTemplateRequirement['copyDestinationAccessPath']}
                                        onChange={(e) => setNewTemplateRequirement({...newTemplateRequirement, copyDestinationAccessPath: e.target.value})}
                                    >
                                        <option></option>
                                        {copyAccessPaths.map((item) => (
                                            <option key={item.id} value={item.accessPath}>{item.accessPath}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            )}
                            {newTemplateRequirement['targetTemplate'] && targetTemplateAccessPaths.length > 0 && (
                                <Button variant="info" size="sm" onClick={() => copyAllValues()}>
                                    Copy All Values
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hideModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => handleCreateTemplateRequirement()}>
                    Add requirement
                </Button>

            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    )
}

export default TemplateRequirementsSettingModal;