import { Button, Form, Modal, Table } from 'react-bootstrap';

import './styles.scss';
import { useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';

const StoreKeywords = ({stores, setStores, setLoading}) => {
    const [filteredMarketplace, setFilteredMarketplace] = useState('boom');
    const [keywordInModal, setKeywordInModal] = useState(null);
    const [storeInModal, setStoreInModal] = useState(null);

    const removeKeywordFromStore = async() => {
        setLoading(true);
        await updateDataAPI('POST', 'resources/remove-keyword-from-store/', {
            keywordID: keywordInModal.id,
            storeID: keywordInModal.storeID,
            marketplace: 'boom'
        });

        const res = await getDataFromAPI('stores/' + keywordInModal.storeID + '?additionalFields=keywords');
        setStores(prev => prev.map(store => {
            if (store.id === res.data['id']) {
                return res.data;
            }
            return store;
        }));

        setLoading(false);
    }

    const duplicateKeywordToOtherStore = async() => {
        const targetStoreID = window.prompt('Duplicate keyword to Store', '');
        if (targetStoreID) {
            setLoading(true);
            await updateDataAPI('POST', 'resources/duplicate-keyword-to-other-store/', {
                keywordID: keywordInModal.id,
                storeID: targetStoreID,
                marketplace: 'boom'
            });

            const res = await getDataFromAPI('stores/' + targetStoreID + '?additionalFields=keywords');
            setStores(prev => prev.map(store => {
                if (store.id === res.data['id']) {
                    return res.data;
                }
                return store;
            }));

            setLoading(false);
        }
    }

    const saveStoreInModal = async() => {
        setLoading(true);
        await updateDataAPI('PATCH', 'stores/' + storeInModal.id + '/', {
            subjects: storeInModal.subjects,
            grades: storeInModal.grades,
        });
        setStores(prev => prev.map(store => {
            if (store.id === storeInModal.id) {
                return storeInModal;
            }

            return store;
        }));
        setStoreInModal(null);
        setLoading(false);
    }

    return (
        <div className="store-keywords">
            <div className="filters">
                <Form.Select value={filteredMarketplace} onChange={e => setFilteredMarketplace(e.target.value)}>
                    <option value="all">all</option>
                    <option value="tpt">tpt</option>
                    <option value="boom">boom</option>
                </Form.Select>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Bundles</th>
                        <th>Keywords</th>
                    </tr>
                </thead>
                <tbody>
                    {stores.filter(store => {
                        if (filteredMarketplace !== 'all' && store.marketplace !== filteredMarketplace) {
                            return false;
                        }

                        return true;
                    }).map(store => (
                        <tr key={store.id}>
                            <td className="store-cell">
                                <div>
                                    <a href={'/ideas?selectedStoreID=' + store.id} target="_blank">
                                        {store.id}
                                    </a>
                                </div>
                                <div onClick={() => setStoreInModal(store)}>Subjects: {store.subjects}</div>
                                <div onClick={() => setStoreInModal(store)}>Grades: {store.grades}</div>
                                <div>Total: {store.keywords.nbKeywords} keywords</div>
                            </td>
                            <td className="bundles-cell">
                                {store.keywords && (
                                    <div className="bundles">
                                        <Table striped bordered hover>
                                            <tbody>
                                                {store.keywords.bundles.map((bundle, bundleIndex) => (
                                                    <tr key={bundle.id}>
                                                        <td>{bundleIndex + 1}</td>
                                                        <td>
                                                            <div className="keywords">
                                                                {bundle.keywords.map(keyword => (
                                                                    <div className={keyword.nbIdeas > 0 ? '' : 'text-danger'} onClick={() => setKeywordInModal({...keyword, storeID: store.id})}>
                                                                        <span>
                                                                            {keyword.name} - {keyword.grades}
                                                                        </span>
                                                                        <span>
                                                                            {keyword.nbIdeas > 0 ? keyword.nbIdeas + ' ideas' : ''}
                                                                        </span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                )}
                            </td>
                            <td className="keywords-cell">
                                {store.keywords && (
                                    <div className="keywords">
                                        {store.keywords.keywords.map(keyword => (
                                            <div className={keyword.nbIdeas > 0 ? 'success' : 'secondary'} onClick={() => setKeywordInModal({...keyword, storeID: store.id})}>
                                                <span>
                                                    {keyword.name} - {keyword.grades}
                                                </span>
                                                <span>
                                                    {keyword.nbIdeas > 0 ? keyword.nbIdeas + ' ideas' : ''}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {keywordInModal !== null && (
                <Modal show={true} onHide={() => setKeywordInModal(null)}>
                    <Modal.Body>
                        <div className="mb-3">
                            <a href={'/product-map/?keyword=' + keywordInModal.id} target="_blank">Open Keyword</a>
                        </div>
                        {keywordInModal.nbIdeas === 0 && (
                            <>
                                <Button variant="danger" size="sm" onClick={removeKeywordFromStore}>Remove Keyword from store</Button>
                            </>
                        )}
                        <Button size="sm" onClick={duplicateKeywordToOtherStore}>Duplicate Keyword to other store</Button>
                    </Modal.Body>
                </Modal>
            )}

            {storeInModal !== null && (
                <Modal show={true} onHide={() => setStoreInModal(null)}>
                    <Modal.Header closeButton>
                        Store {storeInModal.id}
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>Subjects</Form.Label>
                            <Form.Control value={storeInModal.subjects} onChange={e => setStoreInModal({...storeInModal, subjects: e.target.value})} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Grades</Form.Label>
                            <Form.Control value={storeInModal.grades} onChange={e => setStoreInModal({...storeInModal, grades: e.target.value})} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={saveStoreInModal}>Save</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    )
}

export default StoreKeywords;