import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { updateDataAPI } from '../../../utils/query';
import UploadFile from '../../../components/content-studio/upload-file';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

const SubmitTaskCardDesign = ({ selectedPartID, selectedPart, updatePartZipFile }) => {
    const [loading, setLoading] = useState(false);
    const [taskCardColorCanvaUrl, setTaskCardColorCanvaUrl] = useState(selectedPart?.taskCardColorCanvaUrl);
    const [taskCardBWCanvaUrl, setTaskCardBWCanvaUrl] = useState(selectedPart?.taskCardBWCanvaUrl);
    const [taskCardAnswerSheetCanvaUrl, setTaskCardAnswerSheetCanvaUrl] = useState(selectedPart?.taskCardAnswerSheetCanvaUrl);

    useEffect(() => {
        setTaskCardColorCanvaUrl(selectedPart?.taskCardColorCanvaUrl ? selectedPart.taskCardColorCanvaUrl : '');
        setTaskCardBWCanvaUrl(selectedPart?.taskCardBWCanvaUrl ? selectedPart.taskCardBWCanvaUrl : '');
        setTaskCardAnswerSheetCanvaUrl(selectedPart?.taskCardAnswerSheetCanvaUrl ? selectedPart.taskCardAnswerSheetCanvaUrl : '');
    }, [selectedPartID]);

    const submit = async () => {
        await updateDataAPI('PATCH', 'resources/taskcard-parts/' + selectedPartID + '/', {
            taskCardColorCanvaUrl,
            taskCardBWCanvaUrl,
            taskCardAnswerSheetCanvaUrl,
        });
        window.alert('submit successfully');
    }

    return (
        <Container>
            <Form>
                <Row>
                    <Col>
                        <Form.Group className="mb-5">
                            <Form.Label>Link canva Color</Form.Label>
                            <Form.Control value={taskCardColorCanvaUrl} onChange={e => setTaskCardColorCanvaUrl(e.target.value)} />
                            <div className="mt-3">
                                <UploadFile
                                    id={selectedPartID}
                                    setLoading={setLoading}
                                    uploadUrl={'resources/upload-taskcard-zip-file/'}
                                    allowedFileExtentions={['zip']}
                                    fieldName={'taskCardColorZipFile'}
                                    uploadedFileName={selectedPart.taskCardColorZipFile}
                                    setUploadedFileName={fileName => updatePartZipFile(fileName, 'color')}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Link canva B&W</Form.Label>
                            <Form.Control value={taskCardBWCanvaUrl} onChange={e => setTaskCardBWCanvaUrl(e.target.value)} />
                            <div className="mt-3">
                                <UploadFile
                                    id={selectedPartID}
                                    setLoading={setLoading}
                                    uploadUrl={'resources/upload-taskcard-zip-file/'}
                                    allowedFileExtentions={['zip']}
                                    fieldName={'taskCardBWZipFile'}
                                    uploadedFileName={selectedPart.taskCardBWZipFile}
                                    setUploadedFileName={fileName => updatePartZipFile(fileName, 'bw')}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Link canva Answer Sheet</Form.Label>
                            <Form.Control value={taskCardAnswerSheetCanvaUrl} onChange={e => setTaskCardAnswerSheetCanvaUrl(e.target.value)} />
                            <div className="mt-3">
                                <UploadFile
                                    id={selectedPartID}
                                    setLoading={setLoading}
                                    uploadUrl={'resources/upload-taskcard-zip-file/'}
                                    allowedFileExtentions={['zip']}
                                    fieldName={'taskCardAnswerSheetZipFile'}
                                    uploadedFileName={selectedPart.taskCardAnswerSheetZipFile}
                                    setUploadedFileName={fileName => {
                                        updatePartZipFile(fileName, 'answersheet');
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Button onClick={submit} className="mt-3">Submit</Button>
                    </Col>
                </Row>
            </Form>
            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="dark" />
                </div>
            )}
        </Container>
    )
}

export default SubmitTaskCardDesign;