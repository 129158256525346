import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import './styles.scss';

const IconsList = ({
    icons = [],
    selectedIcons = [],
    showDeleteBtn = true,
    removeIcon = () => { },
    handleClickIcon = () => { },
}) => {
    return (
        <div className="icons-list">
            {icons.map((icon, index) => {
                const iconUrl = icon?.colorUrl ?? icon?.colorThumbnailUrl ?? icon?.bwUrl ?? icon?.bwThumbnailUrl;
                return (
                    <Card
                        key={'icon-' + index}
                        onClick={() => handleClickIcon(icon)}
                        className={selectedIcons.some((item) => item.id === icon.id) ? 'icons-list__item--selected' : ''}
                    >
                        <Card.Img variant="top" src={iconUrl} alt={icon.name} />
                        <Card.Body>
                            <Card.Text>{icon.id}. {icon.name}</Card.Text>
                            {showDeleteBtn && (
                                <Button variant="danger" onClick={() => removeIcon(icon.id)} size="sm">
                                    Delete
                                </Button>
                            )}
                        </Card.Body>
                    </Card>
                );
            })}
        </div>
    )
};

export default IconsList;

