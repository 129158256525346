import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Button, Form } from 'react-bootstrap';
import { updateDataAPI } from '../../../utils/query';
import { useState } from 'react';

const IdeaPageComments = ({ page, pages, setPages, setLoading }) => {
    const [activeWSCommentsID, setActiveWSCommentsID] = useState(null);
    const [activeComment, setActiveComment] = useState();

    const deleteComment = async (pageID, commentID) => {
        setLoading(true);
        await updateDataAPI('DELETE', 'product-dev/idea-page-comments/' + commentID + '/');
        setPages(pages.map(page => {
            if (page.id !== pageID) {
                return page;
            }
            return { ...page, comments: page['comments'].filter(c => c.id !== commentID) };
        }));
        setLoading(false);
    }

    const submitComment = async (e, pageID) => {
        setLoading(true);
        e.preventDefault();
        const res = await updateDataAPI('POST', 'product-dev/idea-page-comments/', {
            ideaPage: page.id,
            content: activeComment
        });
        if (res.status === 201) {
            setActiveWSCommentsID(null);
            setActiveComment();
            setPages(pages.map(page => {
                if (page.id !== pageID) {
                    return page;
                }
                return { ...page, comments: page['comments'].concat([res.data]) };
            }));
        } else {
            window.alert('Error');
        }
        setLoading(false);
    }

    return (
        <div className="page-comments">
            <div className="comments">
                {page.comments.map(comment => (
                    <div key={comment.id} className="comment">
                        <div className="comment-author">{comment.authorFullName}</div>
                        <div>{comment.content}</div>
                        <FontAwesomeIcon icon={faClose} className="comment-delete-icon" onClick={() => deleteComment(page.id, comment.id)} />
                    </div>
                ))}
            </div>
            {activeWSCommentsID === page.id ? (
                <>
                    <Form className="comments-form">
                        <Form.Control as="textarea" rows={4} value={activeComment} onChange={e => setActiveComment(e.target.value)} />
                    </Form>
                    <Button size="sm" onClick={e => submitComment(e, page.id)}>Submit</Button>
                </>
            ) : (
                <Button size="sm" variant="light" onClick={() => setActiveWSCommentsID(page.id)}>Add comment</Button>
            )}
        </div>
    )
}

export default IdeaPageComments;