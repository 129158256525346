import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getRandomColorHex } from '../../../utils/commonUtil';

const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="rotate(-35)"
            >
                {payload.value}
            </text>
        </g>
    );
};

const CustomizedLabelLineChart = ({ data = [], dateLineKeys = [], dataKeyX = 'date' }) => {

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} width={1000} height={300} margin={{ top: 20, right: 30, left: 20, bottom: 10, }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey={dataKeyX}
                    tick={<CustomizedAxisTick />}
                    height={80} />
                <YAxis />
                <Tooltip />
                <Legend />
                {dateLineKeys.map((dataKey) => (
                    <Line type="monotone" dataKey={dataKey} stroke={getRandomColorHex()} dot={false} />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
}

export default CustomizedLabelLineChart;