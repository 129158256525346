import { useEffect, useState, useRef } from 'react';
import './styles.scss';

const strToArr = (str) => {
    const regex = /(#(?:[a-zA-Z_]+))/g;
    return str.split(regex).map((item) => ({
        text: item,
        newText: "",
        isDynamic: regex.test(item),
    }));
};

const TextEditable = (props) => {
    const { value, onBlur, onSave, onCancel, isMultiline } = props;
    const [text, setText] = useState(undefined);
    const inputRef = useRef(null);
    useEffect(() => {
        inputRef.current.click();
        setText(value);
    }, []);
    return (
        <>
            {isMultiline ? (
                <textarea
                    className="text-editable__textarea"
                    autoFocus
                    ref={inputRef}
                    value={text ?? ""}
                    onChange={(event) => setText(event.target.value)}
                    onBlur={onBlur}
                    rows={5}
                />
            ) : (
                <input
                    className="text-editable__input"
                    autoFocus
                    ref={inputRef}
                    type="text"
                    value={text ?? ""}
                    onChange={(event) => setText(event.target.value)}
                    onBlur={onBlur}
                />
            )}

            <div>
                <div className="text-editable__action-list">
                    <div className="text-editable__action-item save" onClick={() => onSave(text)}>
                        Save
                    </div>
                    <div className="text-editable__action-item cancel" onClick={() => onCancel()}>
                        Cancel
                    </div>
                </div>
            </div>
        </>
    );
};

const InteractivePromptBlock = (props) => {
    const { prompt, onSubmit, isSubmit, init, multilineFields, onlyClickableFields, callbackClickable, page } = props;
    const [items, setItems] = useState(strToArr(prompt));
    const [missIndexes, setMissIndexes] = useState([]);
    const [indexEditable, setIndexEditable] = useState(null);

    const onClickSubmit = () => {
        let data = {}
        items.forEach(item => {
            if (item.isDynamic) data[item.text] = item.newText
        })
        onSubmit(data)
    };

    useEffect(() => {
        if (isSubmit) {
            onClickSubmit();
        }
    }, [isSubmit])

    useEffect(() => {
        if (init)
        setItems(prev => prev.map(item => {
                if (item.isDynamic && init.hasOwnProperty(item.text)) {
                    return {
                        ...item,
                        newText: init[item.text] ?? ''
                    }
                }
                if (page.theme === null || page.theme === undefined) {
                    item['text'] = item['text'].replace('The content of the worksheet is about:', '');
                }

                if (page.content.trainingText === null || page.content.trainingText === undefined) {
                    item['text'] = item['text'].replace('# TRAINING TEXT AND EXAMPLES:', '').replace('Training Example: ', '')
                }
                return item;
            })
        )
    }, []);

    return (
        <>
            <div className="interactive-prompt">
                {items.map((item, index) => {
                    if (item.isDynamic) {
                        if (index === indexEditable && !onlyClickableFields.includes(item.text)) {
                            return (
                                <TextEditable
                                    value={item.newText}
                                    onSave={(value) => {
                                        const newItems = [...items];
                                        newItems[index].newText = value;
                                        setItems(newItems);
                                        setIndexEditable(null);
                                    }}
                                    onCancel={() => {
                                        setIndexEditable(null);
                                    }}
                                    isMultiline={multilineFields.includes(item.text)}
                                />
                            );
                        }
                        return (
                            <div key={index}>
                                <span
                                    className={`interactive-prompt__text ${
                                        missIndexes.includes(index)
                                            ? "interactive-prompt__text--miss"
                                            : "interactive-prompt__text"
                                    } ${
                                        item.newText.trim() !== ""
                                            ? "interactive-prompt__text--completed"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        if (onlyClickableFields.includes(item.text)) {
                                            if (typeof callbackClickable[item.text] === 'function') {
                                                callbackClickable[item.text]()
                                            }
                                            return;
                                        }
                                        setIndexEditable(index);
                                        setMissIndexes([]);
                                    }}
                                >
                                    {item.newText.trim() !== ""
                                        ? item.newText
                                        : item.text}
                                </span>
                            </div>
                        );
                    }
                    return <span>{item.text}</span>;
                })}
            </div>
            {/* <Button onClick={() => onClickSubmit()}>Submit</Button> */}
        </>
    );
};

export default InteractivePromptBlock;