export const getLastPositionIndexTemplateInCollection = (templates = [], collectionID) => {
    let lastPositionIndex = 0;
    if (templates[templates.length - 1] !== undefined) {
        if (templates[templates.length - 1].hasOwnProperty('positionIndex')) {
            lastPositionIndex = templates[templates.length - 1].positionIndex;
        } else {
            const currentTemplateCollection = templates[templates.length - 1]['collections'].find((item) => item.collection === collectionID);
            if (currentTemplateCollection) {
                lastPositionIndex = currentTemplateCollection['positionIndex'];
            };
        };
    };
    return lastPositionIndex;
}