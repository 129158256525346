import { Container, Spinner, Tab, Tabs } from 'react-bootstrap';

import './styles.scss';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../utils/query';
import StoreKeywords from '../../components/store-map/store-keywords-table';
import OrphanKeywords from '../../components/store-map/orphan-keywords';

const StoreMapPage = () => {
    const [boomStores, setBoomStores] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchStores = async() => {
            setLoading(true);
            const res = await getDataFromAPI('stores/?active=true&additionalFields=keywords');
            setBoomStores(res.data);
            setLoading(false);
        }

        fetchStores();
    }, []);

    return (
        <Container className="store-map-page" fluid>
            <Tabs>
                <Tab eventKey="storeKeywords" title="Store Keywords">
                    <StoreKeywords
                        stores={boomStores}
                        setStores={setBoomStores}
                        setLoading={setLoading}
                    />
                </Tab>
                <Tab eventKey="orphanKeywords" title="Orphan Keywords">
                    <OrphanKeywords
                        setLoading={setLoading}
                    />
                </Tab>
            </Tabs>


            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    );
}

export default StoreMapPage;