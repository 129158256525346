import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { updateDataAPI } from '../../../utils/query';

const NewStoreModal = ({hideModal}) => {
    const [id, setID] = useState(null);
    const [email, setEmail] = useState(null);
    const [name, setName] = useState(null);
    const [slug, setSlug] = useState(null);
    const [tptSlug, setTPTSlug] = useState(null);
    const [vaVpnID, setVAVPNID] = useState(null);
    const [vaVPNCity, setVAVPNCity] = useState(null);
    const [canCreate, setCanCreate] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id !== null && email !== null && name !== null && slug !== null && tptSlug !== null && vaVpnID !== null && vaVPNCity !== null) {
            setCanCreate(true);
        } else {
            setCanCreate(false);
        }
    }, [id, email, name, slug, tptSlug, vaVpnID, vaVPNCity]);

    const create = async() => {
        setLoading(true);
        await updateDataAPI('POST', 'resources/create-new-store/', {
            id, email, name, slug, url: tptSlug, vaVpnID, vaVPNCity
        });
        window.open('/ideas?selectedStoreID=' + id, '_blank', 'noopener,noreferrer');
        setLoading(false);
    }

    return (
        <Modal
            show={true}
            onHide={hideModal}
            className="new-store-modal" size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    New Store
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>ID</Form.Label>
                            <Form.Control value={id} onChange={e => setID(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control value={email} onChange={e => setEmail(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control value={name} onChange={e => setName(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Slug</Form.Label>
                            <Form.Control value={slug} onChange={e => setSlug(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>TPT Slug</Form.Label>
                            <Form.Control value={tptSlug} onChange={e => setTPTSlug(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>VPN ID</Form.Label>
                            <Form.Control value={vaVpnID} onChange={e => setVAVPNID(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>VPN City</Form.Label>
                            <Form.Control value={vaVPNCity} onChange={e => setVAVPNCity(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {canCreate && (
                    <Button onClick={create}>Create</Button>
                )}
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
}

export default NewStoreModal;