import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ImageWrapper from '../../../common/image-wrapper';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';

const DataCell = ({cell, rowIndex, cellIndex, cellItemIndex = null, changeIconCustomTypePhoto, handleDeleteDataSetRow, handleChangeCellValue}) => {
    const [value, setValue] = useState(null);

    const parseKatexTags = html => {
        // const katexRegex = /<katex latex="([^"]+)"><\/katex>/g;
        // const updatedHtml = html.replace(katexRegex, '<BlockMath>$1"</BlockMath>');

        // return updatedHtml;
        const katexRegex = /<katex latex="([^"]+)"><\/katex>/g;
        let match;
        const parts = [];
        let lastIndex = 0;

        while ((match = katexRegex.exec(html)) !== null) {
            if (match.index > lastIndex) {
            parts.push(html.slice(lastIndex, match.index));
            }
            parts.push({ latex: match[1] });
            lastIndex = match.index + match[0].length;
        }
        if (lastIndex < html.length) {
            parts.push(html.slice(lastIndex));
        }
        return parts;
    }

    const save = async() => {
        await handleChangeCellValue(value, rowIndex, cellIndex, cellItemIndex);
        setValue(null);
    }

    const renderContent = (parts) => {
        return parts.map((part, index) => {
          if (typeof part === 'string') {
            return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
          } else {
            return <BlockMath key={index}>{part.latex}</BlockMath>;
          }
        });
    };

    if (cell.indexOf('http') === -1) {
        return (
            value === null ? (
                cell.indexOf('<katex') > -1 ? (
                    renderContent(parseKatexTags(cell))
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: cell }} onClick={() => {
                        setValue(cell);
                    }}></div>
                )
            ) : (
                <div>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                    <Button size="sm" variant="secondary" onClick={save}>Close</Button>
                </div>
            )
        );
    }

    return (
        <div>
            <ImageWrapper src={cell} />
            <div className="cell-images-btns">
                {cell.indexOf('customTypePhoto=real') > -1 ? (
                    <>
                        <Button size="sm" variant="secondary" onClick={() => changeIconCustomTypePhoto('cartoon')}>Cartoon</Button>
                        <Button size="sm" variant="success">Real</Button>
                    </>
                ) : (
                    <>
                        <Button size="sm" variant="success">Cartoon</Button>
                        <Button size="sm" variant="secondary" onClick={() => changeIconCustomTypePhoto('real')}>Real</Button>
                    </>
                )}
                <Button variant="danger" size="sm" onClick={() => handleDeleteDataSetRow(rowIndex)}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </div>
        </div>
    )
}

export default DataCell;
