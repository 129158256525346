import './styles.scss';
import { toast } from 'react-toastify';
import { updateDataAPI } from '../../../../../utils/query';

const TableIndexCell = ({
    rowIndex = 1,
    part = {},
    parts = [],
    handleSelectPart = () => { },
    setParts = () => { },
    refresh = () => { },
    setLoading = () => { }
}) => {
    const movePart = async (part, direction) => {
        setLoading(true);
        const currentPartIndex = part.positionIndex;
        const targetPart = parts[direction === 'up' ? (rowIndex - 1) : (rowIndex + 1)];
        await updateDataAPI('PATCH', 'resources/idea-parts/' + part.id + '/', {
            positionIndex: targetPart.positionIndex
        });
        await updateDataAPI('PATCH', 'resources/idea-parts/' + targetPart.id + '/', {
            positionIndex: currentPartIndex
        });
        refresh(['parts']);
    };

    const handleDeletePart = async (part) => {
        const confirmed = window.confirm('Are you sure want to delete this part ?');
        if (confirmed) {
            const url = `resources/idea-parts/${part.id}/`;

            try {
                const res = await updateDataAPI('DELETE', url);
                setParts((prev) => prev.filter((p) => p.id !== part.id));
                toast.success('Deleted part.');
            } catch (err) {
                console.error('An error occured when deleting part: ', err.message);
            };
        }
    }

    return (
        <div>
            <div className="text-center mb-3">{rowIndex + 1}</div>
            <div className="d-flex justify-content-between">
                {/* <Button size="sm" variant="primary" onClick={() => handleSelectPart(part)} className="me-2">Edit</Button>
                <Button size="sm" variant="danger" onClick={() => handleDeletePart(part, rowIndex)}>Delete</Button> */}
                {/* {rowIndex !== 0 && (
                    <Button onClick={() => movePart(part, 'up')} size="sm" variant="secondary">
                        <FontAwesomeIcon icon={faCaretUp} />
                    </Button>
                )}
                {rowIndex !== parts.length - 1 && (
                    <Button onClick={() => movePart(part, 'down')} size="sm" variant="secondary">
                        <FontAwesomeIcon icon={faCaretDown} />
                    </Button>
                )} */}
            </div>
        </div>
    )
};

export default TableIndexCell;