import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../../../utils/query';
import { Modal } from 'react-bootstrap';
import './styles.scss';

const SelectThumbnailTemplateModal = ({storeID, customID, itemType, hideModal}) => {
    const [thumbnailTemplates, setThumbnailTemplates] = useState([]);
    useEffect(() => {
        fetchThumbnailTemplates();
    }, []);

    const fetchThumbnailTemplates = async () => {
        const res = await getDataFromAPI('auto-content/templates/?storeID=' + storeID + '&page_size=100');
        setThumbnailTemplates(res.data['results']);
    }

    const createIdeaThumbnailPreviewPages = async(templateID) => {
        const res = await updateDataAPI('POST', 'resources/create-idea-thumbnail-preview-page/?customID=' + customID + '&templateID=' + templateID + '&type=' + itemType);
        console.log(res.data);
        hideModal(res.data['templateData']['imageUrl']);
    }

    return (
        <Modal show={true} onHide={hideModal} className="select-thumbnail-template-modal" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    Thumbnail & Preview Templates
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="thumbnail-templates">
                    {thumbnailTemplates.map((template, index) => (
                        <div key={index} className="thumbnail-template" onClick={() => createIdeaThumbnailPreviewPages(template.id)}>
                            <img src={template.imageUrl} alt="thumbnail" />
                            <div className="text-center">{template.name}</div>
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SelectThumbnailTemplateModal;