import { Button, Container, Spinner, Tab, Tabs } from 'react-bootstrap';
import './styles.scss';
import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import TaskCardResult from './taskcard-result';
import Constant from '../../Constant';
import TaskCardAnswerSheet from './taskcard-answer-sheet';
import SubmitTaskCardDesign from './submit-taskcard-design';
import InsertDataTaskCard from './insert-data-taskcard';
import TaskCardTemplates from './taskcard-templates';
import { hasPermission } from '../../utils/auth';
import { useSelector } from 'react-redux';

const FormatAIChatTaskcardPage = ({ }) => {
    const { activityPermissions } = useSelector((state) => state.permissions);

    const [displayMode, setDisplayMode] = useState('taskcard');
    const [customID, setCustomID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [productIdea, setProductIdea] = useState();
    const [parts, setParts] = useState([]);
    const [selectedPartID, setSelectedPartID] = useState();
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        const customID = (new URLSearchParams(window.location.search)).get('customID');
        setCustomID(customID);
        fetchProductIdea(customID);
        fetchTaskCartParts(customID);
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    useEffect(() => {
        if (displayMode === 'taskcard' && customID) {
            fetchTaskCartParts(customID);
        }
    }, [displayMode]);

    useEffect(() => {
        if (parts.length > 0) {
            const selectedPart = parts.find(p => p.id === selectedPartID);
            const csv = selectedPart['taskCardCSV'];
            formatResult(csv);
        }
    }, [selectedPartID]);

    const fetchProductIdea = async (customID) => {
        setLoading(true);
        const res = await getDataFromAPI('resources/get-product-idea-by-customID/?customID=' + customID);
        setProductIdea(res.data);
        setLoading(false);
    }

    const fetchTaskCartParts = async (customID) => {
        const res = await getDataFromAPI('resources/taskcard-parts/?customID=' + customID);
        setParts(res.data);
        if (res.data.length > 0) {
            if (selectedPartID === undefined) {
                setSelectedPartID(res.data[0].id);
            } else {
                formatResult(res.data.find(p => p.id === selectedPartID)['taskCardCSV']);
            }
        }
    }

    const formatResult = (csv) => {
        if (csv) {
            setResult(csv.split('\n').map(row => row.split(';')));
        } else {
            setResult(null);
        }
    }

    const addNewPart = async () => {
        const res = await updateDataAPI('POST', 'resources/taskcard-parts/', {
            productIdea: productIdea.id,
        });
        setSelectedPartID(res.data['id']);
        setParts(parts.concat(res.data));
    }

    const deletePart = async () => {
        if (window.confirm('Are you sure?')) {
            await updateDataAPI('DELETE', 'resources/taskcard-parts/' + selectedPartID + '/');
            setParts(parts.filter(p => p.id !== selectedPartID));
            setSelectedPartID(parts.filter(p => p.id !== selectedPartID)[0].id);
        }
    }

    const clearDataPart = async () => {
        if (window.confirm('Are you sure?')) {
            await updateDataAPI('PATCH', 'resources/taskcard-parts/' + selectedPartID + '/', {
                taskCardCSV: null
            });
            setParts(parts.filter(p => {
                if (p.id === selectedPartID) {
                    return { ...p, taskCardCSV: null };
                }
                return p;
            }));
            setResult(null);
        }
    }

    return (
        <Container fluid className="format-ai-chat-taskcard-container">
            <div className="filters">
                <div className="select-part">
                    <select className="form-select select-part" onChange={e => setSelectedPartID(parseInt(e.target.value))}>
                        {parts.map((p, index) => (
                            <option selected={selectedPartID === p.id} key={p.id} value={p.id}>Part {index + 1}</option>
                        ))}
                    </select>
                    <Button size="sm" onClick={addNewPart}>Add part</Button>
                    {parts.length > 1 && hasPermission(userRoles, activityPermissions['DELETE_TASK_CARD_PART']) && (
                        <Button size="sm" onClick={deletePart} variant="danger">Delete part</Button>
                    )}
                </div>
            </div>
            <Tabs activeKey={displayMode} onSelect={(k) => setDisplayMode(k)} id="taskcard-tabs" className="mb-3">
                <Tab eventKey="taskcard" title="Task Card">
                    <div className="result">
                        {result ? (
                            <TaskCardResult customID={customID}
                                selectedPartID={selectedPartID}
                                result={result}
                                clearDataPart={clearDataPart}
                                setResult={setResult} />
                        ) : (
                            <div>No result</div>
                        )}
                    </div>
                </Tab>
                <Tab eventKey="answersheet" title="Answer Sheet">
                    {result ? (
                        <TaskCardAnswerSheet customID={customID} selectedPartID={selectedPartID} result={result} />
                    ) : (
                        <div>No result</div>
                    )}
                </Tab>
                {hasPermission(userRoles, activityPermissions['INSERT_TASK_CARD']) && (
                    <Tab eventKey="insert" title="Insert Data">
                        <InsertDataTaskCard result={result} selectedPartID={selectedPartID} customID={customID} setDisplayMode={setDisplayMode} />
                    </Tab>
                )}
                {hasPermission(userRoles, activityPermissions['SUBMIT_TASK_CARD']) && selectedPartID !== undefined && (
                    <Tab eventKey="submit" title="Submit Design">
                        <SubmitTaskCardDesign selectedPartID={selectedPartID}
                            selectedPart={parts.find(p => p.id === selectedPartID)}
                            updatePartZipFile={(fileName, fileType) => {
                                setParts(parts.map(p => {
                                    if (p.id === selectedPartID) {
                                        if (fileType === 'color') {
                                            return { ...p, taskCardColorZipFile: fileName };
                                        } else if (fileType === 'bw') {
                                            return { ...p, taskCardBWZipFile: fileName };
                                        }
                                        return { ...p, taskCardAnswerSheetZipFile: fileName };
                                    }
                                    return p;
                                }));
                            }} />
                    </Tab>
                )}
                {hasPermission(userRoles, activityPermissions['SEARCH_TASK_CARD_TEMPLATES']) && (
                    <Tab eventKey="taskcard-templates" title="Templates">
                        <TaskCardTemplates />
                    </Tab>
                )}
            </Tabs>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="dark" />
                </div>
            )}
        </Container>
    )
}

export default FormatAIChatTaskcardPage;