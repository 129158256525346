import { useState, useEffect } from 'react';
import { getDataFromAPI } from '../../../../utils/query';
import SelectFrameOrientation from '../select-frame-orientation';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { generateOptions } from '..';

export const FontFamilyOptionLabel = ({ option }) => (
    <span style={{ fontFamily: option.label }}>{option.id}. {option.label}</span>
);

export const FrameOptionLabel = ({ option }) => {
    const imageUrl = option.imageUrl;

    return (
        <div className="d-flex align-items-center">
            <Image
                style={{
                    width: 70,
                    height: 'auto',
                }}
                src={imageUrl}
                alt={option.label}
                rounded
                className="me-2"
            />
            <span>{option.id}. {option.label}</span>
        </div>
    )
};

const FontsAndFramesTab = ({
    hanldeChangeAssetsStore,
    selectedFontFamilies,
    selectedFrames,
    fontFamilies,
}) => {
    const [frameOrientation, setFrameOrientation] = useState('vertical');
    const [fontFamilyOptions, setFontFamilyOptions] = useState([]);
    const [frameOptions, setFrameOptions] = useState([]);

    useEffect(() => {
        const generateFontFamilyOptions = () => {
            const options = generateOptions(fontFamilies);
            setFontFamilyOptions(options);
        }

        generateFontFamilyOptions();
    }, [fontFamilies]);

    useEffect(() => {
        const generateFrameOptions = async () => {
            const url = `auto-content/frames/?orientation=${frameOrientation}`;

            const res = await getDataFromAPI(url);
            const options = generateOptions(res.data);

            setFrameOptions(options);
        }

        generateFrameOptions();
    }, [frameOrientation]);

    return (
        <Form>
            <Form.Group className="mb-3" controlId="favorite-fonts">
                <Form.Label>Font families:</Form.Label>
                {fontFamilyOptions.length > 0 && (
                    <Select
                        value={selectedFontFamilies}
                        isMulti
                        name="fontfamilies"
                        options={fontFamilyOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(value) => hanldeChangeAssetsStore('font-families', value)}
                        formatOptionLabel={(option) => (<FontFamilyOptionLabel option={option} />)}
                        isClearable={false}
                    />
                )}
                <Form.Text className="text-muted">
                    Select favorites font families for this store.
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="favorite-fonts">
                <Form.Label>Frames:</Form.Label>
                {frameOptions.length > 0 && (
                    <Select
                        value={selectedFrames}
                        isMulti
                        name="frames"
                        options={frameOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(value) => hanldeChangeAssetsStore('frames', value)}
                        formatOptionLabel={(option) => (<FrameOptionLabel option={option} />)}
                        isClearable={false}
                    />
                )}
                <SelectFrameOrientation
                    orientation={frameOrientation}
                    setOrientation={setFrameOrientation}
                />
                <Form.Text className="text-muted">
                    Select favorites frames for this store.
                </Form.Text>
            </Form.Group>
        </Form>
    )
}

export default FontsAndFramesTab;