import { useState, useEffect, Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { toast } from 'react-toastify';

const TrainingTextsPage = () => {
    const [trainingTexts, setTrainingTexts] = useState([]);

    const [editedCell, setEditedCell] = useState(null);

    const [inputNewTrainingText, setInputNewTrainingText] = useState('');

    useEffect(() => {
        const init = async () => {
            const res = await getDataFromAPI('auto-content/training-texts/');
            setTrainingTexts(res.data);
        };

        init();
    }, []);

    const deleteTrainingText = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this training text?');

        if (confirmed) {
            setTrainingTexts((prev) => prev.filter((trainingText) => trainingText.id !== id));

            try {
                await updateDataAPI('DELETE', `auto-content/training-texts/${id}/`);
                toast.success('Deleted successfully.');
                setEditedCell(null);
            } catch (e) {
                toast.error(`An error occured when updating: ${e.message}`);
            };
        };
    };

    const addTrainingText = async () => {
        if (trainingTexts.some((trainingText) => trainingText.name === inputNewTrainingText)) {
            toast.error('The training text with this name already exists.');
            return;
        }

        if (inputNewTrainingText !== '') {
            const url = 'auto-content/training-texts/';

            try {
                const res = await updateDataAPI('POST', url, { name: inputNewTrainingText });
                toast.success('Added successfully.');
                setInputNewTrainingText('');
                setTrainingTexts((prev) => ([
                    ...prev,
                    res.data
                ]));
            } catch (e) {
                toast.error(`An error occured when updating: ${e.message}`);
            }
        }
    };

    const updateTrainingTextOnServer = async () => {
        try {
            await updateDataAPI('PATCH', `auto-content/training-texts/${editedCell['id']}/`, editedCell);
            toast.success('Updated successfully.');
            setEditedCell(null);
        } catch (e) {
            toast.error(`An error occured when updating: ${e.message}`);
        };
    };

    const updateTrainingText = async () => {
        if (editedCell.name !== '') {
            setTrainingTexts((prev) => prev.map((trainingText) => {
                if (trainingText.id === editedCell.id) {
                    return editedCell;
                };
                return trainingText;
            }));
            await updateTrainingTextOnServer();
        };
    };

    const handleCellChange = (event, field) => {
        const { value } = event.target;
        setEditedCell((prevCell) => ({
            ...prevCell,
            [field]: value
        }));
    };

    const editTrainingText = (trainingTextValue) => {
        setEditedCell(trainingTextValue)
    }

    return (
        <Container className="training-texts-page" fluid>
            <Table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Definition</th>
                        <th>Examples</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {trainingTexts.map(trainingText => (
                        <tr key={trainingText.id}>
                            <td>{trainingText.id}</td>
                            <td>
                                {editedCell && editedCell.id === trainingText.id ? (
                                    <Form.Control
                                        name="name"
                                        value={editedCell?.name}
                                        onChange={(e) => handleCellChange(e, 'name')}
                                        autoFocus
                                    />
                                ) : (
                                    trainingText['name']
                                )}
                            </td>
                            <td>
                                {editedCell && editedCell.id === trainingText.id ? (
                                    <Form.Control
                                        name="definition"
                                        as="textarea"
                                        rows={3}
                                        value={editedCell?.definition}
                                        onChange={(e) => handleCellChange(e, 'definition')}
                                        autoFocus
                                    />
                                ) : (
                                    trainingText['definition']
                                )}
                            </td>
                            <td>
                                {editedCell && editedCell.id === trainingText.id ? (
                                    <Form.Control
                                        name="examples"
                                        as="textarea"
                                        rows={3}
                                        value={editedCell?.examples}
                                        onChange={(e) => handleCellChange(e, 'examples')}
                                        autoFocus
                                    />
                                ) : (
                                    trainingText['examples']
                                )}
                            </td>
                            <td>
                                {(editedCell && editedCell.id === trainingText.id) && (
                                    <Fragment>
                                        <Button
                                            variant="success"
                                            size="sm"
                                            onClick={() => updateTrainingText(trainingText)}
                                            className="me-2"
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => setEditedCell(null)}
                                            className="me-2"
                                        >
                                            Cancel
                                        </Button>
                                    </Fragment>
                                )}
                                {editedCell === null && (
                                    <Button variant="primary" size="sm" onClick={() => editTrainingText(trainingText)}>
                                        Edit
                                    </Button>
                                )}
                                {" "}
                                <Button variant="danger" size="sm" onClick={() => deleteTrainingText(trainingText.id)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Row>
                <Col>
                    <Form.Control
                        type="text"
                        name="newTrainingText"
                        placeholder="New training text name"
                        value={inputNewTrainingText}
                        onChange={(e) => setInputNewTrainingText(e.target.value)}
                    />
                </Col>
                <Col>
                    <Button
                        variant="primary"
                        onClick={() => addTrainingText()}
                    >
                        Add training text
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default TrainingTextsPage;