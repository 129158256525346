import { useEffect, useState } from 'react';
import { updateDataAPI } from '../../../utils/query';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Badge } from 'react-bootstrap';
import LiteCanvas from '../../auto-content/lite-canvas';

const PagesGrid = ({
    pages = [],
    setPages = () => { },
    selectedPages = [],
    setSelectedPages = () => { }
}) => {
    const nbThumbnailsPerRow = 6;

    const [rows, setRows] = useState({});
    const [startSelectedPageIndex, setStartSelectedPageIndex] = useState(0);

    useEffect(() => {
        const initRowsThumbnails = () => {
            const nbRows = Math.ceil(pages.length / nbThumbnailsPerRow);

            const result = [];

            for (let i = 0; i < nbRows; i++) {
                const startIndex = i * nbThumbnailsPerRow;
                const endIndex = startIndex + nbThumbnailsPerRow;
                const rowItems = pages.slice(startIndex, endIndex);
                const rowName = `row-${i + 1}`;
                const newRow = {
                    name: rowName,
                    items: rowItems,
                }
                result.push(newRow);
            };

            setRows(result);
        };

        initRowsThumbnails();
    }, [pages]);

    const updatedPageIndex = async (pageID, newPageIndex) => {
        const url = `resources/idea-pages/${pageID}/`;

        try {
            await updateDataAPI('PATCH', url, {
                pageIndex: newPageIndex,
            });
        } catch (e) {
            console.error(`An error occured when updating pageIndex: ${e.message}`);
        }
    }

    const togglePageSelection = (pageId, shiftKey) => {
        const pageIndex = pages.findIndex((page) => page.id === pageId);
        setStartSelectedPageIndex(pageIndex);

        if (shiftKey && startSelectedPageIndex != null) {
            const endSelectedPageIndex = pageIndex;

            if (endSelectedPageIndex !== -1 && pageIndex !== -1) {
                const start = Math.min(endSelectedPageIndex, startSelectedPageIndex);
                const end = Math.max(endSelectedPageIndex, startSelectedPageIndex);

                const newSelectedPages = pages
                    .slice(start, end + 1)
                    .map((page) => page.id);

                setSelectedPages(newSelectedPages);
                return;
            }
        }

        setSelectedPages((prevSelectedPages) => {
            if (prevSelectedPages.includes(pageId)) {
                return prevSelectedPages.filter((id) => id !== pageId);
            } else {
                return [...prevSelectedPages, pageId];
            }
        });
    }

    const handleDragPageThumbnailEnd = (result) => {
        if (!result.destination) return;

        let updatedRows;

        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = rows[source.droppableId];
            const destColumn = rows[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            updatedRows = {
                ...rows,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            };
            setRows(updatedRows);
        } else {
            const column = rows[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            updatedRows = {
                ...rows,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            }
            setRows(updatedRows);
        };
        let updatedPages = Object.values({ ...updatedRows }).flatMap((row) => row.items);
        setPages(updatedPages.map((page, index) => ({
            ...page,
            pageIndex: index + 1,
        })));
        updatedPages.forEach(async (page, index) => {
            await updatedPageIndex(page['id'], index + 1);
        });
    };

    return (
        <DragDropContext onDragEnd={handleDragPageThumbnailEnd}>
            {Object.entries(rows).map(([columnId, column], index) => (
                <div
                    className="auto-content-pages-grid-view__rows"
                    key={columnId}
                >
                    <div>
                        <Droppable
                            droppableId={columnId}
                            key={columnId}
                            direction='horizontal'
                            justifyContent='center'
                            alignContent='center'
                        >
                            {(provided, snapshot) => (
                                <div
                                    style={{ background: snapshot.isDraggingOver ? "lightblue" : "lightgrey" }}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="auto-content-pages-grid-view__row"
                                >
                                    {column.items.map((page, index) => (
                                        <Draggable
                                            key={`page-${page.id}`}
                                            draggableId={`page-${page.id}`}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        background: snapshot.isDragging ? "lightblue" : "white",
                                                        ...provided.draggableProps.style
                                                    }}
                                                    onClick={(event) => togglePageSelection(page.id, event.shiftKey)}
                                                    className={`auto-content-pages-grid-view__row__thumbnail ${selectedPages.includes(page.id) ? 'auto-content-pages-grid-view__row__thumbnail--selected' : ''}`}
                                                >
                                                    {page.type === 'auto' && (
                                                        page.content && page.content.imageUrl ? (
                                                            <img src={page.content.imageUrl} alt={`page-thumbnail-${page?.pageIndex}`} className={page.content.templateData?.orientation + '-img'} />
                                                        ) : (
                                                            page.content.aiResponse ? (
                                                                <LiteCanvas ideaTemplate={page.content} isThumbnail={true} />
                                                            ) : (
                                                                <img src={page.content.templateData?.imageUrl} alt={`page-thumbnail-${page?.pageIndex}`} className={page.content.templateData?.orientation + '-img'} />
                                                            )
                                                        )
                                                    )}
                                                    {page.type === 'manual' && (
                                                        page.content && page.content.imageUrl ? (
                                                            <img src={page.content.imageUrl} alt={`page-thumbnail-${page?.pageIndex}`} className={'img-page-thumbnail ' + page.content.templateData?.orientation + '-img'} />
                                                        ) : (
                                                            page.assets.filter(asset => asset.type === 'note').length > 0 ? (
                                                                <div dangerouslySetInnerHTML={{ __html: page.assets.find(asset => asset.type === 'note').note.substr(0, 100) + '...' }}></div>
                                                            ) : (
                                                                page.assets.filter(asset => asset.type === 'image').length > 0 && (
                                                                    <img src={page.assets.find(asset => asset.type === 'image').image} alt={`page-thumbanail-${page?.pageIndex}`} className="img-page-thumbnail"/>
                                                                )
                                                            )
                                                        )
                                                    )}
                                                    <div className="page-info">
                                                        <h5>{`Page ${page.pageIndex + 1}`}</h5>
                                                        {page['content']['templateData'] && (<div><strong>Template {page['content']['template']}:</strong> <i>{page['content']['templateData']['name']}</i></div>)}
                                                        {page['skillName'] && (<div><strong>Skill:</strong> <i>{page['skillName']}</i></div>)}
                                                        {page['themeName'] && (<div><strong>Theme:</strong> <i>{page['themeName']}</i></div>)}
                                                        {page.content && page.content['trainingText'] && (
                                                            <div>
                                                                <strong>Training text:</strong> <i>{page.content['trainingTextData']['name']}</i>
                                                            </div>
                                                        )}
                                                        <div>
                                                            {page.isFreePage && (
                                                                <strong className="text-info">FREE</strong>
                                                            )}
                                                            {page.isHighlightPage && (
                                                                <strong className="text-danger"> - HIGHLIGHT</strong>
                                                            )}
                                                            {page.dataValidated && (
                                                                <strong className="text-success"> - DATA VALIDATED</strong>
                                                            )}
                                                            {page.designValidated && (
                                                                <strong className="text-success"> - DESIGN VALIDATED</strong>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {page.type === 'auto' && page.content.collection !== null && (
                                                        <Badge bg="warning">
                                                            {page.content.collectionIndex + ' - ' + page.content.collectionName}
                                                        </Badge>
                                                    )}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </div>
            ))
            }
        </DragDropContext >
    );
};

export default PagesGrid;