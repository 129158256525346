import { useEffect, useState } from 'react';
import { Form, Modal, Row, Col, Button } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';

import './styles.scss';
import { toast } from 'react-toastify';
import { isInvalidValue } from '../../../../utils/commonUtil';

export const NON_AI_GENERATE_MODAL_TARGET = {
    link: 'link',
    generate: 'generate',
    other: 'other'
}

const NonAIGenerateModal = ({
    idea,
    ideaTemplateIDs,
    ideaSkill,
    currentFunctionID,
    currentFunctionArgs,
    refresh,
    hideModal,
    setLoading,
    target = NON_AI_GENERATE_MODAL_TARGET.generate,
    handleFunctionArgs,
    showTestResult = null,
    linkedTemplates = [],
    linkedCollections = [],
}) => {
    const [functions, setFunctions] = useState([]);
    const [args, setArgs] = useState([]);
    const [selectedFunctionID, setSelectedFunctionID] = useState(currentFunctionID);
    const [selectedArgs, setSelectedArgs] = useState({});

    useEffect(() => {
        const generateInitialSelectedOption = async () => {
            let url = 'auto-content/non-ai-functions/';

            if (linkedCollections.length > 0 || linkedTemplates.length > 0) {
                url += '?selectedCollections=' + linkedCollections.join(',') + '&selectedTemplates=' + linkedTemplates.join(',');
            };

            try {
                const res = await getDataFromAPI(url);
                const nonAIFunctions = res.data;
                setFunctions(nonAIFunctions);
                if (currentFunctionID) {
                    const selectedFunction = setArgsOfNonAIFunction(currentFunctionID, nonAIFunctions);
                    if (selectedFunction) {
                        setSelectedFunctionID(currentFunctionID);
                        handleSetSelectedFunctionArgs(currentFunctionID, nonAIFunctions);
                    }
                }
            } catch(err) {
                console.error('An error occured when fetching non ai functions: ', err.message);
            }
        };

        generateInitialSelectedOption();
    }, [currentFunctionArgs, currentFunctionID]);

    const setArgsOfNonAIFunction = (selectedNonAIFunctionID, nonAIFunctions = functions) => {
        const selectedFunction = nonAIFunctions.find(func => func.id === parseInt(selectedNonAIFunctionID));
        if (selectedFunction) {
            setArgs(selectedFunction.arguments);
        }
        return selectedFunction;
    };

    const generate = async () => {
        setLoading(true);
        if (!isNonAIFunctionArgsValid(selectedFunctionID, selectedArgs)) {
            toast.warning('Please fill all required values of non ai function.');
            return;
        }
        console.log(selectedFunctionID, selectedArgs);
        for (let i = 0; i < ideaTemplateIDs.length; i++) {
            await updateDataAPI('POST', 'auto-content/reset-zones-and-linked-text-items-in-page/?ideaTemplateID=' + ideaTemplateIDs[i]);
        }
        try {
            const res = await updateDataAPI('POST', 'auto-content/generate-ai-response-by-non-ai/', {
                ideaID: idea.id,
                ideaTemplateIDs,
                functionID: selectedFunctionID,
                args: selectedArgs,
            });
            if (showTestResult) {
                showTestResult(res.data['result'][0]);
            }
        } catch (err) {
            window.alert('Gen data error');
        }
        refresh(['pages']);
        setLoading(false);
        hideModal();
    }

    const linkIdeaSkillWithNonAIFunction = async () => {
        if (!isNonAIFunctionArgsValid(selectedFunctionID, selectedArgs)) {
            toast.warning('Please fill all required values of non ai function.');
            return;
        }
        setLoading(true);
        if (ideaSkill) {
            console.log(selectedFunctionID, selectedArgs);
            try {
                await updateDataAPI('PATCH', `resources/idea-skills/${ideaSkill.id}/`, {
                    nonAIFunction: selectedFunctionID,
                    nonAIFunctionArgs: selectedArgs,
                });
                refresh(['skills']);
                toast.success('Linked successufully.');
            } catch (err) {
                toast.error(`An error occured when linked non ai function to idea skill: ${err.message}`);
            };
        }
        if (handleFunctionArgs) {
            handleFunctionArgs(parseInt(selectedFunctionID), selectedArgs);
        }
        setLoading(false);
        hideModal();
    }

    const isNonAIFunctionArgsValid = (functionID, currentArgs) => {
        const selectedFunction = functions.find(func => func.id === parseInt(functionID));
        if (!selectedFunction || !currentArgs) return false;

        const functionArgs = selectedFunction.arguments;

        return functionArgs.every((arg) => currentArgs.hasOwnProperty(arg['name']) && !isInvalidValue(currentArgs[arg['name']]));
    }

    const handleChangeSelectedNonAIFunction = (value) => {
        setSelectedFunctionID(value);
        if (value) {
            handleSetSelectedFunctionArgs(value);
        } else {
            setArgs([]);
            setSelectedArgs({});
        }
    }

    const handleSetSelectedFunctionArgs = (nonAIFunctionID, nonAIFunctions = functions) => {
        const selectedFunction = setArgsOfNonAIFunction(nonAIFunctionID, nonAIFunctions);
        if (selectedFunction) {
            let selectedArgs = {};
            if (parseInt(nonAIFunctionID) === currentFunctionID && currentFunctionArgs) {
                selectedArgs = currentFunctionArgs;
            } else {
                for (let i = 0; i < selectedFunction.arguments.length; i++) {
                    if (selectedFunction.arguments[i].type === 'choices') {
                        selectedArgs[selectedFunction.arguments[i].name] = selectedFunction.arguments[i].choices[0];
                    }
                }
            }
            console.log('> selectedArgs', selectedArgs);
            setSelectedArgs(selectedArgs);
        }
    }

    return (
        <Modal show={true} onHide={hideModal} size="lg" className="non-ai-generate-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Non-AI Generate
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Label className="mb-3"><strong>Function</strong></Form.Label>
                            <Form.Select
                                onChange={(e) => handleChangeSelectedNonAIFunction(e.target.value)}
                                value={selectedFunctionID}
                            >
                                <option></option>
                                {functions.map((func, index) => (
                                    <option value={func.id} key={index}>{func.id}. {func.name}</option>
                                ))}
                            </Form.Select>
                            {selectedFunctionID !== null && (
                                <Form.Text>{functions.find(f => f.id === parseInt(selectedFunctionID))?.description}</Form.Text>
                            )}
                        </Col>
                        <Col>
                            <Form.Label className="mb-3"><strong>Options</strong></Form.Label>
                            {args.map((arg, index) => (
                                <Form.Group id={'function-option-' + index} key={index} className="mb-3">
                                    <Row>
                                        <Col>
                                            <Form.Label>{arg.name}</Form.Label>
                                            <Form.Text>{arg.description}</Form.Text>
                                        </Col>
                                        <Col>
                                            {arg.type === 'choices' && (
                                                <Form.Select
                                                    onChange={(e) => setSelectedArgs({ ...selectedArgs, [arg.name]: e.target.value })}
                                                    value={selectedArgs[arg.name]}
                                                >
                                                    {arg.choices.map((choice, index) => (
                                                        <option value={choice} key={index}>{choice}</option>
                                                    ))}
                                                </Form.Select>
                                            )}
                                            {arg.type === 'input' && (
                                                <Form.Control
                                                    type="text"
                                                    onChange={(e) => setSelectedArgs({ ...selectedArgs, [arg.name]: e.target.value })}
                                                    value={selectedArgs[arg.name]}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            ))}
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {target === NON_AI_GENERATE_MODAL_TARGET.generate ? (
                    <Button onClick={generate}>Generate</Button>
                ) : (
                    <Button onClick={linkIdeaSkillWithNonAIFunction}>Link</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default NonAIGenerateModal