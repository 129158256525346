import { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import './styles.scss';
import { toast } from 'react-toastify';
import { updateDataAPI } from '../../../utils/query';

const UploadFile = ({
    label,
    className = '',
    id = null,
    setLoading = () => { },
    uploadUrl = null,
    allowedFileExtentions = [],
    fieldName = null,
    uploadedFileName,
    setUploadedFileName,
    showImage = false,
    multiple = false,
}) => {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (selectedFiles) {
            upload();
        }
    }, [selectedFiles]);

    const getFileExtension = (filename) => {
        return filename.split('.').pop().toLowerCase();
    }

    const handleFileChange = (e) => {
        const files = e.target.files;

        if (files && Array.from(files).filter(file => allowedFileExtentions.indexOf(getFileExtension(file.name)) === -1).length === 0) {
            setSelectedFiles(files);
        } else {
            setSelectedFiles(null);
            toast.warn(`Invalid file extension. Please select a file with folowing extensions: ${allowedFileExtentions.join(',')}`)
        }
    };

    const upload = async () => {
        if (fieldName === null || uploadUrl === null || id === null || selectedFiles == null) return;

        setLoading(true);

        const formData = new FormData();
        if (multiple) {
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('files', selectedFiles[i]);
            }
        } else {
            formData.append('file', selectedFiles[0]);
        }
        formData.append('id', id);
        formData.append('fieldName', fieldName);

        const res = await updateDataAPI('POST', uploadUrl, formData);

        if (res.status == 200) {
            toast.info('Your file uploaded successfully.');
            fileInputRef.current.value = null;
            setSelectedFiles(null);
            setUploadedFileName(res.data);
        } else {
            toast.error('An error occured when uploading file.');
        };

        setLoading(false);
    };

    return (
        <div className={'upload-design-file ' + className}>
            <Form >
                <Form.Group controlId="fileUpload">
                    {label && (
                        <Form.Label>{label}</Form.Label>
                    )}
                    <Form.Control
                        type="file"
                        multiple
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                </Form.Group>
            </Form>
            {showImage && (
                <img src={uploadedFileName} />
            )}
            {uploadedFileName && (
                <div className="uploaded-file-name">{uploadedFileName}</div>
            )}
        </div>
    )
};

export default UploadFile;