import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { updateDataAPI } from '../../../../utils/query';
import { useDispatch } from 'react-redux';
import { addNewFontFamily } from '../../autoContentSlice';
import { toast } from 'react-toastify';

const FontUploadFormModal = ({
    handleClose,
}) => {
    const dispatch = useDispatch();
    const [fontFile, setFontFile] = useState(null);
    const [fontType, setFontType] = useState('text');
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFontFile(file);
    };

    const handleUploadFont = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (fontFile) {
            // Perform the file upload logic here, e.g., using FormData and an API endpoint
            const formData = new FormData();
            formData.append('fontFile', fontFile);
            formData.append('fontType', fontType);

            try {
                const url = 'auto-content/upload-local-font-family/';
                const res = await updateDataAPI('POST', url, formData);
                if (res.data.status === 200) {
                    const newFont = {
                        name: fontFile.name.split('.')[0],
                        source: 'local',
                        url: res.data['url'],
                        type: fontType,
                    }
                    dispatch(addNewFontFamily({
                        font: newFont
                    }));
                    toast.success('Uploaded font successfully.')
                };
            } catch (e) {
                console.log(`And error occured when uploading font: ${e.message}`);
                toast.error(`And error occured when uploading font: ${e.message}`);
            }
        }
        setLoading(false);
    };

    return (
        <Modal show={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Upload Font File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control
                    type="file"
                    accept=".woff, .woff2, .tff, .otf, .ttf"
                    onChange={handleFileChange}
                    className="mb-2"
                />
                <Form.Select
                    onChange={(e) => setFontType(e.target.value)}
                    value={fontType}
                >
                    <option value={'title'}>title</option>
                    <option value={'headline'}>headline</option>
                    <option value={'text'}>text</option>
                </Form.Select>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleUploadFont}
                    type='submit'
                    disabled={loading}
                >
                    {loading ? 'Uploading...' : 'Upload font'}
                </Button>
            </Modal.Footer>
        </Modal>


    );
};

export default FontUploadFormModal;