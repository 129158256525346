import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import moment from 'moment';
import Moment from 'react-moment';
import { Button, Form } from 'react-bootstrap';

import './styles.scss';

export const compareDates = (datetime1, datetime2) => {
    const formattedDate1 = moment(datetime1).format('YYYY-MM-DD HH:mm');
    const formattedDate2 = moment(datetime2).format('YYYY-MM-DD HH:mm');

    return formattedDate1 === formattedDate2;
};

const ContentHistoryVersionsModal = ({
    ideaTemplate,
    refreshPage,
    hideModal = () => { }
}) => {
    const [contentVersions, setContentVersions] = useState([]);

    useEffect(() => {
        const fetchIdeaTemplateContentVersions = async () => {
            if (ideaTemplate?.id) {
                const url = `auto-content/content-histories/?ideaTemplateID=${ideaTemplate?.id}`;
                const res = await getDataFromAPI(url);

                setContentVersions(res.data);
            }
        };

        fetchIdeaTemplateContentVersions();
    }, [ideaTemplate]);


    const handleRevertContentVersion = async (versionID) => {
        if (ideaTemplate?.id) {
            await updateDataAPI('POST', `auto-content/revert-idea-template-content-version/?versionID=${versionID}`);
            refreshPage();
            hideModal();
        }
    };

    return (
        <Modal show={true} size="xl" onHide={hideModal} className="content-history-versions-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Content versions
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Version</th>
                            <th>Created At</th>
                            <th>Raw Content</th>
                            <th>Author</th>
                            <th>Source</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contentVersions.map((version, index) => (
                            <tr key={version.id}>
                                <td>{contentVersions.length - index}</td>
                                <td><Moment format="HH:mm DD/MM/YYYY">{version.createdAt}</Moment></td>
                                <td className="raw-content-cell">
                                    <Form.Control as="textarea" rows={10} value={version.aiResponseInText} readOnly />
                                </td>
                                <td>{version.cozeBot ? 'Coze' : (version.staff ? version.staffName : 'PC')}</td>
                                <td>{version.cozeBot ? '' : version.source}</td>
                                <td>
                                    {!compareDates(version.createdAt, ideaTemplate?.lastAiResponseUpdate) && (
                                        <Button variant="warning" onClick={() => handleRevertContentVersion(version.id)}>
                                            Revert
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    );
};

export default ContentHistoryVersionsModal;