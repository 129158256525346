import React from 'react';
import Select from 'react-select';
import './styles.scss';

export const FilterElement = ({
    options = [],
    selectedValue = '',
    name = 'skills',
    handleChangeOption = () => { }
}) => {

    return (
        <div className="page-filter-element">
            <span>Filter by {name}</span>
            <Select
                isClearable
                name={name}
                options={options}
                value={selectedValue}
                onChange={handleChangeOption}
                className="select-filter-pages-option"
                classNamePrefix="select"
            />
        </div>
    )
}

const FilterPagesGrid = ({ filterElements = [] }) => {
    return (
        <div className="filter-pages-grid d-flex flex-column gap-1 mb-1">
            {filterElements.map((element) => (
                <FilterElement
                    key={element.id}
                    options={element.options}
                    selectedValue={element.selectedValue}
                    name={element.name}
                    handleChangeOption={(values = []) => element.handleChangeOption(values)}
                />
            ))}
        </div>
    );
};

export default FilterPagesGrid;