import { Form, Modal } from 'react-bootstrap';
import SearchIdeaInput from '../../filters/search-idea-input';

import './styles.scss';

const QuickSearchIdeaModal = ({hideModal}) => {
    return (
        <Modal show={true} onHide={hideModal} className="quick-search-idea-modal" size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Quick Search Idea</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <SearchIdeaInput />
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default QuickSearchIdeaModal;