import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

const ListSuggestionsReason = ({
    popularReasons = [],
    handleChangeSuggestionReason = () => {},
}) => {
    if (popularReasons.length === 0) {
        return null;
    }

    return (
        <Form.Group>
            <Form.Label>Common Reasons</Form.Label>
            <Dropdown onSelect={handleChangeSuggestionReason}>
                <Dropdown.Toggle variant="secondary" size="sm" id="common-reasons-select">
                    <FontAwesomeIcon icon={faList} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {popularReasons.map((reason, index) => (
                        <Dropdown.Item key={index} eventKey={reason}>{reason}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    )
};

export default ListSuggestionsReason;