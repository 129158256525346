import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getDataFromAPI } from '../../../utils/query';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

const SalesHistoryModal = ({idea, hideModal}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchSaleUnits = async() => {
            const res = await getDataFromAPI('resources/fetch-idea-sale-units/?customID=' + idea.customID);
            console.log(res.data);
            setData(res.data);
        }

        fetchSaleUnits();
    }, []);

    return (
        <Modal
            show={true}
            onHide={hideModal}
            className="sales-history-modal" size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Sales History {idea['customID']} - {idea['title']}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LineChart data={data} width={1000} height={500} margin={{ top: 20, right: 30, left: 20, bottom: 10, }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis yAxisId="left" />
                    <Tooltip />
                    <Line type="monotone" dataKey="nbSales" yAxisId="left" />
                </LineChart>
            </Modal.Body>
        </Modal>
    );
}

export default SalesHistoryModal;