import React from 'react';
import '../../styles.scss';

const TypePattern = ({
    selectedType,
    type,
    handleUpdatePartField = () => { },
}) => {
    return (
        <div 
            className={`step-body__patterns__item ${selectedType === type.name.toLowerCase() && 'step-body__patterns__item--active'}`} 
            onClick={() => handleUpdatePartField('type', type['name'].toLowerCase())}
        >
            {type['name']}
        </div>
    );
};

export default TypePattern;