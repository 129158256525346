import { Fragment } from 'react';
import TypePattern from './type-pattern';

const Step1SelectType = ({
    part = {},
    typeCategory = {},
    handleUpdatePartField = () => { }
}) => {
    return (
        <div className="step">
            <div className="step-body">
                <div className="step-body__patterns">
                    {typeCategory.tags?.map((tag) => (
                        <TypePattern
                            selectedType={part?.type}
                            key={tag.id}
                            type={tag}
                            handleUpdatePartField={handleUpdatePartField}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Step1SelectType;