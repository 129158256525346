import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from "react-redux";

const SearchTemplate = ({ setMatchedTemplates = () => {}}) => {
    const [searchKey, setSearchKey] = useState("");
    const { templates } = useSelector(state => state.generateProduct)

    const handleSearchKeyChange = (event) => {
        const searchKeyValue = event.target.value;
        setSearchKey(searchKeyValue)
        const searchRegex = new RegExp(searchKeyValue, 'i');
        const filteredTemplates = templates.filter(({ tags }) => tags.some((tag) => searchRegex.test(tag)));
        setMatchedTemplates(filteredTemplates);
    };

    return (
        <InputGroup className="mb-3">
            <Form.Control
                placeholder="Search template"
                aria-label="Search template"
                aria-describedby="basic-addon2"
                value={searchKey}
                onChange={handleSearchKeyChange}
                className="container__search"
            />
        </InputGroup>
    )
};

export default SearchTemplate;