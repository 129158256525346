import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { InputGroup } from 'react-bootstrap';

const NewIdeaSkillInput = ({
    skillName,
    skillDetails,
    handleSkillChange = () => { },
    handleSkillSubmit = () => { },
}) => {
    return (
        <Form
            onSubmit={handleSkillSubmit}
            className="d-flex mb-4"
        >
            <InputGroup controlId="skillInput">
                <Form.Control
                    type="text"
                    placeholder="Name"
                    value={skillName}
                    onChange={e => handleSkillChange('name', e.target.value)}
                />
                <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Details (ex: This passage should have at least 5 words with... This worksheet should talk about...)"
                    value={skillDetails}
                    onChange={e => handleSkillChange('details', e.target.value)}
                />
                <Button
                    variant="primary"
                    type="submit"
                    onSubmit={handleSkillSubmit}
                >
                    Add
                </Button>
            </InputGroup>
        </Form>
    );
};

export default NewIdeaSkillInput;