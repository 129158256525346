import { Badge, Button, Form, Spinner, Table } from 'react-bootstrap';

import './styles.scss';
import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import Constant from '../../../../Constant';
import GenDataTicketModal from '../../gen-data-ticket-modal';
import GenDataTicketIdeaLayout from './gen-data-ticket-idea-layout';
import { useSelector } from 'react-redux';

const GenDataTicketsTable = ({ keyword }) => {
    const seasons = useSelector(state => state.filters.seasons);

    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [ideaTypeFilter, setIdeaTypeFilter] = useState('all');
    const [seasonFilter, setSeasonFilter] = useState('all');
    const [qualityFilter, setQualityFilter] = useState('all');
    const [statusFilter, setStatusFilter] = useState('all');
    const [ticketInModal, setTicketInModal] = useState(null);
    const [showTicketModal, setShowTicketModal] = useState(false);

    useEffect(() => {
        const fetchTickets = async() => {
            setLoading(true);
            const res = await getDataFromAPI('product-dev/gen-data-tickets/?keywordID=' + keyword.id + '&additionalFields=ideaData');
            setTickets(res.data);
            setLoading(false);
        }

        fetchTickets();
    }, []);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const save = async(ticket) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'product-dev/gen-data-tickets/' + ticket.id + '/?additionalFields=ideaData', ticket);
        console.log(ticket);
        setLoading(false);
    }

    const deleteTicket = async(ticketID) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            await updateDataAPI('DELETE', 'product-dev/gen-data-tickets/' + ticketID + '/');
            setTickets(prev => prev.filter(t => t.id !== ticketID));
            setLoading(false);
        }
    }

    const clearTicketError = async(type, ticket) => {
        setLoading(true);
        if (type === 'single') {
            const res = await updateDataAPI('PATCH', 'product-dev/gen-data-tickets/' + ticket.id + '/?additionalFields=ideaData', {
                genError: null
            });
            setTickets(prev => prev.map(t => {
                if (t.id === ticket.id) {
                    return res.data;
                }
                return t;
            }));
        } else if (window.confirm('Are you sure?')) {
            await updateDataAPI('POST', 'product-dev/clear-all-seasonal-tickets/', {
                season: ticket.season,
                ideaType: ticket.ideaType,
            })
            setTickets(prev => prev.map(t => {
                if (t.season === ticket.season && t.ideaType === ticket.ideaType) {
                    return {...t, genError: null};
                }
                return t;
            }));
        }
        setLoading(false);
    }

    const deleteSeasonalIdeas = async(ticket) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            await updateDataAPI('POST', 'product-dev/delete-seasonal-ideas/', {
                season: ticket.season,
                ideaType: ticket.ideaType,
                storeIDs: keyword.boomStores,
            });
            setLoading(false);
        }
    }

    const genDataTicket = async(ticket) => {
        let confirmRun = false;
        if (ticket.done) {
            confirmRun = window.confirm('This product will be removed and replaced by a new product');
        } else {
            confirmRun = true;
        }

        if (confirmRun) {
            setLoading(true);
            if (ticket.done && ticket.idea) {
                await updateDataAPI('PATCH', 'product-dev/gen-data-tickets/' + ticket.id + '/?additionalFields=ideaData', {
                    genError: null,
                    idea: null,
                    done: false,
                });
                await updateDataAPI('DELETE', 'product-ideas/' + ticket.idea + '/');
            }
            const res = await updateDataAPI('POST', 'product-dev/gen-data-ticket/', {
                ticketID: ticket.id
            });
            if (res.data['success']) {
                window.alert('success');
                setTickets(prev => prev.map(t => {
                    if (t.id === ticket.id) {
                        return {...t, done: true, ideaData: res.data['idea']};
                    }
                    return t;
                }));
            } else {
                window.alert('error');
            }
            setLoading(false);
        }
    }

    return (
        <div className="gen-data-tickets-table mt-3">
            <div className="header">
                <div className="filters">
                    <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Select value={ideaTypeFilter} onChange={e => setIdeaTypeFilter(e.target.value)}>
                            <option value="all">All</option>
                            <option value="boom_card">boom_card</option>
                            <option value="boom_mystery">boom_mystery</option>
                            <option value="boom_cbc">boom_cbc</option>
                            <option value="tpt_worksheet">tpt_worksheet</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Season</Form.Label>
                        <Form.Select value={seasonFilter} onChange={e => setSeasonFilter(e.target.value)}>
                            <option value="all">All</option>
                            {seasons.map(season => (
                                <option key={season.id} value={season.name.toLowerCase().replace(/ /g, "_")}>{season.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Quality</Form.Label>
                        <Form.Select value={qualityFilter} onChange={e => setQualityFilter(e.target.value)}>
                            <option value="all">all</option>
                            <option value="good">good</option>
                            <option value="medium">medium</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <Form.Select value={statusFilter} onChange={e => setStatusFilter(e.target.value)}>
                            <option value="all">all</option>
                            <option value="done">done</option>
                            <option value="pending">pending</option>
                        </Form.Select>
                    </Form.Group>
                </div>

                <Button onClick={() => {
                    setShowTicketModal(true);
                }}>Create Ticket</Button>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Store</th>
                        <th>Type</th>
                        <th>Season</th>
                        <th>Quality</th>
                        <th>Outline</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tickets.filter(ticket => {
                        if (ideaTypeFilter !== 'all' && ticket.ideaType !== ideaTypeFilter) {
                            return false;
                        }

                        if (seasonFilter !== 'all' && ticket.season !== seasonFilter) {
                            return false;
                        }

                        if (qualityFilter !== 'all' && ticket.quality !== qualityFilter) {
                            return false;
                        }

                        if (statusFilter !== 'all' && ((ticket.done && statusFilter === 'pending') || (!ticket.done && statusFilter === 'done'))) {
                            return false;
                        }

                        return true;
                    }).map((ticket, index) => (
                        <tr key={ticket.id}>
                            <td>
                                <div>{index + 1}</div>
                                {userRoles.indexOf('admin') > -1 && (
                                    <div className="ticket-fingerprint">
                                        <div>Ticket ID: <a href={Constant.STUDIO_API + 'admin/product_dev/gendataticket/' + ticket.id + '/'} target="_blank">{ticket.id}</a></div>
                                        {ticket.genError && (
                                            <div>
                                                <div>Error: {ticket.genError}</div>
                                                <div className="clear-ticket-errors">
                                                    <span onClick={() => clearTicketError('single', ticket)}>clear</span>
                                                    {ticket.season !== 'common' && (
                                                        <span onClick={() => clearTicketError('all', ticket)}>clear all {ticket.season} tickets</span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {ticket.done && ticket.season !== 'common' && userRoles.indexOf('admin') > -1 && (
                                            <div className="clear-ticket-errors">
                                                <span onClick={() => deleteSeasonalIdeas(ticket)}>delete all {ticket.season} ideas</span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </td>
                            <td>{ticket.store}</td>
                            <td>{ticket.ideaType}</td>
                            <td>{ticket.season}</td>
                            <td>{ticket.quality}</td>
                            <td className="outline-cell">
                                <GenDataTicketIdeaLayout
                                    disabled={ticket.done}
                                    layout={ticket.ideaLayout}
                                    dataSets={keyword.dataSets}
                                    updateLayout={layout => {
                                        setTickets(tickets.map(t => {
                                            if (t.id === ticket.id) {
                                                return {...t, ideaLayout: layout};
                                            }

                                            return t;
                                        }));
                                    }} />
                            </td>
                            <td>
                                {ticket.done ? (
                                    <Badge bg="success">DONE</Badge>
                                ) : (
                                    <Badge bg="secondary">pending</Badge>
                                )}
                            </td>
                            <td className="btns-cell">
                                {ticket.done ? (
                                    <div className="idea">
                                        <img src={ticket.ideaData.thumbnail} />
                                        <div>
                                            <a href={'/content-studio/?customID=' + ticket.ideaData.customID} target="_blank">
                                                {ticket.ideaData.customID}. {ticket.ideaData.title}
                                            </a>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <Button size="sm" onClick={() => save(ticket)} variant="success">Save</Button>
                                        <Button size="sm" onClick={() => {
                                            setShowTicketModal(true);
                                            setTicketInModal(ticket);
                                        }}>Edit</Button>
                                        <Button size="sm" variant="danger" onClick={() => deleteTicket(ticket.id)}>Delete</Button>
                                    </div>
                                )}

                                <Button size="sm" onClick={() => genDataTicket(ticket)}>Run</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showTicketModal && (
                <GenDataTicketModal
                    ticket={ticketInModal}
                    setTickets={setTickets}
                    setLoading={setLoading}
                    keyword={keyword}
                    hideModal={() => {
                        setShowTicketModal(false);
                        setTicketInModal(null);
                    }}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </div>
    );
}

export default GenDataTicketsTable;