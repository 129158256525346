import React, { useState } from 'react';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';
import { updateDataAPI } from '../../../../utils/query';

const RefreshTaskProgressButton = ({
    task,
    handleRefreshSteps = async () => { }
}) => {
    const [loading, setLoading] = useState(false);

    const refreshTaskProgress = async (task) => {
        if (!task?.id) return;
        await updateDataAPI('POST', 'product-dev/force-update-task-progress/?taskID=' + task.id);
    }

    const handleRefreshTaskProgress = async () => {
        setLoading(true);
        await refreshTaskProgress(task);
        await handleRefreshSteps();
        setLoading(false);
    }

    return (
        <span onClick={handleRefreshTaskProgress} className="refresh-task-progress-button">
            {!loading && (
                <FontAwesomeIcon icon={faRefresh} />
            )}
        </span>
    )
};

export default RefreshTaskProgressButton;