import { useEffect, useState } from 'react';
import { Button, Container, Form, Spinner, Table } from 'react-bootstrap';
import axios from 'axios';

import './styles.scss';
import Constant from '../../Constant';

const TPTCrawlRequestsPage = () => {
    const [keyword, setKeyword] = useState();
    const [grades, setGrades] = useState([]);
    const [loading, setLoading] = useState(false);
    const [crawlRequests, setCrawlRequests] = useState([]);

    useEffect(() => {
        const fetchAllCrawlRequests = async() => {
            const res = await axios.get(Constant.TPT_SELLER_API + 'external-products/tpt-crawl-requests/');
            setCrawlRequests(res.data);
        }

        fetchAllCrawlRequests();
    }, []);

    const createRequest = async() => {
        if (keyword === undefined || keyword === null || keyword === '') {
            window.alert('keyword error');
            return;
        }

        setLoading(true);
        console.log(keyword, grades);
        try {
            const res = await axios.post(Constant.TPT_SELLER_API + 'external-products/tpt-crawl-requests/', {
                keyword,
                grades,
            });
            setCrawlRequests(crawlRequests.concat(res.data));
        } catch (err) {
            window.alert('check keyword and grades, may exist already');
        }
        setLoading(false);
    }

    const deleteRequest = async(id) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            await axios.delete(Constant.TPT_SELLER_API + 'external-products/tpt-crawl-requests/' + id + '/');
            setCrawlRequests(crawlRequests.filter(r => r['id'] !== id));
            setLoading(false);
        }
    }

    return (
        <Container fluid className="tpt-crawl-requests-page">
            <h2>Crawl Requests</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Keyword</th>
                        <th>Grades</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {crawlRequests.map((request, index) => (
                        <tr key={index}>
                            <td>{request['id']}</td>
                            <td>
                                <a href={'/tpt-crawler/?id=' + request['id']} target="_blank">{request['keyword']}</a>
                            </td>
                            <td>{request['grades']}</td>
                            <td>{request['done'] ? 'DONE' : 'PENDING'}</td>
                            <td>
                                <Button size="sm" variant="danger" onClick={() => deleteRequest(request['id'])}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Form>
                <Form.Group className="form-group">
                    <Form.Label>Keyword</Form.Label>
                    <Form.Control
                        value={keyword}
                        onChange={e => setKeyword(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Grades</Form.Label>
                    <Form.Control
                        value={grades}
                        onChange={e => setGrades(e.target.value)}
                    />
                    <Form.Text>
                        PreK,K,1,2,3,4,5,6,7,8,9,10,
                    </Form.Text>
                </Form.Group>

                <Button onClick={createRequest}>Create Request</Button>
            </Form>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default TPTCrawlRequestsPage;