import Modal from 'react-bootstrap/Modal';
import Constant from '../../../Constant';

import './styles.scss';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faRefresh } from '@fortawesome/free-solid-svg-icons';

const WorksheetMakerIframeModal = ({ type, id, hideModal }) => {
    const [random, setRandom] = useState(0);

    const refreshIframe = () => {
        setRandom(random + 1);
    }

    const closeModal = () => {
        if (window.confirm('Have you saved and exported yet?')) {
            hideModal();
        }
    }

    return (
        <Modal
            show={true}
            fullscreen={true}
            onHide={hideModal}
            className="worksheet-maker-iframe-modal"
        >
            <Modal.Header>
                <Modal.Title>Design {type === 'template' ? (id === '' ? 'New ' : '') + 'Template' : 'Page'}</Modal.Title>
                <div className="header-icons">
                    <FontAwesomeIcon icon={faRefresh} onClick={refreshIframe} />
                    <FontAwesomeIcon icon={faClose} onClick={closeModal} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <iframe 
                    allow="clipboard-read *; clipboard-write *"
                    key={random}
                    src={Constant.WORKSHEET_MAKER_URL + '?type=' + type + '&id=' + id + '&token=' + localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN)}
                    width="1280"
                    height="750"
                    id="worksheet-maker-iframe"
                />
            </Modal.Body>
        </Modal>
    )
}

export default WorksheetMakerIframeModal;