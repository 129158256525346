import { useCallback, useState } from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';
import { Button } from 'react-bootstrap';
import { searchTemplateByQuery } from '../../../../../utils/autoContentUtils';
import { updateDataAPI } from '../../../../../utils/query';
import VersionInfoModal from '../version-info-modal';

export const TemplateOptionLabel = ({ template }) => (
    <span>{template.value}. {template.label}</span>
);

const AddTemplateAsVersion = ({customID, originalTemplate, refreshVersionList, setLoadingModal}) => {
    const [loading, setLoading] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [showVersionInfoModal, setShowVersionInfoModal] = useState(false);

    const loadOptions = useCallback((inputValue, callback) => {
        // Use the debounced fetch function
        fetchTemplateOptions(inputValue, callback);
    }, []);

    const addTemplateToVersionsList = async() => {
        if (selectedTemplate) {
            const versionName = window.prompt('Version name', '');
            if (versionName !== null) {
                setLoading(true);
                const res = await updateDataAPI('PATCH', 'auto-content/templates/' + selectedTemplate.id + '/', {
                    originalVersionTemplate: originalTemplate.id,
                    versionName
                });
                refreshVersionList(res.data);
                setLoadingModal(false);
            }
        }
    }

    const fetchTemplateOptions = debounce(async (inputString, callback) => {
        setLoading(true);
        const data = await searchTemplateByQuery(inputString);
        const formattedData = data.filter(item => item.id !== originalTemplate.id).map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
        }));
        callback(formattedData);
        setLoading(false);
    }, 300);

    return (
        <div className="add-template-to-collection">
            <AsyncSelect
                value={selectedTemplate}
                onChange={(selectedValue) => setSelectedTemplate(selectedValue)}
                isLoading={loading}
                loadOptions={loadOptions}
                defaultOptions
                formatOptionLabel={(template) => (<TemplateOptionLabel key={template.id} template={template} />)}
            />
            <Button variant="success" onClick={() => setShowVersionInfoModal(true)} disabled={selectedTemplate === null}>
                Add Template
            </Button>

            {showVersionInfoModal && (
                <VersionInfoModal
                    originalTemplate={originalTemplate}
                    version={selectedTemplate}
                    hideModal={(action, data = null) => {
                        if (action === 'update') {
                            refreshVersionList({...selectedTemplate, versionName: data['name']});
                        }
                        setShowVersionInfoModal(false);
                    }} />
            )}
        </div>
    )
}

export default AddTemplateAsVersion;