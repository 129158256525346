import { Col, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { hasPermission } from '../../../../utils/auth';

const IdeaModalPriorityDifficultyTab = ({
    userRoles,
    priority,
    setPriority,
    createIdeaDifficulty,
    setCreateIdeaDifficulty,
    checkDataDifficulty,
    setCheckDataDifficulty,
    prior,
    setPrior,
    qualityChecked,
    setQualityChecked,
    blocked,
    setBlocked,
    blockedReasonTitle,
    blockedReasonNote,
    setBlockedReasonTitle = () => {},
    setBlockedReasonNote }) => {
    return (
        <>
            <Row className="mt-3">
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Create Idea Difficulty</Form.Label>
                        <Form.Select
                            onChange={(e) => setCreateIdeaDifficulty(e.target.value)}
                            value={createIdeaDifficulty}
                        >
                            <option value="easy">easy</option>
                            <option value="medium">medium</option>
                            <option value="hard">hard</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Check Data Difficulty</Form.Label>
                        <Form.Select
                            onChange={(e) => setCheckDataDifficulty(e.target.value)}
                            value={checkDataDifficulty}
                        >
                            <option value="easy">easy</option>
                            <option value="medium">medium</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col>
                    {priority !== undefined && (
                        <Form.Group className="mb-3">
                            <Form.Label>Task Priority</Form.Label>
                            <Form.Select
                                onChange={(e) => setPriority(e.target.value)}
                                value={priority}
                            >
                                <option value="low">low</option>
                                <option value="normal">normal</option>
                                <option value="high">high</option>
                            </Form.Select>
                        </Form.Group>
                    )}
                </Col>
            </Row>
            <Row>
                <Form.Group>
                    <Form.Check
                        type="checkbox"
                        label="Blocked"
                        id="idea-blocked-checkbox"
                        onChange={() => setBlocked(!blocked)}
                        checked={blocked} />
                </Form.Group>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Form.Group>
                        <Form.Label>Block Reason title</Form.Label>
                        <Form.Control 
                            value={blockedReasonTitle}
                            onChange={(e) => setBlockedReasonTitle(e.target.value)}
                            type='text'
                            placeholder="Blocked reason title"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Blocked Reason Note:</Form.Label>
                        <Form.Control as="textarea" rows={4} value={blockedReasonNote} onChange={(e) => setBlockedReasonNote(e.target.value)} placeholder="Blocked reason note"/>
                    </Form.Group>
                </Col>
            </Row>

            {hasPermission(userRoles, ['admin']) && (
                <>
                    <h5>For Admin</h5>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="productIdeaPrior">
                                <Form.Check type="checkbox" label="Rate Priority" checked={prior} onChange={e => setPrior(e.target.checked)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="productIdeaQualityChecked">
                                <Form.Check type="checkbox" label="Quality Checked" checked={qualityChecked} onChange={e => setQualityChecked(e.target.checked)} />
                            </Form.Group>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default IdeaModalPriorityDifficultyTab;