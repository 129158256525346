import { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { addTagToSelectedTags, removeTagFromSelectedTags } from '../../autoContentSlice';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import { updateDataAPI } from '../../../../utils/query';
import { toast } from 'react-toastify';

const TagActions = ({
    disabledFilter=false,
    tag = {},
    handleEditTag = () => { },
    fetchAllCategories = () => { },
    shouldShowArrowRight = false,
}) => {
    const { selectedTags } = useSelector(state => state.autoContent);

    const dispatch = useDispatch();

    const [showBtnActions, setShowBtnActions] = useState(false);

    const toggleTag = (tag) => {
        const isSelected = selectedTags.some(selectedTag => selectedTag.id === tag.id);
        if (isSelected) {
            dispatch(removeTagFromSelectedTags({ id: tag.id }));
        } else {
            dispatch(addTagToSelectedTags({ tag }));
        }
    }

    const handleDeleteTag = async () => {
        if (window.confirm('Do you really want to delete this tag?')) {
            try {
                await updateDataAPI('DELETE', `auto-content/tags/${tag.id}/`);

                if (selectedTags.some((t) => t.id === tag.id)) {
                    dispatch(removeTagFromSelectedTags({ id: tag.id }));
                }

                fetchAllCategories();
            } catch (err) {
                toast.error(`An error occured when deleting this tag: ${err.message}`);
            }
        }
    };

    const handleMouseEnter = () => {
        setShowBtnActions(true);
    };

    const handleMouseLeave = () => {
        setShowBtnActions(false);
    }

    return (
        <div
            className="tag-actions"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="d-flex">
            <Form.Check
                disabled={disabledFilter}
                type="checkbox"
                label={tag.name}
                id={'tag-' + tag.id}
                onChange={() => toggleTag(tag)}
                checked={selectedTags.some(selectedTag => selectedTag.id === tag.id)} />
                {tag.templatesCount > 0 && (
                    <div className="tag-actions__tag-label__count">
                        <Badge pill bg="secondary">{tag.templatesCount}</Badge>
                    </div>
                )}
            </div>
            <div className="tag-actions-btns">
                {showBtnActions && (
                    <Fragment>
                        <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => handleEditTag(tag)}
                        />
                        <FontAwesomeIcon
                            icon={faTrash}
                            onClick={handleDeleteTag}
                        />
                    </Fragment>
                )}
                {shouldShowArrowRight && (
                    <FontAwesomeIcon icon={faChevronRight} className="right-arrow" />
                )}
            </div>
        </div>
    );
};

export default TagActions;