import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import './styles.scss';
import PrepareDataStep from "./prepareData";
import ChooseTemplatesStep from "./chooseTemplates";
import MakeWorksheets from "./makeWorksheets";

const TemplateContainer = ({ productIdeaID }) => {
    const { currentStep, templates } = useSelector(state => state.generateProduct);
    
    if (templates === undefined) return;

    return (
        <div className="container">
            {currentStep.id == 1 && (
               <ChooseTemplatesStep productIdeaID={productIdeaID}/>
            )}
            {currentStep.id == 2 && (
                <PrepareDataStep />
            )}
            {currentStep.id == 3 && (
                <MakeWorksheets />
            )}


        </div>
    )
};

export default TemplateContainer;