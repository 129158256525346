import { useState, useMemo, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import CreateNewPartModal from './create-new-part-modal';
import { useLocation } from 'react-router-dom';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import './styles.scss';
import { toast } from 'react-toastify';
import SettingPartModal from './setting-part-modal';
import { useDispatch } from 'react-redux';
import { emtpySelectedTags } from '../../auto-content/autoContentSlice';
import { Modal } from 'react-bootstrap';
import TableRow from './table-row';

export const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
};

const OutlineIdeaModal = ({customID, pages, hideModal}) => {
    const dispatch = useDispatch();

    const query = useQuery();
    const [showPartModal, setShowPartModal] = useState(false);
    const [editingPart, setEditingPart] = useState(null);
    const [parts, setParts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchParts();
    }, []);

    const fetchParts = async () => {
        setLoading(true);
        await updateDataAPI('POST', 'resources/refresh-idea-parts/?customID=' + customID);

        try {
            const res = await getDataFromAPI(`resources/idea-parts/?customID=${customID}&additionalFields=collectionData,templateZones`);
            const sortedData = res.data.sort((a, b) => a.positionIndex - b.positionIndex);

            setParts(sortedData);
        } catch (e) {
            console.error('An error occured when fetching parts: ', e.message);
        };
        setLoading(false);
    };

    const handleCreateNewPart = async () => {
        const url = 'resources/idea-parts/';

        try {
            const res = await updateDataAPI('POST', url, {
                note: editingPart.note,
                activity: editingPart?.activity,
                example: editingPart?.example,
                nbPages: editingPart?.nbPages,
                positionIndex: (parts[parts.length - 1]?.positionIndex + 1) || 1,
                template: parseInt(editingPart?.template),
                customID: query.get('customID'),
                type: editingPart?.type,
            });
            setParts((prev) => ([...prev, res.data]));
            handleCloseModal();
            dispatch(emtpySelectedTags());
            toast.success('Successfully created new part.');
        } catch (err) {
            console.error('An error occured when creating new part: ', err.message);
        };
    };

    const handleSavePart = async () => {
        if (editingPart?.id) {
            const url = `resources/idea-parts/${editingPart.id}/`;

            try {
                const res = await updateDataAPI('PATCH', url, editingPart);
                setParts((prev) => prev.map((part) => {
                    if (part.id === editingPart.id) return res.data
                    return part;
                }));
                handleCloseModal();
                toast.success('Updated successfully.');
            } catch (err) {
                console.log('An error occured when updating part: ', err.message);
                toast.error(`An error occured when updating part: ${err.message}`);
            };
        };
    };

    const handleUpdatePartField = (field, value) => {
        setEditingPart((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleCloseModal = () => {
        setEditingPart(null);
        setShowPartModal(false);
    };

    const handleSelectPart = (part) => {
        setEditingPart(part);
        setShowPartModal('edit');
    };

    const refresh = async (refreshItems) => {
        setLoading(true);
        if (refreshItems.indexOf('parts') > -1) {
            await fetchParts();
        };
        setLoading(false);
    };

    const handleSubmitParts = async () => {
        const url = 'resources/generate-idea-pages-from-idea-parts/';
        try {
            const res = await updateDataAPI('POST', url, { customID: query.get('customID') });
            if (res.status === 201) {
                window.close();
                window.open(`/content-studio/?customID=${query.get('customID')}`);
            }
        } catch (err) {
            console.error('An error occured when submiting part: ', err.message);
            toast.error(`An error occured when submiting part: ${err.message}`);
        };
    };

    const handleOpenCreateNewPartModal = () => {
        setShowPartModal('new');
        setEditingPart({});
        dispatch(emtpySelectedTags());
    }

    return (
        <Modal show={true} onHide={hideModal} fullscreen={true} className="outline-idea-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Outline
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <div className="outline-idea-page__header">
                    <div className="float-end btns">
                        {parts.length > 0 && (
                            <>
                                <Button variant="success" onClick={handleSubmitParts}>Submit</Button>
                                <Button href={'/content-studio/?customID=' + query.get('customID')} target="_blank" variant="success">Content Studio</Button>
                            </>
                        )}
                        <Button
                            className="float-end"
                            variant="primary"
                            onClick={handleOpenCreateNewPartModal}
                        >
                            Add new part
                        </Button>
                    </div>
                </div> */}
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Part</th>
                            <th>Nb Pages</th>
                            <th>Skill</th>
                            <th>Theme</th>
                            <th>Collection</th>
                            <th>Training Text</th>
                            <th>Template</th>
                            <th>Request</th>
                            <th>Note</th>
                            <th>Example</th>
                        </tr>
                    </thead>
                    <tbody className='idea-part-rows'>
                        {parts.map((part, index) => (
                            <TableRow
                                key={part.id}
                                rowIndex={index}
                                part={part}
                                parts={parts}
                                handleSelectPart={handleSelectPart}
                                refresh={refresh}
                                setParts={setParts}
                                setLoading={setLoading}
                                pages={pages}
                                hideModal={hideModal}
                            />))}
                    </tbody>
                </Table>
                {editingPart && showPartModal === 'new' && (
                    <CreateNewPartModal
                        setLoading={setLoading}
                        handleCreateNewPart={handleCreateNewPart}
                        part={editingPart}
                        handleUpdatePartField={handleUpdatePartField}
                        closeModal={handleCloseModal}
                    />
                )}
                {editingPart && showPartModal === 'edit' && (
                    <SettingPartModal
                        part={editingPart}
                        handleUpdatePartField={handleUpdatePartField}
                        closeModal={handleCloseModal}
                        handleSavePart={handleSavePart}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                {loading && (
                    <div className="loading-container">
                        <Spinner animation="border" variant="light" />
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    )
};

export default OutlineIdeaModal;