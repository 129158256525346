import { createSlice } from "@reduxjs/toolkit";

export const colorbycodeMakerSlice = createSlice({
    name: 'generateProduct',
    initialState: {
        steps: [
            {
                id: 1,
                step: 'Choose templates'
            },
            {
                id: 2,
                step: 'Prepare data'
            },
            {
                id: 3,
                step: 'Make worksheets'
            },
        ],
        currentStep: {
            id: 1,
            step: 'Choose templates'
        },
        results: [],
        templates: [],
        selectedPage: {},
        collections: [],
        worksheet: {},
    },
    reducers: {
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setResults: (state, action) => {
            state.results = action.payload;
        },
        setTemplates: (state, action) => {
            state.templates = action.payload;
        },
        setSelectedPage: (state, action) => {
            state.selectedPage = action.payload;
        },
        setCollections: (state, action) => {
            state.collections = action.payload;
        },
        setWorksheet: (state, action) => {
            state.worksheet = action.payload;
        }
    }
});

export const {
    setCurrentStep,
    setResults,
    setTemplates,
    setSelectedPage,
    setCollections,
    setWorksheet,
} = colorbycodeMakerSlice.actions;

export default colorbycodeMakerSlice.reducer;