import React, { useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { setResults, setSelectedPage } from "../colorbycodeMakerSlice";
import { updateDataAPI } from "../../../utils/query";
import Constant from "../../../Constant";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlassPlus } from "@fortawesome/free-solid-svg-icons";
import EnlargePreviewImage from "./enlargePreview";
import Spinner from "react-bootstrap/Spinner";

const Result = () => {
    const dispatch = useDispatch();
    const { currentStep, results, selectedPage, worksheet } = useSelector(state => state.generateProduct);
    const [showEnlargePreview, setShowEnlargePreview] = useState(false);
    const [urlSelectedPreview, setURLSelectedPreview] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSelectedPage = (page, index) => {
        if (currentStep.id !== 3) return;
        const updatedPage = { ...page, "nbPage": index + 1 };
        dispatch(setSelectedPage(updatedPage));
    };

    const getPreviewTemplate = async (result) => {
        if (worksheet.hasOwnProperty("id")) {
            setLoading(true);
            const url = `resources/product-ideas-color-by-code/${worksheet["id"]}/generate-preview-template/`;
            try {
                const res = await updateDataAPI('POST', url, {
                    templateID: result["id"],
                    nbPage: selectedPage["nbPage"],
                });

                if (res.status === 200) {
                    const preview_url = Constant.API_URL + res.data.preview;
                    const cloneResults = results.map((item) => ({ ...item }));
                    const updatedResult = cloneResults.map((item) => {
                        if (item.id === result.id) {
                            item.image = preview_url;
                        }

                        return item;
                    })
                    dispatch(setResults(updatedResult));
                }
            } catch (err) {
                toast.error(`An error occured ${err.message}`);
            };
            setLoading(false);
        }
    };

    const enlargePreviewImage = (url) => {
        setURLSelectedPreview(url);
        setShowEnlargePreview(true);
    };

    return (
        <div className="result">
            <h1 className="result__header">Your selected templates will be here.</h1>
            {results.map((result, index) => (
                <div
                    key={result.id}
                    className={`result__item ${selectedPage?.id === result.id ? 'result__item--selected' : ''}`}
                    onClick={() => handleSelectedPage(result, index)}
                >
                    <div className="d-flex justify-content-between">
                        <span className="result__item__nb">{index + 1}</span>
                        <div onClick={() => enlargePreviewImage(result["image"])}>
                            <FontAwesomeIcon icon={faMagnifyingGlassPlus} className="result__item__enlarge-icon" />
                        </div>
                    </div>
                    <img src={result.image} alt="Template thumbnail" />
                    <Container className="d-flex justify-content-center">
                        <Button
                            variant="primary"
                            onClick={() => getPreviewTemplate(result)}
                        >
                            Refresh
                        </Button>
                    </Container>
                </div>
            ))}
            {showEnlargePreview && (
                <EnlargePreviewImage
                    url={urlSelectedPreview}
                    showEnlargePreview={showEnlargePreview}
                    setShowEnlargePreview={setShowEnlargePreview}
                />
            )}
            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </div>
    )
};

export default Result;