import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { updateDataAPI } from '../../../utils/query';

const IdeaTemplateSelectTrainingText = ({
    ideaTemplate,
    updateOption,
    targetPages = [],
    mode = 'single',
    ideaTemplates = [],
    setLoading = () => { },
}) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [options, setOptions] = useState([]);

    const { trainingTexts } = useSelector(state => state.autoContent);

    useEffect(() => {
        const init = () => {
            const optionsData = trainingTexts.map((trainingText) => ({
                ...trainingText,
                label: trainingText['name'],
                value: trainingText['id'],
            }));

            setOptions(optionsData);

            if (mode === 'single') {
                const defaultValue = optionsData.filter(
                    (option) => option['id'] === ideaTemplate?.trainingText
                );
                if (defaultValue.length > 0) {
                    setSelectedOption(defaultValue[0]);
                } else {
                    setSelectedOption(null);
                }
            }
        };

        init();
    }, [trainingTexts, ideaTemplates]);

    const handleSelectTrainingTextForOnePage = async (targetIdeaTemplate = ideaTemplate, value) => {
        const selectedOptionValue = value?.id ?? null;
        await updateDataAPI('PATCH', `auto-content/idea-templates/${targetIdeaTemplate.id}/`, {
            trainingText: selectedOptionValue,
        });
        if (mode === 'single') {
            setSelectedOption(value);
        }

        updateOption(value, targetPages.map(page => page.id));
    };

    const handleSelectOption = async (value) => {
        setLoading(true);
        if (mode !== 'single') {
            const confirmed = window.confirm(
                `Are you sure want to set this training text to all selected pages?`
            );
            if (confirmed) {
                targetPages.forEach(async (targetPage) =>
                    await handleSelectTrainingTextForOnePage({ id: targetPage.content.id }, value)
                );
            }
        } else {
            await handleSelectTrainingTextForOnePage(ideaTemplate, value);
        }
        setLoading(false);
    };

    return (
        <div className="me-2">
            {mode === 'single' && (
                <h3>Training Text</h3>
            )}
            <Select
                placeholder="Select Training Text"
                options={options}
                value={selectedOption}
                onChange={handleSelectOption}
                isClearable
            />
        </div>
    );
};

export default IdeaTemplateSelectTrainingText;