import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const UpdateJSONDataSetModal = ({
    dataSet,
    updateDataSetData,
    hideModal = () => { }
}) => {
    const [data, setData] = useState(JSON.stringify(dataSet.topics, null, 4));

    return (
        <Modal show={true} onHide={hideModal} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Update DataSet {dataSet.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control
                    as="textarea"
                    value={data}
                    rows={20}
                    onChange={(e) => setData(e.target.value)}
                    wrap='off'
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => updateDataSetData(data)}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateJSONDataSetModal;