import Container from 'react-bootstrap/Container';
import './styles.scss';
import PerformanceTicketForm from '../../components/performance-ticket-form';

const PerformanceTicketPage = () => {

    return (
        <Container className="performance-ticket-page">
            <h1 className="m-auto text-uppercase">New performance ticket:</h1>
            <PerformanceTicketForm />
        </Container>
    )

};

export default PerformanceTicketPage;