import { Col, Form, Row } from "react-bootstrap";
import MultiValues from "../../../../forms/multi-values";

const IdeaModalAssigneesTab = ({
    collectors, setCollectors, designers, setDesigners, informators, setInformators
}) => {
    return (
        <Row className="mt-3">
            <Col>
                <Form.Group className="mb-3" controlId="productIdeaCollectors">
                    <Form.Label>Collectors</Form.Label>
                    <MultiValues value={collectors} setValue={val => setCollectors(val)} choicesDataAPI="product-dev/staffs/?roles=product-collector&allStaffs=true"
                        labelField="name" valueField="name" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="productIdeaDesigners">
                    <Form.Label>Designers</Form.Label>
                    <MultiValues value={designers} setValue={val => setDesigners(val)} choicesDataAPI="product-dev/staffs/?roles=designer&allStaffs=true"
                        labelField="name" valueField="name" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="productIdeaInformators">
                    <Form.Label>Informators</Form.Label>
                    <MultiValues value={informators} setValue={val => setInformators(val)} choicesDataAPI="product-dev/staffs/?roles=product-informator,dev&allStaffs=true"
                        labelField="name" valueField="name" />
                </Form.Group>
            </Col>
        </Row>
    );
}

export default IdeaModalAssigneesTab;