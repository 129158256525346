import Constant from "../Constant";
import { getDataFromAPI } from "./query";

export const logout = () => {
    localStorage.removeItem(Constant.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(Constant.LOCAL_STORAGE_ROLES);
    localStorage.removeItem(Constant.LOCAL_STORAGE_ADMIN_TOKEN);
    localStorage.removeItem(Constant.LOCAL_STORAGE_USER_EMAIL);
    localStorage.removeItem(Constant.LOCAL_STORAGE_STAFF_ID);
    localStorage.removeItem(Constant.LOCAL_STORAGE_ADMIN_EMAIL);
    window.location.href = '/login';
}

export const hasPermission = (userRoles = [], permissions = []) => {
    if (userRoles.indexOf('admin') > -1) {
        return true;
    }

    if (userRoles !== null && userRoles !== undefined) {
        return userRoles.some(role => permissions.includes(role));
    }

    return false;
}

export const checkStaffHasAccessToProductIdea = async (customID) => {
    const staffID = Math.floor(localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID));
    const url = `product-dev/check-staff-has-access/?customID=${customID}&staffID=${staffID}`;

    try {
        const res = await getDataFromAPI(url);
        return res.data;
    } catch (e) {
        console.log("An error occured: ", e.message);
    }

    return false;
}

export const hasRightToDoAction = (availableStaffID) => {
    const staffID = Math.floor(localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID));
    const staffRoles = JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES));

    if (staffRoles.indexOf('admin') > -1) {
        return true;
    }
    return availableStaffID === staffID;
};