import PagesGrid from './pages-grid';
import Modal from 'react-bootstrap/Modal';
import './styles.scss';
import { useEffect, useState } from 'react';
import { updateDataAPI } from '../../../utils/query';
import { toast } from 'react-toastify';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown } from 'react-bootstrap';
import IdeaPageSelectOption, { generateIdeaSkillOptions, generateIdeaThemeOptions } from '../idea-page-select-option';
import Constant from '../../../Constant';
import IdeaTemplateSelectTrainingText from '../idea-template-select-training-text';
import SelectPageParameters from '../select-page-parameters';
import { useSelector } from 'react-redux';
import FilterPagesGrid from './filter-pages-grid';
import { generateTemplatesFromPages } from '../../auto-content/idea-templates-modal';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { warningUserWhenPageIsValidate } from '../../../utils/autoContentUtils';

export const getPageOptionsValueRepresentForAllSelectedPages = (pages = [], selectedPageIds = []) => {
    const selectedPages = pages.filter((page) => selectedPageIds.includes(page.id));
    return {
        'isFreePage': selectedPages.every((page) => page.isFreePage),
        'isHighlightPage': selectedPages.every((page) => page.isHighlightPage),
        'dataValidated': selectedPages.every((page) => page.dataValidated),
        'designValidated': selectedPages.every((page) => page.designValidated),
    };
};

export const generateListOptionIdsFromPages = (pages = [], elementKey = 'skill') => {
    const options = [];
    for (let i = 0; i < pages.length; i++) {
        const element = pages[i][elementKey];
        if (options.find(option => option === element) === undefined) {
            options.push(element);
        }
    }
    return options;
}

const PagesControlModal = ({
    handleChangeTemplateVersion = () => { },
    hideModal = () => { },
    pages = [],
    selectedPages = [],
    customID,
    setPages = () => { },
    setSelectedPages = () => { },
    setLoading = () => { },
    refresh = () => { },
    handleShowReportWrongContentModal = () => { },
    setShowNonAIGenerateModal = () => { },
}) => {
    const { ideaSkills, ideaPageThemes } = useSelector(
        (state) => state.autoContent
    );

    const [currentPages, setCurrentPages] = useState(pages);

    const [selectedSkill, setSelectedSkill] = useState('');
    const [skillOptions, setSkillOptions] = useState([]);

    const [selectedTheme, setSelectedTheme] = useState('');
    const [themeOptions, setThemeOptions] = useState([]);

    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [templateOptions, setTemplateOptions] = useState([]);

    useEffect(() => {
        refresh(['skills']);
    }, []);

    useEffect(() => {
        const handleFilterPages = () => {
            const selectedElements = [
                { key: 'skill', value: selectedSkill },
                { key: 'theme', value: selectedTheme },
                { key: 'template', value: selectedTemplate }
            ];

            setCurrentPages(pages.filter(page => {
                return selectedElements.every(selectedElement => {
                    const { key, value } = selectedElement;
                    if (!value) {
                        return true;
                    } else if (key === 'template') {
                        return page?.content?.templateData?.id === value?.id;
                    } else {
                        return page[key] === value?.id;
                    }
                });
            }));
        };

        handleFilterPages();
    }, [selectedSkill, selectedTheme, selectedTemplate, pages]);

    useEffect(() => {
        const generateFilterOptions = () => {
            const pageSkills = generateListOptionIdsFromPages(pages, 'skill');
            const pageThemes = generateListOptionIdsFromPages(pages, 'theme');

            setSkillOptions(generateIdeaSkillOptions(ideaSkills.filter(ideaSkill => pageSkills.includes(ideaSkill.id))) || []);
            setThemeOptions(generateIdeaThemeOptions(ideaPageThemes.filter(ideaPageTheme => pageThemes.includes(ideaPageTheme.id))) || []);
            setTemplateOptions(generateTemplatesFromPages(pages || []).map(template => ({
                ...template,
                label: `${template.id} ${template.name}`,
                value: template.id,
            })));
        };

        generateFilterOptions();
    }, [ideaSkills, ideaPageThemes, pages]);

    const deletePagesInServer = async (pageIDs) => {
        await updateDataAPI('POST', 'resources/delete-idea-pages/', {
            pageIDs: pageIDs
        });
    }

    const removeIdeaFieldValueInServer = async (pageIDs, newValue) => {
        pageIDs.forEach(async (pageID) => {
            const url = `resources/idea-pages/${pageID}/`;
            try {
                await updateDataAPI('PATCH', url, newValue);
            } catch (e) {
                toast.error(e.message);
            };
        });
    };

    const handleDeleteSelectedPages = async () => {
        if (warningUserWhenPageIsValidate(pages.filter(page => selectedPages.includes(page.id)))) return;
        const shouldDelete = window.confirm('Are you sure you want to delete the selected pages?');
        if (shouldDelete) {
            setLoading(true);
            setPages((prev) => prev.filter((page) => !selectedPages.includes(page.id)));
            setSelectedPages([]);
            await deletePagesInServer(selectedPages);
            setLoading(false);
        }
    }

    const scrollToRow = id => {
        document.getElementById('row-' + id).scrollIntoView();
        hideModal();
    }

    const handleRemoveIdeaFieldValues = (type = 'skill') => {
        const confirmed = window.confirm(`Are you sure you want to remove idea ${type}s from those selected pages?`);

        if (confirmed) {
            setLoading(true);
            setPages((prev) => prev.map((page) => {
                if (selectedPages.includes(page.id)) {
                    page[type] = null;
                    page[`${type}Name`] = null;
                };
                return page;
            }));
            setSelectedPages([]);
            setLoading(false);
            removeIdeaFieldValueInServer(selectedPages, { [type]: null });
        }
    }

    const clearContent = async () => {
        if (warningUserWhenPageIsValidate(pages.filter(page => selectedPages.includes(page.id)))) return;

        if (window.confirm('Are you sure?')) {
            setLoading(true);
            for (let i = 0; i < selectedPages.length; i++) {
                const page = pages.find(p => p.id === selectedPages[i]);
                console.log(page);
                await updateDataAPI('PATCH', 'auto-content/idea-templates/' + page.content.id + '/', {
                    aiResponse: null,
                    answerKey: null,
                    imageUrl: null,
                    lastExportTime: null,
                    lastAiResponseUpdate: null
                });
                await updateDataAPI('POST', 'resources/undone-idea-part/?ideaTemplateID=' + page.content.id);
            }
            setLoading(false);
            window.location.reload();
        }
    }

    return (
        <Modal
            show={true}
            fullscreen
            onHide={hideModal}
            contentLabel="Show page grid view"
            className="auto-content-pages-grid-view"
        >
            <Modal.Header closeButton>
                <Modal.Title className="auto-content-pages-grid-view__title">
                    <Navbar key={false} bg='light' expand={false} className="bg-body-tertiary mb-3">
                        <Container fluid>
                            {selectedPages.length > 0 && (
                                <div className="auto-content-pages-grid-view__actions gap-1">
                                    <IdeaPageSelectOption
                                        page={null}
                                        targetPages={selectedPages}
                                        mode='multi'
                                        updateOption={(value, targetPageIDs) => {
                                            setPages((prev) => (
                                                prev.map((page) => {
                                                    if (targetPageIDs.includes(page['id'])) {
                                                        return {
                                                            ...page,
                                                            skill: value['id'],
                                                            skillName: value['name'],
                                                            skillDetails: value['details']
                                                        };
                                                    };
                                                    return page;
                                                })
                                            ))
                                        }}
                                        pages={pages}
                                        setLoading={setLoading}
                                    />
                                    <IdeaPageSelectOption
                                        page={null}
                                        targetPages={selectedPages}
                                        mode='multi'
                                        updateOption={(value, targetPageIDs) => {
                                            setPages((prev) => (
                                                prev.map((page) => {
                                                    if (targetPageIDs.includes(page['id'])) {
                                                        return { ...page, theme: value['id'], themeName: value['name'] };
                                                    };
                                                    return page;
                                                })
                                            ))
                                        }}
                                        pages={pages}
                                        setLoading={setLoading}
                                        type={Constant.TYPE_IDEA_PAGE_SELECT_OPTION.THEME_TYPE}
                                    />
                                    <IdeaTemplateSelectTrainingText
                                        ideaTemplate={null}
                                        targetPages={pages.filter(page => selectedPages.indexOf(page.id) > -1)}
                                        mode='multi'
                                        updateOption={(value, targetPageIDs) => {
                                            setPages((prev) => (
                                                prev.map((page) => {
                                                    if (targetPageIDs.includes(page['id'])) {
                                                        return {
                                                            ...page, content: {
                                                                ...page.content, trainingText: value['id'],
                                                                trainingTextData: {
                                                                    ...page.content['trainingTextData'],
                                                                    name: value['name'],
                                                                    definition: value['definition'],
                                                                    examples: value['examples'],
                                                                }
                                                            }
                                                        };
                                                    }
                                                    return page;
                                                })
                                            ))
                                        }}
                                        pages={pages}
                                        setLoading={setLoading}
                                        ideaTemplates={[]}
                                    />
                                    <div className="d-flex flex-column gap-1">
                                        <Button variant="warning" onClick={handleShowReportWrongContentModal} size="sm">Report content</Button>
                                        <SelectPageParameters
                                            handleChangeTemplateVersion={handleChangeTemplateVersion}
                                            selectedPageIDs={selectedPages}
                                            setLoading={setLoading}
                                            pages={pages}
                                            setPages={setPages}
                                            customID={customID}
                                            currentPage={getPageOptionsValueRepresentForAllSelectedPages(pages, selectedPages)}
                                            mode='multiple'
                                        />
                                    </div>
                                    <div className="d-flex flex-column gap-1">
                                        <Button
                                            variant="danger"
                                            onClick={handleDeleteSelectedPages}
                                            size="sm"
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                            {" "}
                                            Delete Pages
                                        </Button>
                                        <Dropdown>
                                            <Dropdown.Toggle size="sm" variant="danger" id="clear-multi-pages-options">Clear Content</Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleRemoveIdeaFieldValues('skill')}>Clear Skills</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleRemoveIdeaFieldValues('theme')}>Clear Themes</Dropdown.Item>
                                                <Dropdown.Item onClick={clearContent}>Clear Content</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="d-flex flex-column gap-1">
                                        {selectedPages.length === 1 && (
                                            <Button onClick={() => scrollToRow(selectedPages[0])} size="sm">
                                                Go to page
                                            </Button>
                                        )}
                                        <Button onClick={() => {
                                            if (warningUserWhenPageIsValidate(pages.filter(page => selectedPages.includes(page.id)))) return;
                                            setShowNonAIGenerateModal('generate');
                                        }} size="sm">
                                            Non-AI Generate
                                        </Button>
                                    </div>
                                </div>
                            )}
                            <div className="d-flex flex-column justify-content-center gap-1 mt-1">
                                {selectedPages.length !== currentPages.length && (
                                    <Button
                                        size="sm"
                                        variant="primary"
                                        onClick={() => setSelectedPages(currentPages.map(page => page.id))}
                                    >
                                        Select all
                                    </Button>
                                )}
                                <Button
                                    size="sm"
                                    variant="primary"
                                    onClick={() => setSelectedPages([])}
                                >
                                    Deselect all
                                </Button>
                            </div>

                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand`}
                                aria-labelledby={`offcanvasNavbarLabel-expand`}
                                placement="end"
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                                        Filter & Actions
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <FilterPagesGrid
                                        filterElements={[
                                            {
                                                id: 1,
                                                options: skillOptions,
                                                selectedValue: selectedSkill,
                                                handleChangeOption: (value) => setSelectedSkill(value),
                                                name: 'skills',
                                            },
                                            {
                                                id: 2,
                                                options: themeOptions,
                                                selectedValue: selectedTheme,
                                                handleChangeOption: (value) => setSelectedTheme(value),
                                                name: 'themes',
                                            },
                                            {
                                                id: 3,
                                                options: templateOptions,
                                                selectedValue: selectedTemplate,
                                                handleChangeOption: (value) => setSelectedTemplate(value),
                                                name: 'templates',
                                            },
                                        ]}
                                    />
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                </Modal.Title>
            </Modal.Header>
            <PagesGrid
                pages={currentPages}
                setPages={setPages}
                selectedPages={selectedPages}
                setSelectedPages={setSelectedPages}
            />
        </Modal>
    );
};

export default PagesControlModal;