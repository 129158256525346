import { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { getDataFromAPI } from '../../../utils/query';

const DataSetModal = ({ dataSetID, hideModal }) => {
    const [dataSet, setDataSet] = useState(null);

    useEffect(() => {
        const fetchDataSet = async () => {
            const res = await getDataFromAPI('resources/data-sets/' + dataSetID + '/');
            setDataSet(res.data);
        }

        fetchDataSet();
    }, []);

    return (
        <Modal show={true} onHide={hideModal} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{dataSet?.id}. {dataSet?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dataSet && (
                    <Table striped bordered hover>
                        <tbody>
                            {dataSet.topics.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td>{rowIndex + 1}</td>
                                    {row.map((col, colIndex) => (
                                        <td key={colIndex}>{col}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </Modal.Body>
        </Modal>
    );
}

export default DataSetModal;