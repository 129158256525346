import { Badge, Button, Container, Spinner, Tab, Table, Tabs } from 'react-bootstrap';
import FilterByStore from '../../filters/store';
import FilterByDateRange from '../../filters/datepicker';
import moment from 'moment';
import MultiValues from '../../forms/multi-values';
import { useEffect, useState } from 'react';
import './styles.scss';
import FilterByStaff from '../../filters/staff';
import { useSelector } from 'react-redux';
import { getDataFromAPI } from '../../utils/query';
import IdeaModal from '../idea-list/idea-modal';

const StaffPerformancePage = () => {
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);
    const startDate = useSelector((state) => state.filters.startDate);
    const endDate = useSelector((state) => state.filters.endDate);
    const selectedStaff = useSelector((state) => state.filters.selectedStaff);

    const [steps, setSteps] = useState('');
    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [allSteps, setAllSteps] = useState([]);
    const [editingIdea, setEditingIdea] = useState(null);

    useEffect(() => {
        fetchAllSteps();
    }, []);

    const fetchTasks = async () => {
        setLoading(true);
        let url = 'product-dev/custom-filter-task/?startDate=' + startDate + '&endDate=' + endDate + '&store=' + selectedStoreID;
        if (steps.split(',').length > 0) {
            url += '&steps=' + steps;
        }
        if (selectedStaff) {
            url += '&staff=' + selectedStaff;
        }
        const res = await getDataFromAPI(url);
        setTasks(res.data);
        setLoading(false);
    }

    const fetchAllSteps = async () => {
        const res = await getDataFromAPI('product-dev/task-flow-steps/?category=1');
        setAllSteps(res.data);
    }

    const showIdeaModal = async(task) => {
        const res = await getDataFromAPI('product-ideas/' + task.productIdea);
        setEditingIdea(res.data);
    }

    return (
        <Container className="staff-performance-page-container" fluid>
            <div className="filter">
                <div className="filter-left">
                    <div>
                        <FilterByStore hasAllOption={true} showStoreDescription={false} />
                        <FilterByDateRange defaultDateRange={{
                            startDate: moment().startOf('month').toDate(),
                            endDate: moment().toDate()
                        }} />
                        <div className="filter-by-task-flow-step">
                            <MultiValues value={steps} setValue={val => setSteps(val)}
                                choicesDataAPI="product-dev/task-flow-steps/?category=1" labelField="label" valueField="id" />
                        </div>
                        <div>
                            <FilterByStaff roles="designer,product-collector,product-informator,idea-manager" />
                        </div>
                    </div>
                </div>
                <div className="filter-right">
                    <Button onClick={fetchTasks}>Filter</Button>
                </div>
            </div>

            <div className="nb-tasks">Total: {tasks.length} tasks</div>

            <Tabs id="step-tabs" className="mb-3">
                {allSteps.filter(step => steps.split(',').indexOf(step.id.toString()) > -1).map(step => {
                    const stepTasks = tasks.filter(task => task.history.filter(h => h.step === step.id).length > 0);
                    if (stepTasks.length === 0) {
                        return <div key={step.id}>No tasks</div>;
                    }

                    return (
                        <Tab key={step.id} eventKey={step.id} title={step.categoryName + '. ' + step.label}>
                            <div className="nb-tasks">{stepTasks.length} tasks</div>
                            <Table striped bordered hover>
                                <thead>
                                    <th>ID</th>
                                    <th>Store</th>
                                    <th>Task</th>
                                    <th>Difficulty</th>
                                    <th>Assignees</th>
                                    <th>Current Status</th>
                                </thead>
                                <tbody>
                                    {stepTasks.map(task => (
                                        <tr key={task.id}>
                                            <td>{task.customID}</td>
                                            <td>{task.store}</td>
                                            <td>
                                                <span className="task-name" onClick={() => showIdeaModal(task)}>
                                                    {task.ideaName}
                                                </span>
                                            </td>
                                            <td>
                                                {task.taskDifficulty === 'easy' && (
                                                    <Badge bg="success">Easy</Badge>
                                                )}
                                                {task.taskDifficulty === 'medium' && (
                                                    <Badge bg="warning">Medium</Badge>
                                                )}
                                                {task.taskDifficulty === 'hard' && (
                                                    <Badge bg="danger">Hard</Badge>
                                                )}
                                            </td>
                                            <td>{task.assignedStaffs.map(staff => staff.name).join(', ')}</td>
                                            <td>{task.history.find(h => h.step === task.step)?.stepLabel}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    )
                })}
            </Tabs>

            {editingIdea !== null && (
                <IdeaModal
                    idea={editingIdea}
                    closeModal={action => setEditingIdea(null)}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    );
}

export default StaffPerformancePage;