import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import './styles.scss';

const DuplicateOptionsModal = ({hideModal, duplicate}) => {
    const [withTemplates, setWithTemplates] = useState(true);
    const [withSkills, setWithSkills] = useState(false);
    const [withThemes, setWithThemes] = useState(false);
    const [withSettings, setWithSettings] = useState(false);
    const [withData, setWithData] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        if (selectAll) {
            setWithTemplates(true);
            setWithSkills(true);
            setWithThemes(true);
            setWithSettings(true);
            setWithData(true);
        }
    }, [selectAll]);

    useEffect(() => {
        if (!withTemplates) {
            setWithSkills(false);
            setWithThemes(false);
            setWithSettings(false);
            setWithData(false);
        }
    }, [withTemplates]);

    return (
        <Modal show={true} onHide={hideModal} className="duplicate-options-modal"  backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Duplicate Options</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className="mb-3">
                        <Col>
                            <Form.Check id="duplicate-options-templates"
                                label="Templates"
                                type="checkbox"
                                value={withTemplates}
                                onChange={() => setWithTemplates(!withTemplates)}
                                checked={withTemplates}
                            />
                        </Col>
                        {withTemplates && (
                            <Col>
                                <Form.Check id="duplicate-options-skills"
                                    label="Skills"
                                    type="checkbox"
                                    value={withSkills}
                                    onChange={() => setWithSkills(!withSkills)}
                                    checked={withSkills}
                                />
                            </Col>
                        )}
                        {withTemplates && (
                            <Col>
                                <Form.Check id="duplicate-options-themes"
                                    label="Themes"
                                    type="checkbox"
                                    value={withThemes}
                                    onChange={() => setWithThemes(!withThemes)}
                                    checked={withThemes}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        {withTemplates && (
                            <Col>
                                <Form.Check id="duplicate-options-settings"
                                    label="Settings"
                                    type="checkbox"
                                    value={withSettings}
                                    onChange={() => setWithSettings(!withSettings)}
                                    checked={withSettings}
                                />
                            </Col>
                        )}
                        {withTemplates && (
                            <Col>
                                <Form.Check id="duplicate-options-data"
                                    label="Data"
                                    type="checkbox"
                                    value={withData}
                                    onChange={() => setWithData(!withData)}
                                    checked={withData}
                                />
                            </Col>
                        )}
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <span className="select-all-btn" onClick={() => setSelectAll(!selectAll)}>Select all</span>
                <Button onClick={() => duplicate(withTemplates, withSkills, withThemes, withSettings, withData)}>Duplicate</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DuplicateOptionsModal;