import React from "react";
import './styles.scss'
import Step from "./step";
import { useSelector } from "react-redux";

const ProgressStep = () => {
    const {
        steps,
        currentStep, 
    } = useSelector(state => state.generateProduct)

    return (
        <div className="progress-step">
            {steps.map((step) => (
                <Step step={step} isActive={currentStep.id >= step.id} key={step.id}/>
            ))}
        </div>
    )
}

export default ProgressStep;