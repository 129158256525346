import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const IdeaPricesControl = ({
    price,
    setPrice,
    nbPages,
    discount,
}) => {
    const [salePrice, setSalePrice] = useState(((1 - discount) * price).toFixed(2) ?? 0);

    const handleBasedPriceChange = (e) => {
        const value = e.target.value;
        setPrice(value);
        setSalePrice((value * (1 - discount)).toFixed(2));
    };

    const handleSalePriceChange = (e) => {
        const value = e.target.value;
        setPrice((value / (1 - discount)).toFixed(2));
        setSalePrice(value);
    };

    return (
        <div className="idea-prices-control">
            <Form.Group className="mb-3">
                <Form.Label>
                    Based Price
                    {nbPages && (
                        <span> ({nbPages} pages)</span>
                    )}
                </Form.Label>
                <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control disabled value={price} onChange={handleBasedPriceChange} />
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>
                    Sale Price (discount: {discount * 100}%)
                </Form.Label>
                <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control value={salePrice} onChange={handleSalePriceChange} />
                </InputGroup>
            </Form.Group>
        </div>
    )
}

export default IdeaPricesControl;