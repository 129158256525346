import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import axios from 'axios';
import Constant from '../../Constant';

const PublicProductDescriptionPage = ({ }) => {

    const { customID } = useParams();
    const [description, setDescription] = useState();

    useEffect(() => {
        const isFreeProduct = (new URLSearchParams(window.location.search)).get('free');
        fetchProductDescription(isFreeProduct);
    }, []);

    const fetchProductDescription = async (isFreeProduct) => {
        const res = await axios.get(Constant.API_URL + 'resources/get-product-idea-description-by-customID/?customID=' + customID + '&free=' + isFreeProduct, {
            headers: {
                Authorization: 'Token ' + process.env.REACT_APP_PUBLIC_BOT_TOKEN
            }
        });
        setDescription(res.data);
    }

    if (description === undefined) {
        return null;
    }

    return (
        <ReactQuill theme="snow" value={description} onChange={setDescription} modules={{ toolbar: false }} />
    )
}

export default PublicProductDescriptionPage;