import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FlowProgress from '../../../common/flow-progress';
import Step1SelectType from './step-1-select-type';
import Step2SelectTag from './step-2-select-tag';
import Step3SelectTemplate from './step-3-select-template';
import { getDataFromAPI } from '../../../../utils/query';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { fetchCategories } from '../../../../utils/commonUtil';

export const SETTING_PART_STEPS = [
    {
        id: 1,
        label: 'SELECT TYPE'
    },
    {
        id: 2,
        label: 'SELECT TAGS'
    },
    {
        id: 3,
        label: 'SELECT TEMPLATE'
    }
]

const CreateNewPartModal = ({
    part,
    setLoading = () => { },
    closeModal = () => { },
    handleUpdatePartField = () => { },
    handleCreateNewPart = () => { },
}) => {
    const { selectedTags } = useSelector((state) => state.autoContent);

    const [currentStep, setCurrentStep] = useState(1);
    const [templates, setTemplates] = useState([]);
    const [categories, setCategories] = useState([]);
    const [typeCategory, setTypeCategory] = useState({});

    // Variables for templates search
    const pageSize = 30;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [nbResults, setNbResutls] = useState(0);

    useEffect(() => {
        fetchAllCategories();
    }, []);

    const fetchAllCategories = async () => {
        const data = await fetchCategories();
        setTypeCategory(data.find((category) => category.name === 'Type'));
        setCategories(data);
    };

    const handleNextStep = () => {
        if (currentStep === 1 && !part?.type) {
            toast.info("Please select the type of part.");
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const fetchTemplates = async (pageIndex = currentPage) => {
        setLoading(true);
        setCurrentPage(pageIndex);
        try {
            let tagIds = selectedTags.map(tag => tag.id);
            const selectedType = typeCategory?.tags.find((tag) => tag.name.toLowerCase() === part.type);
            if (selectedType) {
                tagIds.push(selectedType.id);
            };
            const res = await getDataFromAPI(`auto-content/templates/?tags=${tagIds.join(',')}&page=${pageIndex}&page_size=${pageSize}&onlyActiveTemplates=true`);
            if (res) {
                const data = res.data;
                setNbResutls(data.count)
                setTotalPages(Math.ceil(data.count / pageSize))
                const templates = data.results;
                templates.sort((a, b) => b.id - a.id);
                setTemplates(templates);
            }
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
        setLoading(false);
    }

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <Step1SelectType
                    typeCategory={typeCategory}
                    part={part}
                    handleUpdatePartField={handleUpdatePartField}
                />;
            case 2:
                return <Step2SelectTag
                    categories={categories.filter(category => category.name !== 'Type')}
                    fetchAllCategories={fetchAllCategories}
                />;
            case 3:
                return <Step3SelectTemplate
                    fetchTemplates={fetchTemplates}
                    part={part}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={(pageIndex) => fetchTemplates(pageIndex)}
                    templates={templates}
                    handleUpdatePartField={handleUpdatePartField}
                />;
            default:
                return null;
        }
    };

    return (
        <Modal show={true} backdrop="static" onHide={closeModal} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Add new part</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FlowProgress
                    flowSteps={SETTING_PART_STEPS}
                    isStepActive={(step) => step.id === currentStep}
                    hanldeClickStep={(step) => setCurrentStep(step)}
                    showRefreshButton={false}
                    refreshTaskProgress={() => {}}
                />
                {renderStep()}
            </Modal.Body>
            <Modal.Footer>
                {currentStep !== 1 && (
                    <Button onClick={handlePreviousStep} variant="primary">Previous</Button>)
                }
                {currentStep !== 3 && (
                    <Button onClick={handleNextStep} variant="primary">Next</Button>
                )}
                {part?.template && currentStep === 3 && (
                    <Button
                        variant="success"
                        onClick={handleCreateNewPart}
                    >
                        Create
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default CreateNewPartModal;