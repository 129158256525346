import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStores, setSelectedStoreID } from '../filtersSlice';
import { getDataFromAPI } from '../../utils/query';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import './styles.scss';

const FilterByStore = ({ hasAllOption = false, showMetaLink = true, showStoreDescription = true, showNbIdeas = false, defaultStore = 'all' }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const stores = useSelector((state) => state.filters.stores);
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);

    useEffect(() => {
        fetchStores();
    }, []);

    useEffect(() => {
        if (selectedStoreID !== null && !isNaN(selectedStoreID)) {
            const params = new URLSearchParams({ 'selectedStoreID': selectedStoreID });
            history.replace({ pathname: location.pathname, search: params.toString() });
        }
    }, [selectedStoreID]);

    useEffect(() => {
        const getDefaultStoreID = () => {
            let defaultStoreID = stores[0]?.id;
            if (defaultStoreID) {
                const selectedStoreID = (new URLSearchParams(window.location.search)).get('selectedStoreID');
                if (selectedStoreID !== null && !isNaN(selectedStoreID)) {
                    dispatch(setSelectedStoreID(parseInt(selectedStoreID)));
                } else if (defaultStore === 'all') {
                    dispatch(setSelectedStoreID('all'));
                } else {
                    dispatch(setSelectedStoreID(defaultStoreID))
                }
            }
        }

        getDefaultStoreID();
    }, [stores]);

    const fetchStores = async () => {
        if (showNbIdeas) {
            let url = 'stores/?active=true';
            url += '&nbIdeas';
            const res = await getDataFromAPI(url);
            dispatch(setStores(res.data));
        }
    }

    return (
        <div className="filter-by-store">
            <Select
                className="select"
                options={[].concat(
                    hasAllOption ? [{ value: "all", label: "All" }] : [],
                    stores.map((store) => ({
                        value: store.id,
                        label: (
                            <div key={store.id} className="store-label">
                                <strong> {`${store.name}`} </strong> {showNbIdeas ? ' - ' + store.nbIdeas +  ' ideas' : ''}
                                {store.description && (
                                    <>
                                        <br />
                                        <i>{showStoreDescription ? (' (' + store.description + ')') : ''} </i>
                                    </>
                                )}
                            </div>
                        ),
                    }))
                )}
                onChange={(selectedOption) => {
                    dispatch(setSelectedStoreID(selectedOption.value !== 'all' ? parseInt(selectedOption.value) : 'all'));
                }}
                value={(selectedStoreID !== undefined && selectedStoreID !== 'all') ? {label: stores.find(s => s.id === selectedStoreID)?.name, value: selectedStoreID} : { value: "all", label: "All" }}
            />
            {showMetaLink && (
                <div className="meta-links">
                    {stores.find(s => s.id === selectedStoreID)?.worksheetThumbnailFormUrl && (
                        <div>
                            <a href={stores.find(s => s.id === selectedStoreID).worksheetThumbnailFormUrl} target="_blank" rel="noreferrer">Worksheet Thumbnail Form</a>
                        </div>
                    )}
                    {stores.find(s => s.id === selectedStoreID)?.taskcardThumbnailFormUrl && (
                        <div>
                            <a href={stores.find(s => s.id === selectedStoreID).taskcardThumbnailFormUrl} target="_blank" rel="noreferrer">Taskcard Thumbnail Form</a>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default FilterByStore;