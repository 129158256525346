import { createSlice } from '@reduxjs/toolkit';

export const staffSlice = createSlice({
    name: 'staff',
    initialState: {
        performanceTickets: [],
        staffs: [],
    },
    reducers: {
        setPerformanceTicket: (state, action) => {
            state.performanceTickets = action.payload;
        },
        setStaffs: (state, action) => {
            state.staffs = action.payload;
        },
        addPerformanceTicket: (state, action) => {
            state.performanceTickets.push(action.payload);
        },
        updatePerformanceTicket: (state, action) => {
            const updatedTicket = action.payload;
            state.performanceTickets = [...state.performanceTickets].map((ticket) => {
                if (ticket.id === updatedTicket.id) return updatedTicket;
                return ticket;
            });
        }
    }
});

export const {
    setPerformanceTicket,
    addPerformanceTicket,
    updatePerformanceTicket,
    setStaffs,
} = staffSlice.actions;

export default staffSlice.reducer;