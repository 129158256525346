import { Button, Form, Modal } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import { useEffect, useState } from 'react';

const OutlineModal = ({outline, hideModal}) => {
    const [name, setName] = useState(outline ? outline.name : null);
    const [staff, setStaff] = useState(outline ? outline.staff : null);
    const [allStaffs, setAllStaffs] = useState([]);

    useEffect(() => {
        fetchAllStaffs();
    }, []);

    const fetchAllStaffs = async () => {
        const res = await getDataFromAPI('product-dev/staffs/?roles=product-collector');
        setAllStaffs(res.data);
    }

    const save = async() => {
        if (outline) {
            await updateDataAPI('PATCH', 'product-dev/outlines/' + outline.id + '/', {
                name, staff
            });
        } else {
            await updateDataAPI('POST', 'product-dev/outlines/', {
                name, staff
            });
        }
        hideModal('refresh');
    }
    return (
        <Modal
            show={true}
            onHide={hideModal}
            className="new-outline-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    New Outline
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter outline name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Staff</Form.Label>
                        <Form.Select
                            onChange={(e) => setStaff(e.target.value)}
                            value={staff}
                        >
                            <option></option>
                            {allStaffs.map((staff, index) => (
                                <option value={staff.id} key={index}>{staff.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-primary" onClick={save} disabled={!name || !staff}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default OutlineModal;