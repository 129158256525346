import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import './styles.scss';
import { Accordion, Form, Tab, Tabs } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import Constant from '../../../../Constant';
import UploadFile from '../../../content-studio/upload-file';

const StoreTemplatesTab = ({
    storeID,
    marketplace,
    autoGenTemplates,
    updateStore = () => { }
}) => {
    const [loading, setLoading] = useState(false);
    const [requirements, setRequirements] = useState({});
    const [templateID, setTemplateID] = useState(null);
    const [currentRequirementPath, setCurrentRequirementPath] = useState(null);
    const [templates, setTemplates] = useState({});

    useEffect(() => {
        const fetchRequirements = async () => {
            const res = await getDataFromAPI('get-store-template-requirements/');
            setRequirements(res.data);

            const templateIDs = [];

            Object.keys(res.data).forEach(ideaType => {
                Object.keys(res.data[ideaType]).forEach(season => {
                    res.data[ideaType][season].forEach(req => {
                        const templateID = autoGenTemplates?.[ideaType]?.[season]?.[req['slug']];
                        if (templateID !== undefined && templateIDs.indexOf(templateID) === -1) {
                            templateIDs.push(templateID);
                        }
                    });
                });
            });

            const resTemplates = await updateDataAPI('POST', 'auto-content/get-template-thumbnails/', { templateIDs });
            setTemplates(resTemplates.data);
        }

        if (marketplace === 'boom') {
            fetchRequirements();
        }
    }, [marketplace]);

    const updateAutoGenTemplates = async () => {
        setLoading(true);
        const obj = { ...autoGenTemplates };
        if (!(currentRequirementPath[0] in obj)) {
            obj[currentRequirementPath[0]] = {};
        }
        if (!(currentRequirementPath[1] in obj[currentRequirementPath[0]])) {
            obj[currentRequirementPath[0]][currentRequirementPath[1]] = {};
        }
        obj[currentRequirementPath[0]][currentRequirementPath[1]][currentRequirementPath[2]] = !isNaN(templateID) ? parseInt(templateID) : templateID;
        setTemplateID(null);
        setCurrentRequirementPath(null);
        await updateDataAPI('PATCH', 'stores/' + storeID + '/', {
            autoGenTemplates: obj
        });
        if (templates[templateID] === undefined) {
            const resTemplates = await updateDataAPI('POST', 'auto-content/get-template-thumbnails/', { templateIDs: [templateID] });
            setTemplates({ ...templates, [templateID]: resTemplates.data[templateID] });
        }
        updateStore(obj);
        setLoading(false);
    }

    const toggleSeasonReady = async (ideaType, season) => {
        setLoading(true);
        const obj = { ...autoGenTemplates };
        if (obj[ideaType][season]['ready'] === undefined || obj[ideaType][season]['ready'] === false) {
            obj[ideaType][season]['ready'] = true;
        } else {
            obj[ideaType][season]['ready'] = false;
        }
        await updateDataAPI('PATCH', 'stores/' + storeID + '/', {
            autoGenTemplates: obj
        });
        updateStore(obj);
        setLoading(false);
    }

    return (
        <Container className="store-templates-tab" fluid>
            <div className="header">
                <div>
                </div>
                <Button size="sm" onClick={() => window.open(process.env.REACT_APP_WORKSHEET_MAKER_URL + `?type=answer_sheet_template&storeID=${storeID}&id=`, '_blank', 'noopener,noreferrer')}>
                    Add New Template
                </Button>
            </div>
            <Accordion defaultActiveKey="0">
                {Object.keys(requirements).map((ideaType, ideaTypeIndex) => (
                    <Accordion.Item eventKey={ideaTypeIndex} key={ideaTypeIndex}>
                        <Accordion.Header>{ideaType}</Accordion.Header>
                        <Accordion.Body>
                            <Tabs className="mt-3">
                                {Object.keys(requirements[ideaType]).map((season, seasonIndex) => (
                                    <Tab key={seasonIndex} eventKey={season} title={season + (autoGenTemplates?.[ideaType]?.[season]?.['ready'] ? ' (ready)' : '')}>
                                        <div className="mt-3 mb-3">
                                            <Form.Check type="checkbox" label="Ready to Run Auto" id={'seasonal-' + ideaTypeIndex + '-' + seasonIndex + '-checkbox'}
                                                onChange={e => {
                                                    toggleSeasonReady(ideaType, season);
                                                }}
                                                checked={autoGenTemplates?.[ideaType]?.[season]?.['ready']} />
                                        </div>
                                        <Table striped bordered hover className="season-templates-table">
                                            <tbody>
                                                {requirements[ideaType][season].map((req, reqIndex) => (
                                                    <tr key={reqIndex}>
                                                        <td>
                                                            <div>{req['slug']}</div>
                                                            <Form.Text>{req['description']}</Form.Text>
                                                        </td>
                                                        <td>
                                                            {(currentRequirementPath !== null && currentRequirementPath[0] === ideaType && currentRequirementPath[1] === season && currentRequirementPath[2] === req['slug']) ? (
                                                                <div>
                                                                    <Form.Control value={templateID} onChange={e => setTemplateID(e.target.value)} />
                                                                    {req['slug'].startsWith('image') && (
                                                                        <UploadFile
                                                                            id={storeID}
                                                                            setLoading={setLoading}
                                                                            uploadUrl={'resources/upload-store-template-files/'}
                                                                            allowedFileExtentions={['png', 'jpg', 'jpeg']}
                                                                            fieldName={'requirement_store_' + storeID + '_' + ideaType + '_' + season + '_' + req['slug']}
                                                                            uploadedFileName={null}
                                                                            setUploadedFileName={data => {
                                                                                setTemplateID(data);
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    {autoGenTemplates?.[ideaType]?.[season]?.[req['slug']] && (
                                                                        <a href={Constant.WORKSHEET_MAKER_URL + '?type=template&id=' + autoGenTemplates?.[ideaType]?.[season]?.[req['slug']]} target="_blank">
                                                                            {autoGenTemplates?.[ideaType]?.[season]?.[req['slug']]}
                                                                        </a>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {typeof(autoGenTemplates?.[ideaType]?.[season]?.[req['slug']]) === 'string' && (
                                                                <img src={autoGenTemplates?.[ideaType]?.[season]?.[req['slug']]} />
                                                            )}
                                                            {autoGenTemplates?.[ideaType]?.[season]?.[req['slug']] && templates[autoGenTemplates?.[ideaType]?.[season]?.[req['slug']]] && (
                                                                <div>
                                                                    <img src={templates[autoGenTemplates?.[ideaType]?.[season]?.[req['slug']]]['imageUrl']} />
                                                                    <div>{templates[autoGenTemplates?.[ideaType]?.[season]?.[req['slug']]]['name']}</div>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {(currentRequirementPath !== null && currentRequirementPath[0] === ideaType && currentRequirementPath[1] === season && currentRequirementPath[2] === req['slug']) ? (
                                                                <>
                                                                    <Button size="sm" variant="secondary" className="cancel-btn" onClick={() => {
                                                                        setTemplateID(null);
                                                                        setCurrentRequirementPath(null);
                                                                    }}>
                                                                        Cancel
                                                                    </Button>
                                                                    <Button size="sm" variant="secondary" onClick={updateAutoGenTemplates}>
                                                                        Save
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                <Button size="sm" variant="secondary" onClick={() => {
                                                                    if (autoGenTemplates?.[ideaType]?.[season]?.[req['slug']]) {
                                                                        setTemplateID(autoGenTemplates?.[ideaType]?.[season]?.[req['slug']]);
                                                                    }
                                                                    setCurrentRequirementPath([ideaType, season, req['slug']]);
                                                                }}>
                                                                    Edit
                                                                </Button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Tab>
                                ))}
                            </Tabs>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            {/* {answerSheetPreviewTemplates.map((template) => (
                        <tr key={template.id}>
                            <td>{template.id}</td>
                            <td>{template.name}</td>
                            <td>
                                <img src={template.imageUrl} />
                            </td>
                            <td>
                                <Button size="sm" className="editBtn" onClick={() => duplicateTemplate(template)}>
                                    Duplicate
                                </Button>
                                <Button size="sm" className="editBtn"
                                    onClick={() => window.open(process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=answer_sheet_template&id=' + template.id, '_blank', 'noopener,noreferrer')}
                                >
                                    Edit
                                </Button>
                                <Button size="sm" variant="danger"
                                    onClick={() => deleteTemplate(template)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))} */}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default StoreTemplatesTab;