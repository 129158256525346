import { Button, Col, Form, Modal, Row, Spinner, Tab, Tabs } from 'react-bootstrap';

import './styles.scss';
import { useEffect, useState } from 'react';
import { updateDataAPI } from '../../../utils/query';
import { useDispatch } from 'react-redux';
import { setIdeaTemplateContents } from '../autoContentSlice';
import Constant from '../../../Constant';

const BulkGenerateModal = ({ socket, ideaTemplate, hideModal }) => {
    const dispatch = useDispatch();

    const [activity, setActivity] = useState(ideaTemplate.templateData.activity);
    const [nbItems, setNbItems] = useState(5);
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [displayMode, setDisplayMode] = useState('manual');
    const [manualContent, setManualContent] = useState();
    const [prompt, setPrompt] = useState(null);


    useEffect(() => {
        getPrompt();
    }, []);

    const getPrompt = async() => {
        const res = await updateDataAPI('POST', 'auto-content/get-idea-template-prompt/?ideaTemplateID=' + ideaTemplate.id, {
            activity: ideaTemplate.activity
        });
        setPrompt(res.data['prompt'].map(item => item.content).join('\n\n'));
    }

    const insertManualContent = async () => {
        dispatch(setIdeaTemplateContents({
            ideaTemplateID: ideaTemplate.id,
            worksheet: {},
            answerKey: null,
            icons: [],
            loading: true,
        }));
        socket.send(JSON.stringify({
            text: 'format external content for idea template ' + ideaTemplate.id,
            command: 'format_external_content',
            aiResponse: manualContent,
            ideaTemplateID: ideaTemplate.id,
            'sender': 'client',
            staffID: localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID),
            formatData: true,
        }));
    }

    const autoGenerate = async () => {
        try {
            setLoading(true);
            const res = await updateDataAPI('POST', 'auto-content/generate-bulk-content/?ideaTemplateID=' + ideaTemplate.id, {
                activity,
                nbItems,
            });
            setResult(res.data['worksheets']);
        } catch (e) {
            window.alert('Can not generate content, please lower the number of items then try again');
        }
        setLoading(false);
    }

    return (
        <Modal
            show={true}
            fullscreen={true}
            onHide={hideModal}
            contentLabel="Bulk Generate"
            className="bulk-generate-modal"
            backdrop="static" keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Bulk Generate
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="images">
                            <img src={ideaTemplate.templateData.imageUrl} />
                            {prompt && (
                                <Form.Control as="textarea" value={prompt} disabled={true} />
                            )}
                        </div>
                        <Tabs activeKey={displayMode} onSelect={(k) => setDisplayMode(k)} id="taskcard-tabs" className="mt-5">
                            <Tab eventKey="manual" title="Manual">
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Manual Content</Form.Label>
                                        <Form.Control as="textarea" rows={20} value={manualContent} onChange={e => setManualContent(e.target.value)} />
                                    </Form.Group>
                                </Form>
                            </Tab>
                            <Tab eventKey="auto" title="Auto">
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Item Activity</Form.Label>
                                        <Form.Control as="textarea" rows={5} value={activity} onChange={e => setActivity(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mt-3">
                                        <Form.Label>Number of Items</Form.Label>
                                        <Form.Control type="number" value={nbItems} onChange={e => setNbItems(e.target.value)} />
                                    </Form.Group>
                                </Form>
                            </Tab>
                        </Tabs>
                    </Col>
                    <Col>
                        {loading ? (
                            <Spinner animation="border" />
                        ) : (
                            <div className="result">
                                {result.map((item, index) => (
                                    <div key={index}></div>
                                ))}
                            </div>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {displayMode === 'manual' ? (
                    <>
                        <Button onClick={insertManualContent}>Insert</Button>
                        <Button onClick={() => navigator.clipboard.writeText(prompt)}>Copy Prompt</Button>
                    </>
                ) : (
                    <Button onClick={autoGenerate}>Generate</Button>
                )}
                <Button onClick={hideModal} variant="secondary">Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default BulkGenerateModal;