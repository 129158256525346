import { Col, Form, Row } from 'react-bootstrap';
import FilterByCategory from '../../../../filters/category';
import MultiValues from '../../../../forms/multi-values';
import SelectKeywords from '../../select-keywords';
import ReactQuill from 'react-quill';
import { hasPermission } from '../../../../utils/auth';
import { useSelector } from 'react-redux';
import AsyncSelectComponent from '../../../../utils/async-select';
import IdeaPricesControl from './idea-prices-control';

import './styles.scss';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../../../utils/query';

const convertToSelectOptions = (arr = []) => {
    if (arr.length === 0) return [];
    return arr.map((item, index) => ({ id: index + 1, value: item, label: item }));
}

const IdeaModalInformationsTab = ({
    stores,
    idea,
    userRoles,
    loading,
    setLoading,
    store,
    setStore,
    price,
    setPrice,
    category,
    setCategory,
    name,
    setName,
    subjects,
    setSubjects,
    productMapSkill,
    setProductMapSkill,
    productMapSkillLabel,
    setProductMapSkillLabel,
    tags,
    setTags,
    grades,
    setGrades,
    standards,
    setStandards,
    estimatedNbPages,
    setEstimatedNbPages,
    mainKeyword,
    setMainKeyword,
    keywords,
    setKeywords,
    subKeywords,
    setSubKeywords,
    note,
    setNote,
    noteEditorActive,
    setNoteEditorActive,
}) => {
    const { activityPermissions } = useSelector((state) => state.permissions);
    const [mainKeywordLabel, setMainKeywordLabel] = useState(null);

    useEffect(() => {
        const fetchMainKeywordLabel = async() => {
            const res = await getDataFromAPI('resources/keywords/' + mainKeyword + '/');
            setMainKeywordLabel(res.data['label']);
        }

        if (mainKeyword) {
            fetchMainKeywordLabel();
        }
    }, []);

    return (
        <div className="idea-modal-informations-tab">
            <Row className="mt-3">
                <Col>
                    <Form.Group className="mb-3" controlId="productIdeaCategory">
                        <Form.Label><strong>Flow*</strong></Form.Label>
                        <FilterByCategory filtering={false} value={category} setValue={setCategory} hasAllOptions={false} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Store*</strong></Form.Label>
                        <Form.Select onChange={e => setStore(e.target.value)} value={store}>
                            <option value={null}></option>
                            {stores.map(s => (
                                <option value={s.id} key={s.id}>{s.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Name*</strong></Form.Label>
                        <Form.Control value={name} onChange={e => setName(e.target.value)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>TPT Subjects (Select 3 subjects)</Form.Label>
                        <MultiValues value={subjects} setValue={val => setSubjects(val)} choicesDataAPI="resources/subjects/"
                            labelField="name" valueField="name" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="productIdeaTags">
                        <Form.Label>Tags</Form.Label>
                        <MultiValues value={tags} setValue={val => setTags(val)} choicesDataAPI="resources/tags/?types=product,season,quality"
                            labelField="name" valueField="id" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="productIdeaGrades">
                        <Form.Label>Grades</Form.Label>
                        <MultiValues value={grades} setValue={val => setGrades(val)} defaultChoices={[
                            'PreK',
                            'Kindergarten', 'Homeschool', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
                        ]} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="productIdeaEvents">
                        <Form.Label>Product Map</Form.Label>
                        <AsyncSelectComponent
                            searchUrl="resources/product-map-skills/?keyword="
                            onChange={selectedValue => {
                                setProductMapSkill(selectedValue.value);
                                setProductMapSkillLabel(selectedValue.label);
                            }}
                            value={{value: productMapSkill, label: productMapSkillLabel}}
                            setLoading={setLoading}
                            loading={loading}
                            clearSelection={() => setProductMapSkill(null)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Standards</Form.Label>
                        <MultiValues value={standards} setValue={val => setStandards(val)} choicesDataAPI="standards/"
                            labelField="code" valueField="code" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Estimated Number Pages</Form.Label>
                        <Form.Control value={estimatedNbPages} onChange={e => setEstimatedNbPages(e.target.value)} />
                    </Form.Group>
                </Col>

            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Main Keyword</Form.Label>
                        <AsyncSelectComponent
                            searchUrl='resources/keywords/?name='
                            onChange={selectedValue => {
                                console.log(selectedValue);
                                setMainKeyword(selectedValue.id);
                                setMainKeywordLabel(selectedValue.label);
                            }}
                            value={{ value: mainKeyword, label: mainKeywordLabel }}
                            setLoading={setLoading}
                            loading={loading}
                            clearSelection={() => {}}
                            defaultData={[]}
                        />
                        {mainKeyword && (
                            <a href={'/product-map/?keyword=' + mainKeyword} target="_blank">{mainKeyword}</a>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Sub Keywords (optional)</Form.Label>
                        <Form.Control as="textarea" rows={2} value={subKeywords} onChange={e => setSubKeywords(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Keywords</Form.Label>
                        <SelectKeywords
                            selectedKeywords={convertToSelectOptions(keywords ? keywords.split(',') : [])}
                            setSelectedKeywords={(options) => setKeywords(options.map(({ value }) => value).join(','))}
                        />
                    </Form.Group>
                    {(hasPermission(userRoles, activityPermissions['VIEW_PRICE'])) && (
                        <IdeaPricesControl
                            price={price}
                            setPrice={setPrice}
                            nbPages={idea?.nbPages}
                            discount={(stores.find(s => s.id === store)?.sale) / 100 || 0.2}
                        />
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mt-3">
                        <Form.Label className="note-label">
                            <span>Note</span>
                            <span onClick={() => setNoteEditorActive(true)}>edit</span>
                        </Form.Label>
                        {noteEditorActive ? (
                            <ReactQuill theme="snow" value={note} onChange={setNote} />
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: note }} className="note-editor-view"></div>
                        )}
                    </Form.Group>
                </Col>
            </Row>
        </div>
    );
}

export default IdeaModalInformationsTab;