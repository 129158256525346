import { useEffect, useState } from 'react';
import './styles.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import AddNewCollection from './addNewCollection';
import ListCollections from './listCollection';
import { toast } from 'react-toastify';
import { updateDataAPI } from '../../../../utils/query';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export const OperationsArea = ({ operations, setOperations }) => {

    return (
        <Col className="d-flex flex-column">
            <label>Operations Input (1 operation per line)</label>
            <textarea
                value={operations}
                onInput={(e) => setOperations(e.target.value)}
            >

            </textarea>
        </Col>
    )
};

export const ResultsArea = ({ results, setResults }) => {
    const handleResultChange = (e) => {
        const { value } = e.target;

        setResults(value)
    }

    return (
        <Col className="d-flex flex-column">
            <label>Result Input</label>
            <InputGroup>
                <Form.Control
                    placeholder="Result"
                    aria-label="Result"
                    aria-describedby="basic-addon1"
                    value={results}
                    onChange={handleResultChange}
                />
            </InputGroup>
        </Col>
    )
}

const PrepareDataStep = () => {
    const [showEditorCollection, setShowEditorCollection] = useState(false);
    const { operations: data } = showEditorCollection;
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState("");
    const [operations, setOperations] = useState("");

    useEffect(() => {
        if (showEditorCollection.hasOwnProperty("operations")) {
            const value = data.length === 0 ? "" : data[0].result;
            setResults(value);
            setOperations(data.map(item => item.operation).join('\n'));
        }
    }, [showEditorCollection]);

    const handleSaveCollectionOperations = async () => {
        setLoading(true);
        const formatedOperations = operations.split('\n');

        const formatedResults = Array(operations.length).fill(results);

        if (results === '') {
            toast.warn("Please fill the results field.");
            return;
        } else {
            const collections = formatedOperations.map((operation, index) => {
                const item = {
                    operation: operation,
                    result: formatedResults[index]
                };

                return item
            });
            const url = 'product-dev/collection-operations/create-or-update-collection-operations/';

            const data = {
                ...showEditorCollection,
                operations: collections,
            }

            const res = await updateDataAPI('POST', url, data);

            if (res.status === 200) {
                toast.info("Updated collections successfully.");
            }
        }
        setLoading(false);
    }

    return (
        <div className="container__result-step-2">
            {!showEditorCollection ? (
                <Container>
                    <AddNewCollection setShowEditorCollection={setShowEditorCollection} />
                    <ListCollections setShowEditorCollection={setShowEditorCollection} />
                </Container>
            ) : (
                <Container>
                    <div className="d-flex flex-column">
                        <h1
                            onClick={() => setShowEditorCollection(false)}
                            className="container__result-step-2__back"
                        >&lt; Back to all collections</h1>
                        <h4>{showEditorCollection.name} (10 operations, 2 results)</h4>
                    </div>
                    <Row>
                        <OperationsArea
                            operations={operations}
                            setOperations={setOperations}
                        />
                        <ResultsArea
                            results={results}
                            setResults={setResults}
                        />
                    </Row>
                    <Container className="mt-2 d-flex justify-content-center">
                        <Button variant="primary" onClick={handleSaveCollectionOperations}>
                            Save collections
                        </Button>
                    </Container>
                </Container>
            )}
            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </div>
    )
}

export default PrepareDataStep;