import { createSlice } from '@reduxjs/toolkit';

export const permissionsSlice = createSlice({
    name: 'permissions',
    initialState: {
        routePermissions: {},
        activityPermissions: {},
    },
    reducers: {
        setRoutePermissions: (state, action) => {
            state.routePermissions = action.payload;
        },
        setActivityPermissions: (state, action) => {
            state.activityPermissions = action.payload;
        }
    }
});

export const {
    setRoutePermissions,
    setActivityPermissions,
} = permissionsSlice.actions;

export default permissionsSlice.reducer;