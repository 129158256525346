import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import { useEffect, useState } from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import Constant from '../../../Constant';

const TaskCheckListModal = ({ task, taskFlowSteps, closeModal }) => {
    const [allRequirements, setAllRequirements] = useState([]);
    const [currentStepTitle, setCurrentStepTitle] = useState();
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    useEffect(() => {
        const currentStep = taskFlowSteps.find(step => step.id === task.step);
        const taskConditionsToNextStep = task.conditionsToNextSteps ? JSON.parse(task.conditionsToNextSteps) : [];

        const allRequirements = [];
        for (let i = 0; i < taskFlowSteps.length; i++) {
            const step = taskFlowSteps[i];
            for (let j = 0; j < step.requirementLabels.length; j++) {
                if (allRequirements.find(r => r['requirement'] === step.requirementLabels[j]) === undefined && step.requirementLabels[j].length > 0) {
                    allRequirements.push({
                        requirement: step.requirementLabels[j],
                        done: (step.pos <= currentStep.pos + 1) ? (taskConditionsToNextStep.indexOf(step.requirementLabels[j]) === -1) : false,
                        nextStep: step.pos === currentStep.pos + 1
                    });
                }
            }
        }

        setCurrentStepTitle(currentStep.label);
        setAllRequirements(allRequirements);
    }, []);
    return (
        <Modal show={true} onHide={() => closeModal('hide')} size="lg" className="task-checklist-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    {task.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup as="ul">
                    <ListGroup.Item as="li" active>
                        <span>Check List</span>
                        <span>Current Step: {currentStepTitle}</span>
                    </ListGroup.Item>
                    {allRequirements.map((r, index) => (
                        <ListGroup.Item key={index} as="li" variant={r['done'] ? 'success' : 'secondary'}>
                            <div className="requirement">
                                {r['done'] && (
                                    <FontAwesomeIcon icon={faCheckCircle} className="done-icon" />
                                )}
                                {r['nextStep'] && (
                                    <>
                                        <Badge>
                                            Next Step
                                        </Badge>
                                        {userRoles.indexOf('admin') > -1 && (
                                            <Badge bg="secondary">
                                                <span>{r['requirement']}</span>
                                            </Badge>
                                        )}
                                    </>
                                )}
                                <span>{Constant.getRequirementExplanation(r['requirement'], task.customID)}</span>
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Modal.Body>
        </Modal>
    )
}

export default TaskCheckListModal;