import ListGroup from 'react-bootstrap/ListGroup';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const getGroupItemStatusInfo = (status) => {
    let result = {
        variant: 'primary',
        status: 'GENERATING'
    };

    switch (status) {
        case true:
            result = {
                variant: 'success',
                status: 'DONE'
            }
            break;
        case false:
            result = {
                variant: 'primary',
                status: 'NOT GENERATE'
            }
            break;
        case 'loading':
            result = {
                variant: 'info',
                status: 'GENERATING'
            }
            break;

        case 'error':
            result = {
                variant: 'warning',
                status: 'ERROR'
            }
            break;
        default:
            result = {
                variant: 'info',
                status: 'GENERATING'
            }
    }

    return result;
};

const PopupGenDataIdeas = ({ ideas = [], hideModal = () => { } }) => {
    return (
        <div className="popup-gen-data-ideas">
            <FontAwesomeIcon className="close-icon" icon={faClose} onClick={hideModal} />
            <ListGroup as="ol" numbered>
                {ideas.map((idea) => (
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                        variant={getGroupItemStatusInfo(idea.done).variant}>
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Idea {idea.customID}</div>
                            Status: {getGroupItemStatusInfo(idea.done).status}
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    )
}

export default PopupGenDataIdeas;