import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import { addIdeaSkill, addIdeaPageTheme } from '../../auto-content/autoContentSlice';
import { toast } from 'react-toastify';
import { updateDataAPI } from '../../../utils/query';
import Constant from '../../../Constant';

const IdeaPageSettingOptionModal = ({
    ideaPageID,
    inputOptionName,
    handleSelectOption,
    handleCloseModal,
    type = Constant.TYPE_IDEA_PAGE_SELECT_OPTION.SKILL_TYPE
}) => {
    const initialValue = {
        name: '',
        idea: ideaPageID,
        details: '',
        children: []
    };
    const dispatch = useDispatch();

    const [pageOptionSetting, setPageOptionSetting] = useState(initialValue);

    useEffect(() => {
        setPageOptionSetting((prev) => ({
            ...prev,
            name: inputOptionName,
        }));
    }, [inputOptionName]);

    const handleEditField = (field, value) => {
        setPageOptionSetting((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleCloseSettingModal = () => {
        setPageOptionSetting(initialValue);
        handleCloseModal();
    };

    const addNewOptionSetting = async (newSetting) => {
        let url = '';
        let successMessage = '';

        if (type === Constant.TYPE_IDEA_PAGE_SELECT_OPTION.SKILL_TYPE) {
            url = 'resources/idea-skills/';
            successMessage = 'Created a new skill successfully.';
        } else if (type === Constant.TYPE_IDEA_PAGE_SELECT_OPTION.THEME_TYPE) {
            url = 'resources/idea-page-themes/';
            successMessage = 'Created a new theme successfully.';
        }

        try {
            const res = await updateDataAPI('POST', url, newSetting);
            toast.success(successMessage);

            if (type === Constant.TYPE_IDEA_PAGE_SELECT_OPTION.SKILL_TYPE) {
                dispatch(addIdeaSkill(res.data));
            } else if (type === Constant.TYPE_IDEA_PAGE_SELECT_OPTION.THEME_TYPE) {
                dispatch(addIdeaPageTheme(res.data));
            }

            handleSelectOption({
                ...res.data,
                id: res.data['id'],
                label: type === Constant.TYPE_IDEA_PAGE_SELECT_OPTION.THEME_TYPE ? res.data['name'] + (res.data?.details ? '(' + res.data.details + ')' : '') : res.data['children'].length > 0 ? (res.data['name'] + (res.data?.details ? '(' + res.data.details + ')' : '')) + ': ' + res.data['childs'].map(skill => skill['name']).join(',') : (res.data['name'] + (res.data?.details ? '(' + res.data.details + ')' : '')),
                value: res.data['id']
            });
        } catch (e) {
            toast.error(`An error occurred ${e.message}`);
        }
    };

    const handleAddNewOptionSetting = async () => {
        if (pageOptionSetting.name !== '') {
            try {
                await addNewOptionSetting(pageOptionSetting);
                handleCloseSettingModal();
            } catch (e) {
                toast.error(`An error occurred ${e.message}`);
            }
        }
    };

    return (
        <Modal show={true} onHide={handleCloseSettingModal}>
            <Modal.Header closeButton>
                <Modal.Title>Setting {type}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Control
                        type="text"
                        className="mb-2"
                        placeholder={`${type} name`}
                        value={pageOptionSetting?.name}
                        onChange={e => handleEditField('name', e.target.value)}
                    />
                    <Form.Control
                        type="text"
                        className="mb-2"
                        placeholder={`${type} details`}
                        value={pageOptionSetting?.details}
                        onChange={e => handleEditField('details', e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseSettingModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleAddNewOptionSetting}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default IdeaPageSettingOptionModal;