import { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { updateDataAPI } from '../../../utils/query';
import { toast } from 'react-toastify';

const UploadLocalTopicImage = ({
    topicIcon,
    topicName,
    handleUploadedTopic,
}) => {
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    }

    const handleUploadTopicImage = async (e) => {
        e.preventDefault();

        if (!selectedFile) return;
        setLoading(true);

        const formData = new FormData();

        formData.append('file', selectedFile);
        formData.append('topicName', topicName);
        formData.append('topicIcon', topicIcon);

        const res = await updateDataAPI('POST', 'resources/upload-topic-image/', formData);

        if (res.status === 200) {
            toast.success('Your file uploaded successfully.');
            fileInputRef.current.value = null;
            setSelectedFile(null);
            handleUploadedTopic(res.data);
        } else {
            toast.error('An error occured when uploading file.');
        }
        setLoading(false);
    }

    return (
        <div className="d-flex gap-1">
            <Form.Control
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept='.jpeg, .jpg, .png'
            />
            {selectedFile && (
                <Button variant="primary" onClick={handleUploadTopicImage} disabled={loading}>
                    Upload Image
                </Button>
            )}
        </div>
    )
}

export default UploadLocalTopicImage;