import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import TemplatesManagement from '../templates-management';
import { useState } from 'react';
import { updateDataAPI } from '../../../utils/query';
import './styles.scss';
import CustomizeTopicsEventsPerDataSet from '../customize-topics-events-per-data-set';

const AddNewAutoIdeaPageModal = ({ idea, newPageIndex, usedTemplateIDs, hideModal }) => {
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nbNewPages, setNbNewPages] = useState(1);
    const [selectedDataSet, setSelectedDataSet] = useState(null);

    const createIdeaPagesBySelectedTemplates = async (pageIndex, themeID) => {
        for (let j = 0; j < selectedTemplates.length; j++) {
            let data = {
                idea: idea.id,
                pageIndex: pageIndex,
                type: 'auto',
            };
            if (themeID) data['theme'] = themeID;

            const resIdeaPage = await updateDataAPI('POST', 'resources/idea-pages/', data);

            await updateDataAPI('POST', 'auto-content/idea-templates/', {
                idea: idea.id,
                ideaPage: resIdeaPage.data['id'],
                template: selectedTemplates[j].id,
                activity: selectedTemplates[j].activity,
                note: selectedTemplates[j].note,
            });
            pageIndex++;
        }
    }

    const insertTemplates = async () => {
        setLoading(true);
        let themeNames = [];
        if (selectedDataSet?.topics && selectedDataSet?.eventDatas) {
            themeNames = [...selectedDataSet.topics.split('\n'), ...selectedDataSet.eventDatas.map((event) => event.name)];
        }
        await updateDataAPI('POST', 'resources/quick-create-idea-pages/', {
            customID: idea.customID,
            rows: [
                {
                    collections: selectedCollections,
                    templates: selectedTemplates,
                    duplication: parseInt(nbNewPages),
                    themes: themeNames,
                    dataSetID: selectedDataSet?.id,
                }
            ]
        });

        hideModal('add');
    }

    return (
        <Modal
            fullscreen={true}
            centered
            show={true}
            onHide={() => hideModal('hide')}
            className="add-new-auto-idea-page-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    New Template
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TemplatesManagement
                    selectedTemplates={selectedTemplates}
                    setSelectedTemplates={setSelectedTemplates}
                    selectedCollections={selectedCollections}
                    setSelectedCollections={setSelectedCollections}
                    usedTemplateIDs={usedTemplateIDs}
                    insertTemplates={insertTemplates}
                    displayMode="insert"
                />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => hideModal('hide')}>Close</Button>
                <Button onClick={insertTemplates}>Insert</Button>
                <Form>
                    <Form.Control
                        value={nbNewPages}
                        type="number"
                        onChange={e => setNbNewPages(e.target.value)}
                    />
                    <Form.Text>pages/collections</Form.Text>
                </Form>
                <div className="data-set-select">
                    <CustomizeTopicsEventsPerDataSet
                        handleChangeDataSetField={(newValue) => setSelectedDataSet(newValue)}
                    />
                </div>
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
}

export default AddNewAutoIdeaPageModal;