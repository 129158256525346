import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { getDataFromAPI } from '../query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';
import { initOptions } from '../commonUtil';
import AsyncCreatableSelect from 'react-select/async-creatable';

const AsyncSelectComponent = ({ searchUrl, value, onChange, loading, setLoading, clearSelection, labelId = 'id', labelField = 'name', defaultData = null, isMulti = false, onClickItem = () => {}, createable = false, onCreateOption = () => {} }) => {
    const loadOptions = useCallback((inputValue, callback) => {
        fetchOptions(inputValue, callback);
    }, []);

    const searchByQuery = async (query) => {
        try {
            const res = await getDataFromAPI(`${searchUrl}${query}`);
            if (res.data['results']) {
                return res.data['results'];
            }
            return res.data;
        } catch(e) {
            console.log(`An error occured when filter templates by query: ${e.message}`);
            return [];
        }
    }

    const fetchOptions = debounce(async (inputString, callback) => {
        setLoading(true);
        let data;
        if (inputString.trim().length === 0 && defaultData) {
            data = defaultData;
        } else {
            data = await searchByQuery(inputString);
        }
        const formattedData = initOptions(data, labelId, labelField);
        callback(formattedData);
        setLoading(false);
    }, 300);

    return (
        <div className="async-select-component">
            {!createable ? (
                <AsyncSelect
                    value={value}
                    onChange={selectedValue => onChange(selectedValue)}
                    isLoading={loading}
                    loadOptions={loadOptions}
                    defaultOptions
                    isMulti={isMulti}
                    formatOptionLabel={item => (<span onClick={() => onClickItem(item)}>{item[labelId]}. {item.label}</span>)}
                />
            ) : (
                <AsyncCreatableSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptions}
                    onChange={selectedValue => onChange(selectedValue)}
                    onCreateOption={onCreateOption}
                />
            )}
            {clearSelection && (
                <FontAwesomeIcon icon={faClose} onClick={clearSelection} className="clear-icon" />
            )}
        </div>
    )
}

export default AsyncSelectComponent;