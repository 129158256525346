import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchKeyword } from '../filtersSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';

const FilterBySearch = ({ placeholder = 'search by title or code' }) => {
    const dispatch = useDispatch();
    const searchKeyword = useSelector((state) => state.filters.searchKeyword);

    useEffect(() => {
        const searchKeywordParam = (new URLSearchParams(window.location.search)).get('searchKeyword');
        if (searchKeywordParam) {
            dispatch(setSearchKeyword(searchKeywordParam));
        }
    }, []);

    return (
        <div className="search-input">
            <Form.Control value={searchKeyword} placeholder={placeholder} onChange={e => dispatch(setSearchKeyword(e.target.value))} />
            <FontAwesomeIcon icon={faCircleXmark} onClick={() => dispatch(setSearchKeyword(''))} />
        </div>
    );
}

export default FilterBySearch;