import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PagePagination from '../../../../auto-content/templates-management/page-pagination';
import '../../styles.scss';
import PartContent from '../../part-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import WorksheetMakerIframeModal from '../../../../auto-content/worksheet-maker-iframe-modal';
import { useSelector } from 'react-redux';
import { fetchExampleFromAIResponseOfTemplate } from '../../../../../utils/autoContentUtils';

const Step3SelectTemplate = ({
    part = {},
    currentPage = 1,
    totalPages = 1,
    templates = [],
    handlePageChange = () => { },
    handleUpdatePartField = () => { },
    fetchTemplates = async () => { },
}) => {
    const { selectedTags } = useSelector(state => state.autoContent);

    const [templateInModal, setTemplateInModal] = useState(null);

    useEffect(() => {
        fetchTemplates(currentPage);
    }, [selectedTags]);

    useEffect(() => {
        const updatePartValuesBasedOnSelectedTemplate = async () => {
            const selectedTemplate = templates.find(template => template.id === part?.template);
            if (selectedTemplate) {
                handleUpdatePartField('note', selectedTemplate['note']);
                handleUpdatePartField('activity', selectedTemplate['activity']);
                const example = await fetchExampleFromAIResponseOfTemplate(selectedTemplate.id);
                handleUpdatePartField('example', example);
            };
        };

        updatePartValuesBasedOnSelectedTemplate();
    }, [part?.template]);

    return (
        <div className="step">
            <div className="step-body">
                <Row>
                    <Col sm={8}>
                        <div className={`step-body__templates `}>
                            {templates.map((template) => (
                                <div
                                    key={template.id}
                                    className={`step-body__templates__item ${template.imageUrl === null ? "step-body__templates__item__img--placeholder" : ""
                                        } ${part?.template === template.id ? "step-body__templates__item--active" : ""}`}
                                >
                                    <img
                                        src={template.imageUrl}
                                        className="step-body__templates__item__img"
                                        alt={`template-image-${template.name}`}
                                        onClick={() => handleUpdatePartField('template', template.id)}
                                    />
                                    <div className="template-info">
                                        <span>{template.name}</span>
                                        <FontAwesomeIcon icon={faEdit} onClick={() => setTemplateInModal(template)} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <PagePagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                        />
                    </Col>
                    <Col sm={4}>
                        <PartContent part={part} handleUpdatePartField={handleUpdatePartField} />
                    </Col>
                </Row>
            </div>

            {templateInModal && (
                <WorksheetMakerIframeModal
                    type="template"
                    id={templateInModal.id}
                    hideModal={() => setTemplateInModal(null)} />
            )}
        </div>
    );
};

export default Step3SelectTemplate;