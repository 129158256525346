import React, { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import moment from 'moment';
import PerformanceTicketEditModal from './performance-ticket-edit-modal';
import Constant from '../../Constant';
import { hasPermission } from '../../utils/auth';
import { useSelector } from 'react-redux';

const ListTickets = ({ tickets = [], setTickets = () => {} }) => {
    const [showEditTicketForm, setShowEditTicketForm] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [userRoles, setUserRoles] = useState([]);

    const { activityPermissions } = useSelector((state) => state.permissions);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const handleCloseEditTicketModal = () => {
        setShowEditTicketForm(false);
        setSelectedTicket(null);
    };

    const handleShowEditTicketModal = (ticket) => {
        setShowEditTicketForm(true);
        setSelectedTicket(ticket);
    };

    const handleSaveChange = (editedTicket) => {
        if (!editedTicket) return;
        
        setTickets((prev) => [...prev].map((ticket) => {
            if (ticket.id === editedTicket.id) return editedTicket;
            return ticket;
        }));
    };

    return (
        <Container>
            <ListGroup as="ol" numbered>
                {tickets.map((ticket) => (
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Ticket: {ticket.id}</div>
                            <ul>
                                <li>Datetime: {moment(ticket.datetimeTicket).format('YYYY-MM-DD')}</li>
                                <li>Design done ideas: {(ticket.designDoneIdeas || []).join(',')}</li>
                                <li>New ideas: {(ticket.createdIdeas || []).join(',')}</li>
                                <li>Check data done ideas: {(ticket.checkDataDoneIdeas || []).join(',')}</li>
                                <li>Outline ideas: {ticket.outlineIdeas}</li>
                                <li>Note: {ticket.note}</li>
                            </ul>
                        </div>
                        {hasPermission(userRoles, activityPermissions['EDIT_PERFORMANCE_TICKET']) && (
                            <Button variant='primary' onClick={() => handleShowEditTicketModal(ticket)}>
                                Edit
                            </Button>
                        )}
                    </ListGroup.Item>
                ))}
            </ListGroup>
            {showEditTicketForm && (
                <PerformanceTicketEditModal
                    selectedTicket={selectedTicket}
                    handleSaveChange={handleSaveChange}
                    hideModal={handleCloseEditTicketModal}
                />
            )}
        </Container>
    )
};

export default ListTickets;