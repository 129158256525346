import ImageWrapper from '../../common/image-wrapper';
import UploadFile from '../../content-studio/upload-file';
import AnswerKeyResult from './answer-key-result';

import './styles.scss';

const ManualContentResultCell = ({ page, pages, setPages, setLoading, customID }) => {

    return (
        <div className="manual-content-result-cell">
            {page.content?.imageUrl && (
                <ImageWrapper src={page.content?.imageUrl.indexOf('http') === -1 ? ('https://idea-images.s3.us-west-2.amazonaws.com/' + page.content?.imageUrl) : page.content?.imageUrl} />
            )}
            <UploadFile
                id={customID + ',' + page.id}
                setLoading={setLoading}
                uploadUrl={'resources/upload-idea-page-image/'}
                allowedFileExtentions={['png', 'jpg', 'jpeg']}
                fieldName={'asset'}
                uploadedFileName={null}
                setUploadedFileName={data => {
                    setPages(pages.map(p => {
                        if (p.id === page.id) {
                            return data;
                        }
                        return p;
                    }));
                }}
            />

            {page.content && (
                <AnswerKeyResult
                    designWorksheet={page.content}
                    updateAnswerKey={answerKey => {
                        setPages(pages.map(p => {
                            if (p.id === page.id) {
                                return {...p, answerKey};
                            }
                            return p;
                        }));
                    }}
                />
            )}
        </div>
    )
}

export default ManualContentResultCell;