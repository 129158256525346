import { useState } from 'react';
import { Button, Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import './styles.scss';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import { useSelector } from 'react-redux';
import GenDataTicketsTable from './gen-data-tickets-table';
import MultiValues from '../../../forms/multi-values';

const KeywordInfoModal = ({keyword = null, keywords, setLoading, updateKeyword, setKeywords, hideModal}) => {
    const stores = useSelector(state => state.filters.stores);

    const [name, setName] = useState(keyword?.name);
    const [grades, setGrades] = useState(keyword?.grades);
    const [parent, setParent] = useState(keyword?.parent);
    const [subKeywords, setSubKeywords] = useState(keyword?.subKeywords);
    const [thumbnailTitle, setThumbnailTitle] = useState(keyword?.thumbnailTitle);
    const [subjectTPTs, setSubjectTPTs] = useState(keyword?.subjectTPTs);
    const [subjectBooms, setSubjectBooms] = useState(keyword?.subjectBooms);
    const [boomBundleID, setBoomBundleID] = useState(keyword?.boomBundleID);
    const [tptBundleID, setTptBundleID] = useState(keyword?.tptBundleID);
    const [boomStores, setBoomStores] = useState(keyword?.boomStores ? keyword.boomStores.join(',') : '');
    const [tptStore, setTPTStore] = useState(keyword?.tptStore);
    const [ggSheetTab, setGgSheetTab] = useState(keyword?.ggSheetTab);

    const save = async() => {
        if (keyword !== null) {
            setLoading(true);
            await updateDataAPI('PATCH', 'resources/keywords/' + keyword.id + '/', {
                name, grades, parent, subKeywords, thumbnailTitle, subjectTPTs, subjectBooms, boomBundleID, tptBundleID, boomStores: boomStores.length > 0 ? boomStores.split(',') : [], tptStore, ggSheetTab,
            });
            const res = await getDataFromAPI('resources/keywords/' + keyword.id + '/?additionalFields=ideas,dataSets');
            await updateKeyword(res.data);
            setLoading(false);
            hideModal();
        } else if (name !== null) {
            setLoading(true);
            const resKeyword = await updateDataAPI('POST', 'resources/keywords/', {
                name, grades, parent, subKeywords, thumbnailTitle, subjectTPTs, subjectBooms, boomBundleID, tptBundleID, boomStores: boomStores.length > 0 ? boomStores.split(',') : [], tptStore, ggSheetTab,
            });
            const res = await getDataFromAPI('resources/keywords/' + resKeyword.data['id'] + '/?additionalFields=ideas,dataSets');
            await updateKeyword(res.data);
            setLoading(false);
            hideModal();
        }
    }

    const deleteKeyword = async() => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            await updateDataAPI('DELETE', 'resources/keywords/' + keyword.id + '/');
            setKeywords(prev => prev.filter(k => k.id !== keyword.id));
            setLoading(false);
            hideModal();
        }
    }

    return (
        <Modal
            show={true}
            onHide={hideModal}
            contentLabel="Keyword Info Modal"
            className="keyword-info-modal"
            fullscreen={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {keyword !== null ? keyword.name : 'New Keyword'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs id="keyword-tabs">
                    <Tab eventKey="info" title="Info">
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control value={name} onChange={e => setName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Grades</Form.Label>
                                        <Form.Control value={grades} onChange={e => setGrades(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Category</Form.Label>
                                        <Form.Select value={parent} onChange={e => setParent(e.target.value)}>
                                            <option></option>
                                            {keywords.map(keywords => (
                                                <option key={keywords.id} value={keywords.id}>{keywords.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Google Sheet Tab</Form.Label>
                                        <Form.Control value={ggSheetTab} onChange={e => setGgSheetTab(e.target.value)} />
                                    </Form.Group>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Sub Keywords</Form.Label>
                                        <Form.Control value={subKeywords} onChange={e => setSubKeywords(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Thumbnail Title</Form.Label>
                                        <Form.Control value={thumbnailTitle} onChange={e => setThumbnailTitle(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Subject TPTs</Form.Label>
                                        <Form.Control value={subjectTPTs} onChange={e => setSubjectTPTs(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Subject Booms</Form.Label>
                                        <Form.Control value={subjectBooms} onChange={e => setSubjectBooms(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Boom Bundle ID</Form.Label>
                                        <Form.Control value={boomBundleID} onChange={e => setBoomBundleID(e.target.value)} type="number" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>TPT Bundle ID</Form.Label>
                                        <Form.Control value={tptBundleID} onChange={e => setTptBundleID(e.target.value)} type="number" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Boom Store</Form.Label>
                                        <MultiValues
                                            value={boomStores}
                                            setValue={val => setBoomStores(val)}
                                            choicesDataAPI="stores/?active=true&marketplace=boom"
                                            labelField="name"
                                            valueField="id" />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>TPT Store</Form.Label>
                                        <Form.Select value={tptStore} onChange={e => setTPTStore(e.target.value)}>
                                            <option></option>
                                            {stores.filter(store => store.marketplace === 'tpt').map(store => (
                                                <option key={store.id} value={store.id}>{store.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                    <Tab eventKey="genDataTickets" title="Gen Data Tickets">
                        {keyword && (
                            <GenDataTicketsTable keyword={keyword} />
                        )}
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                {keyword !== null && (
                    <Button variant="danger" onClick={deleteKeyword}>Delete</Button>
                )}
                <Button onClick={save}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default KeywordInfoModal;