import { useEffect, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import './styles.scss';
import { updateDataAPI } from '../../../utils/query';
import TaskCardTable from '../taskcard-table';
import Constant from '../../../Constant';

const TaskCardAnswerSheet = ({ customID, selectedPartID, result }) => {
    const [nbAnswersPerPage, setNbAnswersPerPage] = useState(null);
    const [correctAnswerColumn1, setCorrectAnswerColumn1] = useState(null);
    const [correctAnswerColumn2, setCorrectAnswerColumn2] = useState(null);
    const [answerSheet, setAnswerSheet] = useState(null);

    useEffect(() => {
        setNbAnswersPerPage(null);
        setCorrectAnswerColumn1(null);
        setCorrectAnswerColumn2(null);
        setAnswerSheet(null);
    }, [selectedPartID]);

    const refreshAnswerSheet = async () => {
        const correctAnswerColumns = [correctAnswerColumn1];
        if (correctAnswerColumn2) {
            correctAnswerColumns.push(correctAnswerColumn2);
        }
        const res = await updateDataAPI('POST', 'resources/gen-taskcard-answer-sheet/?tarkCardPart=' + selectedPartID + '&nbAnswersPerPage=' + nbAnswersPerPage + '&correctAnswerColumns=' + correctAnswerColumns.join(';'));
        setAnswerSheet(res.data.split('\n').map(row => row.split(';')));
    }

    const downloadCSV = async () => {
        let url = Constant.API_URL + 'resources/download-task-card-csv/?tarkCardPart=' + selectedPartID + '&field=answerSheetCSV';
        fetch(url, {
            method: 'POST',
            headers: {
                Authorization: 'Token ' + localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN),
                'Content-Type': 'multipart/form-data',
            }
        })
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', customID + '_answersheet.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                return Promise.reject({ Error: 'Something Went Wrong', err });
            })
    }

    return (
        <div className="taskcard-answer-sheet">
            <Form>
                <Form.Group className="mb-3" controlId="nbAnswersPerPage">
                    <Form.Label>Number Answers Per Page</Form.Label>
                    <Form.Control type="number" value={nbAnswersPerPage} onChange={e => setNbAnswersPerPage(e.target.value)} />
                    {((correctAnswerColumn2 === null && nbAnswersPerPage > 7) || (correctAnswerColumn2 !== null && nbAnswersPerPage > 5)) && (
                        <Alert variant="danger">Quá số cột quy định (tối đa {correctAnswerColumn2 === null ? 7 : 5} câu trả lời trên một page )</Alert>
                    )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="correctAnswerColumn">
                    <Form.Label>Correct Answer 1</Form.Label>
                    <Form.Select onChange={e => {
                        if (e.target.value !== '') {
                            setCorrectAnswerColumn1(e.target.value);
                        } else {
                            setCorrectAnswerColumn1(null);
                        }
                    }}
                        value={correctAnswerColumn1}>
                        <option value={null}></option>
                        {result[0].map(col => (
                            <option value={col} key={col}>{col}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="correctAnswerColumn">
                    <Form.Label>Correct Answer 2</Form.Label>
                    <Form.Select onChange={e => {
                        if (e.target.value !== '') {
                            setCorrectAnswerColumn2(e.target.value);
                        } else {
                            setCorrectAnswerColumn2(null);
                        }
                    }}
                        value={correctAnswerColumn2}>
                        <option value={null}></option>
                        {result[0].map(col => (
                            <option value={col} key={col}>{col}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Button onClick={refreshAnswerSheet} disabled={nbAnswersPerPage === null || correctAnswerColumn1 === null}>Refresh</Button>
                {answerSheet && answerSheet.length > 1 && (
                    <Button size="sm" onClick={downloadCSV} variant="success">Download CSV</Button>
                )}
            </Form>
            {answerSheet && (
                <TaskCardTable selectedPartID={selectedPartID} data={answerSheet} setData={setAnswerSheet} />
            )}
        </div>
    )
}

export default TaskCardAnswerSheet;