import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './styles.scss';
import { useState } from 'react';
import WorksheetMakerIframeModal from '../../../auto-content/worksheet-maker-iframe-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import Constant from '../../../../Constant';

const PartContent = ({
    part = {},
    handleUpdatePartField = () => { },
    showTemplatePreview = false,
    socket = null,
}) => {
    const [templateInModal, setTemplateInModal] = useState(null);

    const generateTestData = async() => {
        if (socket) {
            console.log(part);
            socket.send(JSON.stringify({
                text: 'auto generate content',
                command: 'auto_generate',
                ideaPartID: part.id,
                sender: 'client',
                staffID: localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID),
                formatData: true,
            }));
        }
    }

    return (
        <div className="part-content">
            <Row>
                <Col xs={showTemplatePreview ? 5 : 12}>
                    <Form>
                        <Form.Group className="mb-3" controlId="partContentForm.nbPages">
                            <Form.Label>Number of pages:</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="nbPages"
                                defaultValue={1}
                                min={1}
                                value={part?.nbPages || 1}
                                onChange={(e) => handleUpdatePartField('nbPages', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="partContentForm.note">
                            <Form.Label>Note:</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder="Fill note for the part"
                                value={part?.note || ''}
                                onChange={(e) => handleUpdatePartField('note', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="partContentForm.activity">
                            <Form.Label>Activity:</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder="Fill activity for the part"
                                value={part?.activity || ''}
                                onChange={(e) => handleUpdatePartField('activity', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="partContentForm.example">
                            <Form.Label>Example:</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder="Fill example for the part"
                                value={part?.example || ''}
                                onChange={(e) => handleUpdatePartField('example', e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Col>
                {showTemplatePreview && (
                    <Col xs={3} className="d-flex flex-column align-items-center">
                        <img
                            src={part?.templateData.imageUrl}
                            alt={`part-template-image-${part.templateData.name}`}
                        />
                        <h4>{part?.templateData.name} <FontAwesomeIcon icon={faEdit} onClick={() => setTemplateInModal(part?.templateData)} /></h4>
                    </Col>
                )}
                {showTemplatePreview && (
                    <Col xs={4}>
                        <h4>TEST DATA</h4>
                        {socket ? (
                            <Button onClick={generateTestData}>Genearte Test</Button>
                        ) : (
                            <div>Connecting to server...</div>
                        )}
                    </Col>
                )}
            </Row>

            {templateInModal && (
                <WorksheetMakerIframeModal
                    type="template"
                    id={templateInModal.id}
                    hideModal={() => setTemplateInModal(null)} />
            )}
        </div>
    );
};

export default PartContent;