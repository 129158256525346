import Table from 'react-bootstrap/Table';
import TagsTreeFilter from '../../../../auto-content/tags-tree-filter';
import { useDispatch, useSelector } from 'react-redux';
import { removeTagFromSelectedTags } from '../../../../auto-content/autoContentSlice';
import TagsList from '../../../../auto-content/templates-management/tags-list';

const Step2SelectTag = ({ categories = [], fetchAllCategories = () => { } }) => {
    const dispatch = useDispatch();

    const { selectedTags } = useSelector(state => state.autoContent);

    const handleRemoveTag = (tagID) => {
        dispatch(removeTagFromSelectedTags({ id: tagID }));
    };

    return (
        <div className="step">
            <TagsList
                tagsList={selectedTags}
                handleRemoveTagFromList={(tagID) => handleRemoveTag(tagID)}
                showCategoryName={false}
            />
            <div className="step-body">
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            {categories.map((category) => (
                                <th>{category.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {categories.map((category) => (
                                <td key={category.id}>
                                    <TagsTreeFilter
                                        disabledFilter={false}
                                        category={category}
                                        tags={category.tags}
                                        categories={categories}
                                        parentId={null}
                                        fetchAllCategories={fetchAllCategories}
                                    />
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default Step2SelectTag;