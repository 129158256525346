import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import FilterByDateRange from '../../filters/datepicker';
import moment from 'moment';
import { Container, Modal } from 'react-bootstrap';

import './styles.scss';
import SearchIdeaInput from '../../filters/search-idea-input';

const BotStatusDashboardPage = () => {
    const [bots, setBots] = useState([]);
    const [botActivities, setBotActivities] = useState([]);
    const startDate = useSelector((state) => state.filters.startDate);
    const endDate = useSelector((state) => state.filters.endDate);
    const [edittingIdeaBot, setEdittingIdeaBot] = useState(null);

    const fetchBotData = async () => {
        try {
            const botData = await getDataFromAPI('product-dev/coze-bots/');
            setBots(botData.data);
        } catch (error) {
            console.error('Error fetching bot data: ', error);
        }
    }

    const fetchBotActivities = async (botID) => {
        if (botID && startDate && endDate) {
            const botActivityData = await getDataFromAPI(`product-dev/coze-bot-activities/?cozeBotID=${botID}&startDate=${startDate}&endDate=${endDate}`);
            setBotActivities(prevActivities => {
                if (!Array.isArray(prevActivities)) {
                    return [...botActivityData.data];
                }
                return [...botActivityData.data, ...prevActivities];
            });
        }
    }

    useEffect(() => {
        fetchBotData();
    }, []);

    useEffect(() => {
        const fetchBotsActivitiesInterval = setInterval(() => {
            fetchBotData();
            bots.forEach(bot => {
                fetchBotActivities(bot.id);
            });
        }, 90000);
        return () => clearInterval(fetchBotsActivitiesInterval);
    }, [bots]);

    useEffect(() => {
        bots.forEach(bot => {
            fetchBotActivities(bot.id);
        });
    }, [startDate, endDate]);

    const filterActivitiesByDate = (activities, botId) => {
        return activities.filter(activity => {
            const activityDate = new Date(activity.created);
            return activity.cozeBot === botId &&
                activityDate >= moment(startDate).startOf('day') &&
                activityDate <= moment(endDate).endOf('day');
        }).sort((a, b) => new Date(b.created) - new Date(a.created));
    }

    const groupActivitiesByDate = (activities) => {
        return Array.from(new Set(activities.map(activity => new Date(activity.created).toLocaleDateString())));
    }

    const groupActivitiesByHour = (activitiesOnDate) => {
        return Array(24).fill(null).map((hour, index) => {
            const activitiesInHour = activitiesOnDate.filter(activity => new Date(activity.created).getHours() === index);
            const uniqueActivities = Array.from(new Set(activitiesInHour.map(act => `${act.created.slice(0, 16) + (act.success ? ' success' : ' fail')}`)));
            const successCount = uniqueActivities.filter(activity => activity.includes('success')).length;
            const failCount = uniqueActivities.length - successCount;
            return { hour: index, successCount, failCount };
        });
    }

    const updateBotGeneratingIdea = async(edittingIdeaBot, idea) => {
        const resTicket = await getDataFromAPI('product-dev/gen-data-tickets/?customID=' + idea.customID);
        if (resTicket.data.length > 0) {
            await updateDataAPI('PATCH', 'product-dev/coze-bots/' + edittingIdeaBot + '/', { assignedTicket: resTicket.data[0]['id'] });
        }
        setEdittingIdeaBot(null);
        fetchBotData();
    }

    return (
        <Container className="bot-status-dashboard-page">
            <div className="bot-status-header">
                <FilterByDateRange defaultDateRange={{ startDate: moment().toDate(), endDate: moment().toDate() }} />
            </div>
            {bots.length > 0 && (
                <div className="bots">
                    {bots.map(bot => (
                        <div className="bot-box-info" key={bot.id}>
                            <h2 className={bot.nbActivitiesToday >= bot.dailyActivitiesQuota ? 'text-danger' : 'text-success'}>Bot {bot.id}</h2>
                            <div>Last 24h activities: {bot.nbActivitiesToday} / {bot.dailyActivitiesQuota}</div>
                            {bot.assignedCustomID && (
                                <div className="generating-idea" onClick={() => setEdittingIdeaBot(bot.id)}>
                                    <span>Generating Idea: {bot.assignedCustomID}</span>
                                    {bot.genDataTicketData && (
                                        <span> ({bot.genDataTicketData['nbFinishedParts']} / {bot.genDataTicketData['totalParts']} parts)</span>
                                    )}
                                </div>
                            )}
                            {groupActivitiesByDate(filterActivitiesByDate(botActivities, bot.id)).map((dateStr, dateIndex) => (
                                <div className="bot-hours-container mt-3" key={`date_${dateIndex}`}>
                                    {groupActivitiesByHour(filterActivitiesByDate(botActivities, bot.id).filter(activity => new Date(activity.created).toLocaleDateString() === dateStr)).map((hourData, index) => (
                                        <div className={`bot-hour-block ${hourData.successCount > 0 || hourData.failCount > 0 ? '' : 'default'}`} key={`bot_hour_${index}`}>
                                            <div className="bot-hour-content">
                                                <div className="bot-hour-statuses">
                                                    {hourData.successCount > 0 && (
                                                        <div className="bot-hour bot-hour-success">
                                                            <span className="success-count">{hourData.successCount}</span>
                                                        </div>
                                                    )}
                                                    {hourData.failCount > 0 && (
                                                        <div className="bot-hour bot-hour-fail">
                                                            <span className="fail-count">{hourData.failCount}</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <span className="hour-label">{hourData.hour}h</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}

            {edittingIdeaBot !== null && (
                <Modal show={true} onHide={() => setEdittingIdeaBot(null)} className="set-editting-idea-bot-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Change Generating Idea
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SearchIdeaInput selectedIdea={(idea) => updateBotGeneratingIdea(edittingIdeaBot, idea)} />
                    </Modal.Body>
                </Modal>
            )}
        </Container>
    );
};

export default BotStatusDashboardPage;
