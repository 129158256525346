import { Form } from 'react-bootstrap';
import Constant from '../../Constant';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedGrade, removeSelectedGrade } from '../filtersSlice';
import './styles.scss';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const FilterByGrades = () => {
    const dispatch = useDispatch();
    const selectedGrades = useSelector((state) => state.filters.selectedGrades);

    const changeGrades = (grade, isSelected) => {
        if (isSelected && selectedGrades.indexOf(grade) === -1) {
            dispatch(addSelectedGrade(grade));
        } else if (!isSelected && selectedGrades.indexOf(grade) > -1) {
            dispatch(removeSelectedGrade(grade));
        }
    }

    const TableColumnRowVilibilities = (
        <Popover>
            <Popover.Header as="h3">Grades</Popover.Header>
            <Popover.Body className="popover-grades">
                <Form>
                    {Constant.GRADES.map(grade => (
                        <Form.Check key={grade} type="checkbox" id={'grade-' + grade} label={isNaN(grade) ? grade : 'Grade ' + grade}
                            checked={selectedGrades.indexOf(grade) > -1}
                            onChange={e => changeGrades(grade, e.target.checked)} />
                    ))}
                </Form>
            </Popover.Body>
        </Popover>
    );

    return (
        <div className="filter-by-grades">
            <OverlayTrigger trigger="click" placement="right" overlay={TableColumnRowVilibilities} rootClose>
                <div>
                    {selectedGrades.length === 0 ? 'All Grades' : selectedGrades.map(grade => isNaN(grade) ? grade : 'Grade ' + grade).join(', ')}
                </div>
            </OverlayTrigger>
            {/* <Form.Select onChange={e => {
                if (e.target.value === 'all') {
                    dispatch(setSelectedCategory(undefined));
                } else {
                    dispatch(setSelectedCategory(e.target.value));
                }
            }} value={selectedCategory}>
                <option value="all">All</option>
                {Constant.CATEGORIES.map(cat => (
                    <option value={cat} key={cat}>{cat}</option>
                ))}
            </Form.Select> */}
        </div>
    );
}

export default FilterByGrades;