import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import './styles.scss';
import { updateDataAPI } from '../../../utils/query';

const ReuploadFilesModal = ({ selectedProductIDs, closeModal, setLoading }) => {
    const [freeFile, setFreeFile] = useState(false);
    const [paidFile, setPaidFile] = useState(false);
    const [previewFile, setPreviewFile] = useState(false);
    const [thumbnail, setThumbnail] = useState(false);
    const [thumbnailFree, setThumbnailFree] = useState(false);
    const [description, setDescription] = useState(false);
    const [descriptionFree, setDescriptionFree] = useState(false);
    const [title, setTitle] = useState(false);
    const [titleFree, setTitleFree] = useState(false);
    const [price, setPrice] = useState(false);

    const reupload = async () => {
        const toReuploadFields = [];
        let buildRequired = false;
        if (freeFile) {
            toReuploadFields.push('freeFile');
            buildRequired = true;
        }
        if (paidFile) {
            toReuploadFields.push('paidFile');
            buildRequired = true;
        }
        if (thumbnail) {
            toReuploadFields.push('thumbnail');
        }
        if (thumbnailFree) {
            toReuploadFields.push('thumbnailFree');
        }
        if (description) {
            toReuploadFields.push('description');
        }
        if (descriptionFree) {
            toReuploadFields.push('descriptionFree');
        }
        if (title) {
            toReuploadFields.push('title');
        }
        if (titleFree) {
            toReuploadFields.push('titleFree');
        }
        if (price) {
            toReuploadFields.push('price');
        }
        if (window.confirm('Are you sure to re-upload ' + selectedProductIDs.length + ' products?')) {
            setLoading(true);
            for (let i = 0; i < selectedProductIDs.length; i++) {
                try {
                    await updateDataAPI('POST', 'product-dev/add-fields-to-update-info-ticket/?productIdea=' + selectedProductIDs[i], {
                        fields: toReuploadFields,
                        buildRequired,
                    });
                } catch (err) {
                    console.log(err);
                }
            }
            setLoading(false);
            closeModal('hide');
        }
    }

    return (
        <Modal
            centered size="lg"
            show={true}
            onHide={() => closeModal('hide')}
            className="reupload-files-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Re-upload files
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <h2>File</h2>
                            <Form.Check type="checkbox" label="Free File" checked={freeFile} id="reupload-free-file"
                                onChange={() => setFreeFile(!freeFile)} />
                            <Form.Check type="checkbox" label="Paid File" checked={paidFile} id="reupload-paid-file"
                                onChange={() => setPaidFile(!paidFile)} />
                            <Form.Check type="checkbox" label="Preview File" checked={previewFile} id="reupload-preview-file"
                                onChange={() => setPreviewFile(!previewFile)} />
                        </Col>
                        <Col>
                            <h2>Thumbnail</h2>
                            <Form.Check type="checkbox" label="Thumbnail" checked={thumbnail} id="reupload-thumbnail"
                                onChange={e => setThumbnail(!thumbnail)} />
                            <Form.Check type="checkbox" label="Thumbnail Free" checked={thumbnailFree} id="reupload-thumbnail-free"
                                onChange={e => setThumbnailFree(!thumbnailFree)} />
                        </Col>
                        <Col>
                            <h2>Description</h2>
                            <Form.Check type="checkbox" label="Description" checked={description} id="reupload-description"
                                onChange={e => setDescription(!description)} />
                            <Form.Check type="checkbox" label="Description Free" checked={descriptionFree} id="reupload-description-free"
                                onChange={e => setDescriptionFree(!descriptionFree)} />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            <h2>Title</h2>
                            <Form.Check type="checkbox" label="Title" checked={title} id="reupload-title"
                                onChange={e => setTitle(!title)} />
                            <Form.Check type="checkbox" label="Title Free" checked={titleFree} id="reupload-title-free"
                                onChange={e => setTitleFree(!titleFree)} />
                        </Col>
                        <Col>
                            <h2>Other</h2>
                            <Form.Check type="checkbox" label="Price" checked={price} id="reupload-price"
                                onChange={e => setPrice(!price)} />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={reupload}>Re-Upload</Button>
                <Button onClick={() => closeModal('hide')} variant="secondary">Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ReuploadFilesModal;