import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import SearchBar from '../search-bar';
import { updateDataAPI } from '../../../utils/query';
import Constant from '../../../Constant';
import { checkObjectHasNullebaleValue } from '../../../utils/commonUtil';

import './styles.scss';

const IconsStorageUploadTab = ({
    selectedIcon,
    handleSelectedIcon = () => { },
}) => {
    const [query, setQuery] = useState('');
    const [icons, setIcons] = useState([]);

    const fetchIcons = async (e) => {
        e.preventDefault();

        if (!checkObjectHasNullebaleValue({ query })) return;

        try {
            let url = 'auto-content/search-resources-by-keyword/';

            const data = {
                "typeResource": [Constant.RESOURCE_ELEMENT],
                "offset": 1,
                "limit": Constant.LIMIT_RESOURCE,
                "keyword": query
            };

            const res = await updateDataAPI('POST', url, data);
            setIcons(res.data);
        } catch (err) {
            console.error(`An error occured when fetching icons: ${err.message}`);
        }
    }

    return (
        <Container fluid className="icons-storage-upload-tab">
            <SearchBar
                query={query}
                handleChangeQuery={(e) => setQuery(e.target.value.toLowerCase())}
                handleSearchByQuery={fetchIcons}
            />
            <i>{icons.length} result</i>
            <div className="icons-storage">
                {icons.map(icon => (
                    <div
                        key={icon.id}
                        className="icon"
                        style={{
                            border: selectedIcon?.link === icon?.url ? '1px solid red' : ''
                        }}
                        onClick={() => handleSelectedIcon({ link: icon.url, title: 'Resource icon ' + icon.id, source: 'iconsStorage' })}
                    >
                        {icon.url && (
                            <Image src={icon.url} width={200} thumbnail alt={`Icon with id ${icon.id}`} />
                        )}
                    </div>
                ))}
            </div>
        </Container>
    )
};

export default IconsStorageUploadTab;