import { Button, Container, Form, Spinner } from 'react-bootstrap';
import UploadFile from '../../components/content-studio/upload-file';
import { useState } from 'react';
import ImageWrapper from '../../components/common/image-wrapper';
import Constant from '../../Constant';
import AsyncSelectComponent from '../../utils/async-select';

import './styles.scss';
import { updateDataAPI } from '../../utils/query';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const ApplyImageToDataSetPage = () => {
    const [uploadingImage, setUploadingImage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imgNames, setImgNames] = useState(null);
    const [selectedDataSet, setSelectedDataSet] = useState(null);
    const [jsonResult, setJSONResult] = useState(null);
    const [noteToPrompt, setNoteToPrompt] = useState('');

    const convert = async() => {
        if (imgNames === null || selectedDataSet === null) {
            window.alert('image or dataset not valid');
            return;
        }

        setLoading(true);
        setJSONResult(null);

        try {
            const res = await updateDataAPI('POST', 'resources/convert-images-to-json/', {
                dataSetID: selectedDataSet.id,
                imgNames,
                noteToPrompt,
            });
            setJSONResult(res.data['result']);
        } catch (err) {
            window.alert('Error', err);
        }
        setLoading(false);
    }

    const applyResultToDataSet = async() => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'resources/insert-json-data-to-dataset/', {
            data: jsonResult,
            dataSetID: selectedDataSet.id,
        });

        setSelectedDataSet({...selectedDataSet, topics: res.data});
        toast.success('New data applied to dataset');
        setJSONResult(null);
        setLoading(false);
    }

    return (
        <Container className="apply-image-to-dataset">
            <Form>
                <div>
                    <UploadFile
                        id="temp"
                        setLoading={setUploadingImage}
                        uploadUrl={'resources/upload-temp-images-to-convert-to-dataset/'}
                        allowedFileExtentions={['png', 'jpg', 'jpeg']}
                        fieldName={'temp'}
                        uploadedFileName={null}
                        setUploadedFileName={data => {
                            setImgNames(data['imgNames']);
                        }}
                        multiple={true}
                    />
                    {uploadingImage && (
                        <div>Uploading...</div>
                    )}

                    {imgNames && (
                        <div className="images">
                            {imgNames.map((imgName, index) => (
                                <div key={index}>
                                    <ImageWrapper src={Constant.API_URL + 'images/temp/' + imgName} />
                                    <FontAwesomeIcon icon={faClose} onClick={() => setImgNames(imgNames.filter(name => name !== imgName))} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div>
                    <div>Select DataSet</div>
                    <AsyncSelectComponent
                        searchUrl='resources/data-sets/?searchKeyword='
                        onChange={selectedValue => setSelectedDataSet(selectedValue)}
                        value={{ value: selectedDataSet?.id, label: selectedDataSet?.id + '. ' + selectedDataSet?.name }}
                        setLoading={() => {}}
                        loading={false}
                        clearSelection={() => {}}
                        defaultData={[]}
                    />
                    {selectedDataSet && selectedDataSet.fieldsFormat && (
                        <div>
                            <pre>
                                {JSON.stringify(selectedDataSet.fieldsFormat, null, 4)}
                            </pre>
                        </div>
                    )}
                    {imgNames !== null && selectedDataSet !== null && (
                        <div>
                            <Form.Group>
                                <Form.Label>Note</Form.Label>
                                <Form.Control as="textarea" rows={5} value={noteToPrompt} onChange={e => setNoteToPrompt(e.target.value)} />
                            </Form.Group>
                            <Button onClick={convert}>Convert</Button>
                            {jsonResult && (
                                <div>
                                    <pre>
                                        {JSON.stringify(jsonResult, null, 4)}
                                    </pre>
                                    <Button onClick={applyResultToDataSet}>Apply to DataSet</Button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Form>


            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default ApplyImageToDataSetPage;