import { Button, Modal, Spinner } from 'react-bootstrap';
import TemplatesManagement from '../auto-content/templates-management';
import { useState } from 'react';

const TemplateSearchModal = ({
    usedTemplateIDs,
    isMultiTemplateSelection = true,
    isMultiCollectionSelection = true,
    hideModal = () => {},
    handleInsertTemplates = async () => {},
    handleInsertCollections = async () => {},
    showTemplatesTab = true,
    showCollectionsTab = true,
}) => {
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [loading, setLoading] = useState(false);

    const insertTemplates = async () => {
        setLoading(true);
        if (showTemplatesTab) await handleInsertTemplates(selectedTemplates);
        if (showCollectionsTab) await handleInsertCollections(selectedCollections);
        setSelectedTemplates([]);
        setSelectedCollections([]);
        setLoading(false);
        hideModal();
    }

    const handleSelectedTemplates = (selectedValues = []) => {
        setSelectedTemplates(isMultiTemplateSelection ? selectedValues : (selectedValues.splice(-1)));
    }

    const handleSelectedCollections = (selectedValues = []) => {
        setSelectedCollections(isMultiCollectionSelection ? selectedValues : (selectedValues.splice(-1)));
    }

    return (
        <Modal
            fullscreen={true}
            centered
            show={true}
            onHide={() => hideModal('hide')}
            className="add-new-auto-idea-page-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    New Template
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TemplatesManagement
                    selectedTemplates={selectedTemplates}
                    setSelectedTemplates={handleSelectedTemplates}
                    selectedCollections={selectedCollections}
                    setSelectedCollections={handleSelectedCollections}
                    usedTemplateIDs={usedTemplateIDs}
                    showCollectionsTab={showCollectionsTab}
                    showTemplatesTab={showTemplatesTab}
                    displayMode="insert"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={insertTemplates}>
                    Save
                </Button>
            </Modal.Footer>
            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
}

export default TemplateSearchModal;