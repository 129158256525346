import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import IdeaPageSettingOptionModal from '../idea-page-setting-option-modal';
import Constant from '../../../Constant';
import { setIdeaPageThemes } from '../../auto-content/autoContentSlice';
import UploadNewTopicImage from '../../dataset/upload-new-topic-image';
import Button from 'react-bootstrap/Button';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const generateIdeaSkillOptions = (ideaSkills = []) => {
    return ideaSkills.map((skill) => ({
        ...skill,
        label:
            skill['children'].length > 0
                ? `${skill['name']}${skill?.details ? '(' + skill.details + ')' : ''
                }: ${skill['childs'].map((skill) => skill['name']).join(',')}`
                : `${skill['name']}${skill?.details ? '(' + skill.details + ')' : ''
                }`,
        value: skill['id'],
    }));
};

export const generateIdeaThemeOptions = (ideaPageThemes = []) => {
    return ideaPageThemes.map((theme) => ({
        ...theme,
        label: `${theme['name']}${theme?.details ? '(' + theme.details + ')' : ''
            }`,
        value: theme['id'],
    }));
};

export const generateTrainingTextOptions = (trainingTexts = []) => {
    return trainingTexts.map((text) => ({
        ...text,
        label: text['name'],
        value: text['id'],
    }));
};

const IdeaPageSelectOption = ({
    page,
    updateOption,
    targetPages = [],
    mode = 'single',
    pages = [],
    setLoading = () => { },
    type = Constant.TYPE_IDEA_PAGE_SELECT_OPTION.SKILL_TYPE,
}) => {
    const dispatch = useDispatch();

    const [selectedOption, setSelectedOption] = useState('');
    const [options, setOptions] = useState([]);
    const [showSettingModal, setShowSettingModal] = useState(false);
    const [inputNewOptionName, setInputNewOptionName] = useState('');

    const { ideaSkills, ideaPageThemes, trainingTexts } = useSelector(
        (state) => state.autoContent
    );

    useEffect(() => {
        const initOptions = () => {
            let optionsData = [];

            if (type === Constant.TYPE_IDEA_PAGE_SELECT_OPTION.SKILL_TYPE) {
                optionsData = generateIdeaSkillOptions(ideaSkills);
            } else if (type === Constant.TYPE_IDEA_PAGE_SELECT_OPTION.THEME_TYPE) {
                optionsData = generateIdeaThemeOptions(ideaPageThemes);
            } else if (type === Constant.TYPE_IDEA_PAGE_SELECT_OPTION.TRAINING_TEXT_TYPE) {
                optionsData = generateTrainingTextOptions(trainingTexts);
            }

            setOptions(optionsData);

            if (mode === 'single') {
                const defaultValue = optionsData.filter(
                    (option) => option['id'] === page[type]
                );
                if (defaultValue.length > 0) {
                    setSelectedOption(defaultValue[0]);
                } else {
                    setSelectedOption(null);
                }
            }
        };

        initOptions();
    }, [ideaSkills, ideaPageThemes, trainingTexts, pages, type]);

    const handleSelectOptionForOnePage = async (targetPage = page, value) => {
        const selectedOptionValue = value?.id ?? null;
        await updateDataAPI('PATCH', `resources/idea-pages/${targetPage.id}/`, {
            [type]: selectedOptionValue,
        });
        if (mode === 'single') {
            setSelectedOption(value);
        }

        updateOption(value, targetPages);
    };

    const searchForCorrespondingIconIdeaPageTheme = async (ideaPageThemeID) => {
        if (!ideaPageThemeID) return;
        try {
            const url = 'resources/search-for-corresponding-icon-idea-page-theme-name/';

            const res = await updateDataAPI('POST', url , { ideaPageThemeID });

            if (res?.data) {
                dispatch(setIdeaPageThemes([...ideaPageThemes].map((theme) => {
                    if (theme.id === res.data?.id) {
                        return res.data
                    }
                    return theme;
                })));
                setSelectedOption()
            }
        } catch(err) {
            console.error(`An error occured when finding corresponding icon: ${err.message}`);
        }
    }

    const handleSelectOption = async (value) => {
        setLoading(true);
        if (type === Constant.TYPE_IDEA_PAGE_SELECT_OPTION.THEME_TYPE && !selectedOption?.icon && value?.id) {
            await searchForCorrespondingIconIdeaPageTheme(value?.id);
        }
        if (mode !== 'single') {
            const confirmed = window.confirm(
                `Are you sure want to set this ${type} to all selected pages?`
            );
            if (confirmed) {
                targetPages.forEach(async (targetPageID) =>
                    await handleSelectOptionForOnePage({ id: targetPageID }, value)
                );
            }
        } else {
            await handleSelectOptionForOnePage(page, value);
        }
        setLoading(false);
    };

    const handleCreateNewOption = (inputValue) => {
        if (mode === 'single') {
            setInputNewOptionName(inputValue);
            setShowSettingModal(true);
        }
    };

    const handleCloseModal = () => {
        setShowSettingModal(false);
        setInputNewOptionName('');
    };

    const refreshTopic = async () => {
        if (selectedOption?.id) {
            const url = `resources/idea-page-themes/${selectedOption.id}/`;
            const res = await getDataFromAPI(url);
            setOptions((prev) => prev.map((option) => {
                if (option.id === selectedOption?.id) {
                    return {
                        ...option,
                        ...res.data,
                    };
                }
                return option;
            }));
            setSelectedOption((prev) => ({
                ...prev,
                ...res.data,
            }))
        }
    }

    const updateIdeaPageTopicIconOnServer = async (updatedIconID) => {
        if (selectedOption?.id) {
            try {
                const url = `resources/idea-page-themes/${selectedOption.id}/`;
                const res = await updateDataAPI('PATCH', url, {
                    icon: updatedIconID || null,
                });
            } catch (err) {
                console.error(`An error occured when updating idea page theme topic icon: ${err.message}`);
            }
        }
    }

    const handleChangeIdeaPageThemeIcon = async (updatedIconID) => {
        if (selectedOption?.id) {
            setSelectedOption((prev) => ({
                ...prev,
                icon: updatedIconID,
            }));
            dispatch(setIdeaPageThemes([...ideaPageThemes].map((theme) => {
                if (theme.id === selectedOption.id) {
                    return {
                        ...theme,
                        icon: updatedIconID
                    }
                }
                return theme;
            })))
            await updateIdeaPageTopicIconOnServer(updatedIconID);
        }
    }

    return (
        <div className="idea-page-select-option">
            {mode === 'single' && (
                <h3>{type.charAt(0).toUpperCase() + type.slice(1)}</h3>
            )}
            {type === Constant.TYPE_IDEA_PAGE_SELECT_OPTION.THEME_TYPE && mode !== 'multi' && (
                <div className="theme-prefix">The content of the worksheet is about: </div>
            )}
            <CreatableSelect
                placeholder={`Select ${type}`}
                isClearable
                className="basic-single mb-3"
                classNamePrefix="select"
                name={type}
                options={options}
                value={selectedOption}
                onCreateOption={handleCreateNewOption}
                onChange={handleSelectOption}
                isValidNewOption={() => mode === 'single'}
            />
            {type === Constant.TYPE_IDEA_PAGE_SELECT_OPTION.THEME_TYPE && mode !== 'multi' && (
                <div className="upload-topic-image">
                    <div className="d-flex flex-column justify-content-between">
                        <div>
                            <Button size="sm" variant="secondary">
                                <FontAwesomeIcon icon={faRefresh} onClick={(refreshTopic)} />
                            </Button>
                        </div>

                    </div>

                    <UploadNewTopicImage
                        topicIcon={selectedOption?.icon}
                        topicName={selectedOption?.name}
                        handleUploadedTopic={() => {}}
                        handleChangeIconOption={handleChangeIdeaPageThemeIcon}
                        type='ideaPageThemeTopic'
                    />
                </div>
            )}
            {showSettingModal && (
                <IdeaPageSettingOptionModal
                    ideaPageID={page['idea']}
                    inputOptionName={inputNewOptionName}
                    handleCloseModal={handleCloseModal}
                    handleSelectOption={handleSelectOption}
                    type={type}
                />
            )}
        </div>
    );
};

export default IdeaPageSelectOption;