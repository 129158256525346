import { useState, useMemo } from 'react';
import TagActions from './tag-actions';
import TagSettingModal from './tag-setting-modal';
import './styles.scss';

export const NB_TREE_LEVELS_SHOW = 1;

const TagsTreeFilter = ({
    disabledFilter = false,
    tags = [],
    categories = [],
    category = {},
    treeLevel = 1,
    parentId,
    fetchAllCategories = () => { },
}) => {
    const [showTagSettingModal, setShowTagSettingModal] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);
    const [showMoreLevelTagsTreeFilter, setShowMoreLevelTagsTreeFilter] = useState(false);

    const getFilteredTags = (inputTags = [], tagParentId) => {
        return inputTags.filter(tag => tag.parent === tagParentId).sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            return 0;
        })
    }

    const filteredTags = useMemo(() => getFilteredTags(tags, parentId), [tags, parentId]);

    if (filteredTags.length === 0) {
        return null;
    }

    const handleEditTag = (value) => {
        setSelectedTag(value);
        setShowTagSettingModal(true);
    };

    const handleShowMoreLevelTagsTreeFilter = (value) => {
        if (treeLevel <= NB_TREE_LEVELS_SHOW && value !== showMoreLevelTagsTreeFilter) {
            setShowMoreLevelTagsTreeFilter(value);
        };
    };

    const tagActionsProps = {
        handleEditTag,
        category,
        fetchAllCategories,
    };

    const tagsTreeFilterProps = {
        disabledFilter,
        tags,
        categories,
        category,
        fetchAllCategories,
    };

    return (
        <ul
            className="tags-tree-filter"
            onMouseEnter={() => { }}
            style={{
                marginLeft: `${(treeLevel > NB_TREE_LEVELS_SHOW) && '10px'}`
            }}
        >
            {filteredTags.map(tag => (
                <li
                    key={tag.id}
                    onMouseEnter={() => handleShowMoreLevelTagsTreeFilter(tag.id)}
                    onMouseLeave={() => handleShowMoreLevelTagsTreeFilter(false)}
                >
                    <TagActions
                        disabledFilter={disabledFilter}
                        tag={tag}
                        {...tagActionsProps}
                        shouldShowArrowRight={treeLevel === 1 && getFilteredTags(tags, tag.id).length > 0}
                    />
                    {treeLevel > NB_TREE_LEVELS_SHOW && (
                        <TagsTreeFilter 
                            {...tagsTreeFilterProps} 
                            parentId={tag.id}
                            treeLevel={treeLevel + 1}
                        />)}
                    {showMoreLevelTagsTreeFilter === tag.id && getFilteredTags(tags, tag.id).length > 0 && (
                        <div className="tags-tree-filter__more-level">
                            <TagsTreeFilter
                                {...tagsTreeFilterProps}
                                parentId={tag.id}
                                treeLevel={treeLevel + 1}
                            />
                        </div>
                    )}
                </li>
            ))}
            {showTagSettingModal && (
                <TagSettingModal
                    fetchAllCategories={fetchAllCategories}
                    tags={tags}
                    selectedTag={selectedTag}
                    categories={categories}
                    hideModal={() => setShowTagSettingModal(false)}
                />
            )}
        </ul>
    );
};

export default TagsTreeFilter;