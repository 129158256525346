import React, { useCallback, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import FilterByDateRange from '../../filters/datepicker';
import moment from 'moment';
import './styles.scss';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import { fetchStaffsExceptAdmin } from '../staffs';
import { fetchTaskSteps } from '../../filters/task-step';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import CustomizedLabelLineChart from '../../components/common/customized-label-line-chart';

const reformatDataForChart = (data = [], stepLabels) => {
    const formattedArray = data.map((item) => {
        const { productIdea__task__histories__step__label: stepLabel, productIdea__task__histories__startDate: date, idea_count } = item;

        const newItem = { date: moment(date).format('YYYY-MM-DD') };
        stepLabels.forEach((label) => {
            newItem[label] = label === stepLabel ? idea_count : 0;
        });

        return newItem;
    });

    return formattedArray;
}

export const caculateTotalIdeaOfEachStepInDateRange = (data = []) => {
    if (!data) return;
    const totalCounts = {};

    [...data].forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
            if (key !== "date") {
                totalCounts[key] = (totalCounts[key] || 0) + value;
            }
        });
    });
    return totalCounts;
}

export const FILTER_STAFF_OPTIONS = [
    {
        id: 1,
        value: 'BY_ROLE',
        label: 'BY ROLE',
    },
    {
        id: 2,
        value: 'BY_NAME',
        label: 'BY NAME',
    }
]

export const DISPLAY_CHART_MODE = [
    {
        id: 1,
        value: 'DAY_BY_DAY',
        label: 'DAY BY DAY',
    },
    {
        id: 2,
        value: 'PROGRESSIVE',
        label: 'PROGRESSIVE',
    }
]

const ProductPerformancePage = () => {
    const startDate = useSelector((state) => state.filters.startDate);
    const endDate = useSelector((state) => state.filters.endDate);
    const [loading, setLoading] = useState(false);
    const [productPerformanceData, setProductPerformanceData] = useState(null);

    const [selectedStaffs, setSelectedStaffs] = useState([]);
    const [selectedSteps, setSelectedSteps] = useState([]);
    const [selectedFilterStaffOption, setSelectedFilterStaffOption] = useState(FILTER_STAFF_OPTIONS[0]);
    const [selectedStaffRoles, setSelectedStaffRoles] = useState([]);
    const [selectedDisplayChartMode, setSelectedDisplayChartMode] = useState(DISPLAY_CHART_MODE[0]);

    const [staffOptions, setStaffOptions] = useState([]);
    const [stepOptions, setStepOptions] = useState([]);
    const [staffRoleOptions, setStaffRoleOptions] = useState([]);

    const useCalculateChartData = useCallback(() => {
        if (!productPerformanceData) return;

        let data = productPerformanceData;

        if (selectedDisplayChartMode === DISPLAY_CHART_MODE[1]) {
            data = calculateProgressiveData(productPerformanceData);
        }
        
        return data;
    }, [selectedDisplayChartMode, productPerformanceData]);

    const calculateProgressiveData = (data) => {
        const stepCounts = {};
        let result = [];

        [...data].forEach((item) => {
            const newItem = { ...item };

            for (const stepLabel in newItem) {
                if (stepLabel !== 'date') {
                    if (!stepCounts[stepLabel]) {
                        stepCounts[stepLabel] = 0;
                    }

                    const count = newItem[stepLabel];
                    const previousCount = stepCounts[stepLabel];
                    const progressiveCount = previousCount + count;

                    stepCounts[stepLabel] = progressiveCount;
                    newItem[stepLabel] = progressiveCount;
                }
            }

            result.push(newItem);
        });

        return result;
    };

    const chartData = useCalculateChartData();

    const useCalculateTotalIdeas = useCallback(() => {
        if (selectedDisplayChartMode === DISPLAY_CHART_MODE[1]) return;
        return caculateTotalIdeaOfEachStepInDateRange(productPerformanceData);
    }, [productPerformanceData, selectedDisplayChartMode]);

    const totalIdeasOfEachStepInDateRange = useCalculateTotalIdeas();

    useEffect(() => {
        const getStaffOptions = async () => {
            setLoading(true);
            const staffsExceptAdmin = await fetchStaffsExceptAdmin();
            setStaffOptions(staffsExceptAdmin.map((staff) => ({
                ...staff,
                label: staff.id + ' ' + staff.name,
                value: staff.id,
            })));
            setLoading(false);
        };

        getStaffOptions();
    }, []);

    useEffect(() => {
        const getStepOptions = async () => {
            setLoading(true);
            const steps = await fetchTaskSteps();
            setStepOptions(steps.map((step) => ({
                ...step,
                label: `${step.categoryName}. ${step.label}`,
                value: step.label,
            })));
            setLoading(false);
        };

        getStepOptions();
    }, []);

    useEffect(() => {
        const getStaffRoleOptions = async () => {
            setLoading(true);
            const url = 'product-dev/staff-roles/';
            const res = await getDataFromAPI(url);
            setStaffRoleOptions(res.data.map((role, index) => ({
                ...role,
                label: `${index + 1}. ${role.role}`,
                value: role.role,
            })));
            setLoading(false);
        };

        getStaffRoleOptions();
    }, []);

    const handleShowProductPerformance = async () => {
        setProductPerformanceData(null);

        const url = `product-dev/get-staffs-product-performance/`;

        const stepLabels = selectedSteps.map((step) => step.value);

        const data = {
            stepLabels,
            staffIds: selectedStaffs.map((staff) => staff.id),
            staffRoles: selectedStaffRoles.map((role) => role.value),
            startDate,
            endDate,
        };

        const res = await updateDataAPI('POST', url, data);

        if (res.data?.length > 0) {
            setProductPerformanceData(reformatDataForChart(res.data, stepLabels));
        }
    };

    return (
        <Container className="product-performance-page">
            <div className="product-performance-page__filter mb-2">
                <h5>Select filter staff options:</h5>
                <Select
                    className="select-filter-staff-options"
                    classNamePrefix="select"
                    defaultValue={FILTER_STAFF_OPTIONS[0]}
                    isLoading={loading}
                    name="filterStaffOptions"
                    options={FILTER_STAFF_OPTIONS}
                    value={selectedFilterStaffOption}
                    onChange={(selectedValue) => {
                        setSelectedFilterStaffOption(selectedValue);
                        setSelectedStaffRoles([]);
                        setSelectedStaffs([]);
                    }}
                />
                {selectedFilterStaffOption?.id === FILTER_STAFF_OPTIONS[0].id && (
                    <>
                        <h5>Select roles:</h5>
                        <Select
                            className="select-role"
                            classNamePrefix="select"
                            isLoading={loading}
                            isSearchable
                            isMulti
                            name="staffRoles"
                            options={staffRoleOptions}
                            value={selectedStaffRoles}
                            onChange={(selectedValues) => setSelectedStaffRoles(selectedValues)}
                        />
                    </>
                )}
                {selectedFilterStaffOption?.id === FILTER_STAFF_OPTIONS[1].id && (
                    <>
                        <h5>Select staffs:</h5>
                        <Select
                            className="select-role"
                            classNamePrefix="select"
                            isLoading={loading}
                            isSearchable
                            isMulti
                            name="staffs"
                            options={staffOptions}
                            value={selectedStaffs}
                            onChange={(selectedValues) => setSelectedStaffs(selectedValues)}
                        />
                    </>
                )}
                <h5>Select steps:</h5>
                <Select
                    className="select-step"
                    classNamePrefix="select"
                    isLoading={loading}
                    isSearchable
                    isMulti
                    name="steps"
                    options={stepOptions}
                    value={selectedSteps}
                    onChange={(selectedValues) => setSelectedSteps(selectedValues)}
                />
                <h5>Select date range:</h5>
                <FilterByDateRange defaultDateRange={{ startDate: moment().toDate(), endDate: moment().toDate() }} />
                <Button variant="primary" onClick={handleShowProductPerformance}>
                    Show product performance
                </Button>
            </div>
            {chartData && (
                <div className="product-performance-page__chart mt-4">
                    <h5>Select display chart mode:</h5>
                    <Select
                        className="select-display-chart-mode mb-4"
                        classNamePrefix="select"
                        defaultValue={DISPLAY_CHART_MODE[0]}
                        isLoading={loading}
                        name="selectDisplayChartMode"
                        options={DISPLAY_CHART_MODE}
                        value={selectedDisplayChartMode}
                        onChange={(selectedValue) => setSelectedDisplayChartMode(selectedValue)}
                    />
                    <h3 className="text-center">Product performance: </h3>
                    <CustomizedLabelLineChart
                        data={chartData}
                        dateLineKeys={selectedSteps.map((step) => step.value)}
                        dataKeyX={"date"}
                    />
                    <h5 className="text-center">Product performance</h5>
                    {selectedDisplayChartMode === DISPLAY_CHART_MODE[0] && (
                        <Container>
                            {selectedSteps.map((step) => (
                                <p>
                                    Total task with step label {step.value}: {totalIdeasOfEachStepInDateRange?.[step.value] ?? 0} tasks.
                                </p>
                            ))}
                        </Container>
                    )}
                </div>
            )}
        </Container>
    )
}

export default ProductPerformancePage;