import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { updateDataAPI } from "../../../../utils/query";

const CollectionInfoModal = ({ collection, collections, setCollections, hideModal }) => {
    const [name, setName] = useState(collection?.name);

    const create = async () => {
        if (name !== null) {
            try {
                const res = await updateDataAPI('POST', 'auto-content/collections/', {
                    name
                });
                setCollections([res.data].concat(collections));
            } catch (err) {
                window.alert('Can not create collection. Maybe collection existed');
            }
        }
        hideModal();
    }

    const update = async () => {
        const res = await updateDataAPI('PATCH', 'auto-content/collections/' + collection.id + '/', {
            name
        });
        setCollections(collections.map(c => {
            if (c.id === collection.id) {
                return res.data;
            }
            return c;
        }));
        hideModal();
    }

    const deleteCollection = async () => {
        if (window.confirm('Are you sure to delete this collection?')) {
            await updateDataAPI('DELETE', 'auto-content/collections/' + collection.id + '/');
            setCollections(collections.filter(c => c.id !== collection.id));
            hideModal();
        }
    }

    return (
        <Modal
            show={true}
            onHide={hideModal}
            className="collection-info-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {collection ? 'Edit Collection ' + collection.name : 'New Collection'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Name*</strong></Form.Label>
                        <Form.Control value={name} onChange={e => setName(e.target.value)} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {collection !== null && (
                    <>
                        <Button variant="danger" onClick={deleteCollection}>Delete</Button>
                        <Button variant="primary" onClick={update}>Update</Button>
                    </>
                )}
                {collection === null && (
                    <Button variant="primary" onClick={create} disabled={!name}>Create</Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default CollectionInfoModal;