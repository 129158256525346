import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../../utils/query';
import { Modal } from 'react-bootstrap';

const TemplateImageModal = ({ templateID, hideModal }) => {
    const [image, setImage] = useState(null);

    useEffect(() => {
        const fetchTemplate = async () => {
            const res = await getDataFromAPI('auto-content/templates/' + templateID + '/');
            if (res.data['imageUrl']) {
                setImage(res.data['imageUrl']);
            } else {
                window.alert('template image not found');
            }
        }

        fetchTemplate();
    }, []);

    return (
        <Modal show={true} onHide={hideModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Template {templateID}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {image && (
                    <img src={image} alt="Template" style={{ width: '100%' }} />
                )}
            </Modal.Body>
        </Modal>
    );
}

export default TemplateImageModal;