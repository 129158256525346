import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './styles.scss';
import InteractivePromptBlock from './interactive-prompt-block';
import { useDispatch, useSelector } from 'react-redux';
import { setIdeaSetting } from '../autoContentSlice';
import { updateDataAPI } from '../../../utils/query';

const PROMPT_TEXT = '# BACKGROUND: \n You are a #subjects teacher (grades #grades) in the United States who wants to make a worksheet with the subject "#title" for the students that Common Core aligned.\n\n # VOCABULARY NOTE \n#noteForAI \n#requirements \n\n# REQUEST:\n#activity\n\n#skills\nThe content of the worksheet is about: #theme\n\n# NOTE: \n #note\n\n# TRAINING TEXT AND EXAMPLES:\n #trainingTextDefinition \n Training Example: #trainingTextExamples';

const InteractivePromptModal = ({ idea, page, ideaTemplate, updateIdeaTemplate, hideModal }) => {
    const dispatch = useDispatch();
    const { ideaSetting } = useSelector((state) => state.autoContent);

    const [isSubmit, setIsSubmit] = useState(false);
    const [formattedPrompt, setFormattedPrompt] = useState();

    const onApplyPrompt = () => {
        setIsSubmit(true)
    }

    useEffect(() => {
        formatPrompt();
    }, []);

    useEffect(() => {
        if (isSubmit) {
            setIsSubmit(false)
        }
    }, [isSubmit]);

    const formatPrompt = async() => {
        let formattedPrompt = PROMPT_TEXT
            .replace('#subjects', idea.subjects.replace(',', ', '))
            .replace('#grades', idea.grades.replace(',', ', '))
            .replace('#title', idea.title ? idea.title : idea.name)
            .replace('#skills', (page.skill && page.skillDetails) ? page.skillDetails : '')
            .replace('#theme', page.theme ? page.themeName : '')
            .replace('#trainingTextDefinition', ideaTemplate.trainingText ? ideaTemplate.trainingTextData['definition'] : '')
            .replace('#trainingTextExamples', ideaTemplate.trainingText ? ideaTemplate.trainingTextData['examples'] : '');
        try {
            const resRequirements = await updateDataAPI('POST', 'auto-content/get-idea-template-requirements/?ideaTemplateID=' + ideaTemplate.id);
            const requirements = resRequirements.data.join(' and ');
            formattedPrompt = formattedPrompt.replace('#requirements', `Based on the following ${requirements}.`);
        } catch (err) {
            formattedPrompt = formattedPrompt.replace('#requirements', '');
        }
        setFormattedPrompt(formattedPrompt);
    }

    const onSubmit = async(data) => {
        updateIdeaTemplate([
            ['activity', data['#activity']],
            ['note', data['#note']]
        ]);
        dispatch(setIdeaSetting({...ideaSetting, noteForAI: data['#noteForAI']}));
        await updateDataAPI('PATCH', 'auto-content/idea-templates/' + ideaTemplate.id + '/', {
            activity: data['#activity'],
            note: data['#note'],
        });
        await updateDataAPI('PATCH', 'auto-content/idea-settings/' + ideaSetting.id + '/', {noteForAI: data['#noteForAI']});
    }

    if (ideaSetting === undefined) {
        return null;
    }

    return (
        <Modal
            show={true} backdrop="static" keyboard={false} size="xl"
            onHide={hideModal}
            contentLabel="Prompt Interactive"
            className="interactive-prompt-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Interactive Prompt
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {formattedPrompt && (
                    <InteractivePromptBlock
                        prompt={formattedPrompt}
                        isSubmit={isSubmit}
                        init={{
                            '#activity': ideaTemplate.activity,
                            '#note': ideaTemplate.note ?? '',
                            '#noteForAI': ideaSetting.noteForAI ? ideaSetting.noteForAI : ''
                        }}
                        multilineFields={[
                            '#activity',
                            '#note',
                            '#noteForAI',
                        ]}
                        onlyClickableFields={[
                            '#zones'
                        ]}
                        onSubmit={data => {
                            onSubmit(data)
                            hideModal()
                        }}
                        callbackClickable={{
                            '#zones': () => { }
                        }}
                        page={page}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onApplyPrompt}>Apply</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default InteractivePromptModal;