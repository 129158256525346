import { Badge, Modal, Table } from 'react-bootstrap';
import './styles.scss';

const BundleItemsModal = ({bundle, userRoles, hideModal}) => {
    return (
        <Modal
            show={true}
            onHide={hideModal}
            className="bundle-items-modal"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {bundle.customID}. {bundle.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover className="keyword-ideas-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Thumbnail</th>
                            <th>Name</th>
                            <th>Grades</th>
                            <th>Tags</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bundle.bundleItems.map(item => (
                            <tr key={item['customID']}>
                                <td>{item['customID']}</td>
                                <td>
                                    <img src={item['thumbnail']} />
                                </td>
                                <td>{item['name']}</td>
                                <td>{item['grades']}</td>
                                <td>
                                    <div className="tags">
                                        {item.tags.map(tag => (
                                            <Badge bg="secondary">{tag.type}: {tag.name}</Badge>
                                        ))}
                                        {userRoles.indexOf('admin') > -1 && (
                                            item.url ? (
                                                <Badge bg="success">
                                                    <a href={item.url} target="_blank" rel="noreferrer">published</a>
                                                </Badge>
                                            ) : (
                                                <Badge bg="danger">{item.status}</Badge>
                                            )
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
}

export default BundleItemsModal;