import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import IconsList from '../../../icons-list';
import SelectIconsModal from '../../../icons/select-icons-modal';
import { updateDataAPI } from '../../../../utils/query';

import './styles.scss';

const IconsTab = ({ storeID, storeIcons = [], setCurrentStoreIcons = () => {} }) => {
    const [showSelectIconsModal, setShowSelectIconsModal] = useState(false);

    const removeStoreIcon = async (iconID) => {
        if (!iconID) return;

        try {
            const url = 'resources/remove-icon-from-store/';

            const res = await updateDataAPI('POST', url, {
                iconID,
                storeID,
            });

            setCurrentStoreIcons((prev) => prev.filter((icon) => icon.id !== iconID));
        } catch(err) {
            console.error(`An error occured when removing icon from store: ${err.message}`);
        };
    };

    return (
        <div className="store-assets-icons-tab">
            <IconsList
                icons={storeIcons}
                removeIcon={removeStoreIcon}
            />
            <Button onClick={() => setShowSelectIconsModal(true)}>
                Add icons
            </Button>

            {showSelectIconsModal && (
                <SelectIconsModal
                    storeID={storeID}
                    storeIcons={storeIcons}
                    setCurrentStoreIcons={setCurrentStoreIcons}
                    closeModal={() => setShowSelectIconsModal(false)} />
            )}
        </div>
    )
}

export default IconsTab;