import { Button, Col, ListGroup, Modal, Row } from 'react-bootstrap';

import './styles.scss';
import IdeaSkillSelect from './idea-skill-select';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const TableOfContentsModal = ({ idea, pages, setPages, hideModal = () => {}, refresh = () => {}, setShowNonAIGenerateModal= () => { }, setSelectedIdeaSkill = () => { } }) => {
    const { ideaSkills } = useSelector((state) => state.autoContent);

    const [pagesBySkill, setPagesBySkill] = useState([]);

    useEffect(() => {
        init();
    }, [ideaSkills, pages]);

    const removeSkillIfSkillNotFound = (page) => {
        if (page.skill != null) {
            const pageSkillName = ideaSkills.find(s => s.id === page.skill)?.name;

            if (pageSkillName === undefined) {
                page.skill = null;
                setPages((prev) => prev.map((p) => {
                    if (p.id === page['id']) {
                        p.skill = null;
                        return p;
                    };
                    return p;
                }));
            };
        };
        return page
    };

    const init = () => {
        const skills = [];
        for (let i = 0; i < pages.length; i++) {
            let page = pages[i];
            page = removeSkillIfSkillNotFound(page);
            const skill = skills.find(skill => skill.id === page.skill);
            if (skill === undefined) {
                skills.push({
                    id: page.skill,
                    name: ideaSkills.find(s => s.id === page.skill)?.name,
                    pages: [i]
                });
            } else {
                skill.pages.push(i);
            }
        }

        setPagesBySkill(skills);
    }

    return (
        <Modal
            show={true}
            size="xl"
            onHide={hideModal}
            className="table-of-contents-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Table of Contents
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <IdeaSkillSelect 
                            idea={idea} 
                            refresh={refresh}
                            setShowNonAIGenerateModal={setShowNonAIGenerateModal}
                            setSelectedIdeaSkill={setSelectedIdeaSkill}
                        />
                    </Col>
                    <Col>
                        <h3>Table of Contents</h3>
                        <ListGroup>
                            {pagesBySkill.map((skill, index) => (
                                <ListGroup.Item key={index}>
                                    <span>{skill['name']}</span>
                                    <span>
                                        Page: {skill.pages.map(p => p + 1).join(', ')}
                                    </span>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={hideModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TableOfContentsModal;