import { useState } from 'react';
import { Col, Form, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AsyncSelectComponent from '../../../../utils/async-select';
import { updateDataAPI } from '../../../../utils/query';

import './styles.scss';
import Constant from '../../../../Constant';
import DataSetMappingTable from './mapping-table';
import { useSelector } from 'react-redux';

const DataSetModal = ({
    dataSet = null,
    setDataSets,
    updateDataSet,
    hideModal = () => { }
}) => {
    const seasons = useSelector(state => state.filters.seasons);

    const [loading, setLoading] = useState(false);
    const [fieldsFormat, setFieldsFormat] = useState(JSON.stringify(dataSet?.fieldsFormat));
    const [keywordID, setKeywordID] = useState(dataSet?.keyword);
    const [dataType, setDataType] = useState(dataSet?.dataType);
    const [activity, setActivity] = useState(dataSet?.activity);
    const [quality, setQuality] = useState(dataSet?.quality);
    const [season, setSeason] = useState(dataSet?.season);
    const [dataSource, setDataSource] = useState(dataSet?.dataSource ? dataSet.dataSource : 'collect');
    const [keywordName, setKeywordName] = useState(dataSet?.keywordData ? dataSet.keywordData.name : null);
    const [template, setTemplate] = useState(dataSet?.template);
    const [collection, setCollection] = useState(dataSet?.collection);
    const [mapping, setMapping] = useState(dataSet?.mapping);
    const [audioAvailable, setAudioAvailable] = useState(dataSet?.audioAvailable);

    const createNewKeyword = async(name) => {
        const res = await updateDataAPI('POST', 'resources/keywords/', {name: name.toLowerCase()});
        setKeywordID(res.data['id']);
        setKeywordName(res.data['label']);
        hideModal();
    }

    const saveInfo = async() => {
        if (dataSet) {
            updateDataSet(keywordID, keywordName, dataType, activity, dataSource, fieldsFormat, template, collection, mapping, quality, season, audioAvailable);
        } else {
            const res = await updateDataAPI('POST', 'resources/data-sets/', {
                dataType,
                activity,
                dataSource,
                keyword: keywordID,
                fieldsFormat,
                template,
                collection,
                quality,
                season,
                audioAvailable,
            });
            setDataSets((prev) => ([...prev, res.data]));
        }
        hideModal();
    }

    const copyPrompt = () => {
        if (fieldsFormat) {
            navigator.clipboard.writeText('Read this image then give me the content in the following json format: ' + fieldsFormat);
        }
    }

    return (
        <Modal show={true} onHide={hideModal} className="data-set-modal" size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{dataSet?.id ? 'Data Set ' + dataSet.id : 'New Data Set'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs id="dataset-tabs">
                    <Tab eventKey="info" title="Info">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Data Type</Form.Label>
                                    <Form.Select value={dataType} onChange={e => setDataType(e.target.value)}>
                                        <option value={null}></option>
                                        <option value="JSON">JSON</option>
                                        <option value="TABLE">TABLE</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Activity</Form.Label>
                                    <Form.Select value={activity} onChange={e => setActivity(e.target.value)}>
                                        <option value={null}></option>
                                        <option value="MCQ">MCQ</option>
                                        <option value="BLANK">BLANK</option>
                                        <option value="DRAW">DRAW</option>
                                        <option value="MATCH">MATCH</option>
                                        <option value="REORDER">REORDER</option>
                                        <option value="WORD_BANK">WORD BANK</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Data Source</Form.Label>
                                    <Form.Select value={dataSource} onChange={e => setDataSource(e.target.value)}>
                                        <option value={null}></option>
                                        <option value="collect">Collect</option>
                                        <option value="function">Function</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Quality</Form.Label>
                                    <Form.Select value={quality} onChange={e => setQuality(e.target.value)}>
                                        <option value={null}></option>
                                        <option value="good">Good</option>
                                        <option value="medium">Medium</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Season</Form.Label>
                                    <Form.Select value={season} onChange={e => setSeason(e.target.value)}>
                                        <option value="common">Common</option>
                                        {seasons.map(season => (
                                            <option key={season.id} value={season.name.toLowerCase().replace(/ /g, "_")}>{season.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="grades">
                                    <Form.Label>Audio</Form.Label>
                                    <Form.Select value={audioAvailable} onChange={e => setAudioAvailable(e.target.value)}>
                                        <option value={null}></option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr />

                        <div className="mb-3">
                            Current Keyword: {dataSet?.keywordData ? dataSet?.keywordData.label : ''}
                        </div>
                        <div>Search Keyword:</div>
                        <AsyncSelectComponent
                            searchUrl='resources/keywords/?name='
                            onChange={selectedValue => {
                                setKeywordID(selectedValue['id']);
                                setKeywordName(selectedValue['label']);
                            }}
                            labelField="label"
                            value={{ value: '', label: '' }}
                            setLoading={setLoading}
                            loading={loading}
                            clearSelection={() => {}}
                            defaultData={[]}
                            createable={true}
                            onCreateOption={createNewKeyword}
                        />

                        <hr />

                        <Form.Group className="mb-3" controlId="grades">
                            <Form.Label>Fields Format</Form.Label>
                            <Form.Control
                                value={fieldsFormat}
                                as="textarea"
                                rows={5}
                                onChange={(e) => setFieldsFormat(e.target.value)}
                            />
                        </Form.Group>
                    </Tab>
                    <Tab eventKey="link" title="Link">
                        <h3>Link DataSet to Template</h3>
                        {template && (
                            <a href={Constant.WORKSHEET_MAKER_URL + '?type=template&id=' + template + '&token=' + localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN)} target="_blank">{template}</a>
                        )}
                        <AsyncSelectComponent
                            searchUrl='auto-content/templates/?page_size=1000&keyword='
                            onChange={selectedValue => {
                                setTemplate(selectedValue['id']);
                            }}
                            value={{ value: '', label: '' }}
                            setLoading={setLoading}
                            loading={loading}
                            clearSelection={() => {}}
                            defaultData={[]}
                            createable={false}
                        />
                        <h3 className="mt-5">Link DataSet to Collection</h3>
                        {collection && (
                            <div>Collection: {collection}</div>
                        )}
                        <AsyncSelectComponent
                            searchUrl='auto-content/collections/?page_size=1000&keyword='
                            onChange={selectedValue => {
                                setCollection(selectedValue['id']);
                            }}
                            value={{ value: '', label: '' }}
                            setLoading={setLoading}
                            loading={loading}
                            clearSelection={() => {}}
                            defaultData={[]}
                            createable={false}
                        />
                    </Tab>
                    <Tab eventKey="mapping" title="Mapping">
                        {dataSet?.topics && (
                            <DataSetMappingTable mapping={mapping} setMapping={setMapping} data={dataSet.topics} activity={activity} />
                        )}
                    </Tab>
                </Tabs>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={copyPrompt}>
                    Copy Prompt
                </Button>
                <Button variant="primary" onClick={saveInfo}>
                    Save
                </Button>
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
};

export default DataSetModal;