import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import './styles.scss';
import { updateDataAPI } from '../../../../utils/query';
import Constant from '../../../../Constant';
import { Badge } from 'react-bootstrap';

const StoreThumbnailTemplateTab = ({
    storeID,
    thumbnailPreviewTemplates = [],
    setStoreThumbnailPreviewTemplates = () => { }
}) => {
    const [loading, setLoading] = useState(false);

    const deleteThumbnailTemplate = async (template) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            await updateDataAPI(
                'DELETE',
                'auto-content/templates/' + template.id + '/'
            );
            setStoreThumbnailPreviewTemplates(thumbnailPreviewTemplates.filter((a) => a.id !== template.id));
            setLoading(false);
        }
    }

    const duplicateTemplate = async (template) => {
        const name = window.prompt('New Template name', '');
        if (name) {
            try {
                const staffID = Math.floor(localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID));
                const res = await updateDataAPI('POST', 'auto-content/duplicate-template/?templateID=' + template.id + '&staffID=' + staffID);
                await updateDataAPI('PATCH', 'auto-content/templates/' + res.data.id + '/', { name });
                setStoreThumbnailPreviewTemplates([res.data].concat(thumbnailPreviewTemplates));
            } catch (e) {
                window.alert(e);
            }
        }
    }

    return (
        <Container className='store-thumbnail-template-tab' fluid>
            <div className="btns">
                <Button size="sm" onClick={() => window.open(process.env.REACT_APP_WORKSHEET_MAKER_URL + `?type=store_thumbnail_template&storeID=${storeID}&id=`, '_blank', 'noopener,noreferrer')}>
                    Add New Template
                </Button>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Template Name</th>
                        <th>Image</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {thumbnailPreviewTemplates.map((template) => (
                        <tr key={template.id}>
                            <td>{template.id}</td>
                            <td>
                                <div>{template.name}</div>
                                <div className="tags">
                                    {template.tagsData.map(tag => (
                                        <Badge bg="secondary" key={tag.id}>{tag.name}</Badge>
                                    ))}
                                </div>
                            </td>
                            <td>
                                <img src={template.imageUrl} />
                            </td>
                            <td>
                                <Button size="sm" className="editBtn" onClick={() => duplicateTemplate(template)}>
                                    Duplicate
                                </Button>
                                <Button size="sm" className="editBtn"
                                    onClick={() => window.open(process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=store_thumbnail_template&id=' + template.id, '_blank', 'noopener,noreferrer')}
                                >
                                    Edit
                                </Button>
                                <Button size="sm" variant="danger"
                                    onClick={() => deleteThumbnailTemplate(template)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default StoreThumbnailTemplateTab;