import { Modal } from 'react-bootstrap';
import LiteCanvas from '..';

const ZoomInLiteCanvasModal = ({ideaTemplate, isThumbnail, hideModal}) => {
    return (
        <Modal show={true} onHide={hideModal}
            contentLabel="Lite Canvas" size="xl"
            className="zoom-in-lite-canvas-modal"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <LiteCanvas
                    ideaTemplate={ideaTemplate}
                    isThumbnail={isThumbnail}
                    size="big"
                />
            </Modal.Body>
        </Modal>
    )
}

export default ZoomInLiteCanvasModal;