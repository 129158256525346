import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { Button, Container, Table } from 'react-bootstrap';
import OutlineModal from '../../components/outlines/outline-modal';

import './styles.scss';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../utils/auth';
import Constant from '../../Constant';

const OutlinesPage = () => {
    const [outlines, setOutlines] = useState([]);
    const [showOutlineModal, setShowOutlineModal] = useState(false);
    const [outlineInModal, setOutlineInModal] = useState(null);
    const [userRoles, setUserRoles] = useState([]);

    const { activityPermissions } = useSelector((state) => state.permissions);

    useEffect(() => {
        fetchAllOutlines();
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const fetchAllOutlines = async () => {
        const res = await getDataFromAPI('product-dev/outlines/');
        setOutlines(res.data);
    }

    const deleteOutline = async (id) => {
        if (window.confirm('Are you sure you want to delete this outline?')) {
            await updateDataAPI('DELETE', 'product-dev/outlines/' + id + '/');
            setOutlines(outlines.filter(outline => outline.id !== id));
        }
    }

    return (
        <Container className="outlines-page">
            <div className="btns">
                {hasPermission(userRoles, activityPermissions['CREATE_OUTLINE']) && (
                    <Button onClick={() => setShowOutlineModal(true)}>New Outline</Button>
                )}
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Staff</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {outlines.map(outline => (
                        <tr key={outline.id}>
                            <td>{outline.id}</td>
                            <td>
                                <a href={'/outline?id=' + outline.id} target="_blank">{outline.name}</a>
                            </td>
                            <td>{outline.staffName}</td>
                            <td className="actions-cell">
                                {hasPermission(userRoles, activityPermissions['CREATE_OUTLINE']) && (
                                    <Button size="sm" onClick={() => {
                                        setOutlineInModal(outline);
                                        setShowOutlineModal(true);
                                    }}>Edit</Button>
                                )}
                                {hasPermission(userRoles, activityPermissions['CREATE_OUTLINE']) && (
                                    <Button size="sm" variant="danger" onClick={() => deleteOutline(outline.id)}>Delete</Button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showOutlineModal && (
                <OutlineModal outline={outlineInModal} hideModal={(action) => {
                    setShowOutlineModal(false);
                    setOutlineInModal(null);
                    if (action === 'refresh') {
                        fetchAllOutlines();
                    }
                }} />
            )}
        </Container>
    )
}

export default OutlinesPage;