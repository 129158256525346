import { useState } from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { updateDataAPI } from '../../utils/query';

const CopyTemplateToDevPage = () => {
    const [templateID, setTemplateID] = useState(null);
    const [loading, setLoading] = useState(false);

    const copy = async() => {
        setLoading(true);
        try {
            const res = await updateDataAPI('POST', 'product-dev/copy-to-dev/', {
                type: 'template',
                id: templateID
            });
            window.alert('New Template in dev: ' + res.data['newID']);
        } catch (err) {
            window.alert('error');
        }
        setLoading(false);
    }
    return (
        <Container className="copy-template-to-dev-page">
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Template ID</Form.Label>
                    <Form.Control type="number" value={templateID} onChange={e => setTemplateID(e.target.value)} />
                </Form.Group>
                {templateID && (
                    <Button onClick={copy}>Migrate</Button>
                )}
            </Form>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default CopyTemplateToDevPage;