import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { getDataFromAPI } from '../../utils/query';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedStaff } from '../filtersSlice';

const FilterByStaff = ({ roles }) => {
    const dispatch = useDispatch();
    const selectedStaff = useSelector((state) => state.filters.selectedStaff);
    const [staffs, setStaffs] = useState([]);

    useEffect(() => {
        fetchStaffs();
    }, []);

    const fetchStaffs = async () => {
        const res = await getDataFromAPI('product-dev/staffs/?roles=' + roles);
        setStaffs(res.data)
    }

    return (
        <Form.Select onChange={e => {
            if (e.target.value !== 'All Staffs') {
                dispatch(setSelectedStaff(parseInt(e.target.value)));
            } else {
                dispatch(setSelectedStaff(null));
            }
        }} value={selectedStaff} className="filter-by-staff">
            <option value={null}>All Staffs</option>
            {staffs.map(staff => (
                <option value={staff.id} key={staff.id}>
                    {staff.name} {staff.description ? `(${staff.description})` : ''}
                </option>
            ))}
        </Form.Select>
    );
}

export default FilterByStaff;