import { useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { updateDataAPI } from '../../../utils/query';
import UploadFile from '../upload-file';

import './styles.scss';

const IdeaPageAssetModal = ({ page, defaultType, asset, hideModal }) => {
    const [type, setType] = useState(defaultType);
    const [note, setNote] = useState(asset?.note);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(asset?.image);

    const addAsset = async () => {
        if (type === 'note' && note) {
            setLoading(true);
            await updateDataAPI('POST', 'resources/idea-page-assets/', {
                ideaPage: page.id,
                type,
                note,
            });
            setLoading(false);
            hideModal('refresh');
        } else if (type === 'image' && image) {
            setLoading(true);
            await updateDataAPI('POST', 'resources/idea-page-assets/', {
                ideaPage: page.id,
                type,
                image,
            });
            setLoading(false);
            hideModal('refresh');
        }
    }

    const editAsset = async () => {
        if (type === 'note' && note) {
            setLoading(true);
            await updateDataAPI('PATCH', 'resources/idea-page-assets/' + asset.id + '/', {
                note,
            });
            setLoading(false);
            hideModal('refresh');
        } else if (type === 'image' && image) {
            setLoading(true);
            await updateDataAPI('PATCH', 'resources/idea-page-assets/' + asset.id + '/', {
                image,
            });
            setLoading(false);
            hideModal('refresh');
        }
    }

    return (
        <Modal
            show={true}
            size="lg"
            onHide={hideModal}
            className="idea-page-asset-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {asset ? ('Edit ' + asset.type) : ('Add new ' + type)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label><strong>Type*</strong></Form.Label>
                            <Form.Select onChange={e => setType(e.target.value)} value={type}>
                                <option value="note">Note</option>
                                <option value="image">Image</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {type === 'note' ? (
                            <ReactQuill theme="snow" value={note} onChange={setNote} />
                        ) : (
                            <div className="img-container">
                                <UploadFile
                                    label="Upload Image"
                                    id={''}
                                    setLoading={setLoading}
                                    uploadUrl={'resources/upload-asset/'}
                                    allowedFileExtentions={['png', 'jpg', 'jpeg']}
                                    fieldName={'asset'}
                                    uploadedFileName={image}
                                    setUploadedFileName={fileName => setImage(fileName)}
                                />
                                {image && (
                                    <img src={'https://idea-assets.s3.us-west-2.amazonaws.com/' + image} />
                                )}
                            </div>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {asset ? (
                    <Button onClick={editAsset}>Edit</Button>
                ) : (
                    <Button onClick={addAsset}>Add</Button>
                )}

                {loading && (
                    <div className="loading-container">
                        <Spinner animation="border" variant="light" />
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default IdeaPageAssetModal;