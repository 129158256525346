import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListSuggestionsReason from './list-suggestions-reason';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import { toast } from 'react-toastify';
import Constant from '../../../../Constant';

const ContentReportModal = ({
    selectedPages = [],
    hideModal = () => { }
}) => {
    const [reason, setReason] = useState('');
    const [note, setNote] = useState('');

    const [popularReasons, setPopularReasons] = useState([]);

    useEffect(() => {
        fetchTheMostPopularReasons();
    }, []);

    const fetchTheMostPopularReasons = async () => {
        const nbTheMostPopularReasons = 10;
        const url = `auto-content/content-reports/?isMostPopularReasons=true&popularityCount=${nbTheMostPopularReasons}`;

        const res = await getDataFromAPI(url);

        setPopularReasons(res.data.map(({ reason }) => reason));
    };

    const handleSaveReport = async () => {
        if (selectedPages.length === 0 || reason == '') {
            return;
        }

        const url = 'auto-content/content-reports/';
        const staffID = localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID);

        for (const selectedPage of selectedPages) {
            const { id: ideaTemplateID } = selectedPage.content;
            if (ideaTemplateID) {
                const lastestContentHistory = await getTheLastestContentHistory(ideaTemplateID);

                if (!lastestContentHistory) {
                    toast.error('There is no content history for this idea page.');
                    continue;
                }

                if (lastestContentHistory.staff === staffID) {
                    toast.error("You can't report your own content history version.");
                    continue;
                }

                const data = {
                    ideaTemplate: ideaTemplateID,
                    contentHistory: lastestContentHistory.id,
                    reason,
                    note,
                };

                try {
                    await updateDataAPI('POST', url, data);
                    toast.success('Successfully reported.');
                    hideModal();
                } catch (err) {
                    console.error('Failed to report:', err.message);
                    toast.error('Failed to report:', err.message);
                }
            }
        }
    };

    const getTheLastestContentHistory = async (ideaTemplateID) => {
        if (!ideaTemplateID) {
            return null;
        }

        const url = `auto-content/content-history/get-the-lastest-content-history/${ideaTemplateID}/`;

        try {
            const res = await getDataFromAPI(url);
            return res.data;
        } catch (err) {
            console.error("Failed to retrieve the latest content history:", err.message);
            return null;
        }
    };

    return (
        <Modal
            show={true}
            size="xl"
            onHide={hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Wrong content report
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="reasonForm">
                                <Form.Label>Reason &#x0028;required&#x0029;</Form.Label>
                                <Form.Control as="textarea" rows={3} value={reason} onChange={(e) => setReason(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <ListSuggestionsReason
                                handleChangeSuggestionReason={(reason) => setReason(reason)}
                                popularReasons={popularReasons}
                            />
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="noteForm">
                        <Form.Label>Note</Form.Label>
                        <Form.Control as="textarea" rows={3} value={note} onChange={(e) => setNote(e.target.value)} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hideModal}>Close</Button>
                {reason !== '' && (<Button variant="primary" onClick={handleSaveReport}>Save report</Button>)}
            </Modal.Footer>
        </Modal>
    )
};

export default ContentReportModal;