import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faLink } from '@fortawesome/free-solid-svg-icons';
import { updateDataAPI } from '../../../../utils/query';
import { toast } from 'react-toastify';

const ListSkills = ({
    skills = [],
    handleEditIdeaSkill = () => { },
    setInputSkill = () => { },
    refresh = () => { },
    setShowNonAIGenerateModal= () => { },
    setSelectedIdeaSkill = () => { }
}) => {

    const handleDeleteIdeaSkill = async (skill) => {
        const confirmed = window.confirm('Are you sure you want to delete this object?');
        if (confirmed) {
            const url = `resources/idea-skills/${skill['id']}/`;

            try {
                await updateDataAPI('DELETE', url);
                refresh(['skills']);
                setInputSkill('');
                toast.success('Deleted successfully.');
            } catch (e) {
                toast.error(`An error occured ${e.message}`);
            }
        };
    };

    return (
        <ListGroup className="list-skills">
            {skills.map((skill, index) => (
                <ListGroup.Item
                    key={index}
                    className="d-flex justify-content-between align-items-center"
                >
                    <div
                        className="d-flex flex-column auto-content-studio-view-answer-key-modal__list-skills"
                    >
                        <div>{skill?.name}</div>
                        <div className="skill-details">{skill?.details}</div>
                        {skill['children'].length > 0 && (
                            <ul>
                                {skill['childs'].map((child) => (
                                    <li className="mb-2 mt-2">
                                        <div style={{ marginRight: 20 }}>{child['name']}</div>
                                        <div style={{ marginRight: 20 }}>{child['details']}</div>
                                    </li>
                                ))}
                            </ul>
                        )}
                        {skill.nonAIFunction && (
                            <div>
                                <div className="skill-non-ai-function"><strong>Linked function: {skill.nonAIFunctionData?.name}</strong></div>
                                <div className="skill-non-ai-function">
                                    <span>Function arguments: </span>
                                    <pre>
                                        {JSON.stringify(skill.nonAIFunctionArgs, null, 4)}
                                    </pre>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="skill-actions">
                        <FontAwesomeIcon icon={faEdit} onClick={() => handleEditIdeaSkill(skill)} />
                        <FontAwesomeIcon icon={faTrash} className="text-danger" onClick={() => handleDeleteIdeaSkill(skill)} />
                        <FontAwesomeIcon icon={faLink} onClick={() => {
                            setShowNonAIGenerateModal('link');
                            setSelectedIdeaSkill(skill);
                        }} />
                    </div>
                </ListGroup.Item>
            ))}
        </ListGroup>
    )
};

export default ListSkills;