import { useEffect, useState } from 'react';
import { Button, Modal, Spinner, Table } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';

const TaskDebugModal = ({ task, hideModal }) => {
    const [parts, setParts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showingPrompt, setShowingPrompt] = useState(null);

    useEffect(() => {
        fetchParts();
    }, []);

    const fetchParts = async() => {
        setLoading(true);
        const res = await getDataFromAPI('resources/idea-parts/?customID=' + task.customID + '&additionalFields=pagesData');
        setParts(res.data);
        setLoading(false);
    }

    const refreshParts = async(refreshFields) => {
        setLoading(true);
        await updateDataAPI('POST', 'product-dev/refresh-idea-parts/?customID=' + task.customID + '&refreshFields=' + refreshFields);
        await fetchParts();
        setLoading(false);
    }

    const handleSkipPart = async (part) => {
        const res = await updateDataAPI('PATCH', `resources/idea-parts/${part.id}/`, {
            "done": true,
            "autoGenError": 5
        });
        setParts(parts.map(p => {
            if (p.id === part.id) {
                return res.data;
            }
            return p;
        }));

    }

    return (
        <Modal show={true} onHide={hideModal} className="task-debug-modal" size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    DEBUG Task {task.customID}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table hover>
                    <thead>
                        <tr>
                            <th>Part ID</th>
                            <th>Template</th>
                            <th>Skill</th>
                            <th>Theme</th>
                            <th>Collection</th>
                            <th>Pages</th>
                            <th>IdeaTemplates</th>
                            <th>Nb Gen Errors</th>
                            <th>Done</th>
                            <th>Skip</th>
                        </tr>
                    </thead>
                    <tbody>
                        {parts.map(part => (
                            <tr key={part.id}>
                                <td>
                                    <span className="part-id" onClick={() => setShowingPrompt(part.prompt)}>{part.id}</span>
                                </td>
                                <td>{part.template}</td>
                                <td>{part.skillName} ({part.skill})</td>
                                <td>{part.theme}</td>
                                <td>
                                    {part.collection && part.collectionIndex && (
                                        <div>{part.collection} - {part.collectionIndex}</div>
                                    )}
                                </td>
                                <td>{part.pagesData && (
                                    <div className={part.pagesData['donePages'] < part.pagesData['totalPages'] ? 'text-danger' : 'text-success'}>
                                        {part.pagesData['donePages']} / {part.pagesData['totalPages']}
                                    </div>
                                )}</td>
                                <td>
                                    {part.ideaTemplateIDs.join(', ')}
                                </td>
                                <td>{part.autoGenError}</td>
                                <td>{part.done ? 'DONE' : ''}</td>
                                <td><Button onClick={() => handleSkipPart(part)} size="sm" variant="secondary">Skip</Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => refreshParts('genDataTicket,exportTicket')}>Refresh Parts And Export</Button>
                <Button onClick={() => refreshParts('genDataTicket')}>Refresh Parts Only</Button>
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}

            {showingPrompt !== null && (
                <Modal show={true} onHide={() => setShowingPrompt(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Prompt
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {showingPrompt}
                    </Modal.Body>
                </Modal>
            )}
        </Modal>
    );
}

export default TaskDebugModal;