import { configureStore } from '@reduxjs/toolkit';
import filtersReducer from './filters/filtersSlice';
import colorbycodeMakerSlice from './components/colorbycode-maker/colorbycodeMakerSlice';
import permissionsReducer from './redux/permissionsSlice';
import autoContentReducer from './components/auto-content/autoContentSlice';
import staffSlice from './redux/staffSlice';

export default configureStore({
    reducer: {
        filters: filtersReducer,
        generateProduct: colorbycodeMakerSlice,
        permissions: permissionsReducer,
        autoContent: autoContentReducer,
        staff: staffSlice,
    },
});