import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { Alert, Col, ListGroup, Row } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from "../../../utils/query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPencil } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import MultiValues from '../../../forms/multi-values';
import ReactQuill from 'react-quill';
import Offcanvas from 'react-bootstrap/Offcanvas';

import './styles.scss';
import { useSelector } from 'react-redux';

const ProductIdeaEventsOffcanvas = ({ field, productID = null, subjects = [], hideModal }) => {
    const { events } = useSelector((state) => state.autoContent);
    const [relationValueFields, setRelationValueFields] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [inputValue, setInputValue] = useState(null);
    const [titleReachMaxLength, setTitleReachMaxLength] = useState(false);

    const removeRelationEvent = async (eventName, relationIndex) => {
        if (window.confirm('Are your sure?')) {
            const url = 'resources/product-idea-event/remove-relation/';

            const res = await updateDataAPI('POST', url, {
                productID: productID,
                eventName: eventName,
                relatedField: field,
            });

            if (res.data.status == "ok") {
                const updateRelations = [...relationValueFields];
                updateRelations.splice(relationIndex, 1);
                setRelationValueFields(updateRelations);
            };
        }
    };

    const addRelationEvent = async () => {
        if (selectedEvent == null || inputValue == null) return;

        const url = 'resources/product-idea-event/add-relation/';

        const res = await updateDataAPI('POST', url, {
            productID: productID,
            eventID: selectedEvent,
            relatedField: field,
            value: inputValue
        });

        if (res.data.status == "ok") {
            const { id, event } = res.data.relation;

            const newValue = {
                id: id,
                event: event,
                value: inputValue,
            };

            let updatedRelations = [...relationValueFields];

            const relationIndex = updatedRelations.findIndex((item) => item.event == event);

            if (relationIndex !== -1) {
                updatedRelations[relationIndex].value = inputValue
            } else {
                updatedRelations.push(newValue);
            };

            setRelationValueFields(updatedRelations);
        };
    }

    useEffect(() => {
        const getAllRelatedEventsByFields = async () => {
            const url = `resources/product-idea-event/get-relations/?relatedField=${field}`;

            const res = await getDataFromAPI(url);

            setRelationValueFields(res.data);
        };

        getAllRelatedEventsByFields();
    }, [field]);

    return (
        <Offcanvas show={true} onHide={hideModal} placement="bottom" className="product-idea-events-offcanvas">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Events</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Row>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Event</Form.Label>
                            <Form.Select onChange={e => setSelectedEvent(e.target.value)} value={selectedEvent}>
                                <option value={null}></option>
                                {events.length > 0 && events.map(s => (
                                    <option value={s.id} key={s.id}>{s.event}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        {field == "title" && (
                            <Form.Group className="mb-3">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" rows={4} value={inputValue} maxLength="80" onChange={e => {
                                    if (e.target.value.length <= 80) {
                                        setInputValue(e.target.value);
                                    }
                                    setTitleReachMaxLength(e.target.value.length >= 80);
                                }} />
                                {titleReachMaxLength && (
                                    <Alert variant="warning" className="mt-3">Title reaches 80 characters</Alert>
                                )}
                            </Form.Group>
                        )}
                        {field == "subject" && (
                            <Form.Group className="mb-3">
                                <Form.Label>Subjects</Form.Label>
                                <MultiValues value={subjects} setValue={val => setInputValue(val)} choicesDataAPI="resources/subjects/"
                                    labelField="name" valueField="name" />
                            </Form.Group>
                        )}
                        {field == "description" && (
                            <ReactQuill theme="snow" value={inputValue} onChange={setInputValue} />
                        )}
                        <Button variant="primary" onClick={() => addRelationEvent()}>
                            Save
                        </Button>
                    </Col>
                    <Col md={8}>
                        <ListGroup>
                            {relationValueFields.length > 0 && relationValueFields.map((f, index) => (
                                <ListGroup.Item key={index}>
                                    {field == "description" ? (
                                        <ReactQuill
                                            theme="snow"
                                            value={f.value}
                                            readOnly={true}
                                            modules={{ toolbar: false }}
                                        />
                                    ) : (
                                        <>
                                            {f.value}
                                        </>
                                    )}
                                    <div>
                                        <FontAwesomeIcon className="edit-btn" icon={faPencil} />
                                        <FontAwesomeIcon className="delete-btn" icon={faClose} onClick={() => removeRelationEvent(f.event, index)} />
                                    </div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                </Row>
            </Offcanvas.Body>
        </Offcanvas>
    )
};

export default ProductIdeaEventsOffcanvas;