import Form from 'react-bootstrap/Form';

const SelectFrameOrientation = ({
    orientation = 'vertical',
    setOrientation = () => { },
}) => {
    return (
        <Form>
            <Form.Group className="d-flex mt-2">
                <Form.Check
                    type="radio"
                    label="Vertical"
                    name="orientation"
                    value="vertical"
                    checked={orientation === 'vertical'}
                    onChange={() => setOrientation('vertical')}
                    className="me-2"
                />
                <Form.Check
                    type="radio"
                    label="Horizontal"
                    name="orientation"
                    value="horizontal"
                    checked={orientation === 'horizontal'}
                    onChange={() => setOrientation('horizontal')}
                />
            </Form.Group>
        </Form>
    );
};

export default SelectFrameOrientation;