import { Button, Form, Table, Modal } from 'react-bootstrap';
import './styles.scss';
import { useEffect, useState } from 'react';
import { updateDataAPI } from '../../../utils/query';

const BundleModal = ({ allProducts, bundle, closeModal }) => {
    const [searchKeyword, setSearchKeyword] = useState();
    const [matchProductIdeas, setMatchProductIdeas] = useState([]);
    const [ideas, setIdeas] = useState(bundle.bundleChildrenIdeas);

    useEffect(() => {
        if (searchKeyword) {
            filterByKeyword();
        }
    }, [searchKeyword]);

    const filterByKeyword = () => {
        const keywords = searchKeyword.toLowerCase().trim().split(' ');
        setMatchProductIdeas(allProducts.filter(p => {
            let found = false;
            for (let i = 0; i < keywords.length; i++) {
                if (p.name.toLowerCase().indexOf(keywords[i]) > -1 ||
                    (p.title && p.title.toLowerCase().indexOf(keywords[i]) > -1) ||
                    p.customID.toLowerCase().indexOf(keywords[i]) > -1) {
                    found = true;
                    break;
                }
            }
            return found;
        }));
    }

    const addProductIdeaToBundle = async (p) => {
        const res = await updateDataAPI('POST', 'resources/idea-bundles/', {
            bundle: bundle.id,
            idea: p.id
        });
        setIdeas(ideas.concat({
            title: p.title ? p.title : p.name,
            id: res.data['id'],
            customID: res.data['customID'],
        }));
    }

    const deleteProductIdeaFromBundle = async (id) => {
        await updateDataAPI('DELETE', 'resources/idea-bundles/' + id + '/');
        setIdeas(ideas.filter(idea => idea.id !== id));
    }

    return (
        <Modal className="bundle-modal" show={true} backdrop="static" keyboard={false}
            onHide={() => closeModal(ideas.length !== bundle.bundleChildrenIdeas.length ? 'update' : 'hide')} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{bundle.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                        <th></th>
                        <th>Code</th>
                        <th>Products</th>
                        <th>Subjects</th>
                        <th></th>
                    </thead>
                    <tbody>
                        {ideas.map((idea, ideaIndex) => (
                            <tr key={idea.id}>
                                <td className="col-index">{ideaIndex + 1}</td>
                                <td>{idea.customID}</td>
                                <td className="col-title">
                                    {idea.title}
                                </td>
                                <td>{idea.subjects}</td>
                                <td>
                                    <Button size="sm" variant="danger" onClick={() => deleteProductIdeaFromBundle(idea.id)}>Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Form className="add-more-product-form">
                    <h2>Add more product</h2>
                    <Form.Control value={searchKeyword} onChange={e => setSearchKeyword(e.target.value)} />
                    <Table striped bordered hover>
                        <thead>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Title</th>
                        </thead>
                        <tbody>
                            {matchProductIdeas.map((p) => (
                                <tr>
                                    <td>{p.customID}</td>
                                    <td>{p.name}</td>
                                    <td>{p.title}</td>
                                    <td>
                                        <Button size="sm" onClick={() => addProductIdeaToBundle(p)}>Add</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default BundleModal;