import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import './styles.scss';
import FilterByStore from '../../filters/store';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SearchIdeaInput from '../../filters/search-idea-input';
import Constant from '../../Constant';
import { hasPermission } from '../../utils/auth';

export const ResolveModal = ({ feedback = null, setSelectedFeedback = () => { } }) => {
    const { activityPermissions } = useSelector((state) => state.permissions);

    const [sources, setSources] = useState([]);
    const [updatedFeedback, setUpdatedFeedback] = useState({
        replyContent: feedback['replyContent'],
        resolved: feedback['resolved'],
        source: feedback['source'],
        productIdea: feedback['productIdea'],
        productData: feedback['productData'],
    });
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        fetchAllFeedbackSources();
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const fetchAllFeedbackSources = async () => {
        const res = await getDataFromAPI('product-dev/get-feedback-sources');
        setSources(res.data);
    }

    const handleCloseResolveModal = () => {
        setSelectedFeedback(null);
    }

    const handleReplyFeedback = async () => {
        const url = `product-dev/user-feedback/${feedback.id}/resolve-feedback/`;

        const res = await updateDataAPI('PATCH', url, updatedFeedback);

        if (res.status === 200) {
            toast.success('Resolved feedback.');
            setSelectedFeedback(null);
        } else {
            toast.error('An error occured.');
        };
    }

    const hanldeRemoveSelectedProductIdea = () => {
        setUpdatedFeedback((prev) => ({
            ...prev,
            productIdea: null,
            productData: null,
        }));
    }

    return (
        <div
            className="modal show"
            style={{ display: 'block', position: 'initial' }}
        >
            <Modal
                show={feedback !== null}
                onHide={handleCloseResolveModal}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Resolve user&apos;s feedback</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="mt-2">
                        <div dangerouslySetInnerHTML={{ __html: feedback.content }}></div>
                    </Row>
                    {sources.length > 0 && (
                        <Row className="mt-4">
                            <h5>Source</h5>
                            <Col>
                                <Form.Select
                                    value={updatedFeedback["source"]}
                                    onChange={(e) => setUpdatedFeedback((prev) => ({ ...prev, "source": e.target.value }))}
                                >
                                    {sources.map((resource, index) => (
                                        <option key={index} value={resource}>{resource}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                    )}
                    {feedback.rating && (
                        <Row className="mt-4">
                            <h5>RATING</h5>
                            <div>{feedback.rating}</div>
                        </Row>
                    )}
                    <Row className="mt-4">
                        <h5>Product</h5>
                        <Col>
                            {updatedFeedback['productData'] !== null ? (
                                <div>{updatedFeedback['productData']['customID']}. {updatedFeedback['productData']['title']}</div>
                            ) : (
                                <SearchIdeaInput
                                    store={feedback['store']}
                                    selectedIdea={(value) => {
                                        setUpdatedFeedback((prev) => (
                                            {
                                                ...prev,
                                                productIdea: value.id,
                                                productData: {
                                                    title: value.title,
                                                    customID: value.customID,
                                                }
                                            }
                                        ))
                                    }}
                                />
                            )}
                            {updatedFeedback["productData"] !== null && (
                                <Button variant="danger" size="sm" className="mt-2" onClick={hanldeRemoveSelectedProductIdea}>
                                    Clear
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Form className="feedback">
                            <h5>Reply Content</h5>
                            <Form.Control
                                as="textarea" rows={10}
                                value={updatedFeedback['replyContent']}
                                onChange={e => setUpdatedFeedback((prev) => ({ ...prev, replyContent: e.target.value }))}
                            />
                        </Form>
                    </Row>
                    {hasPermission(userRoles, activityPermissions['RESOLVE_FEEDBACK']) && (
                        <Row className="mt-4">
                            <Form>
                                <Form.Check
                                    value={updatedFeedback['resolved']}
                                    onChange={(e) => setUpdatedFeedback((prev) => ({ ...prev, resolved: e.target.checked }))}
                                    type="checkbox"
                                    label="Mark as resolved"
                                />
                            </Form>
                        </Row>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseResolveModal}
                    >Close</Button>
                    <Button
                        variant="primary"
                        onClick={handleReplyFeedback}
                    >Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

const UserFeedbacksPage = () => {
    const { selectedStoreID } = useSelector(state => state.filters);
    const { activityPermissions } = useSelector((state) => state.permissions);

    const [initialFeedbacks, setInitialFeedbacks] = useState([]);
    const [feedbacks, setFeedbacks] = useState([]);
    const [selectedUserFeedbackToSolve, setSelectedUserFeedbackToSolve] = useState(null);
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    useEffect(() => {
        fetchUnresolvedFeedbacks();
    }, [selectedUserFeedbackToSolve]);

    useEffect(() => {
        const filterFeedbacksByStore = () => {
            if (selectedStoreID !== null && selectedStoreID !== 'all') {
                const cloneFeedbacks = [...initialFeedbacks];
                const updatedFeedbacks = cloneFeedbacks.filter((feedback) => feedback.storeData.id === selectedStoreID);
                setFeedbacks(updatedFeedbacks);
            } else {
                setFeedbacks(initialFeedbacks);
            }
        };
        filterFeedbacksByStore();
    }, [selectedStoreID, initialFeedbacks]);

    const fetchUnresolvedFeedbacks = async () => {
        const res = await getDataFromAPI('product-dev/user-feedback/');
        setFeedbacks(res.data);
        setInitialFeedbacks(res.data);
    }

    return (
        <Container className="user-feedbacks-page" fluid>
            <Row>
                <Col>
                    Filter feedbacks by store:
                </Col>
            </Row>
            <Row>
                <Col>
                    <FilterByStore hasAllOption={true} />
                </Col>
            </Row>
            <Row>
                <Table striped bordered hover className="user-feedbacks-page__list">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Store</th>
                            <th>Product</th>
                            <th>Content</th>
                            <th>Source</th>
                            <th>Created at</th>
                            <th>Resolved at</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {feedbacks.map((feedback, index) => (
                            <tr key={index}>
                                <td>{feedback.id}</td>
                                <td>
                                    {hasPermission(userRoles, activityPermissions['VIEW_FEEDBACK_STORE_URL']) ? (
                                        <a href={feedback.storeData.url} target="_blank" rel="noreferrer">{feedback.storeData.name}</a>
                                    ) : (
                                        <span>{feedback.storeData.name}</span>
                                    )}
                                </td>
                                <td className="product-cell">
                                    {feedback['productData'] !== null && (
                                        <a href={'/ideas?selectedStoreID=' + feedback.store + '&searchKeyword=' + feedback['productData']['customID']} target="_blank" rel="noreferrer">
                                            {feedback['productData']['customID'] + '. ' + feedback['productData'].title}
                                        </a>
                                    )}
                                </td>
                                <td className="feedback-content-cell"><div dangerouslySetInnerHTML={{ __html: feedback.content }}></div></td>
                                <td>{feedback.source}</td>
                                <td>{feedback.createdAt}</td>
                                <td className={feedback.resolved ? '' : 'bg-danger'}>{feedback.resolvedAt}</td>
                                <td>
                                    <Button variant="primary" size="sm" onClick={() => setSelectedUserFeedbackToSolve(feedback)}>
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
            {selectedUserFeedbackToSolve !== null && (
                <ResolveModal
                    feedback={selectedUserFeedbackToSolve}
                    setSelectedFeedback={setSelectedUserFeedbackToSolve}
                />
            )}
        </Container>

    )
}

export default UserFeedbacksPage;