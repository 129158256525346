import { createSlice } from '@reduxjs/toolkit';

export const autoContentSlice = createSlice({
    name: 'autoContent',
    initialState: {
        newContentGeneratedPages: [],
        pageToScroll: null,
        ideaTemplateContents: {},
        ideaTemplateRawAIResponse: {},
        ideaSetting: {},
        ideaSkills: [],
        trainingTexts: [],
        ideaPageThemes: [],
        selectedTags: [],
        events: [],
    },
    reducers: {
        setNewContentGeneratedPages: (state, action) => {
            state.newContentGeneratedPages = state.newContentGeneratedPages.concat(action.payload);
        },
        setPageToScroll: (state, action) => {
            if (action.payload !== null) {
                state.newContentGeneratedPages = state.newContentGeneratedPages.filter(page => page !== action.payload);
            }
            state.pageToScroll = action.payload;
        },
        setIdeaTemplateContents: (state, action) => {
            state.ideaTemplateContents[action.payload['ideaTemplateID']] = {
                worksheet: { ...action.payload['worksheet'], headline: state.ideaSetting['headline'] },
                answerKey: action.payload['answerKey'],
                text: action.payload['text'],
                loading: action.payload['loading'] ? action.payload['loading'] : false,
                updated: action.payload['updated'] ? action.payload['updated'] : false,
            }
        },
        updateIdeaTemplateContent: (state, action) => {
            state.ideaTemplateContents[action.payload['ideaTemplateID']][action.payload['field']] = action.payload['value'];
        },
        updateHeadlineInAllContents: (state, action) => {
            const ideaTemplateIDs = Object.keys(state.ideaTemplateContents)
            for (let i = 0; i < ideaTemplateIDs.length; i++) {
                state.ideaTemplateContents[ideaTemplateIDs[i]]['worksheet']['headline'] = state.ideaSetting['headline'];
            }
        },
        setIdeaTemplateRawAIResponse: (state, action) => {
            state.ideaTemplateRawAIResponse[action.payload['ideaTemplateID']] = action.payload['content']
        },
        setIdeaSetting: (state, action) => {
            state.ideaSetting = action.payload;
        },
        updateIdeaSetting: (state, action) => {
            state.ideaSetting[action.payload['field']] = action.payload['value'];
        },
        setIdeaSkills: (state, action) => {
            state.ideaSkills = action.payload;
        },
        setTrainingTexts: (state, action) => {
            state.trainingTexts = action.payload;
        },
        addTrainingText: (state, action) => {
            const data = action.payload;
            if (!state.trainingTexts.some(text => text.id === data.id)) {
                state.trainingTexts.push(data);
            };
        },
        setIdeaPageThemes: (state, action) => {
            state.ideaPageThemes = action.payload;
        },
        addIdeaPageTheme: (state, action) => {
            const data = action.payload;
            if (!state.ideaPageThemes.some(theme => theme.id === data.id)) {
                state.ideaPageThemes.push(data);
            };
        },
        deleteIdeaSkill: (state, action) => {
            state.ideaSkills = state.ideaSkills.filter((skill) => skill.id !== action.payload);
        },
        addIdeaSkill: (state, action) => {
            const data = action.payload;
            if (!state.ideaSkills.some(skill => skill.id === data.id)) {
                state.ideaSkills.push(data);
            };
        },
        updateIdeaSkills: (state, action) => {
            const { skillID, field, newValue } = action.payload;
            state.ideaSkills = [...state.ideaSkills].map((skill) => {
                if (skill.id === skillID) {
                    return {
                        ...skill,
                        [field]: newValue
                    };
                };

                return skill;
            });
        },
        addTagToSelectedTags: (state, action) => {
            state.selectedTags = [...state.selectedTags, action.payload['tag']];
        },
        removeTagFromSelectedTags: (state, action) => {
            state.selectedTags = state.selectedTags.filter(selectedTag => selectedTag.id !== action.payload['id']);
        },
        emtpySelectedTags: (state) => {
            state.selectedTags = [];
        },
        setEvents: (state, action) => {
            state.events = action.payload;
        }
    },
})

export const {
    setNewContentGeneratedPages,
    setPageToScroll,
    setIdeaTemplateContents,
    updateIdeaTemplateContent,
    updateHeadlineInAllContents,
    setIdeaTemplateRawAIResponse,
    setIdeaSetting,
    updateIdeaSetting,
    setIdeaSkills,
    setIdeaPageThemes,
    addIdeaPageTheme,
    addIdeaSkill,
    deleteIdeaSkill,
    updateIdeaSkills,
    addTagToSelectedTags,
    removeTagFromSelectedTags,
    emtpySelectedTags,
    addNewFontFamily,
    setTrainingTexts,
    addTrainingText,
    setEvents,
} = autoContentSlice.actions;

export default autoContentSlice.reducer;