import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import AsyncSelectComponent from '../../utils/async-select';
import Constant from '../../Constant';
import './styles.scss';
import { updateDataAPI } from '../../utils/query';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addPerformanceTicket, updatePerformanceTicket } from '../../redux/staffSlice';
import moment from 'moment';

const initIdeaOptions = (ideas = []) => {
    return ideas.map((idea) => ({
        ...idea,
        value: idea.id,
        label: idea.name,
    }));
};

const PerformanceTicketForm = ({ performanceTicket, defaultData = [], handleSaveChange = () => {} }) => {
    const dispatch = useDispatch();
    const currentDate = new Date().toISOString().slice(0, 10);
    const [ticketDate, setTicketDate] = useState(performanceTicket?.datetimeTicket ? moment(performanceTicket.datetimeTicket).format('YYYY-MM-DD') : currentDate);
    const [createdIdeas, setCreatedIdeas] = useState(initIdeaOptions(performanceTicket?.createdIdeasData) ?? []);
    const [designDoneIdeas, setDesignDoneIdeas] = useState(initIdeaOptions(performanceTicket?.designDoneIdeasData) ?? []);
    const [checkDataDoneIdeas, setCheckDataDoneIdeas] = useState(initIdeaOptions(performanceTicket?.checkDataDoneIdeasData) ?? []);
    const [outlineIdeas, setOutlineIdeas] = useState(performanceTicket?.outlineIdeas ?? '');
    const [note, setNote] = useState(performanceTicket?.note ?? '');
    const [loading, setLoading] = useState(false);

    const checkEmptySubmitIdeas = () => {
        return [designDoneIdeas, createdIdeas, checkDataDoneIdeas].every((ideas) => ideas.length === 0);
    }

    const handleCreateNewPerformanceTicket = async (e) => {
        e.preventDefault();
        if (ticketDate === '' || checkEmptySubmitIdeas()) {
            toast.info('Please fill all required fields.');
            return;
        }

        if (window.confirm('Are you sure you want to submit this performance ticket? You can not edit after submit')) {
            setLoading(true);

            try {
                let url;

                const currentStaffId = localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID)
                const data = {
                    staff: performanceTicket?.staff ?? parseInt(currentStaffId),
                    datetimeTicket: ticketDate,
                    createdIdeas: createdIdeas.map((idea) => idea.id),
                    designDoneIdeas: designDoneIdeas.map((idea) => idea.id),
                    checkDataDoneIdeas: checkDataDoneIdeas.map((idea) => idea.id),
                    outlineIdeas,
                    note,
                };

                if (performanceTicket) {
                    url = `product-dev/performance-tickets/${performanceTicket.id}/`;
                    const res = await updateDataAPI('PATCH', url, data);
                    toast.success('Successfully updated performance ticket.');
                    dispatch(updatePerformanceTicket(res.data));
                    handleSaveChange(res.data);
                } else {
                    url = 'product-dev/performance-tickets/';
                    const res = await updateDataAPI('POST', url, data);

                    if (res.status === 201) {
                        toast.success('Successfully created new performance ticket.');
                        dispatch(addPerformanceTicket(res.data));
                    }
                }

                // Clear the form after successful submission
                setTicketDate(currentDate);
                setCreatedIdeas([]);
                setDesignDoneIdeas([]);
                setCheckDataDoneIdeas([]);
                setOutlineIdeas('');
                setNote('');
            } catch (err) {
                console.error('An error occured when creating new performance ticket: ', err?.response?.data);
                toast.error(`An error occured when creating new performance ticket: ${err?.response?.data?.message ?? err.message}`);
            }
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={handleCreateNewPerformanceTicket} className="performance-ticket-form">
            <Form.Group>
                <Form.Label>Date:</Form.Label>
                <Form.Control
                    type='date'
                    value={ticketDate}
                    onChange={(e) => setTicketDate(e.target.value)}
                />
                <Form.Text className="text-muted">
                    Select the datetime for ticket.
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>NEW IDEAS:</Form.Label>
                <AsyncSelectComponent
                    searchUrl={`product-dev/get-all-ideas-with-task-step-label/?taskStepPos=${Constant.TASK_FLOW_STEP_LABELS.LAYOUT_DONE}&customID=`}
                    onChange={selectedValue => setCreatedIdeas(selectedValue)}
                    value={createdIdeas}
                    setLoading={setLoading}
                    loading={loading}
                    clearSelection={() => setCreatedIdeas([])}
                    isMulti={true}
                    defaultData={defaultData ? defaultData : null}
                    labelId={'customID'}
                />
            </Form.Group>

            <Form.Group>
                <Form.Label>DATA DONE IDEAS:</Form.Label>
                <AsyncSelectComponent
                    searchUrl={`product-dev/get-all-ideas-with-task-step-label/?taskStepPos=${Constant.TASK_FLOW_STEP_LABELS.DATA_DONE}&customID=`}
                    onChange={selectedValue => setCheckDataDoneIdeas(selectedValue)}
                    value={checkDataDoneIdeas}
                    setLoading={setLoading}
                    loading={loading}
                    clearSelection={() => setCheckDataDoneIdeas([])}
                    isMulti={true}
                    defaultData={defaultData ? defaultData : null}
                    labelId={'customID'}
                />
            </Form.Group>

            <Form.Group>
                <Form.Label>DESIGN DONE IDEAS:</Form.Label>
                <AsyncSelectComponent
                    searchUrl={`product-dev/get-all-ideas-with-task-step-label/?taskStepPos=${Constant.TASK_FLOW_STEP_LABELS.DESIGN_DONE}&customID=`}
                    onChange={selectedValue => setDesignDoneIdeas(selectedValue)}
                    value={designDoneIdeas}
                    setLoading={setLoading}
                    loading={loading}
                    clearSelection={() => setDesignDoneIdeas([])}
                    isMulti={true}
                    defaultData={defaultData ? defaultData : null}
                    labelId={'customID'}
                />
            </Form.Group>

            <Form.Group controlId="outlineIdeas">
                <Form.Label>OUTLINE:</Form.Label>
                <Form.Control
                    as="textarea" rows={3}
                    value={outlineIdeas}
                    onChange={(e) => setOutlineIdeas(e.target.value)}
                />
                <Form.Text>Number of new ideas or what you've done in outline</Form.Text>
            </Form.Group>
            <Form.Group controlId="note">
                <Form.Label>Note or Other:</Form.Label>
                <Form.Control
                    as="textarea" rows={3}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />
            </Form.Group>
            {!loading && (
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            )}
        </Form>
    )
};

export default PerformanceTicketForm;