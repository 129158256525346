import { useState } from 'react';
import { updateDataAPI } from '../../../../utils/query';
import UploadTopicImageCell from '../../upload-topic-image-cell';
import './styles.scss';

const DataSetTopicIcon = ({
    topic,
    dataSet,
    dataSetIcons = [],
    searchingWordID,
    setSearchingWordID = () => { },
    updateTopicIcon = async () => { },
    handleChangeDataSetIcon = () => { },
    handleUpdatedTopic = () => { }
}) => {
    const [searchingWordIcons, setSearchingWordIcons] = useState(null);

    const searchIconsForWord = async (wordID, word) => {
        setSearchingWordIcons([]);
        word = word.trim();
        word = word.replace(/[^a-zA-Z\s]/g, '');
        console.log(word);
        setSearchingWordID(wordID);
        const res = await updateDataAPI('POST', 'auto-content/search-resources-by-keyword/', {
            keyword: word,
            limit: 40,
            offset: 1,
            typeResource: [5]
        });
        setSearchingWordIcons(res.data);
    }

    if (topic === null || topic === undefined) {
        return null;
    }

    return (
        <td>
            {topic && (
                <UploadTopicImageCell
                    dataSet={dataSet}
                    topic={topic}
                    dataSetIcon={dataSetIcons.find((dataSetIcon) => dataSetIcon.topic === topic.id)}
                    setDataSetIcon={handleChangeDataSetIcon}
                    handleUpdatedTopic={handleUpdatedTopic}
                    />
            )}
        </td>
    )
}

export default DataSetTopicIcon;