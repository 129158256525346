import { Badge, Button, Table } from 'react-bootstrap';
import './styles.scss';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import { useState } from 'react';
import BundleItemsModal from './bundle-items-modal';
import IdeaModal from '../../../pages/idea-list/idea-modal';

const ProductMapIdeasCell = ({ideas, userRoles, setLoading, refreshKeyword}) => {
    const [showBundleItemsModal, setShowBundleItemsModal] = useState(false);
    const [bundleInModal, setBundleInModal] = useState(null);
    const [ideaInModal, setIdeaInModal] = useState(null);

    const showIdeaModal = async(ideaID) => {
        const res = await getDataFromAPI('product-ideas/' + ideaID + '/?additionalFields=highlightImages');
        setIdeaInModal(res.data);
    }

    const deleteIdea = async(idea) => {
        if (window.confirm('Are you sure to delete this idea?')) {
            setLoading(true);
            await updateDataAPI('DELETE', 'product-ideas/' + idea.id + '/');
            refreshKeyword();
            setLoading(false);
        }
    }

    return (
        <Table striped bordered hover className="keyword-ideas-table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Thumbnail</th>
                    <th>Name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {ideas.map(idea => (
                    <tr key={idea.customID}>
                        <td>{idea.customID}</td>
                        <td>
                            <div className="idea-thumbnail">
                                <img src={idea.thumbnail} />
                            </div>
                        </td>
                        <td className="idea-info">
                            <a href={'/content-studio/?customID=' + idea.customID} target="_blank">{idea.customID}. {idea.name}</a>
                            <div>Grades: {idea.grades}</div>

                            <div className="tags">
                                {idea.tags.map(tag => (
                                    <Badge bg="secondary">{tag.type}: {tag.name}</Badge>
                                ))}
                                {userRoles.indexOf('admin') > -1 && (
                                    idea.url ? (
                                        <Badge bg="success">
                                            <a href={idea.url} target="_blank" rel="noreferrer">published</a>
                                        </Badge>
                                    ) : (
                                        <Badge bg="danger">{idea.status}</Badge>
                                    )
                                )}
                            </div>
                        </td>
                        <td>
                            <div className="btns">
                                {idea.bundle && (
                                    <Button size="sm" onClick={() => {
                                        setBundleInModal(idea);
                                        setShowBundleItemsModal(true);
                                    }}>Bundle Items</Button>
                                )}
                                <Button size="sm" onClick={() => showIdeaModal(idea.id)}>View Info</Button>
                                <Button size="sm" variant="danger" onClick={() => deleteIdea(idea)}>Delete</Button>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>

            {showBundleItemsModal && (
                <BundleItemsModal
                    bundle={bundleInModal}
                    userRoles={userRoles}
                    hideModal={() => {
                        setShowBundleItemsModal(false);
                        setBundleInModal(null);
                    }}
                />
            )}

            {ideaInModal !== null && (
                <IdeaModal
                    idea={ideaInModal}
                    defaultStore={ideaInModal.store}
                    closeModal={() => {
                        setIdeaInModal(null);
                    }}
                />
            )}
        </Table>
    );
}

export default ProductMapIdeasCell;