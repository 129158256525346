import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Container, Form, Spinner, Table } from 'react-bootstrap';
import Constant from '../../Constant';

import './styles.scss';
import CollectedResourceModal from '../../components/collected-resources/collected-resource-modal';

const CollectedResourcesPage = () => {
    const [resources, setResources] = useState([]);
    const [filteredQuality, setFilteredQuality] = useState('all');
    const [filteredTaken, setFilteredTaken] = useState('all');
    const [filteredIgnored, setFilteredIgnored] = useState('no');
    const [loading, setLoading] = useState(false);
    const [resourceInModal, setResourceInModal] = useState(null);

    useEffect(() => {
        const fetchResources = async() => {
            setLoading(true);
            const res = await axios.get(Constant.COLLECTED_RESOURCES_URL + 'collected-resources/');
            setResources(res.data);
            setLoading(false);
        }

        fetchResources();
    }, []);

    const updateResource = async(resource) => {
        setLoading(true);
        await axios.patch(Constant.COLLECTED_RESOURCES_URL + 'collected-resources/' + resource['_id'], resource);
        setResources(resources.map(r => {
            if (r['_id'] === resource['_id']) {
                return resource;
            }
            return r;
        }))
        setLoading(false);
    }

    return (
        <Container className="collected-resources-page">
            <div className="filters">
                <Form.Group>
                    <Form.Label>Quality</Form.Label>
                    <Form.Select value={filteredQuality} onChange={e => setFilteredQuality(e.target.value)}>
                        <option value="all">all</option>
                        <option value="high">high (from 20 pages)</option>
                        <option value="medium">medium (10 - 20 pages)</option>
                        <option value="low">low (1 - 10 pages)</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Taken</Form.Label>
                    <Form.Select value={filteredTaken} onChange={e => setFilteredTaken(e.target.value)}>
                        <option value="all">all</option>
                        <option value="yes">yes</option>
                        <option value="no">no</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Ignored</Form.Label>
                    <Form.Select value={filteredIgnored} onChange={e => setFilteredIgnored(e.target.value)}>
                        <option value="all">all</option>
                        <option value="yes">yes</option>
                        <option value="no">no</option>
                    </Form.Select>
                </Form.Group>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Preview</th>
                        <th>TPT</th>
                        <th>Nb Pages</th>
                        <th>Quality</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {resources.filter(resource => {
                        if (filteredQuality !== 'all' && resource['quality'] !== filteredQuality) {
                            return false;
                        }

                        if (filteredTaken !== 'all' && ((resource['taken'] && filteredTaken === 'no') || (!resource['taken'] && filteredTaken === 'yes'))) {
                            return false;
                        }

                        if (filteredIgnored !== 'all' && ((resource['ignored'] && filteredIgnored === 'no') || (!resource['ignored'] && filteredIgnored === 'yes'))) {
                            return false;
                        }

                        return true;
                    }).map((resource, index) => (
                        <tr key={resource['_id']}>
                            <td>{index + 1}</td>
                            <td>
                                <div>{resource['title']}</div>
                                <a target="_blank" rel="noreferrer" href={resource['originalUrl']}>
                                    {resource['originalUrl']}
                                </a>
                            </td>
                            <td>
                                {resource['tptProductUrl'] && (
                                    <a target="_blank" rel="noreferrer" href={resource['tptProductUrl']}>
                                        link
                                    </a>
                                )}
                            </td>
                            <td>{resource['nbPages']}</td>
                            <td>{resource['quality']}</td>
                            <td>
                                <div className="btns">
                                    {resource['taken'] ? (
                                        <Button size="sm" variant="danger" onClick={() => updateResource({...resource, taken: false})}>Untake</Button>
                                    ) : (
                                        <Button size="sm" variant="success" onClick={() => updateResource({...resource, taken: true})}>Take</Button>
                                    )}
                                    <Button size="sm" onClick={() => setResourceInModal(resource)}>Edit</Button>
                                    {resource['ignored'] ? (
                                        <Button size="sm" variant="danger" onClick={() => updateResource({...resource, ignored: false})}>UnIgnore</Button>
                                    ) : (
                                        <Button size="sm" variant="danger" onClick={() => updateResource({...resource, ignored: true})}>Ignore</Button>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {resourceInModal !== null && (
                <CollectedResourceModal
                    resource={resourceInModal}
                    hideModal={() => setResourceInModal(null)}
                    updateResource={(field, value) => {
                        updateResource({...resourceInModal, [field]: value});
                        setResourceInModal(null);
                    }}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    );
}

export default CollectedResourcesPage;