import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setResults } from "../../colorbycodeMakerSlice";
import { getDataFromAPI, updateDataAPI } from "../../../../utils/query";
import Form from "react-bootstrap/Form";

const TemplateItem = ({ template }) => {
    const dispatch = useDispatch();
    const { worksheet, results } = useSelector(state => state.generateProduct);
    const [isChecked, setIsChecked] = useState(results.some((obj) => obj.id === template.id));

    useEffect(() => {
        setIsChecked(results.some((obj) => obj.id === template.id))
    }, [results])

    useEffect(() => {
        const updateResultSelectedTemplates = async () => {
            if (worksheet !== undefined) {
                const url = `resources/product-ideas-color-by-code/${worksheet['id']}/list-all-templates/`;

                const res = await getDataFromAPI(url);

                if (res.status === 200) {
                    dispatch(setResults(res.data.templates));
                }
            }
        };

        updateResultSelectedTemplates();
    }, [isChecked, worksheet]);

    const handleTemplateInProducteIdeaColorByCode = async (event) => {
        const url = `resources/product-ideas-color-by-code/${worksheet['id']}/${isChecked ? 'remove-template' : 'add-template'}/`;
        const res = await updateDataAPI('POST', url, {
            templateId: template.id
        });

        if (res.status === 200 || res.status === 201) {
            setIsChecked((prev) => !prev);
        }
    };

    return (
        <div className="templates__item">
            <Form.Check
                type={'checkbox'}
                id={'default-checkbox'}
                checked={isChecked}
                className="templates__item__checkbox"
                onChange={handleTemplateInProducteIdeaColorByCode}
            />
            <img src={template.image} />
            <span className="templates__item__tags">
                Tags: {template.tags.join(', ')}
            </span>
        </div>
    )
}

export default TemplateItem;