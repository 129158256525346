import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { updateDataAPI } from '../../../utils/query';

import './styles.scss';

const AddContentTagModal = ({ content, contentType = 'template', hideModal, categories, refreshCategories, showParentOption = false }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTag, setSelectedTag] = useState(null);
    const [parentTag, setParentTag] = useState(null);
    const [showSelectParentOption, setShowSelectParentOption] = useState(showParentOption);

    const handleAddTag = async () => {
        if (selectedCategory && selectedTag) {
            try {
                console.log(selectedTag, content.tags);
                await updateDataAPI('PATCH', `auto-content/${contentType}s/` + content.id + '/', {
                    tags: content.tags.concat(selectedTag.value)
                });

                if (parentTag) {
                    await updateDataAPI('PATCH', 'auto-content/tags/' + selectedTag.value + '/', {
                        parent: parentTag.value
                    });
                }

                hideModal('refresh');
            } catch (error) {
                console.error('Error adding tag:', error);
            }
        }
    };

    const createNewTag = async (newTagName) => {
        newTagName = newTagName.charAt(0).toUpperCase() + newTagName.slice(1);
        const newTagRes = await updateDataAPI('POST', 'auto-content/tags/', {
            category: selectedCategory.value,
            name: newTagName
        });
        await refreshCategories();
        setSelectedTag({ value: newTagRes.data['id'], label: newTagName });
        setShowSelectParentOption(true);
    }

    return (
        <Modal
            show={true}
            onHide={hideModal}
            contentLabel="Add Tag Modal"
            className="add-content-tag-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Add Tag
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <label>Select a Category:</label>
                    <Select
                        options={categories.map(category => ({ value: category.id, label: category.name }))}
                        value={selectedCategory}
                        onChange={val => {
                            setSelectedCategory(val);
                        }}
                    />
                </div>
                {selectedCategory && (
                    <div>
                        <div className="mb-4">
                            <label>Select or Create a Tag:</label>
                            <CreatableSelect
                                options={selectedCategory.value ? categories.find(c => c.id === selectedCategory.value).tags.map(tag => ({ value: tag.id, label: tag.name })) : []}
                                value={selectedTag}
                                onChange={val => setSelectedTag(val)}
                                onCreateOption={val => createNewTag(val)}
                                isClearable={true}
                            />
                        </div>
                        {showSelectParentOption && (
                            <div>
                                <label>Parent (optional):</label>
                                <Select
                                    options={selectedCategory.value ? categories.find(c => c.id === selectedCategory.value).tags.filter(tag => tag.id !== selectedTag?.value).map(tag => ({ value: tag.id, label: tag.name })) : []}
                                    value={parentTag}
                                    onChange={val => setParentTag(val)}
                                />
                            </div>
                        )}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleAddTag}>Add Tag</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddContentTagModal;