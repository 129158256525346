import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getRandomItemsFromArray } from "../../../../utils/commonUtil";
import { updateDataAPI } from "../../../../utils/query";
import { toast } from "react-toastify";
import { setWorksheet } from "../../colorbycodeMakerSlice";

export const MAXIMUM_CHOICES = 3;

export const DistrubutionColorsCollections = ({ color, index }) => {
    const dispatch = useDispatch();
    const { selectedPage, collections, worksheet, currentStep } = useSelector(state => state.generateProduct);
    const [selectedCollections, setSelectedCollections] = useState([]);

    const [choiceCollections, setChoiceCollections] = useState([]);

    useEffect(() => {
        const resetChoices = () => {
            let initialValue = [];
            if (worksheet['collections'].hasOwnProperty(selectedPage['nbPage'])) {
                const distrubutionCollections = worksheet['collections'][selectedPage['nbPage']];
                if (distrubutionCollections.hasOwnProperty(color)) {
                    const data = distrubutionCollections[color];
                    const values = Array.from(new Set(data.map(item => item[4])));
                    const choices = collections.map((item) => ({ "value": item.operations, "label": item.name }));
                    initialValue = choices.filter((collection) => values.includes(collection.label));
                }
            }
            setSelectedCollections(initialValue);
        };
        resetChoices();
    }, [selectedPage, collections, currentStep]);

    useEffect(() => {
        const updateChoiceCollections = () => {
            if (collections === undefined) return;
            const data = collections.map((item) => ({ "value": item.operations, "label": item.name }));
            setChoiceCollections(data);
        };

        updateChoiceCollections();
    }, [selectedPage, collections, currentStep]);

    const hanldeSelectCollections = (value) => {
        if (value.length > MAXIMUM_CHOICES) return;
        let data = [];
        setSelectedCollections(value);
        let nbCellsWithColor = selectedPage["coordinate"][color]["cells"].length;
        const nbSelectedCollections = value.length;
        const nbOperationsPerCollections = Math.floor(nbCellsWithColor / nbSelectedCollections);
        for (let i = 0; i < nbSelectedCollections; i++) {
            let nbOperations;
            if (i === nbSelectedCollections - 1) {
                nbOperations = nbCellsWithColor - nbOperationsPerCollections * i;
            } else {
                nbOperations = nbOperationsPerCollections;
            }

            const operations = getRandomItemsFromArray(value[i]['value'], nbOperations);

            const item = operations.map((operation, index) => ([
                operation['operation'],
                operation['result'],
                color,
                'text',
                value[i].label,
            ]));

            data.push(...item);
        };

        const cloneWorksheet = JSON.parse(JSON.stringify(worksheet));

        if (!cloneWorksheet['collections'].hasOwnProperty(selectedPage['nbPage'])) {
            cloneWorksheet['collections'][selectedPage['nbPage']] = {};
        };

        dispatch(setWorksheet({
            ...cloneWorksheet,
            collections: {
                ...cloneWorksheet['collections'],
                [selectedPage['nbPage']]: {
                    ...cloneWorksheet['collections'][selectedPage['nbPage']],
                    [color]: data
                }
            }
        }))
    };


    return (
        <Row>
            <div>{index + 1}. {color} ({selectedPage.coordinate[color].cells.length} operations.) </div>
            <Select
                isMulti={true}
                options={choiceCollections}
                onChange={hanldeSelectCollections}
                value={selectedCollections}
            />
        </Row>
    )
}

const MakeWorksheets = () => {
    const { selectedPage, worksheet } = useSelector(state => state.generateProduct);

    const handleSaveChoices = async () => {
        const url = `resources/product-ideas-color-by-code/${worksheet['id']}/update-collections/`;

        const res = await updateDataAPI('POST', url, {
            collections: worksheet['collections']
        });

        if (res.status === 200) {
            toast.info("Saved choices successfully.");
        } else {
            toast.error("An error occured.");
        };
    };

    return (
        <Container>
            {Object.keys(selectedPage).length === 0 ? (
                <h1>Please select one of the pages</h1>
            ) : (
                <Container>
                    {Object.keys(selectedPage.coordinate).map((color, index) => (
                        <DistrubutionColorsCollections key={index} color={color} index={index} />
                    ))}
                    <Button
                        className="mt-3"
                        onClick={handleSaveChoices}
                    >
                        Save choices
                    </Button>
                </Container>
            )}
        </Container>
    )
};

export default MakeWorksheets;