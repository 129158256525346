import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { updateDataAPI } from '../../../../utils/query';

const RefreshDataFromDataSetModal = ({page, refreshPage, hideModal}) => {
    const [dataSetID, setDataSetID] = useState(page.content.aiResponse['dataSet'] ? page.content.aiResponse['dataSet'] : null);
    const [rowIndex, setRowIndex] = useState(null);

    const refresh = async() => {
        try {
            await updateDataAPI('POST', 'auto-content/refresh-data-from-dataset/', {
                rowIndex,
                dataSetID,
                ideaTemplateID: page.content.id,
            });
        } catch (err) {
            window.alert('error');
        }

        refreshPage(page.id);
        hideModal();
    }

    return (
        <Modal show={true} size="xl" onHide={hideModal} className="refresh-data-from-dataset-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Refresh Data From DataSet
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>DataSet ID</Form.Label>
                            <Form.Control value={dataSetID} onChange={e => setDataSetID(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Row Index</Form.Label>
                            <Form.Control value={rowIndex} onChange={e => setRowIndex(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={refresh}>Refresh</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RefreshDataFromDataSetModal;