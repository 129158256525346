import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../../utils/query';

import './styles.scss';

const TaskCardTemplates = ({ }) => {
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        fetchTemplates();
    }, []);

    const fetchTemplates = async () => {
        const res = await getDataFromAPI('resources/taskcard-templates/');
        setTemplates(res.data);
    }

    return (
        <div className="taskcard-templates-container">
            {templates.map(template => (
                <div className={'template ' + template.type + '-template'}>
                    <div className="template-thumbnails">
                        {template.type === 'taskcard' ? (
                            <>
                                <img src={template.colorThumbnailUrl} />
                                <img src={template.bwThumbnailUrl} />
                            </>
                        ) : (
                            <img src={template.answerSheetThumbnailUrl} />
                        )}
                    </div>
                    <div className="template-name">{template.name}</div>
                    <div className="template-links">
                        {template.type === 'taskcard' ? (
                            <>
                                <a href={template.colorTemplateUrl} target="_blank">Color Template Link</a>
                                <a href={template.bwTemplateUrl} target="_blank">BW Template Link</a>
                            </>
                        ) : (
                            <a href={template.answerSheetTemplateUrl} target="_blank">Answer Sheet Template Link</a>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default TaskCardTemplates;