import { useState } from 'react';
import { updateDataAPI } from '../../../../utils/query';
import AddContentTagModal from '../../add-content-tag-modal';
import { Button } from 'react-bootstrap';

import './styles.scss';
import TagsList from '../tags-list';

const ContentTags = ({ tags, content, contentType = 'template', categories, updateTagsInContents, refreshContents, refreshCategories }) => {
    const [showAddTemplateModal, setShowAddTemplateModal] = useState(null);

    const deleteTag = async (tagID) => {
        const res = await updateDataAPI('PATCH', `auto-content/${contentType}s/` + content.id + '/', {
            tags: tags.filter(t => t.id !== tagID).map(t => t.id)
        });
        await refreshCategories();
        updateTagsInContents(content.id, res.data);
    }

    return (
        <div className="content-tags">
            <TagsList 
                tagsList={tags}
                handleRemoveTagFromList={deleteTag}
            />
            <Button onClick={() => setShowAddTemplateModal(true)} size="sm" variant="secondary">+</Button>

            {showAddTemplateModal && (
                <AddContentTagModal
                    content={content}
                    contentType={contentType}
                    categories={categories}
                    refreshCategories={refreshCategories}
                    hideModal={action => {
                        if (action === 'refresh') {
                            refreshContents();
                        }
                        setShowAddTemplateModal(false);
                    }} />
            )}
        </div>
    );
}

export default ContentTags;