import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './styles.scss';

const EnlargePreviewImage = ({ url, setShowEnlargePreview }) => {
    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            onHide={() => setShowEnlargePreview(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Preview template
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <img src={url} alt="preview" className="enlarged-image" />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowEnlargePreview(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EnlargePreviewImage;