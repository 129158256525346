import { Button, Col, Form, Row } from 'react-bootstrap';
import { updateDataAPI } from '../../../utils/query';
import { useState } from 'react';
import TaskCardTable from '../taskcard-table';

const EMPTY_PATTERN = { label: '', sign: '' };

const InsertDataTaskCard = ({ result, selectedPartID, setDisplayMode, customID }) => {
    const [insertText, setInsertText] = useState();
    const [patterns, setPatterns] = useState([EMPTY_PATTERN]);
    const [insertData, setInsertData] = useState(null);

    const changePatternItem = (patternIndex, itemName, itemValue) => {
        setPatterns(patterns.map((p, index) => {
            if (index !== patternIndex) {
                return p;
            }
            return { ...p, [itemName]: itemValue };
        }))
    }

    const format = async () => {
        try {
            setInsertData(null);
            const res = await updateDataAPI('POST', 'resources/format-chat-ai-content/', {
                text: insertText,
                patterns,
            });
            if (res.data.length > 0) {
                const insertData = [];
                let rowData = [];
                for (let i = 0; i < patterns.length; i++) {
                    rowData.push(patterns[i].label);
                }
                insertData.push(rowData);
                for (let i = 0; i < res.data.length; i++) {
                    rowData = [];
                    for (let j = 0; j < patterns.length; j++) {
                        rowData.push(res.data[i][patterns[j].label]);
                    }
                    insertData.push(rowData);
                }
                setInsertData(insertData);
            } else {
                alert('Can not format text');
            }
        } catch (err) {
            alert('Can not format text');
        }
    }

    const submitInsertData = async () => {
        try {
            let data;
            if (result) {
                data = result.concat(insertData.slice(1));
            } else {
                data = insertData;
            }
            await updateDataAPI('POST', 'resources/convert-json-to-taskCardCSV/?taskCardPart=' + selectedPartID, data);
            alert('submit successfully');
            setDisplayMode('taskcard');
            setInsertData(null);
            setInsertText('');

            if (result === null) {
                // freeze patterns
                await updateDataAPI('PATCH', 'resources/taskcard-parts/' + selectedPartID + '/', {
                    patterns,
                });
            }
        } catch (err) {
            alert('error', err);
        }
    }

    return (
        <Form>
            <Row>
                <Col>
                    <Form.Control as="textarea" rows="100" value={insertText} onChange={e => setInsertText(e.target.value)} />
                </Col>
                <Col>
                    <h2>Patterns</h2>
                    {patterns.map((pattern, index) => (
                        <Row key={index} className="mb-2">
                            <Col>
                                <Form.Control type="text" placeholder="column name" value={pattern['label']} onChange={e => changePatternItem(index, 'label', e.target.value)} />
                            </Col>
                            <Col>
                                <Form.Control type="text" as="textarea" rows="2" placeholder="identification sign" value={pattern['sign']} onChange={e => changePatternItem(index, 'sign', e.target.value)} />
                            </Col>
                            <Col>
                                <Button variant="danger" size="sm" onClick={() => setPatterns(patterns.filter((p, pIndex) => pIndex !== index))}>Delete</Button>
                            </Col>
                        </Row>
                    ))}
                    <div className="btns">
                        <Button variant="info" onClick={() => setPatterns(patterns.concat(EMPTY_PATTERN))}>Add pattern</Button>
                        <Button onClick={format}>Format</Button>
                    </div>

                    {insertData !== null && (
                        <div>
                            <TaskCardTable customID={customID} data={insertData} />
                            <Button variant="success" onClick={submitInsertData}>Insert</Button>
                        </div>
                    )}
                </Col>
            </Row>
        </Form>
    );
}

export default InsertDataTaskCard;