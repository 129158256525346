import { useEffect, useState } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import TemplateList from '../templates-management/template-list';

import './styles.scss';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import GPTPartModal from './gpt-part-modal';

export const generateTemplatesFromPages = (pages = []) => {
    const templates = [];
    for (let i = 0; i < pages.length; i++) {
        const template = pages[i].content.templateData;
        if (template && templates.find(t => t.id === template.id) === undefined) {
            templates.push(template);
        }
    }
    return templates;
}

const IdeaTemplatesModal = ({idea, pages, hideModal}) => {
    const [templates, setTemplates] = useState([]);
    const [gptParts, setGPTParts] = useState([]);
    const [edittingGPTPart, setEdittingGPTPart] = useState(null);
    const [showGPTPartModal, setShowGPTPartModal] = useState(false);

    useEffect(() => {
        setTemplates(generateTemplatesFromPages(pages || []));
    }, []);

    useEffect(() => {
        fetchIdeaParts();
    }, []);

    const fetchIdeaParts = async() => {
        const res = await getDataFromAPI(`product-dev/gpt-parts/?customID=${idea.customID}`);
        setGPTParts(res.data);
    }

    const deletePart = async(partID) => {
        await updateDataAPI('DELETE', `product-dev/gpt-parts/${partID}/`);
        fetchIdeaParts();
    }

    return (
        <Modal show={true} onHide={hideModal} className="idea-templates-modal" size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    All Templates
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TemplateList
                    customID={idea.customID}
                    templates={templates}
                    setTemplates={setTemplates}
                    showTemplateActions={true}
                />
                <div className="gpt-parts-header">
                    <h2>GPT Requests</h2>
                    <Button size="sm" onClick={() => {
                        setEdittingGPTPart(null);
                        setShowGPTPartModal(true);
                    }}>Add New</Button>
                </div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Template</th>
                            <th>Chat URL</th>
                            <th>Account</th>
                            <th>Generate Message</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {gptParts.map((part) => (
                            <tr key={part.id}>
                                <td>{part.template}</td>
                                <td className="chat-url">
                                    <a href={part.chatUrl} target="_blank">{part.chatUrl}</a>
                                </td>
                                <td>{part.bot}</td>
                                <td className="generate-message">
                                    <Form.Control as="textarea" rows={10} value={part.generateMessage} disabled />
                                </td>
                                <td>
                                    <Button size="sm" onClick={() => {
                                        setEdittingGPTPart(part);
                                        setShowGPTPartModal(true);
                                    }}>Edit</Button>
                                    <Button variant="danger" size="sm" onClick={() => deletePart(part.id)}>Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>

            {showGPTPartModal && (
                <GPTPartModal
                    ideaID={idea.id}
                    part={edittingGPTPart}
                    templates={templates}
                    hideModal={(action, data) => {
                        if (action === 'refresh') {
                            fetchIdeaParts();
                        }
                        setEdittingGPTPart(null);
                        setShowGPTPartModal(false);
                    }}
                />
            )}
        </Modal>
    );
}

export default IdeaTemplatesModal;