import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import { updateIdeaSkills } from '../../../auto-content/autoContentSlice';
import { toast } from 'react-toastify';
import { updateDataAPI } from '../../../../utils/query';
import { useDispatch } from 'react-redux';
import ListSkills from './list-skills';
import NewIdeaSkillInput from './new-idea-skill-input';
import './styles.scss';

const IdeaSkillSelect = ({ idea, refresh = () => {}, setShowNonAIGenerateModal= () => { }, setSelectedIdeaSkill = () => { } }) => {
    const dispatch = useDispatch();
    const { ideaSkills } = useSelector((state) => state.autoContent);
    const [skillName, setSkillName] = useState('');
    const [skillDetails, setSkillDetails] = useState('');
    const [showSelectSkillModal, setShowSelectSkillModal] = useState(false);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [listAvailableSkills, setListAvailableSkills] = useState([]);

    useEffect(() => {
        const initListSkills = () => {
            const outputValues = []
            ideaSkills.forEach((skill) => {
                if (skill['name'] !== skillName && skill['children'].length === 0) {
                    const newValue = {
                        ...skill,
                        label: skill['name'],
                        value: skill['name'],
                    };

                    outputValues.push(newValue);
                };
            });

            setListAvailableSkills(outputValues);
        };

        initListSkills();
    }, [ideaSkills, skillName])

    const handleCreateNewGroupSkill = async (e) => {
        const currentGroupSkill = ideaSkills.filter((skill) => skill.name === skillName);

        if (currentGroupSkill.length > 0) {
            const url = `resources/idea-skills/${currentGroupSkill[0]['id']}/`;

            const newSkills = selectedSkills.map((skill) => skill.id);

            try {
                await updateDataAPI('PATCH', url, {
                    children: newSkills,
                    name: skillName,
                    details: skillDetails,
                });
                refresh(['skills']);
                toast.success('Updated idea group skills successfully.');
                setSkillName('');
                setSelectedSkills([]);
                handleCloseSelectSkillsModal();
            } catch (e) {
                toast.error(`An error occured ${e.message}`);
            }
        }
    };

    const addNewIdeaSkill = async (newIdeaSkill) => {
        const url = 'resources/idea-skills/';

        try {
            const res = await updateDataAPI('POST', url, newIdeaSkill);
            refresh(['skills']);
            toast.success('Created successfully new idea skill.');
        } catch (e) {
            toast.error(`An error occured ${e.message}`);
        }
    };

    const handleIdeaSkillChange = (field, value) => {
        if (field === 'name') {
            setSkillName(value);
        } else {
            setSkillDetails(value);
        }
    }

    const handleAddIdeaSkillSubmit = async (e) => {
        e.preventDefault();
        if (skillName.trim() !== '') {
            const newIdeaSkill = {
                name: skillName,
                details: skillDetails,
                idea: idea['id'],
            };
            try {
                await addNewIdeaSkill(newIdeaSkill);
                setSkillName('');
                setSkillDetails('');
            } catch (e) {
                toast.error(`An error occured ${e.message}`);
            }
        };
    };

    const handleEditIdeaSkill = (skill) => {
        setShowSelectSkillModal(true);
        setSkillName(skill.name);
        setSkillDetails(skill.details);
        let groupSkillChildren = ideaSkills.filter((ideaSkill) => skill['children'].includes(ideaSkill['id']));
        const defaultValues = groupSkillChildren.map((skill) => ({
            ...skill,
            label: skill['name'],
            value: skill['name'],
        }));
        setSelectedSkills(defaultValues);
    };

    const handleCloseSelectSkillsModal = () => {
        setShowSelectSkillModal(false);
        setSkillName('');
        setSkillDetails('');
    };

    const handleSelectSkill = (values) => {
        setSelectedSkills(values);
    };

    const handleEditSkillField = (field, newValue) => {
        const fieldMappings = {
            name: { state: skillName, setState: setSkillName },
            details: { state: skillDetails, setState: setSkillDetails },
        };

        const currentGroupSkill = ideaSkills.find((skill) => skill.name === skillName);

        if (currentGroupSkill) {
            dispatch(
                updateIdeaSkills({
                    skillID: currentGroupSkill.id,
                    field,
                    newValue,
                })
            );

            const { setState } = fieldMappings[field];
            setState(newValue);
        };
    };

    return (
        <div className="idea-skill-select">
            <h3>Skills</h3>
            <NewIdeaSkillInput
                skillName={skillName}
                skillDetails={skillDetails}
                typeForm='group'
                handleSkillChange={handleIdeaSkillChange}
                handleSkillSubmit={handleAddIdeaSkillSubmit}
            />
            <ListSkills
                skills={ideaSkills}
                handleEditIdeaSkill={handleEditIdeaSkill}
                setInputSkill={setSkillName}
                refresh={refresh}
                setShowNonAIGenerateModal={setShowNonAIGenerateModal}
                setSelectedIdeaSkill={setSelectedIdeaSkill}
            />
            {showSelectSkillModal && (
                <Modal show={true} onHide={handleCloseSelectSkillsModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit skills</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Control
                                type="text"
                                className="mb-2"
                                placeholder="Skill name"
                                value={skillName}
                                onChange={e => handleEditSkillField('name', e.target.value)}
                            />
                            <Form.Control
                                type="text"
                                className="mb-2"
                                placeholder="Skill details"
                                value={skillDetails}
                                onChange={e => handleEditSkillField('details', e.target.value)}
                            />
                            <Select
                                isMulti
                                name="skills"
                                options={listAvailableSkills}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={handleSelectSkill}
                                value={selectedSkills}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseSelectSkillsModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleCreateNewGroupSkill}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default IdeaSkillSelect;