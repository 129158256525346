import { useDispatch } from 'react-redux';
import { setSelectedTag } from '../filtersSlice';
import './styles.scss';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../utils/query';
import Select from 'react-select';

const FilterByTag = ({ type }) => {
    const dispatch = useDispatch();
    const [tags, setTags] = useState([]);

    useEffect(() => {
        fetchTags();
    }, []);

    const fetchTags = async () => {
        const res = await getDataFromAPI('resources/tags/' + (type ? '?types=' + type : ''));
        setTags([{ label: 'All Tags', value: 'all' }].concat(res.data.map(tag => ({ label: tag.name, value: tag.id }))));
    }

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    return (
        <div className="filter-by-tag">
            <Select
                className="select-container"
                closeMenuOnSelect={false}
                defaultValue={[]}
                options={tags}
                placeholder="Select Tag"
                onChange={selectedValue => {
                    dispatch(setSelectedTag(selectedValue.value !== 'all' ? parseInt(selectedValue.value) : null));
                }}
                formatGroupLabel={formatGroupLabel} />
        </div>
    );
}

export default FilterByTag;