import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './styles.scss';
import Result from '../../components/colorbycode-maker/result';
import TemplateContainer from '../../components/colorbycode-maker/container';
import ProgressStep from '../../components/colorbycode-maker/progress-step';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { setTemplates, setWorksheet, setCollections } from '../../components/colorbycode-maker/colorbycodeMakerSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const ColorByCodeMakerPage = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const customID = (new URLSearchParams(window.location.search)).get('customID');

    useEffect(() => {
        const fetchAllAvailableTemplates = async () => {
            setLoading(true);
            try {
                const res = await getDataFromAPI('product-dev/list-all-available-color-by-code-template/');
                dispatch(setTemplates(res.data));
            } catch (e) {
                toast.warn(`An error occured ${e.message}`)
            };
            setLoading(false);
        };

        fetchAllAvailableTemplates();
    }, []);

    useEffect(() => {
        const getAllCollectionOperations = async () => {
            const url = 'product-dev/collection-operations/list-all-collection-operations/';
            const res = await getDataFromAPI(url);
            if (res.status === 200) {
                dispatch(setCollections(res.data));
            }
        };

        getAllCollectionOperations();
    }, []);

    useEffect(() => {
        const createOrGetProductIdeaColorByCode = async () => {
            const url = 'resources/product-ideas-color-by-code/create-or-get-product-idea-color-by-code/';
            const res = await updateDataAPI('POST', url, {
                customID: customID
            });

            if (res.status === 200 || res.status === 201) {
                dispatch(setWorksheet(res.data));
            }
        };
        createOrGetProductIdeaColorByCode();
    }, []);

    return (
        <Container className="colorbycode-maker-container" fluid>
            <Row>
                <Col xs lg="10">
                    <Row>
                        <ProgressStep />
                    </Row>
                    <Row>
                        <TemplateContainer customID={customID} />
                    </Row>
                </Col>
                <Col xs lg="2">
                    <Result />
                </Col>
            </Row>

            {
                loading && (
                    <div className="loading-container">
                        <Spinner animation="border" variant="dark" />
                    </div>
                )
            }
        </Container>
    )
}

export default ColorByCodeMakerPage;