import React, { Fragment } from 'react';
import TagsTreeFilter from '../../tags-tree-filter';
import { useDispatch } from 'react-redux';
import { removeTagFromSelectedTags } from '../../autoContentSlice';
import Form from 'react-bootstrap/Form';
import TagsList from '../tags-list';

const LeftContent = ({
    categories = [],
    setCategories = () => { },
    selectedTags = [],
    showOnlyActiveCollections = false,
    setShowOnlyActiveCollections = () => { },
    showOnlyActiveTemplates = false,
    setShowOnlyActiveTemplates = () => { },
    activeTab = 'template',
    filterNoCategories = [],
    fetchAllCategories = () => { },
    toggleFilterNoCategoriesList = () => { },
}) => {
    const dispatch = useDispatch();

    return (
        <Fragment>
            {activeTab === 'template' && (
                <Form.Check
                    type="checkbox"
                    label="Active templates"
                    id="Show-only-active-templates"
                    className="active-templates-filter-checkbox"
                    onChange={(event) => setShowOnlyActiveTemplates(event.target.checked)}
                    checked={showOnlyActiveTemplates}
                />
            )}
            {activeTab === 'collection' && (
                <Form.Check
                    type="checkbox"
                    label="Active collections"
                    id="show-only-active-collections"
                    className="active-collections-filter-checkbox"
                    onChange={(event) => setShowOnlyActiveCollections(event.target.checked)}
                    checked={showOnlyActiveCollections}
                />
            )}
            {selectedTags.length > 0 && (
                <div>
                    <h4>Selected</h4>
                    {categories.map((category) => {
                        const filteredTags = selectedTags.filter(
                            (tag) => tag.categoryName === category.name
                        );

                        if (filteredTags.length > 0) {
                            return (
                                <div key={category.id}>
                                    <h4>{category.name}</h4>
                                    <TagsList
                                        tagsList={filteredTags}
                                        handleRemoveTagFromList={(tagID) =>
                                            dispatch(removeTagFromSelectedTags({ id: tagID }))
                                        }
                                        showCategoryName={false}
                                    />
                                </div>
                            );
                        }

                        return null;
                    })}
                </div>
            )}
            {categories.map(category => (
                <div key={category.id}>
                    <h4>{category.name}</h4>
                    <TagsTreeFilter
                        disabledFilter={filterNoCategories.some((cat) => cat.name === category.name)}
                        setCategories={setCategories}
                        category={category}
                        tags={category.tags}
                        categories={categories}
                        parentId={null}
                        fetchAllCategories={fetchAllCategories}
                    />
                    <Form.Check
                        type="checkbox"
                        label={`No ${category.name}`}
                        id={'no-category-' + category.id + '-filter'}
                        className="no-cat-filter-checkbox"
                        onChange={() => toggleFilterNoCategoriesList(category)}
                        checked={filterNoCategories.some(cat => cat.id === category.id)}
                    />
                </div>
            ))}
        </Fragment>
    );
};

export default LeftContent;