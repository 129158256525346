import React from 'react'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

const SearchBar = ({
    query,
    handleChangeQuery = () => { },
    handleSearchByQuery = () => { },
}) => {
    return (
        <div>
            <Form onSubmit={handleSearchByQuery}>
                <InputGroup className="mb-3">
                    <Form.Control value={query} type="text" onChange={handleChangeQuery} placeholder="search keyword" />
                    <Button type="submit">Search</Button>
                </InputGroup>
            </Form>
        </div>
    )
}

export default SearchBar;