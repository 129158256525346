import { Button, Modal, Form, Dropdown, ButtonGroup } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIdeaTemplateContents, updateIdeaTemplateContent } from '../autoContentSlice';
import ZonesAndAIResponseModal from '../template/zones-and-ai-response-modal';
import { updateDataAPI } from '../../../utils/query';
import Constant from '../../../Constant';
import Moment from 'react-moment';

import './styles.scss';
import WorksheetMakerIframeModal from '../worksheet-maker-iframe-modal';
import moment from 'moment';
import AnswerKeyResult from './answer-key-result';
import { hasPermission } from '../../../utils/auth';
import ContentHistoryVersionsModal from './content-history-versions-modal';
import ImageWrapper from '../../common/image-wrapper';
import LiteCanvas from '../lite-canvas';
import { warningUserWhenPageIsValidate } from '../../../utils/autoContentUtils';
import RefreshDataFromDataSetModal from './refresh-data-from-dataset-modal';

const AutoContentResultCell = ({
    page,
    setSelectedPages = () => {},
    ideaTemplate,
    pageResultDisplayMode,
    refreshPage,
    setLoading,
    handleShowReportWrongContentModal = () => {},
}) => {
    const dispatch = useDispatch();

    const content = useSelector(state => state.autoContent.ideaTemplateContents[ideaTemplate.id]);
    const { activityPermissions } = useSelector((state) => state.permissions);

    const pageWidth = ideaTemplate.templateData.orientation === 'vertical' ? Constant.VERTICAL_PAGE_WIDTH : Constant.VERTICAL_PAGE_HEIGHT;
    const [showWorksheetMakerIframeModal, setShowWorksheetMakerIframeModal] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [showZonesAndAIResponseModal, setShowZonesAndAIResponseModal] = useState(false);
    const [showContentHistoryVersionsModal, setShowContentHistoryVersionsModal] = useState(false);
    const [showExampleModal, setShowExampleModal] = useState(false);
    const [showRefreshDataFromDataSetModal, setShowRefreshDataFromDataSetModal] = useState(false);

    // useEffect(() => {
    //     function handleWorksheetCanvasMessage(event) {
    //         if (event.origin === process.env.REACT_APP_WORKSHEET_MAKER_URL.replace(/\/$/, '')) {
    //             const data = event.data;
    //             if (data.type === Constant.EXPORTED_IFRAME_STATUS & data.targetID === ideaTemplate.id) {
    //                 console.log('Received message:', data.message);
    //                 refreshPage(page.id);
    //             }
    //         }
    //     }

    //     window.addEventListener('message', handleWorksheetCanvasMessage, false);

    //     return () => {
    //         window.removeEventListener('message', handleWorksheetCanvasMessage);
    //     };
    // }, []);

    useEffect(() => {
        dispatch(setIdeaTemplateContents({
            ...ideaTemplate.content,
            ideaTemplateID: ideaTemplate.id,
        }));
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    useEffect(() => {
        if (ideaTemplate.lastExportTime === null && ideaTemplate.lastAiResponseUpdate !== null) {
            console.log('update iframe 2');
            dispatch(updateIdeaTemplateContent({
                ideaTemplateID: ideaTemplate.id,
                field: 'updated',
                value: true,
            }));
        } else if (ideaTemplate.lastExportTime !== null && ideaTemplate.lastAiResponseUpdate !== null) {
            const diff = moment(ideaTemplate.lastExportTime).diff(moment(ideaTemplate.lastAiResponseUpdate), 'seconds');
            if (diff < 0) {
                console.log('update iframe 3');
                dispatch(updateIdeaTemplateContent({
                    ideaTemplateID: ideaTemplate.id,
                    field: 'updated',
                    value: true,
                }));
            }
        }
    }, [ideaTemplate.lastExportTime, ideaTemplate.lastAiResponseUpdate])

    const clearContent = async() => {
        if (warningUserWhenPageIsValidate([page])) return;

        if (window.confirm('Are you sure?')) {
            await updateDataAPI('PATCH', 'auto-content/idea-templates/' + ideaTemplate.id + '/', {
                aiResponse: null,
                answerKey: null,
                imageUrl: null,
                lastExportTime: null,
                lastAiResponseUpdate: null
            });
            await updateDataAPI('POST', 'resources/undone-idea-part/?ideaTemplateID=' + ideaTemplate.id);
            refreshPage(page.id);
        }
    }

    const refreshContainersContent = async(ideaTemplateID) => {
        setLoading(true);
        console.log(ideaTemplateID);
        await updateDataAPI('POST', 'auto-content/generate-containers-content/', {
            ideaTemplateID
        });
        setLoading(false);
    }

    if (content === undefined) {
        return null;
    }

    return (
        <div className="auto-content-result-cell">
            <div>
                {content.loading ? (
                    <pre style={{maxWidth: pageWidth + 'px'}}>{JSON.stringify(content.worksheet)}</pre>
                ) : (
                    <div>
                        {/* when we don't have content, display the template's image */}
                        {/* otherwise, display the worksheet's content */}
                        {Object.keys(content.worksheet).filter(key => key !== 'headline').length > 0 ? (
                            <div className="result-container">
                                {/* if content not updated and page has image, then display the image */}
                                {/* otherwise, display the iframe */}
                                {!content.updated && ideaTemplate.imageUrl ? (
                                    <div className="img-data">
                                        <ImageWrapper src={ideaTemplate.imageUrl} styles={{width: pageWidth}} />
                                    </div>
                                ) : (
                                    <div className="iframe-container">
                                        {pageResultDisplayMode === 'simple' ? (
                                            <LiteCanvas ideaTemplate={ideaTemplate} />
                                        ) : (
                                            <>
                                                <iframe src={Constant.WORKSHEET_MAKER_URL + '?type=page&id=' + ideaTemplate.id + '&displayMode=iframe&autoSave&token=' + localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN)}
                                                    width={ideaTemplate.templateData.orientation === 'vertical' ? 500 : 750}
                                                    height={ideaTemplate.templateData.orientation === 'vertical' ? 750 : 500}>
                                                </iframe>
                                                <div className="rendering-status">Rendering...</div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="template-img">
                                {ideaTemplate.imageUrl && ideaTemplate.templateData.autoAddHeadline ? (
                                    <ImageWrapper src={ideaTemplate.imageUrl} styles={{width: pageWidth}} />
                                ) : (
                                    <ImageWrapper src={ideaTemplate.templateData.imageUrl} styles={{width: pageWidth}} />
                                )}
                            </div>
                        )}
                        <div className="last-update-time" onClick={() => setShowContentHistoryVersionsModal(true)}>
                            <i>
                                {ideaTemplate.lastAiResponseUpdate ? (
                                    <>
                                        <span>Last updated at </span>
                                        <Moment format="HH:mm DD/MM/YYYY">
                                            {ideaTemplate.lastAiResponseUpdate}
                                        </Moment>
                                    </>
                                ) : (
                                    <span>Show Changes History</span>
                                )}
                            </i>
                        </div>
                        <div className="btns">
                            {Object.keys(content.worksheet).filter(key => key !== 'headline').length > 0 && (
                                <>
                                    {hasPermission(userRoles, activityPermissions['DELETE_PAGE_CONTENT']) && ideaTemplate.aiResponse && (
                                        <Button variant="danger" onClick={clearContent} size="sm">Clear Content</Button>
                                    )}
                                    <Button onClick={() => setShowZonesAndAIResponseModal(true)} size="sm" variant="info">AI Response</Button>
                                    <Button variant="success" onClick={e => {
                                        if (warningUserWhenPageIsValidate([page], ['designValidated'])) return;
                                        if (e.ctrlKey || e.metaKey) {
                                            window.open(process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=page&id=' + ideaTemplate.id, '_blank', 'noopener,noreferrer');
                                        } else {
                                            setShowWorksheetMakerIframeModal(true)
                                        }
                                    }} size="sm">Design</Button>
                                    {page.type === 'auto' && (
                                        <Button variant="warning" onClick={() => {
                                            handleShowReportWrongContentModal();
                                            setSelectedPages([page.id]);
                                        }} size="sm">
                                            Report content
                                        </Button>
                                    )}
                                </>
                            )}
                            <Dropdown as={ButtonGroup} size="sm">
                                <Button onClick={() => refreshPage(page.id)}>
                                    Refresh
                                </Button>

                                <Dropdown.Toggle split id="refresh-button-dropdown" />

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => refreshContainersContent(page.content.id)}>Refresh Containers</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {
                                        if (page.dataValidated) {
                                            window.alert('Content validated, invalidate content then try again');
                                        } else {
                                            setShowRefreshDataFromDataSetModal(true);
                                        }
                                    }}>Refresh Data From DataSet</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {ideaTemplate.rawExample && (
                                <Button variant="primary" onClick={() => setShowExampleModal(true)} size="sm">Example</Button>
                            )}
                        </div>
                    </div>
                )}

                {ideaTemplate.answerKey && content['answerKey'] && (
                    <AnswerKeyResult
                        ideaTemplate={ideaTemplate}
                        answerKey={content['answerKey']}
                        refreshPage={() => refreshPage(page.id)}
                        setLoading={setLoading} />
                )}

                {showWorksheetMakerIframeModal && (
                    <WorksheetMakerIframeModal
                        type="page"
                        id={ideaTemplate.id}
                        hideModal={() => {
                            setShowWorksheetMakerIframeModal(false);
                            refreshPage(page.id);
                        }} />
                )}

                {showZonesAndAIResponseModal && (
                    <ZonesAndAIResponseModal
                        ideaTemplate={ideaTemplate}
                        hideModal={() => setShowZonesAndAIResponseModal(false)}
                        refreshPage={() => refreshPage(page.id)}
                        setLoading={setLoading}
                        answerKey={content['answerKey']}
                        pageResultDisplayMode={pageResultDisplayMode}
                        page={page} />
                )}

                {showContentHistoryVersionsModal && (
                    <ContentHistoryVersionsModal
                        ideaTemplate={ideaTemplate}
                        refreshPage={() => refreshPage(page.id)}
                        hideModal={() => setShowContentHistoryVersionsModal(false)}
                    />
                )}

                {showExampleModal && (
                    <Modal show={true} size="lg" onHide={() => setShowExampleModal(false)} className="raw-example-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Content versions
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Control as="textarea" rows={20} value={ideaTemplate.rawExample} readOnly />
                        </Modal.Body>
                    </Modal>
                )}

                {showRefreshDataFromDataSetModal && (
                    <RefreshDataFromDataSetModal
                        page={page}
                        refreshPage={refreshPage}
                        hideModal={() => setShowRefreshDataFromDataSetModal(false)}
                    />
                )}
            </div>
        </div>
    )
}

export default AutoContentResultCell;