import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import './styles.scss';
import { useEffect, useState } from 'react';
import { updateDataAPI } from '../../../utils/query';
import Constant from '../../../Constant';
import { setIdeaTemplateContents } from '../autoContentSlice';

const ApplyManualAIResponseModal = ({ socket, ideaTemplate, hideModal }) => {
    const dispatch = useDispatch();

    const [prompt, setPrompt] = useState();
    const [aiResponse, setAIResponse] = useState();

    useEffect(() => {
        getPrompt();
    }, []);

    const getPrompt = async () => {
        const res = await updateDataAPI('POST', 'auto-content/get-idea-template-prompt/?ideaTemplateID=' + ideaTemplate.id, {
            activity: ideaTemplate.activity,
            note: ideaTemplate.note,
            trainingTextID: ideaTemplate.trainingText,
        });
        setPrompt(res.data.map(item => item.content).join('\n\n'));
    }

    const applyAIResponse = async (closeModal = false) => {
        dispatch(setIdeaTemplateContents({
            ideaTemplateID: ideaTemplate.id,
            worksheet: {},
            answerKey: null,
            icons: [],
            loading: true,
        }));
        await updateDataAPI('POST', 'auto-content/reset-zones-and-linked-text-items-in-page/?ideaTemplateID=' + ideaTemplate.id);
        socket.send(JSON.stringify({
            text: 'format external content for idea template ' + ideaTemplate.id,
            command: 'format_external_content',
            aiResponse,
            ideaTemplateID: ideaTemplate.id,
            'sender': 'client',
            staffID: localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID),
            formatData: true,
        }));
        if (closeModal) {
            hideModal();
        }
    }

    return (
        <Modal
            fullscreen={true}
            centered
            show={true}
            onHide={hideModal}
            className="apply-manual-ai-response-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Apply Manual Content
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h3>Prompt</h3>
                        {prompt && (
                            <Form.Control as="textarea" rows={10} value={prompt} disabled />
                        )}
                        <h3>AI Response</h3>
                        <Form.Control
                            value={aiResponse}
                            as="textarea" rows={20}
                            onChange={e => setAIResponse(e.target.value)}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <Button onClick={hideModal} variant="secondary">Close</Button>
                    <Button href={'/template/?id=' + ideaTemplate.template} variant="secondary" target="_blank">
                        Open Template
                    </Button>
                </div>
                <div>
                    <Button onClick={() => navigator.clipboard.writeText(prompt)}>Copy Prompt</Button>
                    {socket && (
                        <>
                            {/* <Button variant="success" onClick={() => applyAIResponse()}>Apply</Button> */}
                            <Button variant="success" onClick={() => applyAIResponse(true)}>Apply & Close</Button>
                        </>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ApplyManualAIResponseModal;