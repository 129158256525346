import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { updateDataAPI } from "../../../../utils/query";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setCollections } from "../../colorbycodeMakerSlice";

const AddNewCollection = ({ setShowEditorCollection }) => {
    const dispatch = useDispatch();
    const { collections } = useSelector(state => state.generateProduct);

    const [collectionName, setCollectionName] = useState("");

    const handleCreateNewCollectionOperation = async () => {
        const data = {
            name: collectionName,
            operations: [],
        };

        const url = 'product-dev/collection-operations/create-or-update-collection-operations/';

        const res = await updateDataAPI('POST', url, data);

        if (res.status === 201) {
            setShowEditorCollection(res.data);
            dispatch(setCollections([
                ...collections,
                res.data,
            ]));

        } else {
            toast.error('An error occured.')
        }
    }

    return (
        <InputGroup className="mb-3">
            <Form.Control
                placeholder="Add new collection"
                aria-label="Add new collection"
                aria-describedby="basic-addon2"
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
                className="container__search"
            />
            <Button
                variant="primary"
                id="add-new-collection"
                onClick={handleCreateNewCollectionOperation}
            >
                Button
            </Button>
        </InputGroup>
    )
};

export default AddNewCollection;