import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { toast } from 'react-toastify';
import './styles.scss';
import PagePagination from '../../components/auto-content/templates-management/page-pagination';
import DataSetRow from '../../components/dataset/data-set-row';
import { Spinner } from 'react-bootstrap';
import DataSetModal from '../../components/dataset/data-set-row/data-set-modal';

const DataSetPage = () => {
    const [dataSets, setDataSets] = useState([]);
    const [dataSetsInServer, setDataSetsInServer] = useState([]);
    const [selectedDataSet, setSelectedDataSet] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activities, setActivities] = useState([]);

    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState(null);
    const [filteredDataType, setFilteredDataType] = useState('all');
    const [filteredActivity, setFilteredActivity] = useState('all');
    const [showDataSetModal, setShowDataSetModal] = useState(false);

    useEffect(() => {
        const id = (new URLSearchParams(window.location.search)).get('id');
        if (id) {
            fetchDataSet(id);
        } else {
            fetchDataSets();
        }
    }, []);

    useEffect(() => {
        const fetchAllActivities = async() => {
            const res = await getDataFromAPI('resources/get-all-data-set-activities/');
            setActivities(res.data);
        }

        fetchAllActivities();
    }, []);

    const fetchDataSet = async (id) => {
        const res = await getDataFromAPI('resources/data-sets/' + id + '/');
        if (res) {
            const data = res.data;
            setDataSets([data]);
            setDataSetsInServer(JSON.parse(JSON.stringify([data])));
        }
    }

    const fetchDataSets = async (pageIndex = currentPage) => {
        let url = `resources/data-sets/?page=${pageIndex}&page_size=${pageSize}`;

        if (searchKeyword) {
            url += '&searchKeyword=' + searchKeyword;
        }

        if (filteredDataType !== 'all') {
            url += '&dataType=' + filteredDataType;
        }

        if (filteredActivity !== 'all') {
            url += '&activity=' + filteredActivity;
        }

        const res = await getDataFromAPI(url);

        if (res) {
            const data = res.data;
            setTotalPages(Math.ceil(data.count / pageSize))
            const results = data.results;
            results.sort((a, b) => b.id - a.id);
            setDataSets(results);
            setDataSetsInServer(JSON.parse(JSON.stringify(results)));
        }
    }

    const handleUpdateDataSetFields = async (fieldName, newFieldValue) => {
        setSelectedDataSet((prev) => ({
            ...prev,
            [fieldName]: newFieldValue,
        }));
    };

    const handleSaveDataSetChanges = async (dataSetData) => {
        if (!dataSetData) return;

        setDataSets((prev) => prev.map((group) => {
            if (group.id === dataSetData.id) {
                return dataSetData;
            }
            return group;
        }));
        setSelectedDataSet(null);
    };

    const handleSaveDataSetInServer = async (targetDataSet) => {
        if (targetDataSet) {
            try {
                const url = `resources/data-sets/${targetDataSet.id}/`;
                const res = await updateDataAPI('PATCH', url, targetDataSet);
                setDataSetsInServer((prev) => prev.map((group) => {
                    if (group.id === res.data?.id) {
                        return res.data;
                    }
                    return group;
                }));
                window.alert('Saved!');
            } catch (err) {
                console.error(`An error occured when updating data set: ${err.message}`);
            }
        }
    }

    const handleDeleteDataSet = async (dataSetID) => {
        const confirmed = window.confirm('Are you sure want to delete this data set?');
        if (confirmed) {
            const url = `resources/data-sets/${dataSetID}/`;

            try {
                await updateDataAPI('DELETE', url);
                setDataSets((prev) => prev.filter((group) => group.id !== dataSetID))
                toast.success('Deleted!');
            } catch (err) {
                console.error(`An error occured when deleting data set: ${err.message}`);
            };
        }
    };

    const search = async(e) => {
        e.preventDefault();
        if (searchKeyword) {
            fetchDataSets(1);
        }
    }

    return (
        <Container className="data-set-page" fluid>
            <div className="data-set-header">
                <Form className="form-search" onSubmit={search}>
                    <Form.Control className="search-input" value={searchKeyword} onChange={e => setSearchKeyword(e.target.value)} placeholder="Search Data Set" />
                    <Form.Select value={filteredDataType} onChange={e => setFilteredDataType(e.target.value)}>
                        <option value="all">All</option>
                        <option value="JSON">JSON</option>
                        <option value="TABLE">TABLE</option>
                    </Form.Select>
                    <Form.Select value={filteredActivity} onChange={e => setFilteredActivity(e.target.value)}>
                        <option value="all">All</option>
                        {activities.map((activity, index) => (
                            <option key={index} value={activity}>{activity}</option>
                        ))}
                    </Form.Select>
                    <Button onClick={search}>Search</Button>
                </Form>
                <div className="header-right">
                    <Button variant="primary" type="submit" onClick={() => setShowDataSetModal(true)}>
                        Create Data Set
                    </Button>
                </div>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Info</th>
                        <th>Items</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {dataSets.map((dataSet) => (
                        <DataSetRow
                            key={dataSet.id}
                            dataSet={dataSet}
                            dataSetInServer={[...dataSetsInServer].find((g) => g.id === dataSet.id)}
                            handleUpdateDataSetFields={handleUpdateDataSetFields}
                            handleSaveDataSetChanges={handleSaveDataSetChanges}
                            handleDeleteDataSet={handleDeleteDataSet}
                            selectedDataSet={selectedDataSet}
                            setSelectedDataSet={setSelectedDataSet}
                            handleSaveDataSetInServer={handleSaveDataSetInServer}
                            setLoading={setLoading}
                        />
                    ))}
                </tbody>
            </Table>

            {showDataSetModal && (
                <DataSetModal
                    hideModal={() => setShowDataSetModal(false)}
                    setDataSets={setDataSets}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}

            <PagePagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={(pageIndex) => {
                    setCurrentPage(pageIndex);
                    fetchDataSets(pageIndex);
                }}
            />
        </Container>
    );
};

export default DataSetPage;