import { Col, Form, Row } from 'react-bootstrap';

const IdeaModalSaleTab = ({star, setStar, saleStrategy, setSaleStrategy}) => {
    return (
        <Row className="mt-3">
            <Col>
                <Form.Group className="mb-3" controlId="ideaStar">
                    <Form.Check type="checkbox" label="Star" checked={star} onChange={e => setStar(e.target.checked)} />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3">
                    <Form.Label>Sale Strategy</Form.Label>
                    <Form.Select
                        onChange={(e) => setSaleStrategy(e.target.value)}
                        value={saleStrategy}
                    >
                        <option></option>
                        <option value="dollar_deals">Dollar Deals</option>
                        <option value="free_paid">Free to Paid</option>
                    </Form.Select>
                </Form.Group>
            </Col>
        </Row>
    )
}

export default IdeaModalSaleTab;