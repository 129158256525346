import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import NonAIGenerateModal from '../../auto-content/auto-content-prompt-cell/non-ai-generate-modal';

import './styles.scss';
import OutlineIdeaContentRow from '../outline-idea-content-row';
import { toast } from 'react-toastify';

const OutlineIdeaContentTable = ({ idea, dataSets, handleUpdateOutlineIdea = () => {}, setWarningNotSaved = () => {},  handleSaveOutlineIdeasContent = async () => {} }) => {
    const [rows, setRows] = useState(idea?.content ?? []);
    const [loading, setLoading] = useState(false);
    const [nonAIFunctionInModal, setNonAIFunctionInModal] = useState(null);
    const [nonAIFunctionRowIndex, setNonAIFunctionRowIndex] = useState(null);

    useEffect(() => {
        setRows(idea.content);
    }, [idea.id]);

    useEffect(() => {
        const updateOutlineIdea = () => {

            const updatedIdea = {
                ...idea,
                content: rows,
            };

            handleUpdateOutlineIdea(updatedIdea);
        };

        updateOutlineIdea();
    }, [rows]);

    const save = async() => {
        setLoading(true);
        await handleSaveOutlineIdeasContent(idea);
        toast.success('All changes saved!');
        setLoading(false);
    }

    const addNewRow = () => {
        setRows([...rows, []]);
    }

    const deleteRow = (index) => {
        setWarningNotSaved(idea.id);
        setRows(rows.filter((row, rowIndex) => rowIndex !== index));
    }

    const duplicateRow = (index) => {
        setWarningNotSaved(idea.id);
        const newRows = [...rows];
        newRows.splice(index, 0, {...rows[index]});
        setRows(newRows);
    }

    return (
        <div className="outline-idea-content-table">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Data Set</th>
                        <th>Template</th>
                        <th>Collection</th>
                        <th>Function</th>
                        <th>Skills</th>
                        <th>Dup</th>
                        <th>Note</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <OutlineIdeaContentRow
                            key={rowIndex}
                            rows={rows}
                            row={row}
                            dataSets={dataSets}
                            setRows={setRows}
                            rowIndex={rowIndex}
                            deleteRow={deleteRow}
                            duplicateRow={duplicateRow}
                            setLoading={setLoading}
                            loading={loading}
                            setNonAIFunctionInModal={setNonAIFunctionInModal}
                            setNonAIFunctionRowIndex={setNonAIFunctionRowIndex}
                        />
                    ))}
                </tbody>
            </Table>
            <div className="table-footer">
                <div>
                    <Button size="sm" onClick={addNewRow}>Add new row</Button>
                    <Button size="sm" onClick={save}>Save</Button>
                </div>
                <div>Total: {rows.reduce((total, row) => {
                    if (row.collection) {
                        if (row.nbPages && !isNaN(row.nbPages)) {
                            return total + row.nbPages * (row.duplications || 1);
                        }
                    } else if (row.duplications && !isNaN(row.duplications)) {
                        return total + row.duplications;
                    }
                    return total;
                }, 0)} pages</div>
            </div>

            {nonAIFunctionInModal !== null && nonAIFunctionRowIndex !== null && (
                <NonAIGenerateModal
                    setLoading={setLoading}
                    idea={null}
                    ideaTemplateIDs={[]}
                    refresh={() => {}}
                    hideModal={() => {
                        setNonAIFunctionInModal(null);
                        setNonAIFunctionRowIndex(null);
                    }}
                    target={null}
                    ideaSkill={null}
                    currentFunctionID={nonAIFunctionInModal.function}
                    currentFunctionArgs={nonAIFunctionInModal.functionArgs}
                    handleFunctionArgs={(selectedFunctionID, selectedArgs) => {
                        if (nonAIFunctionInModal.function === selectedFunctionID) {
                            setRows(rows.map((r, rIndex) => {
                                if (rIndex === nonAIFunctionRowIndex) {
                                    return {...r, function: selectedFunctionID, functionArgs: selectedArgs};
                                }
                                return r;
                            }));
                        } else {
                            window.alert('Function does not match');
                        }
                    }} />
            )}
        </div>
    )
}

export default OutlineIdeaContentTable;