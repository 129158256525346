import { Form, Button } from 'react-bootstrap';
import AsyncSelectComponent from '../../../utils/async-select';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCog, faCopy, faEdit, faFolderTree, faTrash } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import './styles.scss';
import { useEffect, useState } from 'react';
import TemplatesInCollectionModal from '../../auto-content/templates-management/templates-in-collection-modal';
import { getDataFromAPI } from '../../../utils/query';
import TemplateSearchModal from '../../template-search-modal';
import { toast } from 'react-toastify';
import { initOptions } from '../../../utils/commonUtil';
import TemplateImageModal from '../template-image-modal';
import DataSetModal from '../data-set-modal';

const OutlineIdeaContentRow = ({
    rows,
    row,
    rowIndex,
    dataSets,
    setRows,
    deleteRow,
    duplicateRow,
    setLoading,
    loading,
    setNonAIFunctionInModal,
    setNonAIFunctionRowIndex }) => {

    const [collectionInModal, setCollectionInModal] = useState(null);
    const [showTemplateSearchModal, setShowTemplateSearchModal] = useState(false);

    const [defaultNonAIFunctions, setDefaultNonAIFunctions] = useState([]);
    const [templateInTemplateImageModal, setTemplateInTemplateImageModal] = useState(null);
    const [dataSetIDInModal, setDataSetIDInModal] = useState(null);
    const [selectedFunction, setSelectedFunction] = useState('');

    useEffect(() => {
        refreshNonAIFunctions(row['collection'], row['template']);
    }, [row]);

    useEffect(() => {
        const foundFunction = defaultNonAIFunctions.find((f) => f.id === row['function']);
        setSelectedFunction(foundFunction ?? '');
    }, [defaultNonAIFunctions, row['function']]);

    const refreshNonAIFunctions = async (collectionId, templateId) => {
        const url = `auto-content/non-ai-functions/?selectedCollections=${collectionId}&selectedTemplates=${templateId}`;

        const res = await getDataFromAPI(url);

        setDefaultNonAIFunctions(initOptions(res.data, 'name', 'id'));
    };

    const handleShowConfigNonAIFunctionModal = (row, rowIndex) => {
        setNonAIFunctionInModal(row);
        setNonAIFunctionRowIndex(rowIndex);
    };

    const handleChangeNonAIFunctionRow = (selectedValue, row, rowIndex) => {
        if (!row?.template && !row?.collection) {
            toast.warn('Please select template or collection before select function!');
            return;
        }

        setSelectedFunction(selectedValue);

        let updatedRow;

        if (selectedValue) {
            updatedRow = {
                ...row,
                function: selectedValue.id,
                functionLabel: selectedValue.value + '. ' + selectedValue.label,
                functionArgs: row?.function === selectedValue.value ? row?.functionArgs : null,
            };
        } else {
            updatedRow = {
                ...row,
                function: null,
                functionLabel: null,
                functionArgs: null,
            }
        }

        setRows(rows.map((r, rIndex) => {
            if (rIndex === rowIndex) {
                return updatedRow;
            }
            return r;
        }));

        if (selectedValue) {
            handleShowConfigNonAIFunctionModal(updatedRow, rowIndex);
        }
    }

    const showCollectionModal = async () => {
        const res = await getDataFromAPI('auto-content/collections/' + row['collection'] + '/');
        setCollectionInModal(res.data);
    }

    const handleChangeTemplateInRow = (selectedValues = []) => {
        if (!selectedValues?.[0]) return;

        if (row['collection']) {
            toast.warn("Please choose between template and collection. You can't choose both.");
            return;
        }

        setRows(rows.map((r, rIndex) => {
            if (rIndex === rowIndex) {
                return { ...r, template: selectedValues[0].id, templateLabel: selectedValues[0].name };
            }
            return r;
        }))
    }

    const handleChangeCollectionInRow = (selectedValues = []) => {
        if (!selectedValues?.[0]) return;

        if (row['template']) {
            toast.warn("Please choose between template and collection. You can't choose both.");
            return;
        }

        setRows(rows.map((r, rIndex) => {
            if (rIndex === rowIndex) {
                return { ...r, collection: selectedValues[0].id, collectionLabel: selectedValues[0].name + ' (' + selectedValues[0].templates['nb'] + ' templates)' };
            }
            return r;
        }))
    }

    return (
        <tr className="outline-idea-content-row">
            <td className="index-cell">
                <div>{rowIndex + 1}</div>
                <div className="btns">
                    <FontAwesomeIcon icon={faTrash} onClick={() => deleteRow(rowIndex)} />
                    <FontAwesomeIcon icon={faCopy} onClick={() => duplicateRow(rowIndex)} />
                </div>
            </td>
            <td className="data-sets-cell">
                <AsyncSelectComponent
                    searchUrl='resources/data-sets/?searchKeyword='
                    onChange={selectedValues => {
                        console.log('>', selectedValues);
                        setRows(rows.map((r, rIndex) => {
                            if (rIndex === rowIndex) {
                                return { ...r, dataSets: selectedValues.map(v => ({ id: v.id, value: v.value, label: v.label })) };
                            }
                            return r;
                        }))
                    }}
                    value={row['dataSets']}
                    setLoading={setLoading}
                    loading={loading}
                    labelField="name"
                    clearSelection={() => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return _.omit(r, ['dataSets']);
                        }
                        return r;
                    }))}
                    defaultData={dataSets}
                    isMulti={true}
                    onClickItem={item => setDataSetIDInModal(item['id'])}
                />
            </td>
            <td>
                {row['template'] ? (
                    <div className="selected-template-container">
                        <div className="template-name" onClick={() => setTemplateInTemplateImageModal(row['template'])}>
                            {row['template']}. {row['templateLabel']}
                        </div>
                        <div className="icons">
                            <FontAwesomeIcon icon={faClose} onClick={() => {
                                setRows(rows.map((r, rIndex) => {
                                    if (rIndex === rowIndex) {
                                        return _.omit(r, ['template', 'templateLabel']);
                                    }
                                    return r;
                                }))
                            }} />
                            <a href={process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=template&id=' + row['template']} target="_blank">
                                <FontAwesomeIcon icon={faEdit} />
                            </a>
                        </div>
                    </div>
                ) : (
                    <div>
                        <AsyncSelectComponent
                            searchUrl='auto-content/templates/?keyword='
                            onChange={selectedValue => handleChangeTemplateInRow([selectedValue])}
                            value={{value: row['template'], label: row['templateLabel']}}
                            setLoading={setLoading}
                            loading={loading}
                            labelField="name"
                            clearSelection={null}
                            defaultData={[]}
                        />
                        <Button onClick={() => setShowTemplateSearchModal('template')} variant="link" size="sm">
                            Select template
                        </Button>
                    </div>
                )}
            </td>
            <td>
                {row['collection'] ? (
                    <div>
                        <div className="selected-collection-container">
                            <div>{row['collection']}. {row['collectionLabel']}</div>
                            <div>
                                <FontAwesomeIcon icon={faClose} onClick={() => {
                                    setRows(rows.map((r, rIndex) => {
                                        if (rIndex === rowIndex) {
                                            return _.omit(r, ['collection', 'collectionLabel']);
                                        }
                                        return r;
                                    }))
                                }} />
                                <FontAwesomeIcon icon={faFolderTree} onClick={showCollectionModal} />
                            </div>
                        </div>

                        <div className="collection-meta">
                            <Form.Group>
                                <Form.Label>Nb Templates</Form.Label>
                                <Form.Control
                                    value={row['nbPages']}
                                    type="number"
                                    onChange={e => setRows(rows.map((r, rIndex) => {
                                        if (rIndex === rowIndex) {
                                            return { ...r, nbPages: parseInt(e.target.value) };
                                        }
                                        return r;
                                    }))} />
                            </Form.Group>
                        </div>
                    </div>
                ) : (
                    <div>
                        <AsyncSelectComponent
                            searchUrl='auto-content/collections/?page_size=1000&keyword='
                            onChange={selectedValue => setRows(rows.map((r, rIndex) => {
                                if (rIndex === rowIndex) {
                                    return {...r, collection: selectedValue.value, collectionLabel: selectedValue.label};
                                }
                                return r;
                            }))}
                            value={{value: row['collection'], label: row['collectionLabel']}}
                            setLoading={setLoading}
                            loading={loading}
                            labelField="nameWithNbTemplates"
                            clearSelection={null}
                            defaultData={[]}
                        />
                        <Button onClick={() => setShowTemplateSearchModal('collection')} variant="link" size="sm">
                            Select collection
                        </Button>
                    </div>
                )}


            </td>
            <td className="function-cell">
                <Select
                    onMenuOpen={() => refreshNonAIFunctions(row['collection'], row['template'])}
                    onChange={(selectedValue) => handleChangeNonAIFunctionRow(selectedValue, row, rowIndex)}
                    options={defaultNonAIFunctions}
                    value={selectedFunction}
                    formatOptionLabel={item => (<span>{item?.id}. {item?.name}</span>)}
                    isClearable
                />
                <div className="icons">
                    {row['function'] && (
                        <FontAwesomeIcon className="config-function-icon" icon={faCog} onClick={() => handleShowConfigNonAIFunctionModal(row, rowIndex)} />
                    )}
                </div>
                {row['functionArgs'] && (
                    <div className="function-args">{JSON.stringify(row['functionArgs'])}</div>
                )}
            </td>
            <td className="skills-cell">
                <Form.Control
                    value={row['skills']}
                    as="textarea"
                    rows={3}
                    onChange={e => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return { ...r, skills: e.target.value };
                        }
                        return r;
                    }))} />
            </td>
            <td className="duplications-cell">
                <Form.Control
                    value={row['duplications']}
                    type="number"
                    onChange={e => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return { ...r, duplications: parseInt(e.target.value) };
                        }
                        return r;
                    }))} />
            </td>
            <td>
                <Form.Control
                    value={row['note']}
                    as="textarea"
                    rows={3}
                    onChange={e => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return { ...r, note: e.target.value };
                        }
                        return r;
                    }))} />
            </td>

            {collectionInModal !== null && (
                <TemplatesInCollectionModal
                    collection={collectionInModal}
                    hideModal={() => {
                        setCollectionInModal(null);
                    }}
                />
            )}

            {showTemplateSearchModal && (
                <TemplateSearchModal
                    usedTemplateIDs={[row['template']]}
                    hideModal={() => setShowTemplateSearchModal(false)}
                    handleInsertTemplates={(values) => handleChangeTemplateInRow(values)}
                    handleInsertCollections={(values) => handleChangeCollectionInRow(values)}
                    showTemplatesTab={showTemplateSearchModal === 'template'}
                    showCollectionsTab={showTemplateSearchModal === 'collection'}
                    isMultiCollectionSelection={false}
                    isMultiTemplateSelection={false}
                />
            )}

            {templateInTemplateImageModal && (
                <TemplateImageModal templateID={templateInTemplateImageModal} hideModal={() => setTemplateInTemplateImageModal(null)} />
            )}

            {dataSetIDInModal && (
                <DataSetModal dataSetID={dataSetIDInModal} hideModal={() => setDataSetIDInModal(null)} />
            )}
        </tr>
    );
}

export default OutlineIdeaContentRow;