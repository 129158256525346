import { useEffect, useState } from 'react';
import { Button, Container, Form, Spinner, Table } from 'react-bootstrap';
import axios from 'axios';

import './styles.scss';
import Constant from '../../Constant';
import ExtractContentFromImageModal from '../../components/tpt-crawler/extract-content-from-image-modal';
import { getDataFromAPI } from '../../utils/query';

const TPTCrawlerPage = () => {
    const [loading, setLoading] = useState(false);
    const [crawlRequest, setCrawlRequest] = useState();
    const [filterImageTaken, setFilterImageTaken] = useState('all');
    const [imageInExtractContentModal, setImageInExtractContentModal] = useState(null);
    const [dataSets, setDataSets] = useState([]);

    useEffect(() => {
        const id = (new URLSearchParams(window.location.search)).get('id');
        fetchCrawlRequest(id);
    }, []);

    const fetchCrawlRequest = async(id) => {
        setLoading(true);
        const res = await axios.get(Constant.TPT_SELLER_API + 'external-products/tpt-crawl-requests/' + id + '/');
        if (!res.data['done']) {
            window.alert('request is pending');
        } else {
            setCrawlRequest(res.data);
            await fetchDataSets(res.data['keyword'], res.data['grades']);
        }
        setLoading(false);
    }

    const fetchDataSets = async(keyword, grades) => {
        const res = await getDataFromAPI('resources/get-data-sets-by-keyword/?keyword=' + keyword + '&grades=' + grades);
        setDataSets(res.data);
    }

    const disableImage = async(id) => {
        setLoading(true);
        await axios.patch(Constant.TPT_SELLER_API + 'external-products/tpt-product-images/' + id + '/', {active: false});
        setCrawlRequest({...crawlRequest, tptProducts: crawlRequest.tptProducts.map(product => {
            if (product.images.filter(img => img.id === id).length > 0) {
                return {...product, images: product.images.filter(img => img.id !== id)};
            }
            return product;
        })});
        setLoading(false);
    }

    const takeImage = async(id, taken) => {
        setLoading(true);
        await axios.patch(Constant.TPT_SELLER_API + 'external-products/tpt-product-images/' + id + '/', {taken});
        setCrawlRequest({...crawlRequest, tptProducts: crawlRequest.tptProducts.map(product => {
            if (product.images.filter(img => img.id === id).length > 0) {
                return {...product, images: product.images.map(img => {
                    if (img.id === id) {
                        return {...img, taken};
                    }

                    return img;
                })};
            }
            return product;
        })});
        setLoading(false);
    }

    if (crawlRequest === undefined) {
        return null;
    }

    return (
        <Container fluid className="tpt-crawler-page">
            <h2>
                {crawlRequest['keyword']} - Grades {crawlRequest['grades']}
            </h2>

            <div className="header-filters">
                <Form>
                    <Form.Select value={filterImageTaken} onChange={e => setFilterImageTaken(e.target.value)}>
                        <option value="all">all</option>
                        <option value="taken">taken</option>
                    </Form.Select>
                </Form>
            </div>

            <div className="product-images">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>Images</th>
                        </tr>
                    </thead>
                    <tbody>
                        {crawlRequest['tptProducts'].filter(product => {
                            if (product['images'].length === 0) {
                                return false;
                            }

                            if (filterImageTaken === 'taken' && product.images.filter(image => image.taken).length === 0) {
                                return false;
                            }

                            return true;
                        }).map(product => (
                            <tr key={product['tptID']}>
                                <td>
                                    <a href={'https://teacherspayteachers.com/Product/' + product['tptID']} target="_blank" rel="noreferrer">{product['title']}</a>
                                </td>
                                <td>
                                    <div className="product-images">
                                        {product['images'].filter(image => {
                                            if (filterImageTaken === 'taken' && !image.taken) {
                                                return false;
                                            }

                                            return true;
                                        }).map((image, index) => (
                                            <div key={image['id']} className="image-container">
                                                <img src={Constant.TPT_SELLER_API + image['url']} onClick={() => setImageInExtractContentModal(image)} />
                                                <div className="image-btns">
                                                    {image['taken'] ? (
                                                        <Button variant="success" onClick={() => takeImage(image['id'], false)}>Taken</Button>
                                                    ) : (
                                                        <Button onClick={() => takeImage(image['id'], true)}>Take</Button>
                                                    )}
                                                    <Button variant="danger" onClick={() => disableImage(image['id'])}>Delete</Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            {imageInExtractContentModal !== null && (
                <ExtractContentFromImageModal
                    image={imageInExtractContentModal}
                    dataSets={dataSets}
                    hideModal={() => setImageInExtractContentModal(null)} />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default TPTCrawlerPage;