import { Fragment, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { updateDataAPI } from '../../../utils/query';
import { useSelector } from 'react-redux';
import './styles.scss';
import { toast } from 'react-toastify';

export const CheckBoxParameterComponent = ({
    parameterName = 'isFreePage',
    parameterTitle = 'Free Page',
    currentPage = {},
    pageParameters = {},
    handleChangeCurrentPagesParameter = () => { }
}) => {
    return (
        <Form.Group id={parameterName + ' ' + currentPage.id} className="page-parameter-checkbox d-flex">
            <Form.Check type="checkbox" checked={pageParameters[parameterName]}
                onChange={e => handleChangeCurrentPagesParameter(parameterName, e.target.checked)} className="me-2" />
            <Form.Label>{parameterTitle}</Form.Label>
        </Form.Group>
    );
};

export const PageValidateComponent = ({
    pageParameters = {},
    handleChangeCurrentPagesParameter = () => { },
}) => {
    const { activityPermissions } = useSelector((state) => state.permissions);

    return (
        <div className="mt-3">
            {pageParameters.dataValidated ? (
                <div className="page-validated align-items-center">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <Button variant="link" size="sm" onClick={() => handleChangeCurrentPagesParameter('dataValidated', false)}>Invalidate Content</Button>
                </div>
            ) : (
                <div className="mb-3">
                    <Button variant="success" onClick={() => handleChangeCurrentPagesParameter('dataValidated', true)}>Validate Content</Button>
                </div>
            )}
            {pageParameters.designValidated ? (
                <div className="page-validated align-items-center">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <Button variant="link" size="sm" onClick={() => handleChangeCurrentPagesParameter('designValidated', false)}>Invalidate Design</Button>
                </div>
            ) : (
                <div>
                    <Button variant="success" onClick={() => handleChangeCurrentPagesParameter('designValidated', true)}>Validate Design</Button>
                </div>
            )}
        </div>
    )
}

const SelectPageParameters = ({
    selectedPageIDs = [],
    currentPage = {},
    pages = [],
    setPages = () => { },
    customID,
    setLoading = () => { },
    handleChangeTemplateVersion = () => { },
    mode = 'single',
    modeDisplay = 'dropdown'
}) => {
    const [pageParameters, setPageParameters] = useState({
        'isFreePage': currentPage?.isFreePage || false,
        'isHighlightPage': currentPage?.isHighlightPage || false,
        'dataValidated': currentPage?.dataValidated || false,
        'designValidated': currentPage?.designValidated || false,
    });
    const [isSameOriginalVersionTemplates, setIsSameOriginalVersionTemplates] = useState(false);
    const [originalTemplateID, setOriginalTemplateID] = useState(null);

    useEffect(() => {
        setPageParameters({
            'isFreePage': currentPage?.isFreePage || false,
            'isHighlightPage': currentPage?.isHighlightPage || false,
            'dataValidated': currentPage?.dataValidated || false,
            'designValidated': currentPage?.designValidated || false,
        });
    }, [currentPage]);

    useEffect(() => {
        const checkOriginalVersionTemplates = () => {
            const selectedPages = [...pages].filter((page) => selectedPageIDs.includes(page.id));

            if (selectedPages.length === 0) {
                setIsSameOriginalVersionTemplates(false);
                return;
            }

            const firstOriginalVersionTemplate = selectedPages[0]?.content?.templateData?.originalVersionTemplate;
            const checkedResult = selectedPages.every((selectedPage) => selectedPage?.content?.templateData?.originalVersionTemplate === firstOriginalVersionTemplate);
            setIsSameOriginalVersionTemplates(checkedResult);
            setOriginalTemplateID(firstOriginalVersionTemplate ?? selectedPages[0]?.content?.template);
        };

        checkOriginalVersionTemplates();
    }, [selectedPageIDs, pages]);

    const handleChangeCurrentPagesParameter = async (parameterName, parameterValue) => {
        if (parameterName === 'dataValidated' || parameterName === 'designValidated') {
            const validRes = await updateDataAPI('POST', 'resources/check-user-has-role-to-validate-page/', {
                action: parameterName,
                customID,
            });
            if (!validRes.data['valid']) {
                toast.warn('You do not have permission to validate this page.');
                return;
            }
        }
        const updatedParameter = {
            [parameterName]: parameterValue
        };

        setPageParameters(prev => ({
            ...prev,
            ...updatedParameter,
        }));

        setLoading(true);
        await updateDataAPI('POST', 'resources/update-multi-page-options/', {
            pageIDs: selectedPageIDs,
            options: updatedParameter
        });

        setLoading(false);

        setPages((prev) => (prev.map(p => {
            if (!selectedPageIDs.includes(p.id)) {
                return p;
            }
            return { ...p, ...updatedParameter };
        })));
    }

    return (
        <Fragment>
            {modeDisplay === 'dropdown' ? (
                <Dropdown autoClose="outside" className={mode === 'single' ? 'mt-2' : ''}>
                    <Dropdown.Toggle size="sm" variant="secondary" id="pages-action-dropdown">Page Options</Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <CheckBoxParameterComponent
                                parameterName='isFreePage'
                                parameterTitle='Free page'
                                currentPage={currentPage}
                                pageParameters={pageParameters}
                                handleChangeCurrentPagesParameter={handleChangeCurrentPagesParameter}
                            />
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <CheckBoxParameterComponent
                                parameterName='isHighlightPage'
                                parameterTitle='Highlight page'
                                currentPage={currentPage}
                                pageParameters={pageParameters}
                                handleChangeCurrentPagesParameter={handleChangeCurrentPagesParameter}
                            />
                        </Dropdown.Item>
                        {isSameOriginalVersionTemplates && (
                            <Dropdown.Item>
                                <Button size="sm" onClick={() => handleChangeTemplateVersion(originalTemplateID)}>Change Version</Button>
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item>
                            <PageValidateComponent
                                pageParameters={pageParameters}
                                handleChangeCurrentPagesParameter={handleChangeCurrentPagesParameter}
                            />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ) : (
                <div className="actions">
                    <div className="d-flex flex-column">
                        <CheckBoxParameterComponent
                            parameterName='isFreePage'
                            parameterTitle='Free page'
                            currentPage={currentPage}
                            pageParameters={pageParameters}
                            handleChangeCurrentPagesParameter={handleChangeCurrentPagesParameter}
                        />

                        <CheckBoxParameterComponent
                            parameterName='isHighlightPage'
                            parameterTitle='Highlight page'
                            currentPage={currentPage}
                            pageParameters={pageParameters}
                            handleChangeCurrentPagesParameter={handleChangeCurrentPagesParameter}
                        />
                    </div>

                    <PageValidateComponent
                        pageParameters={pageParameters}
                        handleChangeCurrentPagesParameter={handleChangeCurrentPagesParameter}
                    />
                </div>

            )}
        </Fragment>
    );
};

export default SelectPageParameters;