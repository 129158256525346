import { useState } from 'react';
import { updateDataAPI } from '../../utils/query';
import Moment from 'react-moment';
import { Button, Table } from 'react-bootstrap';
import NewEventModal from '../../components/event-groups/new-event-modal';
import { useDispatch, useSelector } from 'react-redux';
import { setEvents } from '../../components/auto-content/autoContentSlice';

const EventsPage = () => {
    const dispatch = useDispatch();
    const { events } = useSelector((state) => state.autoContent);
    const [showNewEventModal, setShowNewEventModal] = useState(false);

    const deleteEvent = async(event) => {
        if (window.confirm('Are you sure?')) {
            await updateDataAPI('DELETE', `resources/events/${event.id}/`);
            dispatch(setEvents((prev) => prev.filter((e) => e.id !== event.id)));
        }
    }

    return (
        <div>
            <Button onClick={() => setShowNewEventModal(true)}>Add New Event</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Event</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Big Event</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {events.map((event) => (
                        <tr key={event.id}>
                            <td className="event-name">{event.name}</td>
                            <td className="event-date">
                                {event.startDate && (
                                    <Moment format="DD/MM/YYYY">{event.startDate}</Moment>
                                )}
                            </td>
                            <td className="event-date">
                                {event.endDate && (
                                    <Moment format="DD/MM/YYYY">{event.endDate}</Moment>
                                )}
                            </td>
                            <td>
                                {event.bigEvent ? 'Big' : ''}
                            </td>
                            <td>
                                <Button onClick={() => deleteEvent(event)} size="sm" variant="danger">Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showNewEventModal && (
                <NewEventModal
                    newEventName=""
                    hideModal={() => setShowNewEventModal(false)}
                    setEventOptions={() => {}}
                    handleAddNewEventToDataSet={newEvent => setEvents((prev) => ([...prev, newEvent]))}
                />
            )}
        </div>
    )
}

export default EventsPage