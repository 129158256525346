import { useState } from 'react';
import { Modal } from 'react-bootstrap';

const ImageWrapper = ({ src, className, alt = '', styles={} }) => {
    const [showImageWrapperModal, setShowImageWrapperModal] = useState(false);

    return (
        <>
            <img src={src} style={{...styles, cursor: 'pointer'}} onClick={() => setShowImageWrapperModal(true)} className={className} />

            {showImageWrapperModal && (
                <Modal show={true} onHide={() => setShowImageWrapperModal(false)} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={src} style={{width: '100%'}} alt={alt} />
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default ImageWrapper;