import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { Button, Container, Form, Spinner, Table } from 'react-bootstrap';

import './styles.scss';
import OutlineIdeaContentTable from '../../components/outlines/outline-idea-content-table';
import IdeaModal from '../idea-list/idea-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faRobot, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { isInvalidValue } from '../../utils/commonUtil';
import _ from 'lodash';
import moment from 'moment';
import PopupGenDataIdeas from '../../components/outlines/popup-gen-data-ideas';
import Constant from '../../Constant';

const OutlinePage = () => {
    const [outline, setOutline] = useState();
    const [showOutlineIdeaModal, setShowOutlineIdeaModal] = useState(false);
    const [outlineIdeaInModal, setOutlineIdeaInModal] = useState();
    const [nonAIFunctions, setNonAIFunctions] = useState([]);
    const [dataSets, setDataSets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [doneFilter, setDoneFilter] = useState('all');
    const [expandingIdeas, setExpandingIdeas] = useState([]);
    const [warningNotSaved, setWarningNotSaved] = useState(null);
    const [showPopupGenDataIdeas, setShowPopupGenDataIdeas] = useState(false);
    const [listGeneratingDataIdeas, setListGeneratingDataIdeas] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [showOutlineDuplicateIdeaModal, setShowOutlineDuplicateIdeaModal] = useState(false);
    const [outlineIdeaToDuplicate, setOutlineIdeaToDuplicate] = useState(null);

    useEffect(() => {
        const id = (new URLSearchParams(window.location.search)).get('id');
        fetchOutline(id);
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    useEffect(() => {
        if (nonAIFunctions.length > 0 && dataSets.length > 0) {
            setLoading(false);
        }
    }, [nonAIFunctions, dataSets]);

    useEffect(() => {
        const fetchAllNonAIFunctions = async() => {
            const res = await getDataFromAPI('auto-content/non-ai-functions/');
            if (res.data['results']) {
                setNonAIFunctions(res.data['results']);
            } else {
                setNonAIFunctions(res.data);
            }
        }

        const fetchAllDataSets = async() => {
            const res = await getDataFromAPI('resources/data-sets/');
            if (res.data['results']) {
                setDataSets(res.data['results']);
            } else {
                setDataSets(res.data);
            }
        }

        fetchAllNonAIFunctions();
        fetchAllDataSets();
    }, []);

    const fetchOutline = async (id) => {
        const res = await getDataFromAPI('product-dev/outlines/' + id + '/?additionalFields=ideas');
        setOutline(res.data);
    }

    const fetchOutlineIdeas = async() => {
        const res = await getDataFromAPI('product-dev/outline-ideas/?outlineID=' + outline.id);
        setOutline({...outline, ideas: res.data});
    }

    const deleteOutlineIdea = async(idea) => {
        if (window.confirm('Are you sure you want to delete this idea?')) {
            await updateDataAPI('DELETE', 'product-dev/outline-ideas/' + idea.id + '/');
            if (idea.ideaData) {
                await updateDataAPI('DELETE', 'product-ideas/' + idea.ideaData.id + '/');
            }
            fetchOutlineIdeas();
        }
    }

    const duplicateOutlineIdea = async(newIdea) => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'product-dev/outline-ideas/', {
            outline: outline.id,
            name: newIdea.name,
            ideaIndex: outline.ideas.length,
            idea: newIdea.id,
            content: outlineIdeaToDuplicate.content
        });
        setOutline({...outline, ideas: [...outline.ideas, res.data]});
        setLoading(false);
    }

    const addNewOutlineIdea = async(idea) => {
        const res = await updateDataAPI('POST', 'product-dev/outline-ideas/', {
            outline: outline.id,
            name: idea.name,
            ideaIndex: outline.ideas.length,
            idea: idea.id,
            content: []
        });
        setOutline({...outline, ideas: [...outline.ideas, res.data]});
    }

    const genData = async(idea) => {
        if (!(await isOutlineValid(idea))) {
            window.alert('Outline is not valid, please check again');
        } else if (window.confirm('Are you sure you want to generate data for this idea? Existing data will be removed.')) {
            setShowPopupGenDataIdeas(true);
            setListGeneratingDataIdeas((prev) => {
                const updatedList = [...prev];
                let alreadyExists = false;

                for (let i = 0; i < updatedList.length; i++) {
                    if (updatedList[i].id === idea.id) {
                        updatedList[i] = { ...updatedList[i], done: 'loading' };
                        alreadyExists = true;
                        break;
                    }
                }

                if (!alreadyExists) {
                    updatedList.push({ ...idea['ideaData'], done: 'loading' });
                }

                return updatedList;
            });
            await updateDataAPI('PATCH', 'product-dev/outline-ideas/' + idea.id + '/', {
                content: idea.content,
            });
            try {
                await updateDataAPI('POST', 'product-dev/gen-outline-idea-data/?outlineIdeaID=' + idea.id);
                setOutline({...outline, lastTimeGenData: new Date(), ideas: outline.ideas.map(outlineIdea => {
                    if (outlineIdea.id === idea.id) {
                        return {...outlineIdea, done: true};
                    }
                    return outlineIdea;
                })});
                setListGeneratingDataIdeas((prev) => [ ...prev ].map((i) => {
                    if (i.id === idea['ideaData'].id) {
                        return {...i, done: true};
                    }
                    return i;
                }));
            } catch (err) {
                window.alert('Error generating data for the idea ' + idea['ideaData']['customID']);
                setListGeneratingDataIdeas((prev) => [ ...prev ].map((i) => {
                    if (i.id === idea['ideaData'].id) {
                        return {...i, done: 'error'};
                    }
                    return i;
                }));
            }
        }
    }

    const isOutlineValid = async(idea) => {
        if (!idea) {
            return false;
        }
        const rows = idea?.content ?? [];

        for (let i = 0; i < rows.length; i++) {
            const nbPages = rows[i]?.nbPages;
            const nonAIFunction = rows[i]?.function;
            const nonAIFunctionArgs = rows[i]?.functionArgs;
            const collection = rows[i]?.collection;

            if (!isInvalidValue(nonAIFunction)) {
                const resFunc = await getDataFromAPI('auto-content/non-ai-functions/' + nonAIFunction + '/');
                if (resFunc.data['arguments'].length > 0 && isInvalidValue(nonAIFunctionArgs)) {
                    console.log('>>>', nonAIFunction, nonAIFunctionArgs);
                    return false;
                }
            }

            if (!isInvalidValue(collection) && isInvalidValue(nbPages)) {
                console.log('>', collection, nbPages);
                return false;
            }
        }

        return true;
    }

    const handleUpdateOutlineIdea = (updatedOutlineIdeaData) => {
        if (!updatedOutlineIdeaData) return;

        const updatedOutlineIdeas = outline.ideas.map((idea) => {
            if (idea.id === updatedOutlineIdeaData?.id) {
                return updatedOutlineIdeaData;
            }
            return idea;
        });

        if (!_.isEqual(updatedOutlineIdeas, outline.ideas)) {
            setWarningNotSaved(updatedOutlineIdeaData.id);
        }

        setOutline((prev) => ({
            ...prev,
            ideas: updatedOutlineIdeas,
        }));
    }

    const handleHideOutline = async (idea) => {
        setExpandingIdeas(expandingIdeas.filter(item => item !== idea.id));
        await handleSaveOutlineIdeasContent(idea);
    }

    const handleSaveOutlineIdeasContent = async (idea) =>  {
        if (!idea) return;
        await updateDataAPI('PATCH', 'product-dev/outline-ideas/' + idea.id + '/', {
            content: idea.content,
        });
        setWarningNotSaved(null);
    }

    if (outline === undefined) {
        return <div>Loading...</div>;
    }

    return (
        <Container className="outline-page" fluid>
            <h1 className="mb-3">{outline.name} - {outline.staffName}</h1>
            <div className="outline-page-header">
                <Form>
                    <Form.Group>
                        <Form.Label>Done</Form.Label>
                        <Form.Select value={doneFilter} onChange={e => setDoneFilter(e.target.value)}>
                            <option value="all">All</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
                <Button onClick={() => setShowOutlineIdeaModal(true)}>New Idea</Button>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Idea</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {outline.ideas.filter(idea => {
                        if (doneFilter === 'false' && idea.done) {
                            return false;
                        }
                        if (doneFilter === 'true' && !idea.done) {
                            return false;
                        }
                        return true;
                    }).map((idea, index) => (
                        <tr key={index}>
                            <td className="idea-index-cell">
                                <div>
                                    <div>{index + 1}</div>
                                    {userRoles.includes('admin') && (
                                        <div className="fingerprint">
                                            <a href={Constant.API_URL + 'admin/product_dev/outlineidea/' + idea.id} target="_blank">
                                                Outline idea: {idea.id}
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <div className="idea-btns">
                                    <Button size="sm" variant="danger" onClick={() => deleteOutlineIdea(idea)}>Delete</Button>
                                    <Button size="sm" variant="info" className="mt-1" onClick={() => {
                                        if (window.confirm('Are you sure you want to duplicate this outline idea?')) {
                                            setOutlineIdeaToDuplicate(idea);
                                            setShowOutlineDuplicateIdeaModal(true);
                                        }
                                    }}>Duplicate</Button>
                                </div>
                            </td>
                            <td className="idea-content-cell">
                                {idea.ideaData ? (
                                    <div className="active-idea-name">
                                        <span onClick={() => {
                                            setOutlineIdeaInModal(idea.ideaData);
                                            setShowOutlineIdeaModal(true);
                                        }}>
                                            {idea.ideaData.customID + '. ' + idea.ideaData.name}
                                        </span>
                                        <div>
                                            <div className="gen-data-btn">
                                                <Button size="sm" variant={idea.done ? 'success' : 'info'} onClick={() => genData(idea)}>
                                                    {idea.done && (
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                    )}
                                                    <span>Gen Data</span>
                                                </Button>
                                                <span className="me-2">Last time: {moment(outline.lastTimeGenData).format('YYYY-MM-DD')}</span>
                                            </div>
                                            <a href={'/content-studio/?customID=' + idea.ideaData.customID} target="_blank" rel="noreferrer">
                                                <FontAwesomeIcon icon={faRobot} />
                                            </a>
                                        </div>
                                    </div>
                                ) : (
                                    <div>{idea.name}</div>
                                )}
                                {expandingIdeas.indexOf(idea.id) > -1 ? (
                                    <div>
                                        {nonAIFunctions.length > 0 && dataSets.length > 0 && (
                                            <OutlineIdeaContentTable
                                                idea={idea}
                                                handleUpdateOutlineIdea={handleUpdateOutlineIdea}
                                                dataSets={dataSets}
                                                setWarningNotSaved={setWarningNotSaved}
                                                handleSaveOutlineIdeasContent={handleSaveOutlineIdeasContent} />
                                        )}
                                        <div className="d-flex align-items-center mt-3" style={{ color: 'orange' }}>
                                            <Button size="sm" variant="link" onClick={() => handleHideOutline(idea)}>Hide</Button>
                                            {warningNotSaved === idea.id && (
                                                <span><FontAwesomeIcon icon={faTriangleExclamation} /> Not save yet!</span>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <Button size="sm" variant="link" onClick={() => setExpandingIdeas(expandingIdeas.concat(idea.id))}>See more</Button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showOutlineIdeaModal && (
                <IdeaModal
                    idea={outlineIdeaInModal}
                    defaultStore={outlineIdeaInModal ? outlineIdeaInModal.store : null}
                    closeModal={action => {
                        setShowOutlineIdeaModal(false);
                        fetchOutlineIdeas();
                        setOutlineIdeaInModal(null);
                    }}
                    openEditIdeaModal={addNewOutlineIdea}/>
            )}

            {showOutlineDuplicateIdeaModal && (
                <IdeaModal
                    defaultStore={null}
                    closeModal={action => {
                        setShowOutlineDuplicateIdeaModal(false);
                    }}
                    openEditIdeaModal={duplicateOutlineIdea}/>
            )}

            {showPopupGenDataIdeas && (
                <PopupGenDataIdeas ideas={listGeneratingDataIdeas} hideModal={() => {
                    setListGeneratingDataIdeas(listGeneratingDataIdeas.filter(idea => !idea.done));
                    setShowPopupGenDataIdeas(false);
                }}/>
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    );
}

export default OutlinePage;