import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import './styles.scss';
import InsertDataSetModal from './insert-data-set-modal';
import DataSetData from './data-set-data';
import _ from 'lodash';
import { updateDataAPI } from '../../../utils/query';
import { Alert, ButtonGroup, Dropdown, ListGroup, Table } from 'react-bootstrap';
import DataSetModal from './data-set-modal';
import Constant from '../../../Constant';
import UpdateJSONDataSetModal from './update-json-data-set-modal';
import DataSetIconsManagementModal from './data-set-icons-management-modal';

const DataSetRow = ({
    dataSet,
    dataSetInServer,
    handleSaveDataSetChanges,
    handleDeleteDataSet,
    selectedDataSet,
    setSelectedDataSet,
    handleSaveDataSetInServer,
    setLoading,
}) => {
    const [showInsertDataSetModal, setShowInsertDataSetModal] = useState(false);
    const [showUpdateJSONDataSetModal, setShowUpdateJSONDataSetModal] = useState(false);
    const [showDataSetModal, setShowDataSetModal] = useState(false);
    const [showDataSetIconsManagementModal, setShowDataSetIconsManagementModal] = useState(false);
    const [warningNotSaveYet, setWarningNotSaveYet] = useState(false);
    const [insertDataType, setInsertDataType] = useState('TABLE');

    useEffect(() => {
        setWarningNotSaveYet(!_.isEqual(dataSet, dataSetInServer));
    }, [dataSet, dataSetInServer]);

    const handleInsertData = async (data = '', quizizzUrl, quizizzKeyword, quizizzGrades, nbPages) => {
        setLoading(true);
        if (insertDataType === 'sheet') {
            const newRows = data.split('\n').map((row) => row.split('\t').map((item) => item.trim()));
            const updatedDataSetItems = [...new Set([...(dataSet?.topics ?? []), ...newRows])];
            await handleSaveDataSetChanges({ ...selectedDataSet, topics: updatedDataSetItems });
        } else if ((insertDataType === 'quizizz_links' || insertDataType === 'ace_links' || insertDataType === 'kahoot_links') && dataSet.dataType) {
            const urls = quizizzUrl.split('\n');
            let nbAddedQuestions = 0;
            let nbLinksError = 0;
            for (let i = 0; i < urls.length; i++) {
                let url;
                if (insertDataType === 'quizizz_links') {
                    url = 'resources/insert-data-to-data-set-by-quiz-link/';
                } else if (insertDataType === 'ace_links') {
                    url = 'resources/insert-data-to-data-set-by-ace-link/';
                } else {
                    url = 'resources/insert-data-to-data-set-by-kahoot-link/';
                }
                try {
                    const res = await updateDataAPI('POST', url, {
                        url: urls[i].trim(),
                        dataType: dataSet.dataType,
                        dataSetID: dataSet.id,
                    });
                    nbAddedQuestions += res.data['nbAddedQuestions'];
                } catch (err) {
                    nbLinksError++;
                }
            }
            window.alert(nbAddedQuestions + ' questions added. ' + nbLinksError + ' links error.');
        } else if (insertDataType === 'quizizz keyword' && dataSet.dataType) {
            try {
                const res = await updateDataAPI('POST', 'resources/insert-data-to-data-set-by-quizizz-keyword/', {
                    keyword: quizizzKeyword,
                    grades: quizizzGrades,
                    dataSetID: dataSet.id,
                    nbPages: nbPages,
                });
                window.alert(res.data['nbAddedQuestions'] + ' questions added');
            } catch (err) {
                window.alert('An error occurred while adding questions');
            }
        } else if (insertDataType === 'JSON') {
            const jsonObj = JSON.parse(data);
            const res = await updateDataAPI('POST', 'resources/insert-json-data-to-dataset/', {
                data: Array.isArray(jsonObj) ? jsonObj : [jsonObj],
                dataSetID: selectedDataSet.id,
            });
            await handleSaveDataSetChanges({ ...selectedDataSet, topics: res.data });
        }
        setLoading(false);
        setShowInsertDataSetModal(false);
    }

    const handleSaveAndValidateDataSet = async () => {
        if (dataSet.keyword === null || (dataSet.dataType !== 'JSON' && dataSet.topics.filter(row => row[0].indexOf('star') > -1).length <= 3)) {
            window.alert('Can not validate data set. Make sure that you\'ve filled grades, keywords and check at least 4 star rows.');
            return;
        }

        if (dataSet.dataType === 'JSON' && dataSet.activity === null && dataSet.template === null && dataSet.collection === null) {
            window.alert('Can not validate, this set must has at least activity, template or collection');
            return;
        }

        if (dataSet.activity === 'MCQ' && dataSet.audioAvailable !== 'yes' && dataSet.audioAvailable !== 'no') {
            window.alert('Can not validate, audio is unknown');
            return;
        }

        setLoading(true);
        const resCheckDataSetMatchesTemplates = await updateDataAPI('POST', 'resources/check-dataset-matches-templates/', {id: dataSet.id});
        if (resCheckDataSetMatchesTemplates.data['valid']) {
            const nbQuestionsValidated = window.prompt('Number of questions to validate');

            if (nbQuestionsValidated) {
                const updatedDataSetData = {
                    ...dataSet,
                    validated: true,
                    nbQuestionsValidated: parseInt(nbQuestionsValidated),
                };

                await handleSaveDataSetInServer(updatedDataSetData);
                handleSaveDataSetChanges({
                    ...dataSet,
                    validated: true,
                    nbQuestionsValidated: parseInt(nbQuestionsValidated),
                });

            }
        } else {
            if (resCheckDataSetMatchesTemplates.data['reason']) {
                window.alert('Error: ' + resCheckDataSetMatchesTemplates.data['reason']);
            } else {
                window.alert('Error');
            }
        }
        setLoading(false);
    }

    const updateDataSet = async(keywordID, keywordLabel, dataType, activity, dataSource, fieldsFormat, template, collection, mapping, quality, season, audioAvailable) => {
        setLoading(true);
        const updatedDataSetData = {
            ...dataSet,
            dataType,
            activity,
            dataSource,
            fieldsFormat: JSON.parse(fieldsFormat),
            keyword: keywordID,
            template,
            collection,
            mapping,
            quality,
            season,
            audioAvailable,
        };

        await handleSaveDataSetInServer(updatedDataSetData);
        handleSaveDataSetChanges({
            ...dataSet,
            keywordData: {id: keywordID, label: keywordLabel},
            keyword: keywordID,
            fieldsFormat: JSON.parse(fieldsFormat),
            dataType,
            activity,
            dataSource,
            template,
            collection,
            mapping,
            quality,
            season,
            audioAvailable,
        });

        setLoading(false);
    }

    const skipDataSet = async () => {
        setLoading(true);

        const updatedDataSetData = {
            ...dataSet,
            skipped: true,
        };

        await handleSaveDataSetInServer(updatedDataSetData);

        setLoading(false);
    }

    const updateJSONDataSetData = async(data) => {
        const jsonObj = JSON.parse(data);
        await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', {
            topics: jsonObj,
        });
        await handleSaveDataSetChanges({ ...dataSet, topics: jsonObj });
        setShowUpdateJSONDataSetModal(false);
    }

    const updateIcons = async(icons) => {
        const rows = [...dataSet.topics];
        Object.keys(icons).forEach(rowIndex => {
            if (rowIndex <= rows.length) {
                rows[rowIndex - 1]['icon'] = icons[rowIndex];
            }
        });
        await handleSaveDataSetChanges({ ...dataSet, topics: rows });
        setShowDataSetIconsManagementModal(false);
    }

    const updateMultiIconsPerRow = async(rowIndex, icons) => {
        const rows = [...dataSet.topics];
        for (let i = 0; i < icons.length; i++) {
            rows[rowIndex]['icon' + (i + 1)] = icons[i];
        }
        await handleSaveDataSetChanges({ ...dataSet, topics: rows });
        setShowDataSetIconsManagementModal(false);
    }

    const genAudio = async() => {
        setLoading(true);
        try {
            const clear = window.confirm('Do you want to clear all existing audios?');
            const res = await updateDataAPI('POST', 'resources/gen-audio-for-dataset/', {
                id: dataSet.id,
                clear
            });
            if (res.data['errors'].length > 0) {
                window.alert('Rows ' + res.data['errors'].map(err => err['rowIndex']).join(', ') + ' errors');
            }
            handleSaveDataSetChanges({
                ...dataSet,
                topics: res.data['topics'],
                audioAvailable: 'yes',
            });
        } catch (err) {
            window.alert('check dataset activity, only support MCQ');
        }
        setLoading(false);
    }

    const clearAllAudio = async() => {
        setLoading(true);
        try {
            const res = await updateDataAPI('POST', 'resources/clear-all-audio-dataset/', {
                id: dataSet.id,
                clear: true
            });
            await handleSaveDataSetInServer({...dataSet, topics: res.data['topics']});
            handleSaveDataSetChanges({
                ...dataSet,
                topics: res.data['topics'],
            });
        } catch (err) {
            window.alert('check dataset activity, only support MCQ');
        }
        setLoading(false);
    }

    return (
        <>
            <tr key={dataSet.id} className="data-set-row">
                <td className="id-cell">
                    <a href={'/data-set/?id=' + dataSet.id} target="_blank">{dataSet.id}</a>
                    {dataSet.validated && (
                        <Alert variant="success" size="sm">
                            <div>Validated</div>
                            <div>{dataSet.nbQuestionsValidated && dataSet.nbQuestionsValidated + ' rows'}</div>
                        </Alert>
                    )}
                    {dataSet.skipped && (<div>Skipped</div>)}
                </td>
                <td className="name-cell">
                    <div className="event-groups-page__group-name">
                        <ListGroup className="dataset-info">
                            <ListGroup.Item>
                                <a href={'/product-map/?keyword=' + dataSet.keyword} target="_blank">
                                    {dataSet.keywordData && dataSet.keywordData.label}
                                </a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Data Type: {dataSet.dataType}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Activity: {dataSet.activity}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Quality: {dataSet.quality}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Season: {dataSet.season}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Source: {dataSet.dataSource}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Total: {dataSet.topics ? dataSet.topics.length : 0} rows
                            </ListGroup.Item>
                            {dataSet.template && (
                                <ListGroup.Item>
                                    <a href={Constant.WORKSHEET_MAKER_URL + '?type=template&id=' + dataSet.template + '&token=' + localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN)} target="_blank">Linked to template: {dataSet.template}</a>
                                </ListGroup.Item>
                            )}
                            {dataSet.collection && (
                                <ListGroup.Item>
                                    Linked to collection: {dataSet.collection}
                                </ListGroup.Item>
                            )}
                            <ListGroup.Item>
                                <div>Mapping:</div>
                                {dataSet.mapping && (
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>From</th>
                                                <th>To</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(dataSet.mapping).map((key) => (
                                                <tr key={key}>
                                                    <td>{key}</td>
                                                    <td>{dataSet.mapping[key]}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </td>
                <td className="data-set-cell">
                    <DataSetData
                        dataSet={dataSet}
                        handleSaveDataSetChanges={handleSaveDataSetChanges}
                        updateIcons={updateIcons}
                        updateMultiIconsPerRow={updateMultiIconsPerRow}
                        setLoading={setLoading}
                    />
                </td>
                <td className="actions-cell">
                    <div>
                        {warningNotSaveYet && (
                            <Button size="sm" variant="warning" onClick={() =>  handleSaveDataSetInServer(dataSet)}>Save</Button>
                        )}
                        <Dropdown as={ButtonGroup} size="sm">
                            <Button variant="info" onClick={handleSaveAndValidateDataSet}>
                                Validate
                            </Button>

                            <Dropdown.Toggle split id="validate-dropdown" variant="info" />

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={skipDataSet}>Skip</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {dataSet.dataType === 'JSON' ? (
                            <Dropdown as={ButtonGroup} size="sm">
                                <Button onClick={() => {
                                    setShowInsertDataSetModal(true);
                                    setInsertDataType('JSON');
                                    setSelectedDataSet(dataSet);
                                }}>Insert JSON</Button>

                                <Dropdown.Toggle split id="insert-json-dropdown" />

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setShowDataSetModal(true)}>Upload Icons</Dropdown.Item>
                                    <Dropdown.Item onClick={() => navigator.clipboard.writeText(JSON.stringify(dataSet.topics, null, 4))}>Copy JSON</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setShowUpdateJSONDataSetModal(true)}>Update JSON</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setShowDataSetIconsManagementModal(true)}>Icons Management</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : (
                            <Dropdown as={ButtonGroup} size="sm">
                                <Button>
                                    Insert Data
                                </Button>

                                <Dropdown.Toggle split id="insert-data-dropdown" />

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => {
                                        setShowInsertDataSetModal(true);
                                        setInsertDataType('sheet');
                                        setSelectedDataSet(dataSet);
                                    }}>By Sheet</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {
                                        setShowInsertDataSetModal(true);
                                        setInsertDataType('quizizz_links');
                                        setSelectedDataSet(dataSet);
                                    }}>By Quizizz Links</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {
                                        setShowInsertDataSetModal(true);
                                        setInsertDataType('quizizz keyword');
                                        setSelectedDataSet(dataSet);
                                    }}>By Quizizz Keyword</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {
                                        setShowInsertDataSetModal(true);
                                        setInsertDataType('ace_links');
                                        setSelectedDataSet(dataSet);
                                    }}>By ACE Links</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {
                                        setShowInsertDataSetModal(true);
                                        setInsertDataType('kahoot_links');
                                        setSelectedDataSet(dataSet);
                                    }}>By Kahoot Links</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}

                        <Button
                            size="sm"
                            variant="danger"
                            onClick={() => handleDeleteDataSet(dataSet.id)}
                        >
                            Delete
                        </Button>

                        <Button size="sm" onClick={() => setShowDataSetModal(true)}>Edit Info</Button>
                        {dataSet.validated && dataSet.nbQuestionsValidated > 0 && (
                            <Dropdown as={ButtonGroup} size="sm">
                                <Button size="sm" onClick={genAudio}>Gen Audio</Button>

                                <Dropdown.Toggle split id="clear-and-gen-audio-dropdown" />

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={clearAllAudio}>Clear All Audios</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div>
                </td>
            </tr>
            {showInsertDataSetModal && (
                <InsertDataSetModal
                    type={insertDataType}
                    handleInsertData={handleInsertData}
                    hideModal={() => setShowInsertDataSetModal(false)}
                />
            )}
            {showUpdateJSONDataSetModal && (
                <UpdateJSONDataSetModal
                    dataSet={dataSet}
                    updateDataSetData={updateJSONDataSetData}
                    hideModal={() => setShowUpdateJSONDataSetModal(false)}
                />
            )}
            {showDataSetIconsManagementModal && (
                <DataSetIconsManagementModal
                    dataSet ={dataSet}
                    updateIcons={updateIcons}
                    hideModal={() => setShowDataSetIconsManagementModal(false)}
                />
            )}
            {showDataSetModal && (
                <DataSetModal
                    dataSet={dataSet}
                    updateDataSet={updateDataSet}
                    hideModal={() => setShowDataSetModal(false)}
                />
            )}
        </>
    )
}

export default DataSetRow;