import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import { updateDataAPI } from '../../../utils/query';
import { toast } from 'react-toastify';

const ImagePreviewModal = ({ topicIcon, iconTitle = "Resource icon", iconLink, iconSource = 'google', closeModal, topicName, handleUploadedTopic }) => {
    const [loading, setLoading] = useState(false);

    const hanldeUploadIconToS3 = async () => {
        setLoading(true);

        try {
            const iconUrl = iconLink;
            const urlParts = iconUrl.split('/');
            const iconName = urlParts[urlParts.length - 1].split('?')[0];
            // const fileExtension = filename.split('.').pop();

            const data = {
                topicIcon,
                iconUrl,
                iconName,
                iconSource,
                topicName,
            };
            const url = 'resources/upload-topic-image-from-url/';

            const res = await updateDataAPI('POST', url, data);

            handleUploadedTopic(res.data);
            closeModal();

            toast.success("Your file upload successfully.");
        } catch (err) {
            console.error(`An error occured when upload topic image: ${err.message}`);
            toast.error(err.message);
        }

        setLoading(false);
    }

    return (
        <Modal show={true} size="lg" onHide={closeModal} className="google-image-modal">
            <Modal.Header>{iconTitle}</Modal.Header>
            <Modal.Body>
                <Row className="mb-3">
                    <Image src={iconLink} width={300} alt={iconTitle} thumbnail/>
                </Row>
                <Row>
                    {!loading ? (
                        <Button
                            onClick={hanldeUploadIconToS3}
                        >
                            Upload
                        </Button>
                    ) : (
                        <p>Uploading icon...Please wait.</p>
                    )}

                </Row>
            </Modal.Body>
        </Modal>
    )
};

export default ImagePreviewModal;