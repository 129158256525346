import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PartContent from '../part-content';
import { useEffect, useState } from 'react';
import Constant from '../../../../Constant';

const SettingPartModal = ({
    part = {},
    closeModal = () => { },
    handleUpdatePartField = () => { },
    handleSavePart = () => { },
}) => {
    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const [socket, setSocket] = useState(null);
    const [testData, setTestData] = useState(null);

    useEffect(() => {
        const connectWebSocket = () => {
            const newSocket = new WebSocket(
                Constant.WEBSOCKET_URL + 'ws/' + localStorage.getItem(Constant.LOCAL_STORAGE_USER_NAME).replace(' ', '_') + '/'
            );
            newSocket.onopen = () => {
                console.log('WebSocket connection opened');
                setIsSocketConnected(true);
                setSocket(newSocket);
            }
            newSocket.onerror = (error) => {
                console.log('WebSocket error:', error);
                newSocket.close();
            };
            newSocket.onclose = () => {
                console.log('Websocket closed.');
                setSocket(null);
                setIsSocketConnected(false);
                setTimeout(() => {
                    console.log('WebSocket reconnecting...');
                    connectWebSocket();
                }, 5000);
            };
            newSocket.onmessage = event => {
                const data = JSON.parse(event.data);
                console.log('new message', data);
                if (data['text'] === 'query_end') {

                } else if ('text' in data) {
                    console.log('>', data);
                }
            }
        }

        if (!isSocketConnected) {
            console.log('WebSocket connecting...');
            if (!process.env.REACT_APP_DEV) {
                connectWebSocket();
            }
        }

        return () => {
            if (socket) {
                socket.close();
            }
        }
    }, []);

    return (
        <Modal show={true} backdrop="static" onHide={closeModal} fullscreen={true}>
            <Modal.Header closeButton>
                <Modal.Title>Edit part {part.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PartContent part={part} handleUpdatePartField={handleUpdatePartField} showTemplatePreview={true} socket={socket}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSavePart}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SettingPartModal;