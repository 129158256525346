import { Button, Container, Form, Spinner, Table } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { useEffect, useState } from 'react';

import './styles.scss';

const MultiLanguagesIdeasPage = () => {
    const [keywords, setKeywords] = useState([]);
    const [newKeyword, setNewKeyword] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAllKeywords();
    }, []);

    const fetchAllKeywords = async() => {
        setLoading(true);
        const res = await getDataFromAPI('product-dev/multi-languages-keywords/');
        setKeywords(res.data);
        setLoading(false);
    }

    const addNewKeyword = async(e) => {
        e.preventDefault();
        setLoading(true);

        const res = await updateDataAPI('POST', 'product-dev/multi-languages-keywords/', {
            name: newKeyword
        });
        setKeywords([res.data, ...keywords]);
        setNewKeyword(null);
        setLoading(false);
    }

    const updateRelatedIdeaStatus = async(keywordID, customID, status) => {
        setLoading(true);
        await updateDataAPI('POST', 'product-dev/update-keyword-related-idea-status/', {
            keywordID,
            customID,
            status
        });
        setLoading(false);
    }

    const deleteKeyword = async(keywordID) => {
        if (window.confirm('Are you sure you want to delete this keyword?')) {
            setLoading(true);
            await updateDataAPI('DELETE', 'product-dev/multi-languages-keywords/' + keywordID + '/');
            setKeywords(keywords.filter(keyword => keyword.id !== keywordID));
            setLoading(false);
        }
    }

    return (
        <Container className="multi-language-ideas-page">
            <Form className="mb-3">
                <Form.Control placeholder="Enter keyword" value={newKeyword} onChange={e => setNewKeyword(e.target.value)} />
                <Button onClick={addNewKeyword}>Add New Keyword</Button>
            </Form>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Keyword</th>
                        <th>Ideas</th>
                    </tr>
                </thead>
                <tbody>
                    {keywords.map(keyword => (
                        <tr key={keyword.id}>
                            <td>
                                <div className="mb-3"><strong>{keyword.name}</strong></div>
                                <Button variant="danger" size="sm" onClick={() => deleteKeyword(keyword.id)}>Delete</Button>
                            </td>
                            <td>
                                <Table striped bordered hover>
                                    <tbody>
                                        {keyword.relatedIdeasData.map((relatedIdea, index) => (
                                            <tr key={keyword.id + '-' + index}>
                                                <td>
                                                    <a href={'/content-studio/?customID=' + relatedIdea.customID} target="_blank" rel="noreferrer">
                                                        {relatedIdea.customID}. {relatedIdea.title}
                                                    </a>
                                                </td>
                                                <td>
                                                    <Form.Select value={relatedIdea.status} onChange={e => updateRelatedIdeaStatus(keyword.id, relatedIdea.customID, e.target.value)}>
                                                        <option value={null}></option>
                                                        <option value="toTranslate">To Translate</option>
                                                        <option value="ignored">Ignored</option>
                                                    </Form.Select>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default MultiLanguagesIdeasPage;