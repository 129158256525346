import React from 'react';
import './styles.scss';

const TagsList = ({
    tagsList = [],
    handleRemoveTagFromList = () => { },
    showCategoryName = true,
}) => {
    return (
        <div className="tags-list">
            {tagsList.map(tag => (
                <span key={tag.id}>
                    <span>{showCategoryName && `${tag.categoryName}:`} {tag.name}</span>
                    <span className="delete-btn" onClick={() => handleRemoveTagFromList(tag.id)}>x</span>
                </span>
            ))}
        </div>
    )
};

export default TagsList;