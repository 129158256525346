import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import UploadLocalTopicImage from '../upload-local-topic-image';
import { Tab, Tabs } from 'react-bootstrap';
import IconsStorageUploadTab from '../../icons/icons-storage-upload-tab';
import ImagesGoogleUploadTab from '../../icons/images-google-upload-tab';
import ImagePreviewModal from '../../icons/image-preview-modal';

const UploadTopicImageModal = ({
    topicIcon,
    topicName,
    hideModal = () => { },
    handleUploadedTopic = () => { }
}) => {
    const [displayMode, setDisplayMode] = useState('uploadLocal');
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);

    const handleSelectedIcon = (icon) => {
        setSelectedIcon(icon);
        setShowImagePreviewModal(true);
    }

    return (
        <Modal show={true} onHide={hideModal} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Upload image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs activeKey={displayMode} onSelect={(k) => setDisplayMode(k)} id="upload-tabs">
                    <Tab eventKey="uploadLocal" title="From your computer">
                        <div className="mt-3">
                            <UploadLocalTopicImage
                                topicIcon={topicIcon}
                                topicName={topicName}
                                handleUploadedTopic={handleUploadedTopic}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="iconStorage" title="From Icon Storage">
                        <div className="mt-3">
                            <IconsStorageUploadTab
                                selectedIcon={selectedIcon}
                                handleSelectedIcon={handleSelectedIcon}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="google" title="From Google">
                        <div className="mt-3">
                            <ImagesGoogleUploadTab 
                                selectedIcon={selectedIcon}
                                handleSelectedIcon={handleSelectedIcon}
                            />
                        </div>
                    </Tab>
                </Tabs>
                {showImagePreviewModal && (
                    <ImagePreviewModal
                        topicIcon={topicIcon}
                        iconLink={selectedIcon?.link}
                        iconTitle={selectedIcon?.title}
                        iconSource={selectedIcon?.source}
                        closeModal={() => setShowImagePreviewModal(false)}
                        topicName={topicName}
                        handleUploadedTopic={handleUploadedTopic}
                    />
                )}
            </Modal.Body>
        </Modal>
    )
}

export default UploadTopicImageModal;