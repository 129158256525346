import { Button, Modal, Table } from 'react-bootstrap';
import { updateDataAPI } from '../../../utils/query';
import { useEffect, useState } from 'react';
import Constant from '../../../Constant';

import './styles.scss';
import { hasPermission } from '../../../utils/auth';
import { useSelector } from 'react-redux';

const TaskCardTable = ({ selectedPartID, data, setData, canDeleteRow = false }) => {
    const { activityPermissions } = useSelector((state) => state.permissions);

    const [userRoles, setUserRoles] = useState([]);
    const [modalContent, setModalContent] = useState(null);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const deleteRow = async (index) => {
        const toSubmitData = data.filter((row, rowIndex) => rowIndex !== index);
        await updateDataAPI('POST', 'resources/convert-json-to-taskCardCSV/?taskCardPart=' + selectedPartID, toSubmitData);
        setData(toSubmitData);
    }

    return (
        <div className="taskcard-table-container">
            <div className="nb-cards">{data.length - 1} cards</div>
            <Table striped bordered hover>
                <thead>
                    {data.slice(0, 1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((col, colIndex) => (
                                <th key={colIndex}>{col}</th>
                            ))}
                            {canDeleteRow && hasPermission(userRoles, activityPermissions['DELETE_TASK_CARD_PART']) && (
                                <th></th>
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {data.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((val, colIndex) => (
                                <td key={colIndex}>
                                    {val.length >= 100 ? (
                                        <div className="excerpt-content" onClick={() => setModalContent(val)}>
                                            {val.substr(0, 100)}...
                                        </div>
                                    ) : (
                                        <span>{val}</span>
                                    )}
                                </td>
                            ))}
                            {canDeleteRow && hasPermission(userRoles, activityPermissions['DELETE_TASK_CARD_PART']) && (
                                <td className="actions-cell">
                                    <Button size="sm" variant="danger" onClick={() => deleteRow(rowIndex + 1)}>Delete</Button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>

                <Modal show={modalContent !== null} onHide={() => setModalContent(null)} size="lg">
                    <Modal.Body>
                        <div>{modalContent}</div>
                    </Modal.Body>
                </Modal>
            </Table>
        </div>
    );
}

export default TaskCardTable;