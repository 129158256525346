import { Button, Form, Modal } from 'react-bootstrap';
import Constant from '../../../Constant';
import './styles.scss';
import { useState } from 'react';

const ExtractContentFromImageModal = ({image, dataSets, hideModal}) => {
    const [selectedDataSet, setSelectedDataSet] = useState(null);

    return (
        <Modal
            show={true}
            onHide={hideModal}
            className="extract-content-from-modal"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Extract Content from Image
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="image-container">
                    <img src={Constant.TPT_SELLER_API + image['url']} />
                    <div>
                        <Form.Group className="select-data-set-form">
                            <Form.Label>Data Set</Form.Label>
                            <Form.Select value={selectedDataSet} onChange={e => setSelectedDataSet(e.target.value)}>
                                <option></option>
                                {dataSets.map(ds => (
                                    <option key={ds['id']}>{ds.name} - {ds.dataType} - Grades {ds.grades}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        {selectedDataSet && (
                            <Button>Convert</Button>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ExtractContentFromImageModal;