import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { updateDataAPI } from '../../../utils/query';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setEvents } from '../../auto-content/autoContentSlice';

const NewEventModal = ({
    newEventName = '',
    hideModal = () => { },
    setEventOptions = () => { },
    handleAddNewEventToDataSet = () => { },
}) => {
    const dispatch = useDispatch();
    const { events } = useSelector((state) => state.autoContent);
    const [newEvent, setNewEvent] = useState({
        name: newEventName,
        startDate: null,
        endDate: null,
        bigEvent: false,
    });

    const handleChangeEventField = (fieldName, fieldValue) => {
        setNewEvent((prev) => ({
            ...prev,
            [fieldName]: fieldValue,
        }));
    };

    const handleCreateNewEvent = async (e) => {
        e.preventDefault();

        if (!newEvent.name) return;

        try {
            const res = await updateDataAPI('POST', 'resources/events/', newEvent);
            setEventOptions((prev) => ([...prev, res.data]));
            dispatch(setEvents([ ...events, res.data ]));
            handleAddNewEventToDataSet(res.data);
            toast.success('Created event.');
            hideModal();
        } catch (err) {
            console.error(`An error occured when creating new event: ${err.message}`);
        };
    };

    return (
        <Modal show={true} onHide={hideModal} className="quick-search-idea-modal">
            <Modal.Header closeButton>
                <Modal.Title>New Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Event name:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter event's name"
                            value={newEvent?.name || ''}
                            onChange={(e) => handleChangeEventField('name', e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Start date:</Form.Label>
                        <Form.Control
                            type="date"
                            value={newEvent?.startDate || ''}
                            onChange={(e) => handleChangeEventField('startDate', e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Label>End date:</Form.Label>
                        <Form.Control
                            type="date"
                            value={newEvent?.endDate || ''}
                            onChange={(e) => handleChangeEventField('endDate', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="bigEventCheckbox">
                        <Form.Check
                            type="checkbox"
                            label="Big event"
                            value={newEvent.bigEvent}
                            onChange={(e) => handleChangeEventField('bigEvent', e.target.checked)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hideModal}>Close</Button>
                <Button variant="primary" type="submit" onClick={handleCreateNewEvent}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NewEventModal;