import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';
import { faCheckCircle, faClose, faEdit, faFolderTree } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import TemplatesInCollectionModal from '../templates-in-collection-modal';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import CollectionInfoModal from '../collection-info-modal';
import ContentTags from '../template-tags';
import { useSelector } from 'react-redux';
import Constant from '../../../../Constant';
import { hasPermission } from '../../../../utils/auth';
import PagePagination from '../page-pagination';
import CollectionThumbnail from './collection-thumbnail';

const CollectionList = ({
    collections,
    setCollections,
    selectedCollections,
    setSelectedCollections,
    fetchCollections,
    refreshCategories,
    categories,
    fetchAllCategories,
    setLoading,
    currentPage = 1,
    totalPages = 1,
    handlePageChange = () => { },
    showPagination = false,
}) => {
    const [collectionInModal, setCollectionInModal] = useState(null);
    const [edittingCollectionInInfoModal, setEdittingCollectionInInfoModal] = useState(false);
    const [userRoles, setUserRoles] = useState([]);

    const { activityPermissions } = useSelector((state) => state.permissions);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const refreshCollection = async (id) => {
        const res = await getDataFromAPI('auto-content/collections/' + id + '/');
        setCollections(collections.map(collection => {
            if (collection.id === id) {
                return res.data;
            }
            return collection;
        }));
    }

    const deleteCollection = async (id) => {
        if (window.confirm('Are you sure you want to delete this collection?')) {
            setLoading(true);
            await updateDataAPI('DELETE', 'auto-content/collections/' + id + '/');
            setCollections(collections.filter(collection => collection.id !== id));
            setLoading(false);
        }
    }

    return (
        <div className="d-flex flex-column align-items-center">
            <div className="collection-list">
                {collections.map(collection => (
                    <div key={collection.id} className="collection" onClick={() => {
                        if (setSelectedCollections) {
                            if (selectedCollections.find(c => c.id === collection.id) !== undefined) {
                                setSelectedCollections(selectedCollections.filter(t => t.id !== collection.id));
                            } else {
                                setSelectedCollections(selectedCollections.concat(collection));
                            }
                        }
                    }}>
                        <div className={'collection-thumbnail ' + (selectedCollections.find(c => c.id === collection.id) !== undefined ? 'selected ' : ' ')}>
                            {collection.thumbnail && (
                                <CollectionThumbnail 
                                    collectionThumbnail={collection.thumbnail}
                                    collectionID={collection.id}
                                />
                            )}
                            {selectedCollections.find(c => c.id === collection.id) !== undefined && (
                                <FontAwesomeIcon className="check-icon" icon={faCheckCircle} />
                            )}
                        </div>
                        <div className="collection-name">
                            <div><strong>{collection.id}. {collection.name}</strong></div>
                            <div>({collection.templates.nb} templates)</div>
                            <div>
                                <FontAwesomeIcon icon={faEdit} onClick={() => setEdittingCollectionInInfoModal(collection)} />
                                <FontAwesomeIcon icon={faFolderTree} onClick={() => setCollectionInModal(collection)} />
                                {hasPermission(userRoles, activityPermissions['DELETE_COLLECTION']) && (
                                    <FontAwesomeIcon icon={faClose} onClick={() => deleteCollection(collection.id)} />
                                )}
                            </div>
                        </div>
                        <ContentTags
                            content={collection}
                            contentType={'collection'}
                            tags={collection.tagsData}
                            categories={categories}
                            refreshContents={() => {
                                fetchCollections();
                                refreshCategories();
                            }}
                            refreshCategories={fetchAllCategories}
                            updateTagsInContents={(collectionID, data) => {
                                setCollections(collections.map(t => {
                                    if (t.id === collectionID) {
                                        return data;
                                    }
                                    return t;
                                }));
                            }} />
                    </div>
                ))}
            </div>
            {showPagination && (
                <PagePagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                />
            )}

            {collectionInModal && (
                <TemplatesInCollectionModal
                    collection={collectionInModal}
                    hideModal={() => {
                        refreshCollection(collectionInModal.id);
                        setCollectionInModal(null);
                    }}
                />
            )}

            {edittingCollectionInInfoModal && (
                <CollectionInfoModal
                    collection={edittingCollectionInInfoModal}
                    hideModal={() => setEdittingCollectionInInfoModal(null)}
                    collections={collections}
                    setCollections={setCollections} />
            )}
        </div>
    )
}

export default CollectionList;