export default class Constant {
    static API_URL = process.env.REACT_APP_API_URL;
    static WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
    static WORKSHEET_MAKER_URL = process.env.REACT_APP_WORKSHEET_MAKER_URL;
    static COLLECTED_RESOURCES_URL = process.env.REACT_APP_COLLECTED_RESOURCES_URL;

    static TPT_SELLER_API = 'https://tptseller-api.k12worksheet.com/';
    static STUDIO_API = 'https://studio-api.k12worksheet.com/';

    static LOCAL_STORAGE_PREFIX = '_bee1203_learningmaterials_';
    static LOCAL_STORAGE_TOKEN = this.LOCAL_STORAGE_PREFIX + 'token';
    static LOCAL_STORAGE_ROLES = this.LOCAL_STORAGE_PREFIX + 'roles';
    static LOCAL_STORAGE_USER_EMAIL = this.LOCAL_STORAGE_PREFIX + 'user_email';
    static LOCAL_STORAGE_USER_NAME = this.LOCAL_STORAGE_PREFIX + 'user_name';
    static LOCAL_STORAGE_STAFF_ID = this.LOCAL_STORAGE_PREFIX + 'staff_id';
    static LOCAL_STORAGE_ADMIN_TOKEN = this.LOCAL_STORAGE_PREFIX + 'admin_token';
    static LOCAL_STORAGE_ADMIN_EMAIL = this.LOCAL_STORAGE_PREFIX + 'admin_email';
    static LOCAL_STORAGE_ADMIN_ID = this.LOCAL_STORAGE_PREFIX + 'admin_id';

    static GRADES = ['PreK', 'Kindergarten', 'Homeschool', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
    static WORKSHEET_CATEGORY_ID = 1;
    static TASKCARD_CATEGORY_ID = 2;
    static MANUAL_CATEGORY_ID = 3;
    static COLORBYCODE_CATEGORY_ID = 8;
    static BUNDLE_CATEGORY_ID = 9;
    static TASK_PRIORITIES = ['low', 'normal', 'high'];
    static TASK_DIFFICULTIES = ['easy', 'medium', 'hard'];
    static CREATE_IDEA_DIFFICULTIES = ['easy', 'medium', 'hard'];
    static CHECK_DATA_DIFFICULTIES = ['easy', 'medium'];

    static VERTICAL_PAGE_WIDTH = 500;
    static VERTICAL_PAGE_HEIGHT = 700;
    static PAGE_RATIO_VS_A4 = 0.35;

    static WORKSHEET_TAG = 361;
    static TASKCARD_TAG = 362;
    static COLOR_BY_CODE_TAG = 364;

    static WORKSHEET_DESIGN_VALIDATION_CRITERIAS = [
        'Đã check Chất lượng design bộ đen trắng',
        'Đảm bảo đã thêm answer sheet template',
    ];

    static TASKCARD_DESIGN_VALIDATION_CRITERIAS = [
        'Đã tạo bộ màu và check Chất lượng design bộ màu',
        'Đã thêm page_index và check Hiển thị page_index trong cả 2 bộ đen trắng và màu',
        'Đảm bảo đã thêm answer sheet template',
    ];

    static CBC_DESIGN_VALIDATION_CRITERIAS = [
        'Đã check Chất lượng design bộ đen trắng',
        'Đã tạo bộ màu và check Chất lượng design bộ màu',
    ];

    static WIDTH_A4 = 495;
    static HEIGHT_A4 = 700;
    static LITE_CANVAS_SCALE = 0.62;

    static THUMBNAIL_WIDTH_A4 = 150;
    static THUMBNAIL_HEIGHT_A4 = 215;
    static LITE_CANVAS_THUMBNAIL_SCALE = 0.19;

    static DEFAULT_HEADLINE = {
        "id": "1702842159772_96529173",
        "idType": "",
        "activityId": "",
        "pageIndex": 0,
        "x": 0,
        "y": 54.666666666666686,
        "type": 4,
        "worksheet": "",
        "width": 794,
        "height": 56,
        "rotate": 0,
        "textAttribute": {
            "offset": 0,
            "direction": 0,
            "blur": 0,
            "transparency": 0,
            "intensity": 0,
            "thickness": 0,
            "roundness": 0,
            "spread": 0,
            "color": "#212121",
            "textEffectId": 0,
            "fontFamily": "ComicSansMS",
            "align": "center",
            "isBold": true,
            "isItalic": false,
            "fontColor": "#212121",
            "fontSize": 40,
            "content": "DEMO HEADLINE",
            "isAppliedAutoFontSize": true,
            "autoFill": false,
            "lineHeight": 1.4,
            "underline": false,
            "cmd": {
                "id": 0,
                "content": ""
            },
            "subTexts": [],
            "letterSpacing": 0,
            "fontColors": []
        },
        "wordList": [],
        "isShow": true,
        "opacity": 1,
        "moduleId": "",
        "isGroup": false,
        "resourceIds": [],
        "backgroundColor": "#FFFFFF",
        isHeadline: true
    };

    static NAME_TEXT_ITEM = {
        "id": "1729149382232_96229302",
        "idType": "",
        "activityId": "",
        "pageIndex": 0,
        "x": 30,
        "y": 10,
        "type": 4,
        "worksheet": "",
        "width": 300,
        "height": 30,
        "rotate": 0,
        "textAttribute": {
            "offset": 0,
            "direction": 0,
            "blur": 0,
            "transparency": 0,
            "intensity": 0,
            "thickness": 0,
            "roundness": 0,
            "spread": 0,
            "color": "#212121",
            "textEffectId": 0,
            "fontFamily": "ComicSansMS",
            "align": "left",
            "isBold": true,
            "isItalic": false,
            "fontColor": "#212121",
            "fontSize": 20,
            "content": "Name:.........................",
            "autoFill": false,
            "isAppliedAutoFontSize": true,
            "lineHeight": 1.4,
            "underline": false,
            "cmd": {
                "id": 0,
                "content": ""
            },
            "subTexts": [],
            "letterSpacing": 0,
            "fontColors": []
        },
        "wordList": [],
        "isShow": true,
        "opacity": 1,
        "moduleId": "",
        "isGroup": false,
        "resourceIds": [],
        "backgroundColor": "#FFFFFF",
        "isNameText": true
    };

    static DEFAULT_FONT = 'Georgia';

    static S3_AUTO_CONTENT_TEMPLATE_DOMAIN = 'https://auto-content-templates.s3.us-west-2.amazonaws.com/';

    static TYPE_IDEA_PAGE_SELECT_OPTION = {
        'THEME_TYPE': 'theme',
        'SKILL_TYPE': 'skill',
        'TRAINING_TEXT_TYPE': 'trainingText'
    }

    static TYPE_RESOURCE_ZONE = 50;
    static TYPE_RESOURCE_ZONE_STATIC = 52;

    static TYPE_RESOURCE_ZONE_IMAGE = 51;

    static EXPORTED_IFRAME_STATUS = 'EXPORTED_IFRAME';

    static RESOURCE_ELEMENT = 5;

    static LIMIT_RESOURCE = 40;

    static TASK_FLOW_STEP_LABELS = {
        DESIGN_DONE: 6,
        DATA_DONE: 5,
        LAYOUT_DONE: 3,
    }

    static getRequirementExplanation = (cond, customID) => {
        switch (cond) {
            case 'subjects':
                return <span>Fill the Subjects in the idea's popup.</span>;
            case 'grades':
                return <span>Fill the Grades in the idea's popup.</span>;
            case 'price':
                return <span>Fill the Price in the idea's popup.</span>;
            case 'layout_done':
                return <span>Check the Layout Done checkbox.</span>;
            case 'design_done':
                return <span>Check the Design Done checkbox.</span>;
            case 'content_done':
                return <span>All auto pages should have content.</span>;
            case 'all_pages_data_validated':
                return <span>Validate all pages' content in the <a href={'/content-studio?customID=' + customID} target="_blank">content studio page</a></span>;
            case 'all_pages_design_validated':
                return <span>Validate all pages' design in the <a href={'/content-studio?customID=' + customID} target="_blank">content studio page</a></span>;
            case 'taskcard_designs_submitted':
                return <span>Nhập 3 links canva color, b&w, answer sheet và up 3 file zip tương ứng trong <a href={'/format-ai-chat-taskcard?customID=' + customID} target="_blank">trang quản lý taskcard</a></span>
            case 'thumbnails':
                return <span>Render all Thumbnails in the idea's popup.</span>;
            case 'previews':
                return <span>Render all Previews in the idea's popup.</span>;
            case 'title':
                return <span>Fill the Title in the idea's popup.</span>;
            case 'description':
                return <span>Fill the Description in the idea's popup.</span>;
            case 'titleFree':
                return <span>Fill the Title FREE in the idea's popup.</span>;
            case 'descriptionFree':
                return <span>Fill the Description FREE in the idea's popup.</span>;
            case 'thumbnailTitle':
                return <span>Fill the Thumbnail Title in the idea's popup.</span>;
            case 'thumbnailSubTitle':
                return <span>Fill the Thumbnail SubTitle in the idea's popup.</span>;
            case 'type_tag':
                return <span>Choose at least one product tag (Worksheet, Taskcard, Color by Code...).</span>;
            case 'free_pages':
                return <span>Worksheets must have at least 5 free pages.<br />Taskcards must have at least 8 free pages.<br />Color By Code must have 3 free pages.<br />Tick on checkbox free page in the <a href={'/content-studio?customID=' + customID} target="_blank">content studio page</a></span>;
            case 'highlight_pages':
                return <span>A product must have at least 4 highlight pages. Tick on checkbox highlight page in the <a href={'/content-studio?customID=' + customID} target="_blank">content studio page</a></span>;
            case 'original_design_file':
                return <span>Up file AI gốc trong trang <a href={'/content-studio?customID=' + customID} target="_blank">content studio page</a></span>
            case 'taskDifficulty':
                return <span>Choose a task difficulty in the idea's popup.</span>
            case 'product_exists':
                return <span>Product has been uploaded successfully</span>
            default:
                return null;
        }
    }
}