import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { updateDataAPI } from '../../../../utils/query';
import { toast } from 'react-toastify';
import TemplateSearchModal from '../../../template-search-modal';
import './styles.scss';
import { getLastPositionIndexTemplateInCollection } from '../../../../utils/templateManagementUtils';
import AsyncSelectComponent from '../../../../utils/async-select';

export const TemplateOptionLabel = ({ template }) => (
    <span>{template.value}. {template.label}</span>
);

const AddTemplateToCollection = ({
    collection = {},
    templates = [],
    updateTemplatePositionInCollection = async () => { },
    refresh = async () => { },
}) => {
    const [loading, setLoading] = useState(false);
    const [showTemplateSearchModal, setShowTemplateSearchModal] = useState(false);

    const handleAddTemplatesToCollection = async (selectedValues = []) => {
        setLoading(true);
        const promises = selectedValues.map((value) => addTemplateToCollection(value));
        await Promise.all(promises);
        await refresh('templates');
        setLoading(false);
    };

    const addTemplateToCollection = async (selectedTemplate) => {
        if (selectedTemplate !== null && selectedTemplate.hasOwnProperty('id')) {
            try {
                const lastPositionIndex = getLastPositionIndexTemplateInCollection(templates, collection['id']);
                const newTemplateCollection = {
                    template: selectedTemplate['id'],
                    collection: collection['id'],
                    positionIndex: lastPositionIndex + 1,
                };
                const res = await updateDataAPI('POST', 'auto-content/template-collections/', newTemplateCollection);

                const newTemplatesData = [
                    ...templates,
                    {
                        ...selectedTemplate,
                        positionIndex: lastPositionIndex + 1,
                        templateCollectionID: res.data['id']
                    },
                ];

                await updateTemplatePositionInCollection(newTemplatesData);
            } catch (e) {
                toast.error(`An error occured when adding template: ${e.message}`);
            };
        };
    };

    return (
        <div className="add-template-to-collection">
            <div className="add-template-ways">
                <AsyncSelectComponent
                    searchUrl='auto-content/templates/?keyword='
                    onChange={selectedValue => addTemplateToCollection(selectedValue)}
                    value={{ value: '', label: '' }}
                    setLoading={setLoading}
                    loading={loading}
                    clearSelection={() => {}}
                    defaultData={[]}
                />
                {!loading && (
                    <Button variant="success" onClick={() => setShowTemplateSearchModal(true)}>
                        Select Template
                    </Button>
                )}
            </div>
            {showTemplateSearchModal && (
                <TemplateSearchModal
                    usedTemplateIDs={[]}
                    hideModal={() => setShowTemplateSearchModal(false)}
                    handleInsertTemplates={handleAddTemplatesToCollection}
                    showCollectionsTab={false}
                />
            )}
        </div>
    )
}

export default AddTemplateToCollection;