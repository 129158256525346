import { Modal, Table } from "react-bootstrap";

const HotKeyTutorialModal = ({hideModal}) => {
    return (
        <Modal show={true} onHide={hideModal} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Hotkeys</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover size="sm" centered>
                    <thead>
                        <tr>
                            <th>Page</th>
                            <th>HotKey</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Any Page</td>
                            <td>Ctrl/Command I</td>
                            <td>Open Idea Quick Search Popup</td>
                        </tr>
                        <tr>
                            <td>Content Studio</td>
                            <td>Ctrl/Command G</td>
                            <td>Go to page</td>
                        </tr>
                        <tr>
                            <td>Content Studio</td>
                            <td>Ctrl/Command P</td>
                            <td>Pages Management</td>
                        </tr>
                        <tr>
                            <td>Content Studio</td>
                            <td>Ctrl/Command O</td>
                            <td>Outline Management</td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    )
}

export default HotKeyTutorialModal;