import { Button, Form } from 'react-bootstrap';
import { updateDataAPI } from '../../../../utils/query';
import './styles.scss';
import { useState } from 'react';

const AnswerKeyResult = ({designWorksheet, updateAnswerKey}) => {
    const [answerKey, setAnswerKey] = useState(designWorksheet.answerKey);

    const save = async () => {
        updateAnswerKey(answerKey);
        await updateDataAPI('PATCH', 'product-dev/design-worksheets/' + designWorksheet.id + '/', {
            answerKey
        });
        window.alert('Answer Key saved successfully');
    }

    return (
        <div className="manual-content-answer-key">
            <h3>Answer Key</h3>
            <Form.Control
                as="textarea" rows={7}
                value={answerKey} onChange={e => setAnswerKey(e.target.value)} />

            <div className="btns">
                {answerKey !== designWorksheet.answerKey && (
                    <Button size="sm" variant="success" onClick={save}>Save</Button>
                )}
            </div>
        </div>
    );
}

export default AnswerKeyResult;