import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getDataFromAPI } from '../../utils/query';
import { useDispatch, useSelector } from 'react-redux';
import { setStaffs } from '../../redux/staffSlice';
import { fetchStaffsExceptAdmin } from '../staffs';
import FilterByDateRange from '../../filters/datepicker';
import moment from 'moment';
import ListTickets from '../../components/performance-reports/list-tickets';
import './styles.scss';

const PerformanceReportsPage = () => {
    const dispatch = useDispatch();
    const startDate = useSelector((state) => state.filters.startDate);
    const endDate = useSelector((state) => state.filters.endDate);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [staffOptions, setStaffOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [appliedFilterDate, setAppliedFilterDate] = useState(false);

    useEffect(() => {
        const getStaffs = async () => {
            setLoading(true);
            const staffsExceptAdmin = await fetchStaffsExceptAdmin();
            dispatch(setStaffs(staffsExceptAdmin));
            setStaffOptions([{ label: 'All Staffs', value: 'all' }].concat(staffsExceptAdmin.map((staff) => ({
                ...staff,
                label: staff.id + ' ' + staff.name,
                value: staff.id,
            }))));
            setLoading(false);
        };

        getStaffs();
    }, []);

    useEffect(() => {
        fetchStaffPerformanceTickets();
    }, [appliedFilterDate, selectedStaff])

    const fetchStaffPerformanceTickets = async () => {
        if (selectedStaff) {
            setLoading(true);

            let url = `product-dev/performance-tickets/?staffID=${selectedStaff.value}`;

            if (appliedFilterDate && startDate && endDate) {
                url += `&startDate=${startDate}&endDate=${endDate}`;
            }

            try {
                const res = await getDataFromAPI(url);

                setTickets(res.data);
            } catch (err) {
                console.error('An error occured when fetching performance tickets: ', err.message);
            };
            setLoading(false);
        };
    };

    const handleFilterByDate = (e) => {
        setAppliedFilterDate(e.target.checked);
    }

    return (
        <Container className="performance-reports-page">
            <h2>Filter tickets:</h2>
            <div className="performance-reports-page__filter">
                <Select
                    className="select-staff"
                    classNamePrefix="select"
                    defaultValue={staffOptions[0]}
                    isLoading={loading}
                    isSearchable
                    name="staff"
                    options={staffOptions}
                    value={selectedStaff}
                    onChange={(selectedValue) => setSelectedStaff(selectedValue)}
                />
                <Form.Check
                    type='checkbox'
                    checked={appliedFilterDate}
                    label={'Filter by date range'}
                    onChange={handleFilterByDate}
                />
                <FilterByDateRange defaultDateRange={{ startDate: moment().toDate(), endDate: moment().toDate() }} />
                <Button onClick={fetchStaffPerformanceTickets}>
                    Search
                </Button>
            </div>
            <div>
                <ListTickets 
                    tickets={tickets}
                    setTickets={setTickets}
                />
            </div>
        </Container>
    )
};

export default PerformanceReportsPage;