import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import axios from 'axios';
import { checkObjectHasNullebaleValue } from '../../../utils/commonUtil';
import SearchBar from '../search-bar';

import './styles.scss';

const ImagesGoogleUploadTab = ({
    selectedIcon,
    handleSelectedIcon = () => { },
}) => {
    const resultsPerPage = 10;
    const [icons, setIcons] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [query, setQuery] = useState('');

    const fetchIconsInGoogleImages = async (pageIndex = currentPage) => {
        if (!checkObjectHasNullebaleValue({ query })) return;

        try {
            const startIndex = (pageIndex - 1) * resultsPerPage;
            const apiKey = process.env.REACT_APP_GOOGLE_ENGINE_API_KEY;
            const searchEngineId = process.env.REACT_APP_GOOGLE_ENGINE_SEARCH_ENGINE_KEY;
            // const searchQuery = `${query} b%26w icon`;
            const url = `https://www.googleapis.com/customsearch/v1?searchType=image&q=${query}&num=${resultsPerPage}&start=${startIndex}&key=${apiKey}&cx=${searchEngineId}`;

            const response = await axios.get(url);
            const data = response.data;
            if (pageIndex > 1) {
                setIcons(icons.concat(data.items));
            } else {
                setIcons(data.items);
            }
        } catch (error) {
            console.error('Error fetching image search results:', error);
        }
    }

    useEffect(() => {
        fetchIconsInGoogleImages();
    }, [currentPage]);

    return (
        <Container className="results-icons" fluid>
             <SearchBar
                query={query}
                handleChangeQuery={(e) => setQuery(e.target.value.toLowerCase())}
                handleSearchByQuery={(e) => {
                    e.preventDefault();
                    setCurrentPage(1);
                    fetchIconsInGoogleImages(1);
                }}
            />
            <div>
                {icons.map((result) => (
                    <span
                        key={result.link}
                        sm={6} md={4} lg={1}
                        className="icon"
                        style={{
                            border: selectedIcon?.link === result?.link ? '1px solid green' : ''
                        }}
                    >
                        <Image
                            src={result.link}
                            alt={result.title}
                            width={300}
                            onClick={() => handleSelectedIcon({ link: result.link, title: result.title, source: 'google' })}
                            className="img-fluid"
                            thumbnail
                        />
                    </span>
                ))}
            </div>
            <div>
                <Button onClick={() => setCurrentPage(prev => (prev + 1))} className="mt-2 mb-2" variant="success">
                    Load more icons
                </Button>
            </div>
        </Container>
    );
};

export default ImagesGoogleUploadTab;