import { useEffect, useState } from 'react';
import './styles.scss';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { getDataFromAPI } from '../../utils/query';
import moment from 'moment';

const MultiValues = ({ value, setValue, isMulti = true, canCreateNewItem = false, choicesDataAPI = null, labelField, valueField, refreshChoicesOnFocus = false, defaultChoices = null }) => {
    const [choices, setChoices] = useState();

    useEffect(() => {
        if (choicesDataAPI !== null && defaultChoices === null) {
            fetchChoicesFromAPI();
        } else {
            setChoices(defaultChoices.map(choice => ({ label: choice, value: choice })));
        }
    }, []);

    const fetchChoicesFromAPI = async () => {
        const res = await getDataFromAPI(choicesDataAPI);
        if (choicesDataAPI === 'resources/subjects/') {
            const choices = [];
            for (let i = 0; i < res.data.length; i++) {
                const subject = res.data[i];
                if (subject['parent'] === null) {
                    choices.push({
                        label: subject['name'],
                        options: []
                    })
                } else {
                    const parentIndex = choices.findIndex(c => c.label === subject['parentName']);
                    choices[parentIndex]['options'].push({
                        label: subject['name'],
                        value: subject['name'],
                    });
                }
            }
            setChoices(choices);
        } else if (choicesDataAPI.startsWith('product-dev/design-worksheet-activities/?productIdea')) {
            const choices = [];
            for (let i = 0; i < res.data.length; i++) {
                const activity = res.data[i];
                if (choices.find(c => c.value === activity['activity']) === undefined) {
                    choices.push({
                        label: activity['activity'],
                        value: activity['activity'],
                    });
                }
            }
            setChoices(choices);
        } else if (choicesDataAPI.startsWith('product-dev/staffs/')) {
            setChoices(res.data.map(c => ({
                label: c['name'] + (c['description'] ? (' (' + c['description'] + ')') : ''),
                value: c['id']
            })));
        } else if (choicesDataAPI.startsWith('resources/events/?additionalFields=nbIcons')) {
            setChoices(res.data.map(c => ({
                label: c['event'] + ' - ' + c['nbIcons'],
                value: c['id']
            })));
        } else if (choicesDataAPI.startsWith('resources/events/')) {
            setChoices(res.data.map(c => ({
                label: c['event'] + ' (' + moment(c['startDate']).format('DD/MM') + ' - ' + moment(c['endDate']).format('DD/MM') + ')',
                value: c['id']
            })));
        } else if (choicesDataAPI.startsWith('resources/tags/?additionalFields=nbIcons')) {
            setChoices(res.data.map(c => ({
                label: c['name'] + ' - ' + c['nbIcons'],
                value: c['id']
            })));
        } else if (choicesDataAPI.startsWith('product-dev/task-flow-steps/')) {
            setChoices(res.data.map(c => ({
                label: c['categoryName'] + ' - ' + c['pos'] + '. ' + c['label'],
                value: c['id']
            })));
        } else {
            setChoices(res.data.map(item => ({
                label: item[labelField],
                value: item[valueField]
            })));
        }
    }

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    )

    const handleFocus = async () => {
        if (refreshChoicesOnFocus) {
            fetchChoicesFromAPI();
        }
    }

    if (choices === undefined) {
        return <div>Loading...</div>;
    }

    return (
        <div className="multi-values-form">
            {canCreateNewItem ? (
                <CreatableSelect
                    className="select-container"
                    closeMenuOnSelect={false}
                    defaultValue={value ? value.split(',').map(val => {
                        const choice = choices.find(c => c.value == val);
                        if (choice) {
                            return choice;
                        }
                        return { label: val, value: val };
                    }) : []}
                    isMulti={isMulti}
                    options={choices}
                    onFocus={handleFocus}
                    onChange={selectedValues => {
                        if (isMulti) {
                            setValue(selectedValues.map(item => item.value).join(','))
                        } else {
                            setValue(selectedValues.value);
                        }
                    }} />
            ) : (
                <Select
                    className="select-container"
                    closeMenuOnSelect={false}
                    defaultValue={value ? value.split(',').map(val => {
                        const choice = choices.find(c => c.value == val);
                        if (choice) {
                            return choice;
                        }
                        return { label: val, value: val };
                    }) : []}
                    isMulti={isMulti}
                    options={choices}
                    onChange={selectedValues => {
                        if (isMulti) {
                            setValue(selectedValues.map(item => item.value).join(','))
                        } else {
                            setValue(selectedValues.value);
                        }
                    }}
                    formatGroupLabel={formatGroupLabel} />
            )}
        </div>
    )
}

export default MultiValues;