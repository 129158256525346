import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Container, Spinner, Table } from 'react-bootstrap';
import Constant from '../../../Constant';

import './styles.scss';

const CollectedResourcesSearchQueriesPage = () => {
    const [queries, setQueries] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchQueries = async() => {
            setLoading(true);
            const res = await axios.get(Constant.COLLECTED_RESOURCES_URL + 'search-queries/');
            setQueries(res.data);
            setLoading(false);
        }

        fetchQueries();
    }, []);

    const updateQuery = async(query) => {
        setLoading(true);
        await axios.patch(Constant.COLLECTED_RESOURCES_URL + 'search-queries/' + query['_id'], query);
        setQueries(queries.map(q => {
            if (q['_id'] === query['_id']) {
                return query;
            }
            return q;
        }))
        setLoading(false);
    }

    return (
        <Container className="collected-resources-search-queries-page">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Query</th>
                        <th>Done</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {queries.map(query => (
                        <tr key={query['_id']}>
                            <td>{query['query']}</td>
                            <td>{query['done'] ? 'DONE' : ''}</td>
                            <td>
                                <div className="btns">
                                    <Button size="sm" variant="danger">Delete</Button>
                                    {query['done'] ? (
                                        <Button size="sm" onClick={() => updateQuery({...query, done: false})}>Undone</Button>
                                    ) : (
                                        <Button size="sm" variant="success" onClick={() => updateQuery({...query, done: true})}>Set Done</Button>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default CollectedResourcesSearchQueriesPage;