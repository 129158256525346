import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

import './styles.scss';
import { Fragment, useState } from 'react';
import { updateDataAPI } from '../../../utils/query';
import { useDispatch, useSelector } from 'react-redux';
import { updateHeadlineInAllContents, updateIdeaSetting } from '../autoContentSlice';
import FontUploadFormModal from './font-upload-form-modal';

const IdeaSettingModal = ({ idea, hideModal }) => {
    const dispatch = useDispatch();

    const { ideaSetting } = useSelector(state => state.autoContent);

    const [noteForAI, setNoteForAI] = useState(ideaSetting.noteForAI);
    const [headline, setHeadline] = useState(ideaSetting.headline);
    const [showUploadFontModal, setShowUploadFontModal] = useState(false);

    const save = async () => {
        await updateDataAPI('PATCH', 'auto-content/idea-settings/' + ideaSetting.id + '/', {
            noteForAI,
            headline,
        });
        if (noteForAI !== ideaSetting.noteForAI) {
            dispatch(updateIdeaSetting({ field: 'noteForAI', value: noteForAI }));
        }
        if (headline !== ideaSetting.headline) {
            dispatch(updateIdeaSetting({ field: 'headline', value: headline }));
            dispatch(updateHeadlineInAllContents());
        }
        hideModal();
    }

    return (
        <Fragment>
            <Modal
                fullscreen={true}
                centered
                show={true}
                onHide={hideModal}
                className="auto-content-studio-idea-setting-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {idea.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form className="mb-5">
                                <Form.Group className="mb-3">
                                    <Form.Label>Headline</Form.Label>
                                    <Form.Control
                                        value={headline}
                                        onChange={(e) => setHeadline(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Vocab Note</Form.Label>
                                    <Form.Control
                                        value={noteForAI}
                                        onChange={(e) => setNoteForAI(e.target.value)}
                                        as="textarea"
                                        rows={15}
                                    />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideModal}>Close</Button>
                    <Button variant="success" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
            {showUploadFontModal && (
                <FontUploadFormModal
                    handleClose={() => setShowUploadFontModal(false)}
                />
            )}
        </Fragment>
    );
}

export default IdeaSettingModal;