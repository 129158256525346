import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { updateDataAPI } from '../../../../utils/query';

const GPTPartModal = ({ideaID, part, templates, hideModal}) => {
    const [chatUrl, setChatUrl] = useState(part ? part.chatUrl : null);
    const [template, setTemplate] = useState(part ? part.template : null);
    const [generateMessage, setGenerateMessage] = useState(part ? part.generateMessage : null);
    const [bot, setBot] = useState(part ? part.bot : null);

    useEffect(() => {
        console.log(part);
    }, []);

    const save = async() => {
        if (chatUrl === null || template === null || generateMessage === null || bot === null) {
            window.alert('Please fill all required fields')
            return;
        }

        if (generateMessage.indexOf('#ID') === -1) {
            window.alert('Please include #ID in Generate Message');
            return;
        }

        if (part) {
            await updateDataAPI('PATCH', `product-dev/gpt-parts/${part.id}/`, {chatUrl, template, generateMessage, bot});
        } else {
            await updateDataAPI('POST', 'product-dev/gpt-parts/', {chatUrl, template, generateMessage, bot, idea: ideaID});
        }
        hideModal('refresh');
    }

    return (
        <Modal show={true} onHide={() => hideModal('hide')} className="gpt-part-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    GPT Part
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Chat URL*</strong></Form.Label>
                        <Form.Control value={chatUrl} onChange={e => setChatUrl(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Template*</strong></Form.Label>
                        <Form.Select value={template} onChange={e => setTemplate(e.target.value)}>
                            <option>Select a Template</option>
                            {templates.map((template) => (
                                <option key={template.id} value={template.id}>{template.id}. {template.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Account*</strong></Form.Label>
                        <Form.Select value={bot} onChange={e => setBot(parseInt(e.target.value))}>
                            <option>Select an Account</option>
                            <option value={1}>Account 1</option>
                            <option value={2}>Account 2 (BeeClear)</option>
                            <option value={3}>Account 3 (Ecotek)</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Generate Message*</strong></Form.Label>
                        <Form.Control as="textarea" rows={10} value={generateMessage} onChange={e => setGenerateMessage(e.target.value)} />
                        <Form.Text>Remember to replace the page id by #ID to auto generate</Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={save}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default GPTPartModal;