import UploadNewTopicImage from '../upload-new-topic-image';
import ImageWrapper from '../../../components/common/image-wrapper';
import { updateDataAPI } from '../../../utils/query';

const UploadTopicImageCell = ({ topic, handleUpdatedTopic = () => { }, dataSet = {}, dataSetIcon, setDataSetIcon }) => {

    const handleChangeDataSetIcon = async (updatedIconID) => {
        try {
            let res;
            const url = dataSetIcon ? `resources/data-set-icons/${dataSetIcon.id}/` : 'resources/data-set-icons/';

            if (dataSetIcon) {
                res = await updateDataAPI('PATCH', url, {
                    icon: updatedIconID || null,
                });
            } else {
                res = await updateDataAPI('POST', url, {
                    topic: topic.id,
                    dataSet: dataSet.id,
                    icon: updatedIconID || null,
                });
            }

            setDataSetIcon(res.data);
        } catch (err) {
            const errorMessage = dataSetIcon
                ? `An error occurred when updating topic group icon with id ${dataSetIcon.id}: ${err.message}`
                : `An error occurred when creating topic group icon: ${err.message}`;

            console.error(errorMessage);
        }
    };

    return (
        <div>
            <div className="d-flex align-items-center gap-2">
                <div className="m-auto mb-2">
                    {topic?.imageUrl && (
                        <ImageWrapper src={topic.imageUrl} alt={`Topic image ${topic.name}`} className="topic-image" />
                    )}
                </div>
            </div>
            <UploadNewTopicImage
                topic={topic}
                topicIcon={dataSetIcon?.icon}
                topicName={topic.name}
                handleUploadedTopic={handleUpdatedTopic}
                handleChangeIconOption={handleChangeDataSetIcon}
                type='dataSets'
            />
        </div>
    )
}

export default UploadTopicImageCell;