import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PerformanceTicketForm from '../../performance-ticket-form';

const PerformanceTicketEditModal = ({
    selectedTicket,
    hideModal = () => { },
    handleSaveChange = () => { },
}) => {
    return (
        <Modal show={true} onHide={hideModal} className="performance-ticket-edit-modal" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit performance ticket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PerformanceTicketForm 
                    performanceTicket={selectedTicket} 
                    defaultData={false}
                    handleSaveChange={handleSaveChange}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={hideModal} variant="secondary">Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PerformanceTicketEditModal;