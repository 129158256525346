import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTaskFlow } from '../filtersSlice';
import './styles.scss';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../utils/query';
import { useHistory, useLocation } from 'react-router-dom';

const FilterByTaskFlow = ({ value }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const selectedTaskFlow = useSelector((state) => state.filters.selectedTaskFlow);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        if (selectedTaskFlow !== null && !isNaN(selectedTaskFlow)) {
            const params = new URLSearchParams({ 'taskFlow': selectedTaskFlow });
            history.replace({ pathname: location.pathname, search: params.toString() });
        }
    }, [selectedTaskFlow]);

    const fetchCategories = async () => {
        const res = await getDataFromAPI('resources/categories/');
        setCategories(res.data);
        const selectedTaskFlow = (new URLSearchParams(window.location.search)).get('taskFlow');
        if (selectedTaskFlow !== null && !isNaN(selectedTaskFlow)) {
            dispatch(setSelectedTaskFlow(parseInt(selectedTaskFlow)));
        } else {
            dispatch(setSelectedTaskFlow(res.data[0].id));
        }
    }

    return (
        <div className="filter-by-category">
            <Form.Select onChange={e => {
                dispatch(setSelectedTaskFlow(parseInt(e.target.value)));
            }} value={selectedTaskFlow}>
                {categories.map(cat => (
                    <option value={cat.id} key={cat.id}>{cat.name}</option>
                ))}
            </Form.Select>
        </div>
    );
}

export default FilterByTaskFlow;