import Pagination from "react-bootstrap/Pagination";

const IconsPagination = ({ currentPage, setCurrentPage, nbPages }) => {

    return (
        <div className="d-flex justify-content-center">
            <Pagination>
                {currentPage > 1 && (
                    <>
                        <Pagination.First onClick={() => setCurrentPage(1)} />
                        <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />
                    </>
                )}
                {[3, 2, 1].map(index => (
                    currentPage > index && (
                        <Pagination.Item key={index} onClick={() => setCurrentPage(currentPage - index)}>
                            {currentPage - index}
                        </Pagination.Item>
                    )
                ))}
                <Pagination.Item active={true}>
                    {currentPage}
                </Pagination.Item>
                {[1, 2, 3].map(index => (
                    currentPage + index <= nbPages && (
                        <Pagination.Item key={index} onClick={() => setCurrentPage(currentPage + index)}>
                            {currentPage + index}
                        </Pagination.Item>
                    )
                ))}
                {currentPage < nbPages && (
                    <>
                        <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />
                        <Pagination.Last onClick={() => setCurrentPage(nbPages)} />
                    </>
                )}
            </Pagination>
        </div>
    );
}

export default IconsPagination;