import { Button, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import NonAIGenerateModal from '../../components/auto-content/auto-content-prompt-cell/non-ai-generate-modal';

const TestFunctionPage = () => {
    const idea = { id: 3 };
    const [showNonAIGenerateModal, setShowNonAIGenerateModal] = useState(false);
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    const page = { id: 3, name: 'Example Page' };

    const handleShowNonAIGenerateModal = () => {
        setShowNonAIGenerateModal(true);
    }

    return (
        <div>
            <Button onClick={() => handleShowNonAIGenerateModal(page)}>Test Function</Button>
            {showNonAIGenerateModal && (
                <NonAIGenerateModal
                    idea={idea}
                    ideaTemplateIDs={[1085]}
                    ideaSkill={null}
                    currentFunctionID={null}
                    currentFunctionArgs={null}
                    refresh={() => {}}
                    hideModal={() => setShowNonAIGenerateModal(false)}
                    setLoading={setLoading}
                    target='generate'
                    showTestResult={data => setResult(data)}
                />
            )}
            {result && (
                <div>
                    <p>Result:</p>
                    <pre>{JSON.stringify(result, null, 2)}</pre>
                </div>
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </div>
    );
}

export default TestFunctionPage;