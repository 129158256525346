import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faColumns, faList, faWarning } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import { Alert, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../../utils/query';

const FloatingToolbar = ({ pages, discordChannelID, customID, showPagesControl, showOutlineModal, pageResultDisplayMode, setPageResultDisplayMode }) => {
    // const [showChatPopup, setShowChatPopup] = useState(false);
    const [designTickets, setDesignTickets] = useState([]);

    useEffect(() => {
        fetchDesignTickets();
    }, []);

    const fetchDesignTickets = async () => {
        const res = await getDataFromAPI('product-dev/design-tickets/?customID=' + customID);
        setDesignTickets(res.data);
    }

    return (
        <div className="floating-toolbar bg-primary">
            <FontAwesomeIcon icon={faBars} />
            {designTickets.filter(ticket => !ticket.done).length > 0 && (
                <FontAwesomeIcon icon={faWarning} className="text-warning warning-icon" />
            )}

            <div className="content">
                <Alert className="info-container">
                    <div>Total Pages: {pages.length}</div>
                    <Form className="page-result-display-mode">
                        <Form.Group controlId="resultDisplayMode">
                            <Form.Check
                                id="result-display-lite-canvas"
                                label="Simple Display"
                                type="checkbox"
                                onChange={() => setPageResultDisplayMode('simple')}
                                checked={pageResultDisplayMode === 'simple'}
                            />
                            <Form.Check
                                id="result-display-design-canvas"
                                label="Design Display"
                                type="checkbox"
                                onChange={() => setPageResultDisplayMode('design')}
                                checked={pageResultDisplayMode === 'design'}
                            />
                        </Form.Group>
                    </Form>
                    <div>
                        <a href="/design-tickets" target="_blank">
                            Design Tickets: {designTickets.filter(ticket => !ticket.done).length} running, {designTickets.filter(ticket => ticket.done).length} finished
                        </a>
                    </div>
                </Alert>
                {/* <div className="icon-container bg-primary" onClick={() => setShowChatPopup(true)}>
                    <FontAwesomeIcon icon={faComment} />
                </div> */}
                <div className="icon-container bg-primary" onClick={showPagesControl}>
                    <FontAwesomeIcon icon={faColumns} />
                </div>
                <div className="icon-container bg-primary" onClick={showOutlineModal}>
                    <FontAwesomeIcon icon={faList} />
                </div>
            </div>

            {/* {discordChannelID && (
                <div className="task-chat-popup">
                    {showChatPopup && (
                        <WidgetBot
                            server="716349344403423402"
                            channel={discordChannelID}
                            width={400}
                            height={600}
                        />
                    )}

                    {showChatPopup && (
                        <span className="task-chat-trigger" onClick={() => setShowChatPopup(!showChatPopup)}>
                            <FontAwesomeIcon icon={faClose} />
                        </span>
                    )}
                </div>
            )} */}
        </div>
    )
}

export default FloatingToolbar;