import { useState, useEffect } from 'react';
import { getDataFromAPI } from '../../utils/query';
import './styles.scss';

const StaffGenDataTasksDashboardPage = () => {
    const [staffs, setStaffs] = useState([]);
    const [inProgressTasks, setInProgressTasks] = useState([]);
    const [doneTasks, setDoneTasks] = useState([]);

    const fetchData = async () => {
        try {
            const staffData = await getDataFromAPI('product-dev/staffs/?roles=product-collector,idea-manager,designer,product-informator');
            console.log('staffData: ', staffData);
            setStaffs(staffData.data);

            const inProgressTasksData = await getDataFromAPI('product-dev/tasks/?stepPos=3&additionalFields=assignedStaffs');
            console.log('In Progress Task: ', inProgressTasksData);
            setInProgressTasks(inProgressTasksData.data);

            const doneTasksData = await getDataFromAPI('product-dev/tasks/?stepPos=4&additionalFields=assignedStaffs');
            console.log('Done Task: ', doneTasksData);
            setDoneTasks(doneTasksData.data);

        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    }

    useEffect(() => {
        fetchData();
        const updatedTime = setInterval(() => {
            fetchData();
        }, 60000);
        return () => clearInterval(updatedTime);
    }, []);

    const getTasksByStaffId = (tasks, staffId) => {
        return tasks.filter(task => task.assignedStaffs.some(staff => staff.id === staffId));
    }

    return (
        <div className="Box-info">
            {staffs.length > 0
                ? (staffs.map(staff => (
                    <div className="task-box" key={staff.id}>
                        <div className="assignees">{staff.name}</div>
                        <div>Các task đang tiến hành:</div>
                        {getTasksByStaffId(inProgressTasks, staff.id).map(task => (
                            <div key={task.id}>
                                <div className="task-title">{task.ideaName ? `${task.customID}. ${task.ideaName}` : task.title}</div>
                                <div className="divider"></div>
                                <div className="task-footer">
                                    <div className="ticket-progress">
                                        <span>Auto:</span>
                                        <div className="progress">
                                            <span style={{
                                                width: `${task.nbPageStatues.totalAutoPages !== 0 ? Math.floor(task.nbPageStatues.nbAutoPagesHasData / task.nbPageStatues.totalAutoPages * 100) : 0}%`,
                                                backgroundColor: `${task.nbPageStatues.totalAutoPages !== 0 && Math.floor((task.nbPageStatues.nbAutoPagesHasData / task.nbPageStatues.totalAutoPages) * 100) > 0 ? 'green' : 'transparent'}`
                                            }}></span>
                                        </div>
                                        <span>{task.nbPageStatues.nbAutoPagesHasData} / {task.nbPageStatues.totalAutoPages} pages (
                                            {`${task.nbPageStatues.totalAutoPages !== 0 ? Math.floor(task.nbPageStatues.nbAutoPagesHasData / task.nbPageStatues.totalAutoPages * 100) : 0}%`})</span>
                                    </div>
                                    <div className="ticket-progress">
                                        <span>Gendata:</span>
                                        <div className="progress">
                                            <span style={{
                                                width: `${task.genDataTicketData.totalParts !== 0 ? Math.floor(task.genDataTicketData.nbFinishedParts / task.genDataTicketData.totalParts * 100) : 0}%`,
                                                backgroundColor: `${task.genDataTicketData.totalParts !== 0 && Math.floor((task.genDataTicketData.nbFinishedParts / task.genDataTicketData.totalParts) * 100) > 0 ? 'green' : 'transparent'}`
                                            }}></span>
                                        </div>
                                        <span>{task.genDataTicketData.nbFinishedParts} / {task.genDataTicketData.totalParts} parts (
                                            {task.genDataTicketData.totalParts !== 0 ? Math.floor(task.genDataTicketData.nbFinishedParts / task.genDataTicketData.totalParts * 100) : 0}%)
                                        </span>
                                    </div>
                                    <div className="ticket-progress">
                                        <span>Export:</span>
                                        <div className="progress">
                                            <span style={{
                                                width: `${task.exportTicketData && task.exportTicketData.totalPages !== 0 ? Math.floor((task.exportTicketData.finishedPages ? task.exportTicketData.finishedPages.length : 0) / task.exportTicketData.totalPages * 100) : 0}%`,
                                                backgroundColor: `${task.exportTicketData && task.exportTicketData.totalPages !== 0 && Math.floor(((task.exportTicketData.finishedPages ? task.exportTicketData.finishedPages.length : 0) / task.exportTicketData.totalPages) * 100) > 0 ? 'green' : 'transparent'}`
                                            }}></span>
                                        </div>
                                        <span>{task.exportTicketData && task.exportTicketData.done ? 100 : task.exportTicketData &&
                                            Math.floor(((task.exportTicketData.finishedPages ? task.exportTicketData.finishedPages.length : 0) / task.exportTicketData.totalPages) * 100)}
                                        </span>
                                    </div>
                                    <div className="ticket-progress">
                                        <span>Design:</span>
                                        <div className="progress">
                                            <span style={{
                                                width: `${task.designTicketData && task.designTicketData.totalPages !== 0 ? Math.floor((task.designTicketData.finishedPages ? task.designTicketData.finishedPages.length : 0) / task.designTicketData.totalPages * 100) : 0}%`,
                                                backgroundColor: `${task.designTicketData && task.designTicketData.totalPages !== 0 && Math.floor(((task.designTicketData.finishedPages ? task.designTicketData.finishedPages.length : 0) / task.designTicketData.totalPages) * 100) > 0 ? 'green' : 'transparent'}`
                                            }}></span>
                                        </div>
                                        <span>{task.designTicketData && task.designTicketData.done ? 100 : task.designTicketData &&
                                            Math.floor(((task.designTicketData.finishedPages ? task.designTicketData.finishedPages.length : 0) / task.designTicketData.totalPages) * 100)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div>Các task đã hoàn thành:</div>
                        {getTasksByStaffId(doneTasks, staff.id).map(task => (
                            <div key={task.id}>
                                <div className="task-title">{task.title}</div>
                                <div className="divider"></div>
                                <div className="task-footer">
                                    <div className="ticket-progress">
                                        <span>Auto:</span>
                                        <div className="progress">
                                            <span style={{
                                                width: `${task.nbPageStatues.totalAutoPages !== 0 ? Math.floor(task.nbPageStatues.nbAutoPagesHasData / task.nbPageStatues.totalAutoPages * 100) : 0}%`,
                                                backgroundColor: `${task.nbPageStatues.totalAutoPages !== 0 && Math.round((task.nbPageStatues.nbAutoPagesHasData / task.nbPageStatues.totalAutoPages) * 100) > 0 ? 'green' : 'transparent'}`
                                            }}></span>
                                        </div>
                                        <span>{task.nbPageStatues.nbAutoPagesHasData} / {task.nbPageStatues.totalAutoPages} pages (
                                            {`${task.nbPageStatues.totalAutoPages !== 0 ? Math.floor(task.nbPageStatues.nbAutoPagesHasData / task.nbPageStatues.totalAutoPages * 100) : 0}%`})</span>
                                    </div>
                                    <div className="ticket-progress">
                                        <span>Gendata:</span>
                                        <div className="progress">
                                            <span style={{
                                                width: `${task.genDataTicketData.totalParts !== 0 ? Math.floor(task.genDataTicketData.nbFinishedParts / task.genDataTicketData.totalParts * 100) : 0}%`,
                                                backgroundColor: `${task.genDataTicketData.totalParts !== 0 && Math.round((task.genDataTicketData.nbFinishedParts / task.genDataTicketData.totalParts) * 100) > 0 ? 'green' : 'transparent'}`
                                            }}></span>
                                        </div>
                                        <span>{task.genDataTicketData.nbFinishedParts} / {task.genDataTicketData.totalParts} parts (
                                            {task.genDataTicketData.totalParts !== 0 ? Math.floor(task.genDataTicketData.nbFinishedParts / task.genDataTicketData.totalParts * 100) : 0}%)
                                        </span>
                                    </div>
                                    <div className="ticket-progress">
                                        <span>Export:</span>
                                        <div className="progress">
                                            <span style={{
                                                width: `${task.exportTicketData && task.exportTicketData.totalPages !== 0 ? Math.floor((task.exportTicketData.finishedPages ? task.exportTicketData.finishedPages.length : 0) / task.exportTicketData.totalPages * 100) : 0}%`,
                                                backgroundColor: `${task.exportTicketData && task.exportTicketData.totalPages !== 0 && Math.round(((task.exportTicketData.finishedPages ? task.exportTicketData.finishedPages.length : 0) / task.exportTicketData.totalPages) * 100) > 0 ? 'green' : 'transparent'}`
                                            }}></span>
                                        </div>
                                        <span>{task.exportTicketData && task.exportTicketData.done ? 100 : task.exportTicketData &&
                                            Math.floor(((task.exportTicketData.finishedPages ? task.exportTicketData.finishedPages.length : 0) / task.exportTicketData.totalPages) * 100)}
                                        </span>
                                    </div>
                                    <div className="ticket-progress">
                                        <span>Design:</span>
                                        <div className="progress">
                                            <span style={{
                                                width: `${task.designTicketData && task.designTicketData.totalPages !== 0 ? Math.floor((task.designTicketData.finishedPages ? task.designTicketData.finishedPages.length : 0) / task.designTicketData.totalPages * 100) : 0}%`,
                                                backgroundColor: `${task.designTicketData && task.designTicketData.totalPages !== 0 && Math.round(((task.designTicketData.finishedPages ? task.designTicketData.finishedPages.length : 0) / task.designTicketData.totalPages) * 100) > 0 ? 'green' : 'transparent'}`
                                            }}></span>
                                        </div>
                                        <span>{task.designTicketData && task.designTicketData.done ? 100 : task.designTicketData &&
                                            Math.floor(((task.designTicketData.finishedPages ? task.designTicketData.finishedPages.length : 0) / task.designTicketData.totalPages) * 100)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>))
                )
                : (
                    <p>No staff data available</p>
                )
            }
        </div>
    );
};

export default StaffGenDataTasksDashboardPage;