import React, { useEffect, useState } from "react";
import SearchTemplate from "./searchTemplate";
import TemplateItem from "./templateItem";
import { useSelector } from "react-redux/es/hooks/useSelector";
import "./styles.scss";

const ChooseTemplatesStep = ({ customID }) => {
    const [matchedTemplates, setMatchedTemplates] = useState([]);
    const { templates } = useSelector(state => state.generateProduct);

    useEffect(() => {
        setMatchedTemplates(templates);
    }, [templates]);

    return (
        <div className="container__result-step-1">
            <SearchTemplate setMatchedTemplates={setMatchedTemplates} />
            <div className="templates">
                {matchedTemplates.map((template) => (
                    <TemplateItem
                        template={template}
                        key={template.id}
                        customID={customID}
                    />
                ))}
            </div>
        </div>
    )
};

export default ChooseTemplatesStep;