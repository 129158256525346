import React from "react";
import Container from "react-bootstrap/Container";
import { updateDataAPI } from "../../../../utils/query";
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setCollections } from "../../colorbycodeMakerSlice";

const ListCollections = ({ setShowEditorCollection }) => {
    const dispatch = useDispatch();
    const { collections } = useSelector(state => state.generateProduct);

    const deleteCollectionOperations = async (collection) => {
        const confirmed = window.confirm('Are you sure you want to delete this collection?');
        if (confirmed) {
            const cloneCollections = [...collections];
            dispatch(setCollections(cloneCollections.filter((item) => item.id !== collection.id)))
            const url = 'product-dev/collection-operations/delete-collection-operations/';
            const res = await updateDataAPI('POST', url, {
                id: collection.id
            });
            if (res.status === 204) {
                toast.info("Your collection has been deleted.");
            } else {
                toast.error("An error occured.")
            };
        };
    };

    return (
        <Container>
            {collections.length > 0 ? (
                <ListGroup as="ol" numbered className="container__result-step-2__list">
                    {collections.map((collection, index) => (
                        <ListGroup.Item
                            key={index}
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <h5 onClick={() => setShowEditorCollection(collection)}>{collection.name}</h5>
                            <div
                                className="container__result-step-2__delete"
                                onClick={() => deleteCollectionOperations(collection)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            ) : (
                <h6>You haven&#39;t any collection yet.</h6>
            )}
        </Container>
    )
};

export default ListCollections;