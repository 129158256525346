import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import './styles.scss';

const CollectionThumbnail = ({ collectionThumbnail = [], collectionID }) => {
    return (
        <Container>
            <div className="layered-images-container">
                {collectionThumbnail.map((image, index) => (
                    <Image
                        key={index}
                        src={image}
                        thumbnail
                        alt={`collection-${collectionID}-cover-image-${index}`}
                        className="layered-image" 
                        style={{
                            left: index * 40,
                        }}/>
                ))}
            </div>
        </Container>
    )
};

export default CollectionThumbnail;