import TableIndexCell from './table-cell-index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import WorksheetMakerIframeModal from '../../../auto-content/worksheet-maker-iframe-modal';
import { Button, Form, Modal } from 'react-bootstrap';
import { updateDataAPI } from '../../../../utils/query';

const TableRow = ({
    rowIndex = 1,
    part = {},
    parts = [],
    pages,
    handleSelectPart = () => { },
    setParts = () => { },
    refresh = () => { },
    setLoading = () => { },
    hideModal,
}) => {
    const [templateInModal, setTemplateInModal] = useState(null);
    const [edittingField, setEdittingField] = useState(null);
    const [promptInModal, setPromptInModal] = useState(null);
    const [example, setExample] = useState(null);
    const [activity, setActivity] = useState(null);
    const [note, setNote] = useState(null);

    const fetchPromptThenShow = async() => {
        const res = await updateDataAPI('POST', 'auto-content/get-idea-template-prompt/?ideaTemplateID=' + part.ideaTemplateIDs[0]);
        setPromptInModal(res.data[0]['content'] + '\n\n' + res.data[1]['content']);
    }

    const goToPage = () => {
        const selectedPage = pages.find(p => p.content.id === part.ideaTemplateIDs[0]);
        if (selectedPage) {
            console.log(selectedPage.id);
            const pageElement = document.getElementById(`row-${selectedPage.id}`);

            if (pageElement) {
                pageElement.scrollIntoView({ behavior: "smooth" });
            }

            hideModal();
        }
    }

    const autoGenerateExample = async() => {
        setLoading(true);
        const resPrompt = await updateDataAPI('POST', 'resources/get-idea-part-prompt/?ideaPartID=' + part.id);
        const resExample = await updateDataAPI('POST', 'resources/auto-generate-idea-part-example/', {
            prompt: resPrompt.data
        });
        updatePart('example', resExample.data);
        setLoading(false);
    }

    const updatePart = async(field, value) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'resources/idea-parts/' + part.id + '/', {
            [field]: value,
        });
        setParts(parts.map(p => {
            if (p.id === part.id) {
                return {...p, [field]: value};
            }
            return p;
        }));
        setLoading(false);
    }

    return (
        <tr className="idea-part-rows__item" id={'row-' + part.id}>
            <td className="table-index-cell">
                <TableIndexCell
                    rowIndex={rowIndex}
                    part={part}
                    parts={parts}
                    handleSelectPart={handleSelectPart}
                    refresh={refresh}
                    setParts={setParts}
                    setLoading={setLoading}
                />
            </td>
            <td>{part.nbPages}</td>
            <td>{part.skillName}</td>
            <td>{part.themeName}</td>
            <td>{part.collectionName} - {part.collectionIndex}</td>
            <td>{part.trainingText}</td>
            <td>
                <img
                    src={part.templateData.imageUrl}
                    alt={`part-template-image-${part.templateData.name}`}
                />
                <div className="template-info">
                    {part.templateData.name}
                    <FontAwesomeIcon icon={faEdit} onClick={() => setTemplateInModal(part.templateData)} />
                </div>
            </td>
            <td>
                {edittingField === 'activity' ? (
                    <div>
                        <Form.Control value={activity} as="textarea" rows={5}
                            onChange={e => setActivity(e.target.value)} />
                        <FontAwesomeIcon icon={faCheck} className="submit-field-value-icon" onClick={() => {
                            setEdittingField(null);
                            updatePart('activity', activity);
                        }} />
                        <FontAwesomeIcon icon={faClose} className="close-field-editor-icon" onClick={() => setEdittingField(null)} />
                    </div>
                ) : (
                    <div>
                        <div>
                            {part.activity && (
                                <Form.Control as="textarea" rows={10} readOnly value={part.activity} />
                            )}
                        </div>
                        <FontAwesomeIcon icon={faEdit} className="open-field-editor-icon" onClick={() => {
                            setActivity(part.activity);
                            setEdittingField('activity');
                        }} />
                    </div>
                )}
            </td>
            <td>
                {edittingField === 'note' ? (
                    <div>
                        <Form.Control value={note} as="textarea" rows={5}
                            onChange={e => setNote(e.target.value)} />
                        <FontAwesomeIcon icon={faCheck} className="submit-field-value-icon" onClick={() => {
                            setEdittingField(null);
                            updatePart('note', note);
                        }} />
                        <FontAwesomeIcon icon={faClose} className="close-field-editor-icon" onClick={() => setEdittingField(null)} />
                    </div>
                ) : (
                    <div>
                        <div>
                            {part.note && (
                                <Form.Control as="textarea" rows={10} readOnly value={part.note} />
                            )}
                        </div>
                        <FontAwesomeIcon icon={faEdit} className="open-field-editor-icon" onClick={() => {
                            setNote(part.note);
                            setEdittingField('note');
                        }} />
                    </div>
                )}
            </td>
            <td className="example-cell">
                {edittingField === 'example' ? (
                    <div>
                        <Form.Control value={example} as="textarea" rows={5}
                            onChange={e => setExample(e.target.value)} />
                        <FontAwesomeIcon icon={faCheck} className="submit-field-value-icon" onClick={() => {
                            setEdittingField(null);
                            updatePart('example', example);
                        }} />
                        <FontAwesomeIcon icon={faClose} className="close-field-editor-icon" onClick={() => setEdittingField(null)} />
                    </div>
                ) : (
                    <div>
                        <div>
                            {part.example && (
                                <Form.Control as="textarea" rows={10} readOnly value={part.example} />
                            )}
                        </div>
                        <FontAwesomeIcon icon={faEdit} className="open-field-editor-icon" onClick={() => {
                            setExample(part.example);
                            setEdittingField('example');
                        }} />
                    </div>
                )}

                <div className="btns">
                    <Button size="sm" className="mt-3" onClick={fetchPromptThenShow}>Show Prompt</Button>
                    <Button size="sm" className="mt-3" variant="info" onClick={goToPage}>Go to page</Button>
                    <Button size="sm" className="mt-3" onClick={autoGenerateExample}>Auto Generate</Button>
                </div>
            </td>

            {templateInModal && (
                <WorksheetMakerIframeModal
                    type="template"
                    id={templateInModal.id}
                    hideModal={() => setTemplateInModal(null)} />
            )}

            {promptInModal && (
                <Modal
                    show={true}
                    onHide={() => setPromptInModal(null)}
                    className="part-prompt-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Prompt
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control as="textarea" rows={10} value={promptInModal} readOnly />
                    </Modal.Body>
                </Modal>
            )}
        </tr>
    )
};

export default TableRow;