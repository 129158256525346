import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedSubject, removeSelectedSubject } from '../filtersSlice';
import './styles.scss';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../utils/query';

const FilterBySubjects = ({storeID}) => {
    const dispatch = useDispatch();

    const selectedSubjects = useSelector((state) => state.filters.selectedSubjects);

    const [subjects, setSubjects] = useState([]);

    useEffect(() => {
        fetchAllSubjectsByStore();
    }, []);

    const fetchAllSubjectsByStore = async() => {
        const res = await getDataFromAPI('resources/fetch-all-subjects-by-store/?storeID=' + storeID);
        setSubjects(res.data);
    }

    const changeSubject = (subject, isSelected) => {
        if (isSelected && selectedSubjects.indexOf(subject) === -1) {
            dispatch(addSelectedSubject(subject));
        } else if (!isSelected && selectedSubjects.indexOf(subject) > -1) {
            dispatch(removeSelectedSubject(subject));
        }
    }

    const TableColumnRowVilibilities = (
        <Popover>
            <Popover.Header as="h3">Subjects</Popover.Header>
            <Popover.Body className="popover-subjects">
                <Form>
                    {subjects.map((subject, index) => (
                        <Form.Check key={index} type="checkbox" id={'subject-' + index} label={subject}
                            checked={selectedSubjects.indexOf(subject) > -1}
                            onChange={e => changeSubject(subject, e.target.checked)} />
                    ))}
                </Form>
            </Popover.Body>
        </Popover>
    );

    return (
        <div className="filter-by-subjects">
            <OverlayTrigger trigger="click" placement="right" overlay={TableColumnRowVilibilities} rootClose>
                <div>
                    {selectedSubjects.length === 0 ? 'All Subjects' : selectedSubjects.join(', ')}
                </div>
            </OverlayTrigger>
        </div>
    );
}

export default FilterBySubjects;