import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

const CollectedResourceModal = ({ resource, hideModal, updateResource }) => {
    const [tptProductUrl, setTPTProductUrl] = useState(resource.tptProductUrl);

    return (
        <Modal
            show={true}
            onHide={hideModal}
            className="collected-resource-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Resource
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>
                        TPT URL
                    </Form.Label>
                    <Form.Control value={tptProductUrl} onChange={e => setTPTProductUrl(e.target.value)} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => updateResource('tptProductUrl', tptProductUrl)}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CollectedResourceModal;