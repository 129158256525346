import './styles.scss';
import Constant from '../../../../Constant';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

const TaskPreviewBoxTags = ({task, selectedTaskFlow}) => {
    return (
        <div className="task-preview-box-tags">
            {selectedTaskFlow !== Constant.MANUAL_CATEGORY_ID ? (
                <div>
                    {task.createIdeaDifficulty === 'hard' ? (
                        <Badge bg="danger">
                            <span>create: {task.createIdeaDifficulty}</span>
                        </Badge>
                    ) : (
                        task.createIdeaDifficulty === 'medium' ? (
                            <Badge bg="warning">
                                <span style={{color: 'black'}}>create: {task.createIdeaDifficulty}</span>
                            </Badge>
                        ) : (
                            <Badge bg="success">
                                <span>create: {task.createIdeaDifficulty}</span>
                            </Badge>
                        )
                    )}
                    {task.checkDataDifficulty === 'medium' ? (
                        <Badge bg="warning">
                            <span style={{color: 'black'}}>check: {task.checkDataDifficulty}</span>
                        </Badge>
                    ) : (
                        <Badge bg="success">
                            <span>check: {task.checkDataDifficulty}</span>
                        </Badge>
                    )}
                    {task.priority === 'high' && (
                        <Badge bg="warning">
                            <span style={{color: 'black'}}>build: prio</span>
                        </Badge>
                    )}
                    {task.blocked && (
                        <OverlayTrigger overlay={<Tooltip id={'task-' + task.id + '-blocked-reason'}>{task.blockedReasonTitle}</Tooltip>}>
                            <Badge bg="danger">Blocked</Badge>
                        </OverlayTrigger>
                    )}
                </div>
            ) : (
                <>
                    {task.priority === 'high' && (
                        <Badge bg="danger">
                            <span>High Prior</span>
                        </Badge>
                    )}
                    {task.taskDifficulty === 'hard' ? (
                        <Badge bg="danger">
                            <span>Hard</span>
                        </Badge>
                    ) : (
                        task.taskDifficulty === 'medium' ? (
                            <Badge bg="warning">
                                <span style={{color: 'black'}}>Medium</span>
                            </Badge>
                        ) : (
                            <Badge bg="easy">
                                <span>Easy</span>
                            </Badge>
                        )
                    )}
                </>
            )}
            {task.tags !== null && (
                task.tags.map((tag) => (
                    <Badge key={tag.id}>{tag.name}</Badge>
                ))
            )}
        </div>
    );
}

export default TaskPreviewBoxTags;