import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faClose } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../../utils/query';
import { Col, Nav, Row, Tab } from 'react-bootstrap';

import moment from 'moment';
import TaskStatisticHistory from '../task-statistic-history';

const TaskStatistic = ({ tasks, showTaskWidget, setShowTaskWidget }) => {
    const [taskStepHistories, setTaskStepHistories] = useState([]);
    const [selectedDuration, setSelectedDuration] = useState('yesterday');
    const [durationStartDate, setDurationStartDate] = useState();
    const [durationEndDate, setDurationEndDate] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchTaskStepHistories();
    }, [selectedDuration]);

    const fetchTaskStepHistories = async () => {
        let startDate, endDate;
        switch (selectedDuration) {
            case 'yesterday':
                startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last7days':
                startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last30days':
                startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            default:
                break;
        }
        if (startDate, endDate) {
            setLoading(true);
            setDurationStartDate(startDate);
            setDurationEndDate(endDate);
            const res = await getDataFromAPI('product-dev/task-step-histories/?durationStartDate=' + startDate + '&durationEndDate=' + endDate);
            setTaskStepHistories(res.data);
            setLoading(false);
        }
    }

    return (
        <div className="task-statistic-container">
            {showTaskWidget && showTaskWidget === 'statistic' && (
                <div className="task-statistic-detail-panel">
                    <Tab.Container id="select-statictic-duration" activeKey={selectedDuration} onSelect={(k) => setSelectedDuration(k)}>
                        <Row>
                            <Col md={4}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="yesterday">Yesterday</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="last7days">Last 7 days</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="last30days">Last 30 days</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="custom-duration">Custom Duration</Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                            </Col>
                            <Col md={8}>
                                {loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <Tab.Content>
                                        <Tab.Pane eventKey="yesterday">
                                            <TaskStatisticHistory
                                                durationStartDate={durationStartDate}
                                                durationEndDate={durationEndDate}
                                                allActivities={taskStepHistories} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="last7days">
                                            <TaskStatisticHistory
                                                durationStartDate={durationStartDate}
                                                durationEndDate={durationEndDate}
                                                allActivities={taskStepHistories} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="last30days">
                                            <TaskStatisticHistory
                                                durationStartDate={durationStartDate}
                                                durationEndDate={durationEndDate}
                                                allActivities={taskStepHistories} />
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="custom-duration"> */}
                                        {/* <TaskStatisticHistory activities={taskStepHistories} /> */}
                                        {/* </Tab.Pane> */}
                                    </Tab.Content>
                                )}
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            )}
            {showTaskWidget == 'statistic' ? (
                <span className="statistic-trigger" onClick={() => setShowTaskWidget(null)}>
                    <FontAwesomeIcon icon={faClose} />
                </span>
            ) : (
                <span className="statistic-trigger" onClick={() => setShowTaskWidget('statistic')}>
                    <FontAwesomeIcon icon={faChartBar} />
                </span>
            )}
        </div>
    )
}

export default TaskStatistic;