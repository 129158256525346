import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp, faChevronCircleDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import TaskPreviewBox from '../task-preview-box';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TaskBoard = ({ taskFlowSteps, tasks, stepStats, setTasks, setLoading, fetchTask, fetchTasks, setNotification, setActiveChatChannelID, ADDITION_FIELDS }) => {
    const [sortingColumn, setSortingColumn] = useState(null);
    const [sortingDirection, setSortingDirection] = useState('down');

    const updateSorting = col => {
        if (sortingColumn !== col) {
            setSortingColumn(col);
        } else if (sortingDirection === 'up') {
            setSortingDirection('down')
        } else {
            setSortingDirection('up');
        }
    }

    return (
        <div className="board-standard-container">
            {taskFlowSteps.map(step => (
                <div className="step-column" key={step.id}>
                    <div className="step-header" onClick={() => updateSorting(step.id)}>
                        <div className="step-label">
                            <span>
                                <span className="step-label-index">{step.pos}</span> {step.label.replaceAll(/_/g, ' ')}
                            </span>
                            <div className="sorting-icon">
                                {sortingColumn === step.id && sortingDirection === 'up' && (
                                    <FontAwesomeIcon icon={faChevronCircleUp} />
                                )}
                                {sortingColumn === step.id && sortingDirection === 'down' && (
                                    <FontAwesomeIcon icon={faChevronCircleDown} />
                                )}
                            </div>
                        </div>
                        {stepStats[step.id] && (
                            <div className="step-stats">
                                <div>{step.roleLabels.join(' + ')} - {tasks.filter(task => task.step === step.id).length} tasks</div>
                                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={(props) => (
                                    <Tooltip id={'tooltip-step-' + step.id} {...props}>
                                        {step.requirementLabels.map(label => label.replaceAll(/_/g, ' ')).join(', ')}
                                    </Tooltip>
                                )}>
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </OverlayTrigger>
                            </div>
                        )}
                    </div>

                    {tasks.filter(task => task.step === step.id).map(task => (
                        <div className="task-preview-container" key={task.id}>
                            <TaskPreviewBox
                                displayMode="standard"
                                task={task}
                                tasks={tasks}
                                setTasks={setTasks}
                                setLoading={setLoading}
                                step={step}
                                taskFlowSteps={taskFlowSteps}
                                fetchTask={fetchTask}
                                fetchTasks={fetchTasks}
                                setNotification={setNotification}
                                setActiveChatChannelID={setActiveChatChannelID}
                                ADDITION_FIELDS={ADDITION_FIELDS} />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default TaskBoard;