import { useState, useEffect } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { getDataFromAPI } from '../../../utils/query';

import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { addTagToSelectedTags, emtpySelectedTags, removeTagFromSelectedTags } from '../autoContentSlice';
import TemplateList from './template-list';
import WorksheetMakerIframeModal from '../worksheet-maker-iframe-modal';
import CollectionList from './collection-list';
import { fetchCategories } from '../../../utils/commonUtil';
import CollectionInfoModal from './collection-info-modal';
import LeftContent from './left-content';

const TemplatesManagement = ({ selectedTemplates = [], setSelectedTemplates, selectedCollections = [], setSelectedCollections, usedTemplateIDs = [], displayMode = 'display', showCollectionsTab = true, showTemplatesTab = true }) => {
    const dispatch = useDispatch();

    const [keyword, setKeyword] = useState('');
    const [templates, setTemplates] = useState([]);
    const [collections, setCollections] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [suggestedTags, setSuggestedTags] = useState([]);
    const [showWorksheetMakerIframeModal, setShowWorksheetMakerIframeModal] = useState(false);
    const [showOnlyActiveTemplates, setShowOnlyActiveTemplates] = useState(true);

    const [showNewCollectionModal, setShowNewCollectionModal] = useState(false);
    const [showOnlyActiveCollections, setShowOnlyActiveCollections] = useState(true);

    const templatePageSize = 30;
    const [currentTemplatePage, setCurrentTemplatePage] = useState(1);
    const [totalTemplatePages, setTotalTemplatePages] = useState(1);
    const [nbTemplateResults, setNbTemplateResutls] = useState(0);

    const collectionPageSize = 30;
    const [currentCollectionPage, setCurrentCollectionPage] = useState(1);
    const [totalCollectionPages, setTotalCollectionPages] = useState(1);
    const [nbCollectionResults, setNbCollectionResutls] = useState(0);

    const currentTab = showTemplatesTab ? 'template' : 'collection';
    const [activeTab, setActiveTab] = useState(currentTab);

    const [filterNoCategories, setFilterNoCategories] = useState([]);

    const { selectedTags } = useSelector(state => state.autoContent);

    useEffect(() => {
        fetchAllCategories();
        fetchCollections();
    }, []);

    useEffect(() => {
        const delaySearchInputDebounce = setTimeout(() => {
            if (keyword) {
                let suggestedTags = [];
                for (let i = 0; i < categories.length; i++) {
                    const catSuggestedTags = categories[i].tags.filter(tag => {
                        if (tag.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
                            return true;
                        }
                        if (keyword.toLowerCase().indexOf(tag.name.toLowerCase()) > -1) {
                            return true;
                        }
                        return false;
                    });
                    if (catSuggestedTags.length > 0) {
                        suggestedTags = suggestedTags.concat({
                            category: categories[i].name,
                            tags: catSuggestedTags
                        });
                    }
                }
                setSuggestedTags(suggestedTags);
            } else {
                setSuggestedTags([]);
            }
        }, 500);
        return () => clearTimeout(delaySearchInputDebounce);
    }, [keyword, 500]);

    const fetchAllCategories = async () => {
        const data = await fetchCategories();
        setCategories(data);
    }

    useEffect(() => {
        if (activeTab === 'template') {
            fetchTemplates();
        }
    }, [selectedTags, showOnlyActiveTemplates]);

    useEffect(() => {
        if (activeTab === 'collection') {
            fetchCollections();
        }
    }, [selectedTags, showOnlyActiveCollections]);

    const handleTabSelect = (selectedTab) => {
        setKeyword('');
        setActiveTab(selectedTab);
        dispatch(emtpySelectedTags());
        setFilterNoCategories([]);

        if (selectedTab === 'template') {
            fetchTemplates(null, currentTemplatePage, '');
        } else {
            fetchCollections('');
        };
    }

    const resetTemplatesPagination = () => {
        setCurrentTemplatePage(1);
        setTotalTemplatePages(1);
    }

    const resetCollectionsPagination = () => {
        setCurrentCollectionPage(1);
        setTotalCollectionPages(1);
    }

    const fetchTemplates = async (e, pageIndex = currentTemplatePage, provideKeyword = null) => {
        let searchKeyword = provideKeyword !== null ? provideKeyword : keyword;

        if (e) {
            e.preventDefault();
        }
        setLoading(true);
        try {
            const tagIds = selectedTags.map(tag => tag.id);
            let url = `auto-content/templates/?keyword=${searchKeyword}&tags=${tagIds.join(',')}&page=${pageIndex}&page_size=${templatePageSize}&filterNoCategories=${filterNoCategories.map(cat => cat.name).join(',')}`;
            if (showOnlyActiveTemplates) {
                console.log('only ', showOnlyActiveTemplates);
                url += '&onlyActiveTemplates=true';
            }
            console.log('url', url);
            const res = await getDataFromAPI(url);
            if (res) {
                const data = res.data;
                setNbTemplateResutls(data.count)
                setTotalTemplatePages(Math.ceil(data.count / templatePageSize))
                const templates = data.results;
                templates.sort((a, b) => b.id - a.id);
                setTemplates(templates);
            }
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
        setLoading(false);
    }

    const fetchCollections = async (e, pageIndex = currentCollectionPage, provideKeyword = null) => {
        let searchKeyword = provideKeyword !== null ? provideKeyword : keyword;
        if (e) {
            e.preventDefault();
        }

        setLoading(true);
        const tagIds = selectedTags.map(tag => tag.id);

        let url = `auto-content/collections/?keyword=${searchKeyword}&tags=${tagIds.join(',')}&filterNoCategories=${filterNoCategories.map(cat => cat.name).join(',')}&page=${pageIndex}&page_size=${collectionPageSize}`;

        if (showOnlyActiveCollections) {
            url += '&onlyActiveCollections=true';
        }
        const res = await getDataFromAPI(url);

        if (res) {
            const data = res.data;
            setNbCollectionResutls(data.count)
            setTotalCollectionPages(Math.ceil(data.count / collectionPageSize))
            const results = data.results;
            results.sort((a, b) => b.id - a.id);
            setCollections(results);
        }
        setLoading(false);
    }

    const handleSubmitSearchTemplateByTag = (e) => {
        resetTemplatesPagination();
        fetchTemplates(e, 1);
    }

    const handleSubmitSearchCollection = (e) => {
        resetCollectionsPagination();
        fetchCollections(e, 1);
    }

    const defineModeSearch = (e) => {
        e.preventDefault();
        if (activeTab === 'template') {
            handleSubmitSearchTemplateByTag(e);
        } else {
            handleSubmitSearchCollection(e);
        }
    }

    useEffect(() => {
        const refreshRightContent = async () => {
            const removedTags = [...selectedTags].filter(((tag) => filterNoCategories.some((cat) => tag.categoryName === cat.name)));
            removedTags.forEach((removedTag) => dispatch(removeTagFromSelectedTags({ id: removedTag.id })));
            if (activeTab === 'template') {
                await fetchTemplates(null, 1);
            } else if (activeTab === 'collection') {
                await fetchCollections(null, 1);
            }
        };

        refreshRightContent();
    }, [filterNoCategories]);

    const toggleFilterNoCategoriesList = async (category) => {
        const isSelected = filterNoCategories.some(cat => cat.id === category.id);
        if (isSelected) {
            setFilterNoCategories((prev) => prev.filter((cat) => cat.id !== category.id));
        } else {
            setFilterNoCategories((prev) => ([...prev, category]))
        };
    }

    return (
        <Container className="templates-management">
            <Row>
                <Col md={3}>
                    <LeftContent
                        categories={categories}
                        setCategories={setCategories}
                        selectedTags={selectedTags}
                        showOnlyActiveTemplates={showOnlyActiveTemplates}
                        showOnlyActiveCollections={showOnlyActiveCollections}
                        setShowOnlyActiveTemplates={setShowOnlyActiveTemplates}
                        setShowOnlyActiveCollections={setShowOnlyActiveCollections}
                        activeTab={activeTab}
                        filterNoCategories={filterNoCategories}
                        fetchAllCategories={fetchAllCategories}
                        toggleFilterNoCategoriesList={toggleFilterNoCategoriesList}
                    />
                </Col>
                <Col md={9}>
                    <div className="templates-header">
                        <Form onSubmit={defineModeSearch} className="search-form">
                            <InputGroup controlId="activity">
                                <Form.Control
                                    type="text"
                                    placeholder="Enter keyword"
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                />
                                <Button variant="primary" onClick={defineModeSearch}>
                                    Search
                                </Button>
                                <FontAwesomeIcon icon={faClose} className="clear-keyword-icon" onClick={() => setKeyword('')} />
                                {suggestedTags.length > 0 && (
                                    <div className="suggested-tags">
                                        {suggestedTags.map((cat, catIndex) => (
                                            cat.tags.map((tag, tagIndex) => (
                                                <div key={catIndex + '-' + tagIndex} onClick={() => dispatch(addTagToSelectedTags({ tag }))}>
                                                    {cat.category} - {tag.name}
                                                </div>
                                            ))
                                        ))}
                                    </div>
                                )}
                            </InputGroup>
                        </Form>

                        {displayMode === 'display' && (
                            <div>
                                <Button variant="success" onClick={e => {
                                    if (e.ctrlKey || e.metaKey) {
                                        window.open(process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=template&id=', '_blank', 'noopener,noreferrer');
                                    } else {
                                        setShowWorksheetMakerIframeModal(true);
                                    }
                                }}>New Template</Button>
                                <Button onClick={() => setShowNewCollectionModal(true)}>New Collection</Button>
                            </div>
                        )}

                    </div>

                    <div className="nb-results">{ activeTab === 'template' ? `${nbTemplateResults} templates` : `${nbCollectionResults} collections`}</div>

                    <Tabs fill activeKey={activeTab} onSelect={handleTabSelect}>
                        {showTemplatesTab && (
                            <Tab eventKey="template" title={'Template (' + templates.length + ')'}>
                                <TemplateList
                                    templates={templates}
                                    setTemplates={setTemplates}
                                    selectedTemplates={selectedTemplates}
                                    usedTemplateIDs={usedTemplateIDs}
                                    setSelectedTemplates={setSelectedTemplates}
                                    categories={categories}
                                    fetchTemplates={fetchTemplates}
                                    fetchAllCategories={fetchAllCategories}
                                    showOnlyActiveTemplates={showOnlyActiveTemplates}
                                    currentPage={currentTemplatePage}
                                    resetTemplatesPagination={resetTemplatesPagination}
                                    totalPages={totalTemplatePages}
                                    handlePageChange={(pageIndex) => {
                                        setCurrentTemplatePage(pageIndex);
                                        fetchTemplates(null, pageIndex);
                                    }}
                                    showPagination={true}
                                />
                            </Tab>
                        )}
                        {showCollectionsTab && (
                            <Tab eventKey="collection" title={'Collection (' + collections.length + ')'}>
                                <CollectionList
                                    collections={collections}
                                    setCollections={setCollections}
                                    selectedCollections={selectedCollections}
                                    setSelectedCollections={setSelectedCollections}
                                    fetchCollections={fetchCollections}
                                    refreshCategories={fetchAllCategories}
                                    categories={categories}
                                    fetchAllCategories={fetchAllCategories}
                                    setLoading={setLoading}
                                    currentPage={currentCollectionPage}
                                    resetCollectionsPagination={resetCollectionsPagination}
                                    totalPages={totalCollectionPages}
                                    handlePageChange={(pageIndex) => {
                                        setCurrentCollectionPage(pageIndex);
                                        fetchCollections(null, pageIndex);
                                    }}
                                    showPagination={true}
                                />
                            </Tab>
                        )}
                    </Tabs>
                </Col>
            </Row>

            {showWorksheetMakerIframeModal && (
                <WorksheetMakerIframeModal
                    type="template"
                    id=""
                    hideModal={() => {
                        setShowWorksheetMakerIframeModal(false);
                        fetchTemplates();
                    }}
                />
            )}

            {showNewCollectionModal && (
                <CollectionInfoModal
                    collection={null}
                    hideModal={() => setShowNewCollectionModal(false)}
                    collections={collections}
                    setCollections={setCollections} />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default TemplatesManagement;